import './App.css';
import Login from './assets/js/Login/Loginv2';
import React from 'react';
function App() {
	return (
		<div className="App">
			<Login/>
		</div>
	);
}

export default App;
