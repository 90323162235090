import React, { Component } from 'react'
import {Row, Col, Input, Button, Select, Tag,Tabs} from 'antd'
import Modal from 'react-modal'
import axios from 'axios'
import '../../css/Dashboard.css'
import { base64_encode, base64_decode, generateKey } from '../Utilities/Utilities';


const Option = Select
const { TabPane } = Tabs;


class Domain extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            //liste
            liste_base: [],
            liste_base_user: [],
            liste_router: [],
            liste_user: [],
            user_base_list: [],
            displayAdmin: 'none',
            displayUpdate: 'none',
            displayAdd: 'block',
            user_id: base64_decode(localStorage.getItem(base64_encode('user_id'))),
            username: base64_decode(localStorage.getItem(base64_encode('username'))),
            apikey: base64_decode(localStorage.getItem(base64_encode('apikey'))),
            statsapikey: base64_decode(localStorage.getItem(base64_encode('stats_apikey'))),
            endpoint: base64_decode(localStorage.getItem(base64_encode('endpoint'))),
            email: base64_decode(localStorage.getItem(base64_encode('email'))),
            roles: base64_decode(localStorage.getItem(base64_encode('roles'))),
            user_random_id: null,
            //filter
            keywords: null,
            //loading
            loadingRefresh: false,
            loadingUpdate: false,
            // other data
            base_id: null,
            base_name: null,
            base_url: null,
            base_hosting: null,
            base_jumping: null,
            base_jumping_url: null,
            base_abreviation: null,
            base_routeur_id: null,
            base_stats_id: null,
            base_es_id: null,
            base_jumping_status: null,
            base_status: null,
            //base godaddy detail
            base_gHost : null,
            base_gTrackLink : null,
            base_gTrackLinkUser :null,
            base_gTrackLinkMdp : null,
            base_gTrackImg : null,
            base_gTrackImgUser : null,
            base_gTrackImgMdp : null,
            //base ovh detail
            base_oFtpHost : null,
            base_oTrackLink : null,
            base_oTrackLinkUser: null,
            base_oTrackLinkMdp :null,
            base_oTrackImg : null,
            base_oTrackImgUser :null,
            base_oTrackImgMdp : null,
            base_bimi: null,
            //modal 
            isCreate: false,
            isAssigned: false
        }
    }

    componentDidMount(){
        localStorage.setItem(base64_encode('def_dash_pref'), "3")
        if(base64_decode(localStorage.getItem(base64_encode('roles'))) === 'Admin'){
            this.setState({isAdmin: true,displayAdmin: 'block'})
        }
        this.getListBaseAll()
        this.getListUser()
        this.getListRouter()
    }

    getListBaseUser(userid){
        var fd = new FormData()
        fd.append('user_id', this.state.user_id)
        fd.append('apikey',this.state.apikey)
        fd.append('user', userid)
        axios.post(process.env.REACT_APP_ENDPOINT_BASE_ADMIN_USER_MANAGEMENT, fd)
        .then(res => {
            var base_user = []
            res.data.map(item => {
                base_user.push(item['id'])
            })
            this.setState({
                user_base_list : base_user
            })
        })
		// if(localStorage.getItem(base64_encode('base_user')) !== null){
        //     var base_list = []
        //     var data = JSON.parse(base64_decode(localStorage.getItem(base64_encode('base_user'))))
        //     data.map(item => {
        //         base_list.push(item['id'])
        //     })
        //     console.log(base_list)
        //     this.setState({
        //         user_base_list: base_list
        //     })
			
		// }else{
		// 	var url = this.state.endpoint + 'api/base/liste'
		// 	var fd = new FormData()
		// 	fd.append('user_id', user_id)
		// 	fd.append('apikey', this.state.apikey)
		// 	axios.post(url, fd)
		// 	.then(res => {
		// 		var base_list = []
        //         res.data.map(item => {
        //             base_list.push(item['id'])
        //         })
        //         this.setState({
        //             user_base_list: base_list
        //         })
					
        //     })
		// }
    }

	getListBaseAll(){
        if (localStorage.getItem(base64_encode('all_base')) !== null) {
            console.log(JSON.parse(base64_decode(localStorage.getItem(base64_encode('all_base')))))
            this.setState({
                liste_base: JSON.parse(base64_decode(localStorage.getItem(base64_encode('all_base'))))
            })
        } else {
            var fd = new FormData()
            fd.append('user_id', this.state.user_id)
            fd.append('apikey', this.state.apikey)
            axios.post(process.env.REACT_APP_ENDPOINT_BASE_ALL, fd)
                .then(res => {
                    localStorage.setItem(base64_encode('all_base'), base64_encode(JSON.stringify(res.data)))
                    this.setState({
                        liste_base: res.data
                    })
                })
        }
    }

    getListRouter(){
        if(localStorage.getItem(base64_encode('all_router')) !== null){
            this.setState({liste_router : JSON.parse(base64_decode(localStorage.getItem(base64_encode('all_router'))))})
            return
        }else{
            var fd = new FormData()
            fd.append('user_id', this.state.user_id)
            fd.append('apikey', this.state.apikey)
            axios.post(process.env.REACT_APP_ENDPOINT_ROUTER_LIST, fd)
            .then(res => {
                localStorage.setItem(base64_encode('all_router'), base64_encode(JSON.stringify(res.data)))
                this.setState({liste_router: res.data})
            })
        }
    }

    onFilterDomainList(e){
        this.setState({keywords: e.target.value})
		let result = []
		if(e.target.value !== ''){
			this.state.liste_base.map((base) => {
				if(base.url.indexOf(e.target.value) > -1 || base.basename.toLowerCase().indexOf(e.target.value) > -1){
					result.push(base)
					this.setState({liste_base: result})
                }
                return null
            })
            
		}else{
			this.setState({
                liste_base: JSON.parse(base64_decode(localStorage.getItem(base64_encode('all_base'))))
            })
		}
    }

    onRequestCloseAddBase(){
        this.setState({
            //modal close
            isCreate: false,
            base_gHost : null,
            base_gTrackLink : null,
            base_gTrackLinkUser : null,
            base_gTrackLinkMdp : null,
            base_gTrackImg : null,
            base_gTrackImgUser : null,
            base_gTrackImgMdp : null,
            //ovh data
            base_oTrackLink : null,
            base_oTrackLinkUser : null,
            base_oTrackLinkMdp : null,
            base_oTrackImg : null,
            base_oTrackImgUser : null,
            base_oTrackImgMdp : null,
            base_oFtpHost : null,
            //other info
            base_id: null,
            base_name: null,
            base_url: null,
            base_hosting: null,
            base_jumping_status: null,
            base_abreviation: null,
            base_routeur_id: null,
            base_stats_id: null,
            base_es_id: null,
            base_status: null,
            base_jumping_url: null,
            base_bimi: null,
        })
    }

    getBaseDetail(base_id){
        var fd = new FormData()
        fd.append('user_id', this.state.user_id)
        fd.append('apikey',this.state.apikey)
        fd.append('baseid',base_id)
        axios.post(process.env.REACT_APP_ENDPOINT_BASE_DETAIL, fd)
        .then(res => {
            this.setState({
                //godaddy data
                base_gHost : res.data[0]['godaddyftphost'],
                base_gTrackLink : res.data[0]['godaddytrackedlink'],
                base_gTrackLinkUser : res.data[0]['godaddytrackedlinkuser'],
                base_gTrackLinkMdp : res.data[0]['godaddytrackedlinkmdp'] !== null ? base64_decode(res.data[0]['godaddytrackedlinkmdp']) : null,
                base_gTrackImg : res.data[0]['godaddytrackedimglink'],
                base_gTrackImgUser : res.data[0]['godaddytrackedimguser'],
                base_gTrackImgMdp : res.data[0]['godaddytrackedimgmdp'] !== null ? base64_decode(res.data[0]['godaddytrackedimgmdp']) : null,
                //ovh data
                base_oTrackLink : res.data[0]['ovhtrackedlink'],
                base_oTrackLinkUser : res.data[0]['ovhtrackedlinkuser'],
                base_oTrackLinkMdp : res.data[0]['ovhtrackedlinkmdp'] !== null ? base64_decode(res.data[0]['ovhtrackedlinkmdp']) : null,
                base_oTrackImg : res.data[0]['ovhtrackedimglink'],
                base_oTrackImgUser : res.data[0]['ovhtrackedimguser'],
                base_oTrackImgMdp : res.data[0]['ovhtrackedimgmdp'] !== null ? base64_decode(res.data[0]['ovhtrackedimgmdp']) : null,
                base_oFtpHost : res.data[0]['ovhftphost'],
                //other info
                base_hosting: res.data[0]['principal'],
                base_jumping_url: res.data[0]['jp'],
            })
            
        })
    }

    onUpdateDatabase(){
        this.setState({loadingUpdate: true})
        var fd = new FormData()
        fd.append('user_id', this.state.user_id)
        fd.append('apikey', this.state.apikey)
        if(this.state.displayAdd === 'block'){
            fd.append('action', 'add')
        }else{
            fd.append('action', 'update')
        }
        fd.append('baseid',this.state.base_id)
        fd.append('baseurl', this.state.base_url)
        fd.append('basename',this.state.base_name)
        fd.append('routerid',this.state.base_routeur_id)
        fd.append('status_base', this.state.base_status)
        fd.append('jp', this.state.base_jumping_url)
        fd.append('status_jumping', this.state.base_jumping_status)
        fd.append('abreviation', this.state.base_abreviation)
        fd.append('logo', this.state.base_bimi)
        fd.append('statsid', this.state.base_stats_id)
        fd.append('es_id', this.state.base_es_id)
        fd.append('principal', this.state.base_hosting)
        fd.append('trackingGodaddy',this.state.base_gTrackLink)
        fd.append('trackingGodaddyUser',this.state.base_gTrackLinkUser)
        fd.append('trackingGodaddyMdp', this.state.base_gTrackLinkMdp)
        fd.append('trackingGodaddyImg', this.state.base_gTrackImg)
        fd.append('trackingGodaddyImgUser', this.state.base_gTrackImgUser)
        fd.append('trackingGodaddyImgMdp', this.state.base_gTrackImgMdp)
        fd.append('trackingOvh', this.state.base_oTrackLink)
        fd.append('trackingOvhUser', this.state.base_oTrackLinkUser)
        fd.append('trackingOvhMdp', this.state.base_oTrackLinkMdp)
        fd.append('trackingOvhImg', this.state.base_oTrackImg)
        fd.append('trackingOvhImgUser', this.state.base_oTrackImgUser)
        fd.append('trackingOvhImgMdp', this.state.base_oTrackImgMdp)
        fd.append('ovhftphost', this.state.base_oFtpHost)
        fd.append('godaddyftphost', this.state.base_gHost)
        axios.post(process.env.REACT_APP_ENDPOINT_BASE_UPDATE, fd)
        .then(res => {
            localStorage.removeItem(base64_encode('all_base'))
            setTimeout(() => {
                window.location.reload()
            },1000)
        })
    }

    onRequestCloseAssignBase(){
        this.setState({
            isAssigned: false,
            base_gHost : null,
            base_gTrackLink : null,
            base_gTrackLinkUser : null,
            base_gTrackLinkMdp : null,
            base_gTrackImg : null,
            base_gTrackImgUser : null,
            base_gTrackImgMdp : null,
            //ovh data
            base_oTrackLink : null,
            base_oTrackLinkUser : null,
            base_oTrackLinkMdp : null,
            base_oTrackImg : null,
            base_oTrackImgUser : null,
            base_oTrackImgMdp : null,
            base_oFtpHost : null,
            //other info
            base_id: null,
            base_name: null,
            base_url: null,
            base_hosting: null,
            base_jumping_status: null,
            base_abreviation: null,
            base_routeur_id: null,
            base_stats_id: null,
            base_es_id: null,
            base_status: null,
            base_jumping_url: null,
            base_bimi: null,
            user_random_id: null,
            user_base_list: []
        })
    }

    getListUser(){
        if(localStorage.getItem(base64_encode('all_user')) !== null){
            console.log(JSON.parse(base64_decode(localStorage.getItem(base64_encode('all_user')))))

            this.setState({
                liste_user: JSON.parse(base64_decode(localStorage.getItem(base64_encode('all_user'))))
            })
        }else{
            console.log('fetching data')
            var fd = new FormData()
            fd.append('user_id', this.state.user_id )
            fd.append('apikey', this.state.apikey)
            axios.post(process.env.REACT_APP_ENDPOINT_USER_LIST, fd)
            .then(res => {
                localStorage.setItem(base64_encode('all_user'), base64_encode(JSON.stringify(res.data)))
                this.setState({liste_user: res.data})
            })
        }
    }

    onAssignedDatabaseUser(){
        this.setState({loadingUpdate: true})
        var fd = new FormData()
        fd.append('user_id', this.state.user_id)
        fd.append('apikey', this.state.apikey)
        fd.append('user', this.state.user_random_id)
        fd.append('baseid', this.state.user_base_list)
        axios.post(process.env.REACT_APP_ENDPOINT_BASE_ASSIGN, fd)
        .then(res => {
            setTimeout(() => {
                window.location.reload()
            },1000)
        })
    }
 
    
    render() {
        const bg = {
            overlay: {
                backgroundColor: "rgba(0, 0, 0, .5)",
            }
        }

        return (
             <div style={{backgroundColor: '#fff', marginTop: '0px', height: '92vh', overflowY: 'hidden'}}>
                <div style={{marginTop: 10}}>
                    <Row style={{padding: 10}}> 
                        <Col span={1} style={{textAlign: 'center', padding: 5}}>
                            <span>Search</span>
                        </Col>
                        <Col span={6}>
                            <Input
                                value={this.state.keywords}
                                size="medium"
                                style={{border: '1px solid gray', color: '#192a56',fontWeight: 700, textAlign: 'center'}}
                                onChange={this.onFilterDomainList.bind(this)}
                            />
                        </Col>
                        <Col span={3} style={{textAlign: 'center'}}>
                            <Button
                                loading={this.state.loadingRefresh}
                                className="btn btn-secondary"
                                style={{width: '75%', fontSize: 16}}
                                onClick={() =>{
                                    localStorage.removeItem(base64_encode('base_user'))
                                    localStorage.removeItem(base64_encode('all_base'))
                                    this.setState({loadingRefresh: true})
                                    setTimeout(() => {
                                        window.location.reload()
                                    },2000)
                                }}
                            >
                                Refresh
                            </Button>
                        </Col>
                        <Col span={3} style={{textAlign: 'center', display: `${this.state.displayAdmin}`}}>
                            <Button
                                className="btn btn-primary"
                                style={{width: '75%', fontSize: 16}}
                                onClick={() =>{
                                    this.setState({
                                        isCreate: true,
                                        modalTitle: 'ADD',
                                        displayAdd: 'block',
                                        displayUpdate: 'none'
                                    })
                                }}
                            >
                                +Add
                            </Button>
                        </Col>
                        <Col span={4} style={{textAlign: 'center', display: `${this.state.displayAdmin}`}}>
                            <Button
                                className="btn btn-success"
                                style={{width: '80%', fontSize: 16}}
                                onClick={() =>{
                                    this.setState({
                                        isAssigned: true,
                                        user_name: '',
                                        user_apikey: '',
                                        user_stats_apikey: '',
                                        user_email : '',
                                        user_roles: '',
                                        modalAction : 'NEW',
                                        modalCTA : 'SUBMIT',
                                        user: [],
                                    })
                                }}
                            >
                                Database Management
                            </Button>
                        </Col>
                    </Row>
                    <Row style={{height: '84vh', overflow: 'scroll', overflowX: 'hidden'}}>
                        <table className="table">
                            <thead className="thead-dark">
                                <tr>
                                    <th style={{textAlign: 'center', width: '5%'}}>ID</th>
                                    <th style={{textAlign: 'center', width: '15%'}}>CREATE DATE</th>
                                    <th style={{textAlign: 'center', width: '10%'}}>NAME</th>
                                    <th style={{textAlign: 'center', width: '3%'}}>ABREVIATION</th>
                                    <th style={{textAlign: 'center', width: '10%'}}>URL</th>
                                    <th style={{textAlign: 'center', width: '10%'}}>ROUTER</th>
                                    <th style={{textAlign: 'center', width: '10%'}}>STATS ID</th>
                                    <th style={{textAlign: 'center', width: '10%'}}>ROUTER ID</th>
                                    <th style={{textAlign: 'center', width: '10%'}}>JUMPING PAGE</th>
                                    <th style={{textAlign: 'center', width: '60%'}}>ACTION</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                     this.state.liste_base && this.state.liste_base.map(item => {
                                        var date = new Date(Date.parse(item['createDate']))
                                        var day = date.getDate()
                                        var month = date.getMonth() + 1
                                        var year = date.getFullYear()
                                        var hours = date.getHours()
                                        var minutes = date.getMinutes()
                                        var second = date.getSeconds()
                                        if (month < 10) {month = "0" + month.toString()}
                                        if (minutes < 10) {minutes = "0" + minutes.toString()}
                                        if (second < 10) {second = "0" + second.toString()}
                                        if (day < 10) {day = "0" + day.toString()}
                                        var create_date = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + second
                                        var key = generateKey()
                                        return(
                                            <tr key={key}>
                                               <td style={{textAlign: 'center', width: '5%'}}>{item.id}</td>
                                                <td style={{textAlign: 'center', width: '8%'}}>{create_date}</td>
                                                <td style={{textAlign: 'center', width: '15%'}}>{item.basename}</td>
                                                <td style={{textAlign: 'center', width: '5%'}}>{item.abreviation}</td>
                                                <td style={{textAlign: 'center', width: '10%'}}>{item.url}</td>
                                                <td style={{textAlign: 'center', width: '10%'}}>{item.router_name}</td>
                                                <td style={{textAlign: 'center', width: '5%'}}>{item.stats_id}</td>
                                                <td style={{textAlign: 'center', width: '5%'}}>{item.routeurID}</td>
                                                <td style={{textAlign: 'center', width: '5%'}}>{parseInt(item.redirect) === 1 ? <Tag color="green">ACTIVED</Tag> : <Tag color="GRAY">INACTIVED</Tag>}</td>
                                                <td style={{textAlign: 'center', width: '25%' }}>
                                                    <Row>
                                                        <Col span={24} style={{textAlign: 'center',display: `${this.state.displayAdmin}`}}>
                                                            <Button
                                                                className="btn btn-primary"
                                                                style={{fontSize: 16}}
                                                                onClick={() => {
                                                                    this.getBaseDetail(item.id)
                                                                    this.setState({
                                                                        isCreate: true,
                                                                        modalTitle: 'EDIT',
                                                                        base_id: item.id,
                                                                        base_name: item.basename,
                                                                        base_url: item.url,
                                                                        base_routeur_id: item.id_router,
                                                                        base_es_id: item.routeurID,
                                                                        base_stats_id: item.stats_id,
                                                                        displayAdd: 'none',
                                                                        displayUpdate: 'block',
                                                                        base_jumping_status: parseInt(item.redirect),
                                                                        base_abreviation: item.abreviation,
                                                                        base_status: parseInt(item.status),
                                                                        base_bimi: item.logo
                                                                    })
                                                                }}
                                                            >
                                                                EDIT
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </td> 
                                            </tr>
                                        )
                                     })
                                }
                            </tbody>
                        </table>
                    </Row>
                </div>
                <Modal
                    id="new-base"
                    isOpen={this.state.isCreate}
                    onRequestClose={this.onRequestCloseAddBase.bind(this)}
                    shouldCloseOnOverlayClick={true}
                    style={bg}
					ariaHideApp={false}
                >
                    <div>
                        <div
                            style={{background: '#001529', marginTop: '0px', textAlign: 'center', width: '100%', marginLeft: '0px'}}
                        >
                            <h3 style={{color: "#fff", padding: 5}}>{this.state.modalTitle} DATABASE</h3>
                        </div>
                        <span
                            style={{color: "#C23616", marginTop: '-49px', fontSize: 35, float: 'right', cursor: 'pointer'}} onClick={this.onRequestCloseAddBase.bind(this)}
                        >
                            X
                        </span>
                    </div>
                    <div>
                        <Row>
                            <Col span={8}>
                                <Row>
                                    <Col span={4} style={{padding: 5, textAlign: 'center', fontSize: 16}}>Url</Col>
                                    <Col span={20}>
                                        <Input
                                            size="medium"
                                            style={{color: '#192a56', fontWeight: 700, textAlign: 'center'}}
                                            value={this.state.base_url}
                                            onChange={(e) => { this.setState({base_url: e.target.value})}}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={8}>
                                <Row>
                                    <Col span={6} style={{padding: 5, textAlign: 'center', fontSize: 16}}>Name</Col>
                                    <Col span={18}>
                                        <Input
                                            size="medium"
                                            style={{color: '#192a56', fontWeight: 700, textAlign: 'center'}}
                                            value={this.state.base_name}
                                            onChange={(e) => { this.setState({base_name: e.target.value})}}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={8}>
                                <Row>
                                    <Col span={7} style={{padding: 5, textAlign: 'center', fontSize: 16}}>Router</Col>
                                    <Col span={17}>
                                        <Select
                                            size="medium"   
                                            style={{width: '100%',  color: '#192a56',fontWeight: 700, textAlign: 'center' }}
                                            value={this.state.base_routeur_id}
                                            onChange={(value) => {
                                                this.setState({base_routeur_id: value})
                                            }}
                                        >
                                            {
                                                this.state.liste_router.map(item => {
                                                    return(
                                                        <Option key={item.id} value={item.id}>{item['name']}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col span={8}>
                                <Row>
                                    <Col span={4} style={{padding: 5, textAlign: 'center', fontSize: 16}}>Abr.</Col>
                                    <Col span={20}>
                                        <Input
                                            size="medium"
                                            style={{color: '#192a56', fontWeight: 700, textAlign: 'center', width: '98%'}}
                                            value={this.state.base_abreviation}
                                            onChange={(e) => { this.setState({base_abreviation: e.target.value})}}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={8}>
                                <Row>
                                    <Col span={5} style={{padding: 5, textAlign: 'center', fontSize: 16}}>Stats ID</Col>
                                    <Col span={18}>
                                        <Input
                                            size="medium"
                                            style={{color: '#192a56', fontWeight: 700, textAlign: 'center'}}
                                            value={this.state.base_stats_id}
                                            onChange={(e) => { this.setState({base_stats_id: e.target.value})}}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={8}>
                                <Row>
                                    <Col span={6} style={{padding: 5, textAlign: 'center', fontSize: 16}}>ES ID</Col>
                                    <Col span={16}>
                                        <Input
                                            size="medium"
                                            style={{color: '#192a56', fontWeight: 700, textAlign: 'center'}}
                                            value={this.state.base_es_id}
                                            onChange={(e) => { this.setState({base_es_id: e.target.value})}}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col span={8}>
                                <Row>
                                    <Col span={4} style={{padding: 5, textAlign: 'center', fontSize: 16}}>Status</Col>
                                    <Col span={20}>
                                        <Select
                                            style={{color: '#192a56', fontWeight: 700, textAlign: 'center', width: '98%'}}
                                            value={this.state.base_status}
                                            onChange={(value) => {this.setState({base_status: value})}}
                                        >
                                            <Option value={1}>Actived</Option>
                                            <Option value={0}>Inactived</Option>
                                        </Select>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={8}>
                                <Row>
                                    <Col span={5} style={{padding: 5, textAlign: 'center', fontSize: 16}}>Hosting</Col>
                                    <Col span={18}>
                                        <Select
                                            style={{color: '#192a56', fontWeight: 700, textAlign: 'center', width: '100%'}}
                                            value={this.state.base_hosting}
                                            onChange={(value) => {this.setState({base_hosting: value})}}
                                        >
                                            <Option value="GODADDY">CLOUD</Option>
                                            <Option value="OVH">OVH</Option>
                                        </Select>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={6}>
                                <Row>
                                    <Col span={8} style={{padding: 5, textAlign: 'center', fontSize: 16}}>Jumping</Col>
                                    <Col span={16}>
                                        <Select
                                            style={{color: '#192a56', fontWeight: 700, textAlign: 'center', width: '100%'}}
                                            value={this.state.base_jumping_status}
                                            onChange={(value) => {this.setState({base_jumping_status: value})}}
                                        >
                                            <Option value={1}>Actived</Option>
                                            <Option value={0}>Inactived</Option>
                                        </Select>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                             <Col span={8}>
                                <Row>
                                    <Col span={4} style={{padding: 5, textAlign: 'center', fontSize: 16}}>JP</Col>
                                    <Col span={20}>
                                        <Input
                                            size="medium"
                                            style={{color: '#192a56', fontWeight: 700, textAlign: 'center', width: '98%'}}
                                            value={this.state.base_jumping_url}
                                            onChange={(e) => { this.setState({base_jumping_url: e.target.value})}}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={16}>
                                <Row>
                                    <Col span={2} style={{padding: 5, textAlign: 'center', fontSize: 16}}>BIMI</Col>
                                    <Col span={22}>
                                        <Input
                                            size="medium"
                                            style={{color: '#192a56', fontWeight: 700, textAlign: 'center', width: '98%'}}
                                            value={this.state.base_bimi}
                                            onChange={(e) => { this.setState({base_bimi: e.target.value})}}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <hr/>
                        <div style={{width: '100%', margin: '0 auto', marginTop: '-15px', textAlign: 'center', height: 40, padding: 5, fontSize: 18, background: '#23bcbe', color: "#fff", fontWeight: 400}}>
                            <span><strong>CLOUD HOSTING</strong></span>
                        </div>
                        <Row>
                            <Col span={11}>
                                <div style={{textAlign: 'CENTER', width: '100%'}}>
                                    <span style={{textDecoration: 'underline'}}><strong>LINKS</strong></span>
                                </div> 
                                <Row>
                                    <Col span={6} style={{textAlign: 'center'}}>
                                        <span>Host</span>
                                    </Col>
                                    <Col span={18}>
                                        <Input
                                            size="medium"
                                            style={{color: '#192a56', fontWeight: 700, textAlign: 'center'}}
                                            value={this.state.base_gHost}
                                            onChange={(e) => { this.setState({base_gHost: e.target.value})}}
                                        />
                                    </Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col span={6} style={{textAlign: 'center'}}>
                                        <span>Tracking</span>
                                    </Col>
                                    <Col span={18}>
                                        <Input
                                            size="medium"
                                            style={{color: '#192a56', fontWeight: 700, textAlign: 'center'}}
                                            value={this.state.base_gTrackLink}
                                            onChange={(e) => { this.setState({base_gTrackLink: e.target.value})}}
                                        />
                                    </Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col span={6} style={{textAlign: 'center'}}>
                                        <span>User</span>
                                    </Col>
                                    <Col span={18}>
                                        <Input
                                            size="medium"
                                            style={{color: '#192a56', fontWeight: 700, textAlign: 'center'}}
                                            value={this.state.base_gTrackLinkUser}
                                            onChange={(e) => { this.setState({base_gTrackLinkUser: e.target.value})}}
                                        />
                                    </Col>
                                </Row>   
                                <br/>
                                <Row>
                                    <Col span={6} style={{textAlign: 'center'}}>
                                        <span>Password</span>
                                    </Col>
                                    <Col span={18}>
                                        <Input
                                            size="medium"
                                            style={{color: '#192a56', fontWeight: 700, textAlign: 'center'}}
                                            value={this.state.base_gTrackLinkMdp}
                                            onChange={(e) => { this.setState({base_gTrackLinkMdp: e.target.value})}}
                                        />
                                    </Col>
                                </Row>   
                                    
                            </Col>
                            <Col span={1}></Col>
                            <Col span={11}>
                                <div style={{textAlign: 'CENTER', width: '100%'}}>
                                    <span style={{textDecoration: 'underline'}}><strong>IMAGES</strong></span>
                                </div> 
                                <Row>
                                    <Col span={6} style={{textAlign: 'center'}}>
                                        <span>Host</span>
                                    </Col>
                                    <Col span={18}>
                                        <Input
                                            size="medium"
                                            style={{color: '#192a56', fontWeight: 700, textAlign: 'center'}}
                                            value={this.state.base_gHost}
                                            onChange={(e) => { this.setState({base_gHost: e.target.value})}}
                                        />
                                    </Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col span={6} style={{textAlign: 'center'}}>
                                        <span>Tracking</span>
                                    </Col>
                                    <Col span={18}>
                                        <Input
                                            size="medium"
                                            style={{color: '#192a56', fontWeight: 700, textAlign: 'center'}}
                                            value={this.state.base_gTrackImg}
                                            onChange={(e) => { this.setState({base_gTrackImg: e.target.value})}}
                                        />
                                    </Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col span={6} style={{textAlign: 'center'}}>
                                        <span>User</span>
                                    </Col>
                                    <Col span={18}>
                                        <Input
                                            size="medium"
                                            style={{color: '#192a56', fontWeight: 700, textAlign: 'center'}}
                                            value={this.state.base_gTrackImgUser}
                                            onChange={(e) => { this.setState({base_gTrackImgUser: e.target.value})}}
                                        />
                                    </Col>
                                </Row>   
                                <br/>
                                <Row>
                                    <Col span={6} style={{textAlign: 'center'}}>
                                        <span>Password</span>
                                    </Col>
                                    <Col span={18}>
                                        <Input
                                            size="medium"
                                            style={{color: '#192a56', fontWeight: 700, textAlign: 'center'}}
                                            value={this.state.base_gTrackImgMdp}
                                            onChange={(e) => { this.setState({base_gTrackImgMdp: e.target.value})}}
                                        />
                                    </Col>
                                </Row>   
                            </Col>
                        </Row>
                        {/* <br/>
                        <div style={{ width: '100%', margin: '0 auto', marginTop: '-15px', textAlign: 'center', height: 40, padding: 5, fontSize: 18, background: '#23bcbe', color: "#fff", fontWeight: 400}}>
                            <span><strong>OVH HOSTING</strong></span>
                        </div>
                        <Row>
                            <Col span={11}>
                                <div style={{textAlign: 'CENTER', width: '100%'}}>
                                    <span style={{textDecoration: 'underline'}}><strong>LINKS</strong></span>
                                </div> 
                                <Row>
                                    <Col span={6} style={{textAlign: 'center'}}>
                                        <span>Host</span>
                                    </Col>
                                    <Col span={18}>
                                        <Input
                                            size="medium"
                                            style={{color: '#192a56', fontWeight: 700, textAlign: 'center'}}
                                            value={this.state.base_oFtpHost}
                                            onChange={(e) => { this.setState({base_oFtpHost: e.target.value})}}
                                        />
                                    </Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col span={6} style={{textAlign: 'center'}}>
                                        <span>Tracking</span>
                                    </Col>
                                    <Col span={18}>
                                        <Input
                                            size="medium"
                                            style={{color: '#192a56', fontWeight: 700, textAlign: 'center'}}
                                            value={this.state.base_oTrackLink}
                                            onChange={(e) => { this.setState({base_oTrackLink: e.target.value})}}
                                        />
                                    </Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col span={6} style={{textAlign: 'center'}}>
                                        <span>User</span>
                                    </Col>
                                    <Col span={18}>
                                        <Input
                                            size="medium"
                                            style={{color: '#192a56', fontWeight: 700, textAlign: 'center'}}
                                            value={this.state.base_oTrackLinkUser}
                                            onChange={(e) => { this.setState({base_oTrackLinkUser: e.target.value})}}
                                        />
                                    </Col>
                                </Row>   
                                <br/>
                                <Row>
                                    <Col span={6} style={{textAlign: 'center'}}>
                                        <span>Password</span>
                                    </Col>
                                    <Col span={18}>
                                        <Input
                                            size="medium"
                                            style={{color: '#192a56', fontWeight: 700, textAlign: 'center'}}
                                            value={this.state.base_oTrackLinkMdp}
                                            onChange={(e) => { this.setState({base_oTrackLinkMdp: e.target.value})}}
                                        />
                                    </Col>
                                </Row>   
                                    
                            </Col>
                            <Col span={1}></Col>
                            <Col span={11}>
                                <div style={{textAlign: 'CENTER', width: '100%'}}>
                                    <span style={{textDecoration: 'underline'}}><strong>IMAGES</strong></span>
                                </div> 
                                <Row>
                                    <Col span={6} style={{textAlign: 'center'}}>
                                        <span>Host</span>
                                    </Col>
                                    <Col span={18}>
                                        <Input
                                            size="medium"
                                            style={{color: '#192a56', fontWeight: 700, textAlign: 'center'}}
                                            value={this.state.base_oFtpHost}
                                            onChange={(e) => { this.setState({base_oFtpHost: e.target.value})}}
                                        />
                                    </Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col span={6} style={{textAlign: 'center'}}>
                                        <span>Tracking</span>
                                    </Col>
                                    <Col span={18}>
                                        <Input
                                            size="medium"
                                            style={{color: '#192a56', fontWeight: 700, textAlign: 'center'}}
                                            value={this.state.base_oTrackImg}
                                            onChange={(e) => { this.setState({base_oTrackImg: e.target.value})}}
                                        />
                                    </Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col span={6} style={{textAlign: 'center'}}>
                                        <span>User</span>
                                    </Col>
                                    <Col span={18}>
                                        <Input
                                            size="medium"
                                            style={{color: '#192a56', fontWeight: 700, textAlign: 'center'}}
                                            value={this.state.base_oTrackImgUser}
                                            onChange={(e) => { this.setState({base_oTrackImgUser: e.target.value})}}
                                        />
                                    </Col>
                                </Row>   
                                <br/>
                                <Row>
                                    <Col span={6} style={{textAlign: 'center'}}>
                                        <span>Password</span>
                                    </Col>
                                    <Col span={18}>
                                        <Input
                                            size="medium"
                                            style={{color: '#192a56', fontWeight: 700, textAlign: 'center'}}
                                            value={this.state.base_oTrackImgMdp}
                                            onChange={(e) => { this.setState({base_oTrackImgMdp: e.target.value})}}
                                        />
                                    </Col>
                                </Row>   
                            </Col>
                        </Row> */}
                        <br/>
                        <div style={{width: '50%', margin: '0 auto', textAlign: 'center', display: `${this.state.displayUpdate}`}}>
                            <Button
                                loading={this.state.loadingUpdate}
                                className="btn btn-dark"
                                style={{width: '50%', height: 50, fontSize: 16}}
                                onClick={this.onUpdateDatabase.bind(this)}

                            >
                                UPDATE
                            </Button>
                        </div>
                        <div style={{width: '50%', margin: '0 auto', textAlign: 'center', display: `${this.state.displayAdd}`}}>
                            <Button
                                loading={this.state.loadingUpdate}
                                className="btn btn-dark"
                                style={{width: '50%', height: 50, fontSize: 16}}
                                onClick={this.onUpdateDatabase.bind(this)}

                            >
                                SUBMIT
                            </Button>
                        </div>
                    </div>

                </Modal>

                <Modal
                    id="assign-base"
                    isOpen={this.state.isAssigned}
                    onRequestClose={this.onRequestCloseAssignBase.bind(this)}
                    shouldCloseOnOverlayClick={true}
                    style={bg}
					ariaHideApp={false}
                >
                    <div>
                        <div
                            style={{background: '#001529', marginTop: '0px', textAlign: 'center', width: '100%', marginLeft: '0px'}}
                        >
                            <h3 style={{color: "#fff", padding: 5}}>ASSIGN DATABASE&nbsp;</h3>
                        </div>
                        <span
                            style={{color: "#C23616", marginTop: '-59px', fontSize: 43, float: 'right', cursor: 'pointer'}} onClick={this.onRequestCloseAssignBase.bind(this)}
                        >
                            X
                        </span> 
                        <div>
                            <Tabs type="card" style={{height: 'auto'}} defaultActiveKey={"1"}>
                            <TabPane tab={<div style={{width: '50%', textAlign: 'center'}} ><span style={{fontSize: 18}}>USER HAS DATABASE</span></div>} key="1">
                                <div style={{width: '90%', margin: '0 auto', textAlign: 'center'}}>
                                    <br/>
                                    <Row>
                                        <Col span={6} style={{padding: 5}}>User</Col>
                                        <Col span={14}>
                                            <Select
                                                showSearch={true}
                                                optionFilterProp="children"
                                                size="medium"   
                                                style={{width: '100%',  color: '#192a56',fontWeight: 700, textAlign: 'center' }}
                                                value={this.state.user_random_id}
                                                onChange={(value) => {
                                                    this.getListBaseUser(value)
                                                    this.setState({user_random_id: value})
                                                }}
                                            >
                                                {
                                                    this.state.liste_user.map(item => {
                                                        return(
                                                            <Option key={item.id} value={item.id}>{item['username']}</Option>
                                                        )
                                                    })
                                                }
                                            </Select> 
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col span={4} style={{padding: 5}}>Database</Col>
                                        <Col span={20}>
                                            <Select
                                                mode="multiple"
                                                size="medium"   
                                                showSearch={true}
                                                optionFilterProp="children"
                                                style={{width: '100%',  color: '#192a56',fontWeight: 700, textAlign: 'center' }}
                                                value={this.state.user_base_list}
                                                onChange={(value) => {
                                                    this.setState({user_base_list: value})
                                                }}
                                            >
                                                {
                                                    this.state.liste_base.map(item => {
                                                        var key = generateKey()
                                                        return(
                                                            <Option key={key} value={item.id}>{item['basename']}</Option>
                                                        )
                                                    })
                                                }
                                            </Select> 
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col span={6}></Col>
                                        <Col span={14}>
                                            <Button 
                                                loading={this.state.loadingUpdate}
                                                className="btn btn-primary"
                                                style={{height: '30px', width: '100%', fontSize: 16}}
                                                onClick={this.onAssignedDatabaseUser.bind(this)}
                                            >
                                                UPDATE
                                            </Button>
                                        </Col>
                                    </Row>
                                    </div>
                                </TabPane>
                            </Tabs>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default Domain
