import axios from 'axios'
import { base64_decode, base64_encode } from './Utilities';


export async function getStatsData() {
    if (localStorage.getItem(base64_encode('Stats_clients')) === null || localStorage.getItem(base64_encode('tags')) === null || localStorage.getItem(base64_encode('Stats_country_and_currency')) === null || localStorage.getItem(base64_encode('stats_model')) === null) {
        var fd = new FormData()
        fd.append('user_id', base64_decode(localStorage.getItem(base64_encode('user_id'))))
        fd.append('apikey', base64_decode(localStorage.getItem(base64_encode('apikey'))))
        fd.append('stats_apikey', base64_decode(localStorage.getItem(base64_encode('stats_apikey'))))
        let res = await axios({
            url: process.env.REACT_APP_ENDPOINT_STATS_DATA,
            method: 'post',
            timeout: 20000,
            // headers: {
            //     'Content-Type': 'application/json',
            // },
            data: fd
        })
        localStorage.setItem(base64_encode('tags'), base64_encode(JSON.stringify(res.data['liste_tags'])))
        localStorage.setItem(base64_encode('Stats_clients'), base64_encode(JSON.stringify(res.data['liste_clients'])))
        localStorage.setItem(base64_encode('Stats_country_and_currency'), base64_encode(JSON.stringify(res.data['liste_country'])))
        localStorage.setItem(base64_encode('stats_model'), base64_encode(JSON.stringify(res.data['liste_models'])))
    } else {
        console.log('stats data already getted')
    }
}

export async function list_tags(user_id, apikey, stats_apikey) {
    var fd = new FormData()
    try {
        if (base64_decode(localStorage.getItem(base64_encode('tags'))) !== null || base64_decode(localStorage.getItem(base64_encode('tags'))) !== undefined) {
            return JSON.parse(base64_decode(localStorage.getItem(base64_encode('tags'))))
        } else {
            fd.append('user_id', user_id)
            fd.append('apikey', apikey)
            fd.append('stats_apikey', stats_apikey)
            let res = await axios({
                url: process.env.REACT_APP_ENDPOINT_STATS_TAGS,
                method: 'post',
                timeout: 20000,
                // headers: {
                //     'Content-Type': 'application/json',
                // },
                data: fd
            })
            let field = 'tag'
            var list_tag = (res.data).sort((a, b) => (a[field] || "").toString().localeCompare((b[field] || "").toString()));
            localStorage.setItem(base64_encode('tags'), base64_encode(JSON.stringify(list_tag)))
            return list_tag
        }

    }
    catch (err) {
        fd.append('user_id', base64_decode(localStorage.getItem(base64_encode('user_id'))))
        fd.append('apikey', base64_decode(localStorage.getItem(base64_encode('apikey'))))
        fd.append('stats_apikey', base64_decode(localStorage.getItem(base64_encode('stats_apikey'))))
        let res = await axios({
            url: process.env.REACT_APP_ENDPOINT_STATS_TAGS,
            method: 'post',
            timeout: 20000,
            // headers: {
            //     'Content-Type': 'application/json',
            // },
            data: fd
        })
        localStorage.setItem(base64_encode('tags'), base64_encode(JSON.stringify(res.data)))
        return res.data
    }
}

export async function list_advertiser(user_id, apikey, stats_apikey, advername) {
    var fd = new FormData()
    try {
        fd.append('user_id', user_id)
        fd.append('apikey', apikey)
        fd.append('stats_apikey', stats_apikey)
        fd.append('advername', advername)
        let res = await axios({
            url: process.env.REACT_APP_ENDPOINT_STATS_ADVERTISER,
            method: 'post',
            timeout: 20000,
            data: fd
        })
        return res.data
    }
    catch (err) {
        console.error('error agetting list advertiser  ' + err);
        return null
    }
}

export async function list_client(user_id, apikey, stats_apikey) {
    var fd = new FormData()
    try {
        if (base64_decode(localStorage.getItem(base64_encode('Stats_clients'))) !== null || base64_decode(localStorage.getItem(base64_encode('Stats_clients'))) !== undefined) {
            return JSON.parse(base64_decode(localStorage.getItem(base64_encode('Stats_clients'))))
        } else {
            console.log('need to download')
            fd.append('user_id', user_id)
            fd.append('apikey', apikey)
            fd.append('stats_apikey', stats_apikey)
            let res = await axios({
                url: process.env.REACT_APP_ENDPOINT_STATS_CLIENTS,
                method: 'post',
                timeout: 20000,
                // headers: {
                //     'Content-Type': 'application/json',
                // },
                data: fd
            })
            localStorage.setItem(base64_encode('Stats_clients'), base64_encode(JSON.stringify(res.data)))
            return res.data
        }

    }
    catch (err) {
        fd.append('user_id', base64_decode(localStorage.getItem(base64_encode('user_id'))))
        fd.append('apikey', base64_decode(localStorage.getItem(base64_encode('apikey'))))
        fd.append('stats_apikey', base64_decode(localStorage.getItem(base64_encode('stats_apikey'))))
        let res = await axios({
            url: process.env.REACT_APP_ENDPOINT_STATS_CLIENTS,
            method: 'post',
            timeout: 20000,
            // headers: {
            //     'Content-Type': 'application/json',
            // },
            data: fd
        })
        localStorage.setItem(base64_encode('Stats_clients'), base64_encode(JSON.stringify(res.data)))
        return res.data
    }

}

export async function list_country(user_id, apikey, stats_apikey) {
    var fd = new FormData()
    try {
        if (base64_decode(localStorage.getItem(base64_encode('Stats_country_and_currency'))) !== null || base64_decode(localStorage.getItem(base64_encode('Stats_country_and_currency'))) !== undefined) {
            return JSON.parse(base64_decode(localStorage.getItem(base64_encode('Stats_country_and_currency'))))
        } else {
            fd.append('user_id', user_id)
            fd.append('apikey', apikey)
            fd.append('stats_apikey', stats_apikey)
            let res = await axios({
                url: process.env.REACT_APP_ENDPOINT_STATS_COUNTRY_CURRENCY,
                method: 'post',
                timeout: 20000,
                data: fd
            })
            localStorage.setItem(base64_encode('Stats_country_and_currency'), base64_encode(JSON.stringify(res.data)))
            return res.data
        }

    }
    catch (err) {
        fd.append('user_id', base64_decode(localStorage.getItem(base64_encode('user_id'))))
        fd.append('apikey', base64_decode(localStorage.getItem(base64_encode('apikey'))))
        fd.append('stats_apikey', base64_decode(localStorage.getItem(base64_encode('stats_apikey'))))
        let res = await axios({
            url: process.env.REACT_APP_ENDPOINT_STATS_COUNTRY_CURRENCY,
            method: 'post',
            timeout: 20000,
            data: fd
        })
        localStorage.setItem(base64_encode('Stats_country_and_currency'), base64_encode(JSON.stringify(res.data)))
        return res.data
    }

}

export async function list_model(user_id, apikey, stats_apikey) {
    var fd = new FormData()
    try {
        if (base64_decode(localStorage.getItem(base64_encode('stats_model'))) !== null || base64_decode(localStorage.getItem(base64_encode('stats_model'))) !== undefined) {
            return JSON.parse(base64_decode(localStorage.getItem(base64_encode('stats_model'))))
        } else {
            fd.append('user_id', user_id)
            fd.append('apikey', apikey)
            fd.append('stats_apikey', stats_apikey)
            let res = await axios({
                url: process.env.REACT_APP_ENDPOINT_STATS_MODELS,
                method: 'post',
                timeout: 20000,
                // headers: {
                //     'Content-Type': 'application/json',
                // },
                data: fd
            })
            localStorage.setItem(base64_encode('stats_model'), base64_encode(JSON.stringify(res.data)))
            return res.data
        }

    }
    catch (err) {
        fd.append('user_id', base64_decode(localStorage.getItem(base64_encode('user_id'))))
        fd.append('apikey', base64_decode(localStorage.getItem(base64_encode('apikey'))))
        fd.append('stats_apikey', base64_decode(localStorage.getItem(base64_encode('stats_apikey'))))
        let res = await axios({
            url: process.env.REACT_APP_ENDPOINT_STATS_MODELS,
            method: 'post',
            timeout: 20000,
            // headers: {
            //     'Content-Type': 'application/json',
            // },
            data: fd
        })
        localStorage.setItem(base64_encode('stats_model'), base64_encode(JSON.stringify(res.data)))
        return res.data
    }

}

export async function list_header(user_id, apikey, stats_apikey, base_id, basename) {
    try {
    var fd = new FormData()
    fd.append('user_id', user_id)
    fd.append('apikey', apikey)
    fd.append('stats_apikey', stats_apikey)
    fd.append('base_id', base_id)
    let res = await axios({
        url: process.env.REACT_APP_ENDPOINT_STATS_HEADER,
        method: 'post',
        timeout: 20000,
        // headers: {
        //     'Content-Type': 'application/json',
        // },
        data: fd
    })
    return res.data
    
        
        
    }
    catch (err) {
        return []
    }
}

export async function list_footer(user_id, apikey, stats_apikey, base_id, basename) {
    try {
        var fd = new FormData()
        fd.append('user_id', user_id)
                fd.append('apikey', apikey)
                fd.append('stats_apikey', stats_apikey)
                fd.append('base_id', base_id)
                let res = await axios({
                    url: process.env.REACT_APP_ENDPOINT_STATS_FOOTER,
                    method: 'post',
                    timeout: 20000,
                    data: fd
                })
                console.log(res.data)
                return res.data
    }
    catch (err) {
       return []
    }
}

export async function list_seedlist(user_id, apikey, stats_apikey, base_id, basename) {
    var name = 'Seedlist_' + basename
    var fd = new FormData()
    try {
        fd.append('user_id', user_id)
        fd.append('apikey', apikey)
        fd.append('stats_apikey', stats_apikey)
        fd.append('base_id', base_id)
        let res = await axios({
            url: process.env.REACT_APP_ENDPOINT_STATS_SEEDLIST,
            method: 'post',
            timeout: 20000,
            // headers: {
            //     'Content-Type': 'application/json',
            // },
            data: fd
        })
        localStorage.setItem(base64_encode(name), base64_encode(JSON.stringify(res.data)))
        return res.data
        // }
    }
    catch (err) {
        console.error('error getting list seedlist ' + err);
        return []
    }
}

export async function list_segment(user_id, apikey, stats_apikey, base_id) {
    try {
        var fd = new FormData()
        fd.append('user_id', user_id)
        fd.append('apikey', apikey)
        fd.append('stats_apikey', stats_apikey)
        fd.append('base_id', base_id)
        let res = await axios({
            url: process.env.REACT_APP_ENDPOINT_STATS_SEGMENT,
            method: 'post',
            timeout: 20000,
            data: fd
        })
        return res.data
    }
    catch (err) {
        console.error('error getting segment lists ' + err);
        return null
    }
}

export async function list_subscriber(user_id, apikey, stats_apikey, base_id) {
    try {
        var fd = new FormData()
        fd.append('user_id', user_id)
        fd.append('apikey', apikey)
        fd.append('stats_apikey', stats_apikey)
        fd.append('base_id', base_id)
        let res = await axios({
            url: process.env.REACT_APP_ENDPOINT_STATS_SUBSCRIBER,
            method: 'post',
            timeout: 20000,
            data: fd
        })
        return res.data
    }
    catch (err) {
        console.error('error getting segment lists ' + err);
        return null
    }
}

export async function list_suppression(user_id, apikey, stats_apikey, base_id) {
    try {
        var fd = new FormData()
        fd.append('user_id', user_id)
        fd.append('apikey', apikey)
        fd.append('stats_apikey', stats_apikey)
        fd.append('base_id', base_id)
        let res = await axios({
            url: process.env.REACT_APP_ENDPOINT_STATS_SUPPRESSION,
            method: 'post',
            timeout: 20000,
            data: fd
        })
        return res.data
    }
    catch (err) {
        console.error('error getting suppression list ' + err);
        return null
    }
}

export async function save_stats(data) {
    try {
        var fd = new FormData()
        fd.append('user_id', data['user_id'])
        fd.append('apikey', data['apikey'])
        fd.append('stats_apikey', data['stats_apikey'])
        fd.append('creaid', data['crea_id'])
        fd.append('id_v1', data['id_v1'])
        fd.append('id_v2', data['id_v2'])
        fd.append('countryid', data['country_id'])
        fd.append('countryname', data['country_name'])
        fd.append('currency_id', data['currency_id'])
        fd.append('currencyname', data['currency_name'])
        fd.append('advname', data['advname'])
        fd.append('advid', data['advid'])
        fd.append('clientid', data['client_id'])
        fd.append('clientname', data['client_name'])
        fd.append('affname', data['aff_name'])
        fd.append('affid', data['aff_id'])
        fd.append('tagid', data['tags_id'])
        fd.append('tagsname', data['tagsname'])
        fd.append('baseid', data['base_id'])
        fd.append('stats_date', data['crea_date']) //moment(this.state.crea_stats_date).format('YYYY-MM-DD').toString()
        fd.append('stats_comment', data['comment'])
        fd.append('stats_segment', data['segment'])
        fd.append('stats_revenu', data['stats_revenu'])
        fd.append('stats_campagn_status', data['bat_status'])
        fd.append('stats_campagn_type', data['schedule_type'])
        fd.append('campagn_name', data['creativity'])
        fd.append('campagn_from', data['campagn_from'])
        fd.append('campagn_until', data['campagn_until'])
        fd.append('sendoutid', data['router_id'])
        fd.append('router', data['router'])
        fd.append('brand', data['brand'])
        fd.append('stats_base_id', data['stats_base_id'])
        fd.append('isb2c', data['crea_isb2c'])
        fd.append('segment_name', data['segment_name'])
        fd.append('action', data['action'])
        let res = await axios({
            url: process.env.REACT_APP_ENDPOINT_STATS_SCHEDULE_EMAILING,
            method: 'post',
            data: fd
        })
        return res.data
    }
    catch (err) {
        console.error('error getting suppression list ' + err);
        return null
    }
}


