import React, { Component } from 'react'
import { Row, Col, Button, Input, Tag, Select, message, Radio, Tooltip } from 'antd'
import axios from 'axios'
import Modal from 'react-modal'
// import  {ImPencil}  from "react-icons/fa";
import { EditOutlined, CheckOutlined, CloseSquareOutlined,ClearOutlined } from '@ant-design/icons';

import { Redirect } from 'react-router-dom'
import { base64_encode, base64_decode, generateKey } from '../Utilities/Utilities';
import '../../css/Dashboard.css'
const { Option } = Select;
const RadioGroup = Radio.Group;
class User extends Component {
    constructor(props) {
        super(props)

        this.state = {
            userid: base64_decode(localStorage.getItem(base64_encode('user_id'))),
            apikey: base64_decode(localStorage.getItem(base64_encode('apikey'))),
            statsapikey: base64_decode(localStorage.getItem(base64_encode('stats_apikey'))),
            email: base64_decode(localStorage.getItem(base64_encode('email'))),
            username: base64_decode(localStorage.getItem(base64_encode('username'))),
            endpoint: base64_decode(localStorage.getItem(base64_encode('endpoint'))),
            roles: base64_decode(localStorage.getItem(base64_encode('roles'))),
            liste_user: [],
            keywords: null,
            isAdmin: false,
            displayAdmin: 'none',
            isOpenAdd: true,
            user_name: '',
            user_apikey: '',
            user_stats_apikey: '',
            user_email: '',
            user_roles: '',
            user_id: '',
            user_mdp: '',
            user_mdp1: '',
            //modal
            modalAction: 'NEW',
            modalCTA: 'SUBMIT',
            isOpen: false,
            isOpenAdd: false,
            isDisable: false,
            cacheClear: false,
            modalStatus: 'DISABLE',
            //loading 
            loadingUpdate: false,
            loadingStatus: false,
            loadingCache: false,
            clear_all_cache: false
        }
    }

    componentDidMount() {
        localStorage.setItem(base64_encode('def_dash_pref'), "2")
        if (base64_decode(localStorage.getItem(base64_encode('roles'))) === 'Admin') {
            this.setState({ isAdmin: true, displayAdmin: 'block' })
        }
        this.getListUser()
    }

    getListUser() {
        if (localStorage.getItem(base64_encode('all_user')) !== null) {
            this.setState({ liste_user: JSON.parse(base64_decode(localStorage.getItem(base64_encode('all_user')))) })
        } else {
            var fd = new FormData()
            fd.append('user_id', this.state.userid)
            fd.append('apikey', this.state.apikey)
            axios.post(process.env.REACT_APP_ENDPOINT_USER_LIST, fd)
                .then(res => {
                    localStorage.setItem(base64_encode('all_user'), base64_encode(JSON.stringify(res.data)))
                    this.setState({ liste_user: res.data })
                })

        }
    }

    onUpdateUser() {
        if (this.state.user_email === '' || this.state.user_name === '' || this.state.user_roles === '' || this.state.user_apikey === '') {
            message.warning('Mandatory fields missing')
            return null
        } else {
            this.setState({ loadingUpdate: true })
            var fd = new FormData()
            fd.append('user_id', this.state.userid)
            fd.append('apikey', this.state.apikey)
            fd.append('user_id_update', this.state.user_id)
            fd.append('user_email', this.state.user_email)
            fd.append('username', this.state.user_name)
            fd.append('roles', this.state.user_roles)
            fd.append('user_apikey', this.state.user_apikey)
            fd.append('user_stats_apikey', this.state.user_stats_apikey)
            axios.post(process.env.REACT_APP_ENDPOINT_USER_ADMIN_UPDATE, fd)
                .then(res => {
                    localStorage.removeItem(base64_encode('all_user'))
                    setTimeout(() => {
                        window.location.reload()
                    }, 2000)
                })
        }
    }

    onFilterUserList(e) {
        this.setState({ keywords: e.target.value })
        let result = []
        if (e.target.value !== '') {
            this.state.liste_user.map((user) => {
                if (user.email.indexOf(e.target.value) > -1 || user.username.toLowerCase().indexOf(e.target.value) > -1 || user.roles.toLowerCase().indexOf(e.target.value) > -1) {
                    result.push(user)
                    this.setState({ liste_user: result })
                }
                return null
            })

        } else {
            this.setState({
                liste_user: JSON.parse(base64_decode(localStorage.getItem(base64_encode('all_user'))))
            })
        }
    }

    onAddUser() {
        if (this.state.user_email === '' || this.state.user_name === '' || this.state.user_roles === '' || this.state.user_apikey === '' || this.state.user_mdp === '' || this.state.user_mdp1 === '') {
            message.warning('Mandatory fields missing')
            return null
        } else {
            if (this.state.user_mdp !== this.state.user_mdp1) {
                message.warning('Password is not matching')
                return null
            } else {
                this.setState({ loadingUpdate: true })
                var fd = new FormData()
                fd.append('user_id', this.state.userid)
                fd.append('apikey', this.state.apikey)
                fd.append('user_email', this.state.user_email)
                fd.append('username', this.state.user_name)
                fd.append('mdp', this.state.user_mdp)
                fd.append('roles', this.state.user_roles)
                fd.append('user_apikey', this.state.user_apikey)
                fd.append('user_stats_apikey', this.state.user_stats_apikey)
                fd.append('company_id', 1)
                axios.post(process.env.REACT_APP_ENDPOINT_USER_ADMIN_ADD, fd)
                    .then(res => {
                        localStorage.removeItem(base64_encode('all_user'))
                        setTimeout(() => {
                            window.location.reload()
                        }, 1000)
                    })
            }
        }
    }

    onDisableUser() {
        this.setState({ loadingStatus: true })
        var fd = new FormData()
        fd.append('user_id', this.state.userid)
        fd.append('apikey', this.state.apikey)
        fd.append('id', this.state.user_id)
        if (this.state.modalStatus == 'DISABLE') {
            axios.post(process.env.REACT_APP_ENDPOINT_USER_ADMIN_DISABLE, fd)
                .then(res => {
                    localStorage.removeItem(base64_encode('all_user'))
                })
        } else {
            axios.post(process.env.REACT_APP_ENDPOINT_USER_ADMIN_ENABLE, fd)
                .then(res => {
                    localStorage.removeItem(base64_encode('all_user'))
                })
        }
        this.getListUser()
        setTimeout(() => {
            window.location.reload()
        }, 2000)
    }

    onClearAllCache(){
        this.setState({clear_all_cache: true, email: null})
        this.onClearChache()
    }

    onClearChache(){
        this.setState({loadingCache: true})
        var fd = new FormData()
        fd.append('user_id', this.state.userid)
        fd.append('apikey', this.state.apikey)
        fd.append('id', this.state.user_id)
        fd.append('email', this.state.user_email)
        axios.post(process.env.REACT_APP_ENDPOINT_USER_ADMIN_CACHE_CLEAR, fd)
            .then(res => {
                message.success('Cache successfully clear !!!', 2)
                setTimeout(() => {
                    window.location.reload()
                }, 2000)
        })
    }
    render() {
        const bg = {
            overlay: {
                backgroundColor: "rgba(0, 0, 0, .5)",
            }
        }

        return (
            <div style={{ backgroundColor: '#fff', marginTop: '0px', height: '92vh' }}>
                <Row style={{ padding: 10 }}>
                    <Col span={1} style={{ textAlign: 'center', padding: 5 }}>
                        <span>Search</span>
                    </Col>
                    <Col span={6}>
                        <Input
                            value={this.state.keywords}
                            size="medium"
                            style={{ border: '1px solid gray', color: '#192a56', fontWeight: 700, textAlign: 'center' }}
                            onChange={this.onFilterUserList.bind(this)}
                        />
                    </Col>
                    <Col span={3} style={{ textAlign: 'center', display: `${this.state.displayAdmin}` }}>
                        <Button
                            className="btn btn-primary"
                            style={{ width: '75%', fontSize: 16 }}
                            onClick={() => {
                                this.setState({
                                    isOpenAdd: true,
                                    user_name: '',
                                    user_apikey: '',
                                    user_stats_apikey: '',
                                    user_email: '',
                                    user_roles: '',
                                    modalAction: 'NEW',
                                    modalCTA: 'SUBMIT'
                                })
                            }}
                        >
                            +Add
                        </Button>
                    </Col>
                    <Col span={5} style={{ textAlign: 'center', display: `${this.state.displayAdmin}` }}>
                        <Button
                            loading={this.state.clear_all_cache}
                            className="btn btn-warning"
                            style={{ width: '75%', fontSize: 16, color: '#fff' }}
                            onClick={this.onClearAllCache.bind(this)}
                        >
                            CLEAR CACHE HISTORY
                        </Button>
                    </Col>
                </Row>

                <Row style={{ height: '85vh', overflow: 'scroll', overflowX: 'hidden' }}>
                    <table className="table">
                        <thead className="bg-dark" style={{ color: "#fff" }}>
                            <tr>
                                <th style={{ textAlign: 'center', width: '5%' }}>ID</th>
                                <th style={{ textAlign: 'center', width: '15%' }}>EMAIL</th>
                                <th style={{ textAlign: 'center', width: '10%' }}>NAME</th>
                                <th style={{ textAlign: 'center', width: '10%' }}>ROLE</th>
                                <th style={{ textAlign: 'center', width: '10%' }}>STATUS</th>
                                <th style={{ textAlign: 'center', width: '30%' }}>ACTION</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.liste_user && this.state.liste_user.map(item => {
                                    var key = generateKey()
                                    var color = '#108ee9'
                                    var poste = "Commerciale"
                                    var status = "enabled"
                                    if (item['roles'] == "Admin") {
                                        color = "#f50"
                                        poste = "Admin"
                                    } else {
                                        if (item['roles'] == "RD") {
                                            color = "#2db7f5"
                                            poste = "Delivery Manager"
                                        } else {
                                            if (item['roles'] == "TM") {
                                                color = "#87d068"
                                                poste = "Traffic Manager"
                                            }
                                        }
                                    }
                                    if (item['status'] == 0) {
                                        status = "disabled"
                                    }

                                    return (
                                        <tr key={key}>
                                            <td style={{ textAlign: 'center' }}>{item['id']}</td>
                                            <td style={{ textAlign: 'center' }}>{item['email']}</td>
                                            <td style={{ textAlign: 'center' }}>{item['username']}</td>
                                            <td style={{ textAlign: 'center' }}>
                                                <Tag color={color} style={{ fontSize: 14 }}>{poste}</Tag>
                                            </td>
                                            <td style={{ textAlign: 'center' }}>{status}</td>
                                            <td style={{ textAlign: 'center', display: `${this.state.displayAdmin}` }}>
                                                <div style={{ width: '70%', margin: '0 auto' }}>
                                                    <Row>
                                                        <Col span={4}>
                                                            <Tooltip title="Edit" placement="bottom">
                                                                <Button
                                                                    className="btn btn-outline-info"
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            isOpen: true,
                                                                            user_id: item['id'],
                                                                            user_name: item['username'],
                                                                            user_apikey: item['apikey'],
                                                                            user_stats_apikey: item['stats_apikey'],
                                                                            user_email: item['email'],
                                                                            user_roles: item['roles'],
                                                                            modalAction: 'EDIT',
                                                                            modalCTA: 'UPDATE'

                                                                        })
                                                                    }}
                                                                >
                                                                    {/* Edit */}
                                                                    <EditOutlined style={{ fontSize: 16 }} />
                                                                </Button>
                                                            </Tooltip>
                                                        </Col>

                                                        <Col span={4}>
                                                            <Tooltip title="Enable" placement="bottom">
                                                                <Button
                                                                    className="btn btn-outline-success"
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            isDisable: true,
                                                                            user_id: item['id'],
                                                                            user_name: item['username'],
                                                                            modalStatus: "ENABLE"
                                                                        })
                                                                    }}
                                                                    style={{ fontSize: 16 }}
                                                                >
                                                                    <CheckOutlined style={{ fontSize: 16 }} />
                                                                </Button>
                                                            </Tooltip>
                                                        </Col>

                                                        <Col span={4}>
                                                            <Tooltip title="Disable" placement="bottom">
                                                                <Button
                                                                    className="btn btn-outline-danger"
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            isDisable: true,
                                                                            user_id: item['id'],
                                                                            user_name: item['username'],
                                                                            modalStatus: "DISABLE"
                                                                        })
                                                                    }}
                                                                    style={{ fontSize: 16, textAlign: 'center' }}
                                                                >
                                                                    <CloseSquareOutlined style={{ marginTop: '-20px !important' }} />
                                                                </Button>
                                                            </Tooltip>
                                                        </Col>

                                                        <Col span={4}>
                                                            <Tooltip title="Cache clear" placement="bottom">
                                                                <Button
                                                                    className="btn btn-outline-warning"
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            user_id: item['id'],
                                                                            user_name: item['username'],
                                                                            user_email: item['email'],
                                                                            modalStatus: "CLEAR CACHE",
                                                                            cacheClear: true
                                                                        })
                                                                    }}
                                                                    style={{ fontSize: 16, textAlign: 'center' }}
                                                                >
                                                                    <ClearOutlined style={{ marginTop: '-20px !important' }} />
                                                                </Button>
                                                            </Tooltip>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </Row>

                <Modal
                    id="new-edit-user"
                    isOpen={this.state.isOpen}
                    onRequestClose={() => {
                        this.setState({ isOpen: false })
                    }}
                    shouldCloseOnOverlayClick={true}
                    style={bg}
                    ariaHideApp={false}
                >
                    <div stye={{ fontSize: '5px !important' }}>
                        <div
                            style={{ background: '#001529', marginTop: '0px', textAlign: 'center', width: '100%', marginLeft: '0px' }}
                        >
                            <h3 style={{ color: "#fff", padding: 5 }}>{this.state.modalAction} USER</h3>
                        </div>
                        <span
                            style={{ color: "#C23616", marginTop: '-48px', fontSize: 35, float: 'right', cursor: 'pointer' }} onClick={() => {
                                this.setState({ isOpen: false })
                            }}
                        >
                            X
                        </span>
                        <div style={{ marginLeft: 100 }}>
                            <div>
                                <Row>
                                    <Col span={24}>
                                        <Row>
                                            <Col span={4} style={{ padding: 5 }}><span style={{ fontSize: 16 }}>Username</span></Col>
                                            <Col span={17}>
                                                <Input
                                                    size="medium"
                                                    style={{ border: '1px solid gray', color: '#192a56', fontWeight: 700, textAlign: 'center', width: '100%', fontSize: 19 }}
                                                    value={this.state.user_name}
                                                    onChange={(e) => {
                                                        this.setState({ user_name: e.target.value })
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col span={4} style={{ padding: 5 }}><span style={{ fontSize: 16 }}>Email</span></Col>
                                            <Col span={17}>
                                                <Input
                                                    size="medium"
                                                    style={{ border: '1px solid gray', color: '#192a56', fontWeight: 700, textAlign: 'center', width: '100%', fontSize: 19 }}
                                                    value={this.state.user_email}
                                                    onChange={(e) => {
                                                        this.setState({ user_email: e.target.value })
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col span={4} style={{ padding: 5 }}><span style={{ fontSize: 16 }}>Apikey</span></Col>
                                            <Col span={17}>
                                                <Input
                                                    size="medium"
                                                    style={{ border: '1px solid gray', color: '#192a56', fontWeight: 700, textAlign: 'center', width: '100%', fontSize: 19 }}
                                                    value={this.state.user_apikey}
                                                    onChange={(e) => {
                                                        this.setState({ user_apikey: e.target.value })
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col span={4} style={{ padding: 5 }}><span style={{ fontSize: 16 }}>Stats Apikey</span></Col>
                                            <Col span={17}>
                                                <Input
                                                    size="medium"
                                                    style={{ border: '1px solid gray', color: '#192a56', fontWeight: 700, textAlign: 'center', width: '100%', fontSize: 19 }}
                                                    value={this.state.user_stats_apikey}
                                                    onChange={(e) => {
                                                        this.setState({ user_stats_apikey: e.target.value })
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col span={4} style={{ padding: 5 }}><span style={{ fontSize: 16 }}>Rôle</span></Col>
                                            <Col span={17}>
                                                <Select
                                                    size="medium"
                                                    style={{ border: '1px solid gray', color: '#192a56', fontWeight: 700, textAlign: 'center', width: '100%', fontSize: 19 }}
                                                    value={this.state.user_roles}
                                                    onChange={(value) => {
                                                        this.setState({ user_roles: value })
                                                    }}
                                                >
                                                    <Option value="Admin">Admin</Option>
                                                    <Option value="RD">Delivery Manager</Option>
                                                    <Option value="TME">Traffic Manager Emailing</Option>
                                                    <Option value="TMS">Traffic Manager SMS</Option>
                                                    <Option value="TMES">Traffic Manager Emailing and SMS</Option>
                                                    <Option value="Sales">Commercial</Option>
                                                </Select>

                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col span={10}></Col>
                                            <Col span={5}>
                                                <Button
                                                    loading={this.state.loadingUpdate}
                                                    className="btn btn-primary"
                                                    style={{ border: '1px solid gray', textAlign: 'center', width: '100%', height: 50, fontSize: 16 }}
                                                    onClick={this.onUpdateUser.bind(this)}
                                                >
                                                    {this.state.modalCTA}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal
                    id="new8"
                    isOpen={this.state.isOpenAdd}
                    onRequestClose={() => {
                        this.setState({
                            isOpenAdd: false
                        })
                    }}
                    shouldCloseOnOverlayClick={true}
                    style={bg}
                    ariaHideApp={false}
                >
                    <div style={{ background: '#001529', marginTop: '0px', textAlign: 'center', height: 50, width: '100%', marginLeft: '0px' }}>
                        <h3 style={{ color: "#fff", padding: 5 }}>ADDING NEW USER</h3>
                    </div>
                    <span
                        style={{ color: "#C23616", marginTop: '-48px', fontSize: 35, float: 'right', cursor: 'pointer' }} onClick={() => {
                            this.setState({ isOpenAdd: false })
                        }}
                    >
                        X
                    </span>
                    <div style={{ marginLeft: '25px' }}>
                        <br />
                        <Row>
                            <Col span={6} style={{ textAlign: 'center' }}>
                                <span style={{ fontSize: 16 }}>Username:</span>
                            </Col>
                            <Col span={16}>
                                <Input
                                    type="text"
                                    size="middle"
                                    style={{ border: '1px solid gray', color: 'rgb(25, 42, 86)', fontWeight: 700, textAlign: 'center' }}
                                    value={this.state.user_name}
                                    onChange={(e) => {
                                        this.setState({ user_name: e.target.value })
                                    }}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col span={6} style={{ textAlign: 'center' }}>
                                <span style={{ fontSize: 16 }}>Apikey:</span>
                            </Col>
                            <Col span={14}>
                                <Input
                                    type="text"
                                    size="middle"
                                    style={{ border: '1px solid gray', color: 'rgb(25, 42, 86)', fontWeight: 700, textAlign: 'center' }}
                                    value={this.state.user_apikey}
                                    onChange={(e) => {
                                        this.setState({ user_apikey: e.target.value })
                                    }}
                                />
                            </Col>
                            <Col span={1}></Col>
                            <Col span={3} style={{ textAlign: 'center' }}>
                                <button
                                    className="btn btn-outline-info"
                                    style={{ height: 30, padding: 0, width: '100%', fontSize: 16 }}
                                    onClick={() => {
                                        var random = Math.random().toString(36).toUpperCase().substring(2, 8) + '-' + Math.random().toString(36).toLowerCase().substring(2, 8) + '-' + Math.random().toString(36).toUpperCase().substring(2, 8) + '-' + Math.random().toString(36).toLowerCase().substring(2, 8) + '-' + Math.random().toString(36).toUpperCase().substring(2, 8);
                                        this.setState({ user_apikey: random })
                                    }}
                                >
                                    Generate
                                </button>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col span={6} style={{ textAlign: 'center' }}>
                                <span style={{ fontSize: 16 }}>Stats Apikey:</span>
                            </Col>
                            <Col span={16}>
                                <Input
                                    type="text"
                                    size="middle"
                                    style={{ border: '1px solid gray', color: 'rgb(25, 42, 86)', fontWeight: 700, textAlign: 'center' }}
                                    value={this.state.user_stats_apikey}
                                    onChange={(e) => {
                                        this.setState({ user_stats_apikey: e.target.value })
                                    }}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col span={6} style={{ textAlign: 'center' }}>
                                <span style={{ fontSize: 16 }}>Email:</span>
                            </Col>
                            <Col span={16}>
                                <Input
                                    size="middle"
                                    style={{ border: '1px solid gray', color: 'rgb(25, 42, 86)', fontWeight: 700, textAlign: 'center' }}
                                    value={this.state.user_email}
                                    onChange={(e) => {
                                        this.setState({ user_email: e.target.value })
                                    }}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col span={6} style={{ textAlign: 'center' }}>
                                <span style={{ fontSize: 16 }}>Password:</span>
                            </Col>
                            <Col span={12}>
                                <Input.Password
                                    size="middle"
                                    style={{ border: '1px solid gray', color: 'rgb(25, 42, 86)', fontWeight: 700, textAlign: 'center', width: '100%' }}
                                    value={this.state.user_mdp}
                                    onChange={(e) => {
                                        this.setState({ user_mdp: e.target.value })
                                    }}
                                />
                            </Col>
                            <Col span={4} style={{ marginLeft: 5 }}>
                                <button
                                    className="btn btn-outline-info"
                                    style={{ height: 30, padding: 0, width: '100%', fontSize: 16 }}
                                    onClick={() => {
                                        var random = Math.random().toString(36).substring(2, 10)
                                        this.setState({
                                            user_mdp: random,
                                            user_mdp1: random
                                        })
                                    }}
                                >
                                    Generate
                                </button>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col span={6} style={{ textAlign: 'center' }}>
                                <span style={{ fontSize: 16 }}>Confirm:</span>
                            </Col>
                            <Col span={12}>
                                <Input.Password
                                    size="middle"
                                    style={{ border: '1px solid gray', color: 'rgb(25, 42, 86)', fontWeight: 700, width: '100%' }}
                                    value={this.state.user_mdp1}
                                    onChange={(e) => {
                                        this.setState({ user_mdp1: e.target.value })
                                    }}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col span={6} style={{ textAlign: 'center' }}>
                                <span style={{ fontSize: 16 }}>Roles:</span>
                            </Col>
                            <Col span={16}>
                                <Select
                                    size="medium"
                                    style={{ border: '1px solid gray', color: '#192a56', fontWeight: 700, textAlign: 'center', width: '100%', fontSize: 19 }}
                                    value={this.state.user_roles}
                                    onChange={(value) => {
                                        this.setState({ user_roles: value })
                                    }}
                                >
                                    <Option value="Admin">Admin</Option>
                                    <Option value="RD">Delivery Manager</Option>
                                    <Option value="TME">Traffic Manager Emailing</Option>
                                    <Option value="TMS">Traffic Manager SMS</Option>
                                    <Option value="TMS">Traffic Manager Emailing and SMS</Option>
                                    <Option value="Sales">Commercial</Option>
                                </Select>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col span={6} style={{ textAlign: 'center' }}>
                                <span style={{ fontSize: 16 }}>Status:</span>
                            </Col>
                            <Col span={16}>
                                <RadioGroup onChange={(e) => { this.setState({ user_status: e.target.value }) }} defaultValue={this.state.user_status} >
                                    <Radio value={1} style={{ color: 'black' }}>Enabled</Radio>
                                    <Radio value={0} style={{ color: 'black' }}>Disabled</Radio>
                                </RadioGroup>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <div style={{ textAlign: 'center', margin: '0 auto', width: 211 }}>
                                <button
                                    className="btn btn-outline-primary"
                                    style={{ width: '100%', fontSize: 16 }}
                                    onClick={this.onAddUser.bind(this)}
                                >
                                    Submit
                                </button>
                            </div>

                        </Row>
                    </div>


                </Modal>

                <Modal
                    id="new-disable-user"
                    isOpen={this.state.isDisable}
                    onRequestClose={() => { this.setState({ isDisable: false }) }}
                    shouldCloseOnOverlayClick={true}
                    style={bg}
                >
                    <div stye={{ fontSize: '5px !important' }}>
                        <div
                            style={{ background: '#001529', marginTop: '0px', textAlign: 'center', width: '100%', marginLeft: '0px' }}
                        >
                            <h3 style={{ color: "#fff", padding: 5 }}>{this.state.modalStatus} USER</h3>
                        </div>
                        <span
                            style={{ color: "#C23616", marginTop: '-49px', fontSize: 35, float: 'right', cursor: 'pointer' }} onClick={() => { this.setState({ isDisable: false }) }}
                        >
                            X
                        </span>

                        <div style={{ marginLeft: 100 }}>
                            <div>
                                <div style={{ textAlign: 'center' }}>
                                    <h3>Do you want really {this.state.modalStatus.toLowerCase()} the user "{this.state.user_name}" at id {this.state.user_id}?</h3>
                                </div>
                                <br />
                                <Row>
                                    <Col span={12} style={{ textAlign: 'center' }}>
                                        <Button
                                            className="btn btn-info"
                                            onClick={() => { this.setState({ isDisable: false }) }}
                                            style={{ width: '50%', height: 50, fontSize: 16 }}
                                        >
                                            CANCEL
                                        </Button>
                                    </Col>

                                    <Col span={12} style={{ textAlign: 'center' }}>
                                        <Button
                                            loading={this.state.loadingStatus}
                                            className="btn btn-warning"
                                            onClick={this.onDisableUser.bind(this)}
                                            style={{ width: '50%', height: 50, fontSize: 16 }}
                                        >
                                            SUBMIT
                                        </Button>
                                    </Col>

                                </Row>
                            </div>
                        </div>

                    </div>
                </Modal>

                <Modal
                    id="new-disable-user"
                    isOpen={this.state.cacheClear}
                    onRequestClose={() => { this.setState({ cacheClear: false }) }}
                    shouldCloseOnOverlayClick={true}
                    style={bg}
                >
                    <div stye={{ fontSize: '5px !important' }}>
                        <div
                            style={{ background: '#001529', marginTop: '0px', textAlign: 'center', width: '100%', marginLeft: '0px' }}
                        >
                            <h3 style={{ color: "#fff", padding: 5 }}>{this.state.modalStatus}</h3>
                        </div>
                        <span
                            style={{ color: "#C23616", marginTop: '-49px', fontSize: 35, float: 'right', cursor: 'pointer' }} onClick={() => { this.setState({ cacheClear: false }) }}
                        >
                            X
                        </span>

                        <div style={{ marginLeft: 100 }}>
                            <div>
                                <div style={{ textAlign: 'center' }}>
                                    <h3>Do you want really to {this.state.modalStatus.toLowerCase()} for the user "{this.state.user_name}" at id {this.state.user_id}?</h3>
                                </div>
                                <br />
                                <Row>
                                    <Col span={12} style={{ textAlign: 'center' }}>
                                        <Button
                                            className="btn btn-info"
                                            onClick={() => { this.setState({ cacheClear: false }) }}
                                            style={{ width: '50%', height: 50, fontSize: 16 }}
                                        >
                                            CANCEL
                                        </Button>
                                    </Col>

                                    <Col span={12} style={{ textAlign: 'center' }}>
                                        <Button
                                            loading={this.state.loadingCache}
                                            className="btn btn-warning"
                                            onClick={this.onClearChache.bind(this)}
                                            style={{ width: '50%', height: 50, fontSize: 16 }}
                                        >
                                            SUBMIT
                                        </Button>
                                    </Col>

                                </Row>
                            </div>
                        </div>

                    </div>
                </Modal>
            </div>
        )
    }
}
export default User
