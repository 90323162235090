import React, { Component } from 'react';
import "../../css/Loginv2.css"
import { Button } from 'antd';
import logo from '../../images/icone.png'
import { login_user, check_user } from '../Utilities/User';
import { base64_decode, base64_encode } from '../Utilities/Utilities';
import { Redirect } from 'react-router-dom'

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogin: false,
            displayError: 'none',
            displaySuccess: 'none',
            loadingLogin: false,
            email: null,
            password: null,
            username: null,
        }
    }


    componentDidMount() {
        this.checkStatusLogging()
        localStorage.setItem('defaulttabcrea', '3')
    }

    checkStatusLogging(){
        var user_id = localStorage.getItem(base64_encode('user_id'))
        var apikey = localStorage.getItem(base64_encode('apikey'))
        console.log(user_id, apikey)
        if(user_id !== null && apikey !== null){
        	Promise.resolve(check_user(base64_decode(user_id), base64_decode(apikey))).then((value) => {
                if(value['status'] === "success"){
        			sessionStorage.setItem(base64_encode('ktksession'), base64_encode('loggin'))
                    this.setState({
                        isLogin: true,
                        displaySuccess: 'block'
                    })                            			
                }
            })
        }
    }

    onLogin(e) {
        this.setState({ loadingLogin: true })
        Promise.resolve(login_user(this.state.email, this.state.password)).then((value) => {
            if (value['status'] === 'success') {
                localStorage.setItem('defaultDeliveryTab', "1")
                localStorage.setItem('defaulttabcrea', '3')
                localStorage.setItem(base64_encode('endpoint'), base64_encode(value['endpoint']))
                localStorage.setItem(base64_encode('email'), base64_encode(this.state.email))
                localStorage.setItem(base64_encode('apikey'), base64_encode(value['data'][0]['apikey']))
                localStorage.setItem(base64_encode('username'), base64_encode(value['data'][0]['username']))
                localStorage.setItem(base64_encode('roles'), base64_encode(value['data'][0]['roles']))
                localStorage.setItem(base64_encode('apikey'), base64_encode(value['data'][0]['apikey']))
                localStorage.setItem(base64_encode('user_id'), base64_encode(value['data'][0]['user_id']))
                localStorage.setItem(base64_encode('stats_apikey'), base64_encode(value['data'][0]['stats_apikey']))
                localStorage.setItem(base64_encode('avatar'), base64_decode(value['data'][0]['avatar']))
                sessionStorage.setItem(base64_encode('avatar'), base64_decode(value['data'][0]['avatar']))
                sessionStorage.setItem(base64_encode('ktksession'), base64_encode('loggin'))
                this.setState({ isLogin: true, loadingLogin: false, displayError: 'none', displaySuccess: 'block', username: value['data'][0]['username'] })
            } else {
                this.setState({ isLogin: false, loadingLogin: false, displayError: 'block', displaySuccess: 'none' })
            }
        })
    }

    render() {
        if (this.state.isLogin === true) {
            return (<Redirect to={{
                pathname: '/app/creativities/task'
            }} />)
        }
        return (
            <div className='gx-app-login-wrap'>
                <div className='gx-app-login-container'>
                    <div className="gx-app-login-main-content">
                        <div className="gx-app-logo-content">
                            <div className="gx-app-logo-content-bg">
                                <img src={logo} alt="Neature" />
                            </div>
                        </div>

                        <div style={{ width: '60%' }}>
                            <div style={{ width: '100%', height: '80%', padding: 20, marginTop: 30 }}>
                                <div className="form-group" style={{textAlign: 'left'}}>
                                    <label>Email address</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        placeholder="Enter email"
                                        style={{fontSize: 15}}
                                        value={this.state.email}
                                        onChange={(e) => { this.setState({ email: e.target.value }) }}
                                    />
                                </div>
                                <br />
                                <div className="form-group" style={{textAlign: 'left'}}>
                                    <label>Password</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="exampleInputPassword1"
                                        placeholder="Password"
                                        style={{fontSize: 15}}
                                        value={this.state.password}
                                        onChange={(e) => { this.setState({ password: e.target.value }) }}
                                    />
                                </div>
                                <br />
                                <div className="form-check" style={{textAlign: 'left'}}>
                                    <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                                    <label className="form-check-label">Remember me </label>
                                </div>
                                <div className="form-check" style={{ textAlign: 'center' }}>
                                    <Button
                                        loading={this.state.loadingLogin}
                                        className="btn btn-primary"
                                        style={{ textAlign: 'center', width: '60%' }}
                                        onClick={this.onLogin.bind(this)}
                                    >Log in</Button>
                                </div>
                                <br />
                                <div style={{ display: this.state.displayError }}>
                                    <div>
                                        <div className="alert alert-danger" role="alert" style={{ textAlign: 'center', width: '100%', margin: '0 auto' }}>
                                            <h6>AUTHENTIFICATION ERROR, PLEASE TRY AGAIN !!!</h6>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: this.state.displaySuccess, marginTop: '-10px' }}>
                                    <div>
                                        <div className="alert alert-success" role="alert" style={{ textAlign: 'center', width: '80%', margin: '0 auto' }}>
                                            Welcome back <strong>{this.state.username} &#128512;</strong>, please wait a bit during getting data !
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>





                    </div>
                </div>
            </div>
        );
    }
}

Login.propTypes = {

};

export default Login;