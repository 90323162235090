import axios from 'axios'
import { base64_decode, base64_encode } from './Utilities';

export async function add_router(name,email,firstname,lastname,zipcode,civility,birthday) {
    try {
        var fd = new FormData() 
        fd.append('user_id', base64_decode(localStorage.getItem(base64_encode('user_id'))))
        fd.append('apikey', base64_decode(localStorage.getItem(base64_encode('apikey'))))
        fd.append('router_name', name)
        fd.append('router_email',email)
        fd.append('router_firstname', firstname)
        fd.append('router_lastname', lastname)
        fd.append('router_zipcode', zipcode)
        fd.append('router_civility', civility)
        fd.append('router_birthday', birthday)
        let res = await axios({
            url: process.env.REACT_APP_ENDPOINT_ROUTER_CREATE,
            method: 'post',
            timeout: 30000,
            // headers: {
            //     'Content-Type': 'application/json',
            // },
            data: fd
        }) 
        return res.data
    }
    catch (err) {
        console.error('error on add router  ' + err);
        return null
    }
}

export async function liste_router(user_id, apikey) {
    try {
        if(localStorage.getItem(base64_encode('all_router')) !== null){
            return JSON.parse(base64_decode(localStorage.getItem(base64_encode('all_router'))))
        }else{
            var fd = new FormData() 
            fd.append('user_id', user_id)
            fd.append('apikey', apikey)
            let res = await axios({
                url: process.env.REACT_APP_ENDPOINT_ROUTER_LIST,
                method: 'post',
                timeout: 30000,
                // headers: {
                //     'Content-Type': 'application/json',
                // },
                data: fd
            }) 
            localStorage.setItem(base64_encode('all_router'), base64_encode(JSON.stringify(res.data)))
            return res.data
        }
    }
    catch (err) {
        console.error('error getting liste router  ' + err);
        return []
    }
}

export async function disable_router(id) {
    try {
        var fd = new FormData() 
        fd.append('user_id', base64_decode(localStorage.getItem(base64_encode('user_id'))))
        fd.append('apikey', base64_decode(localStorage.getItem(base64_encode('apikey'))))
        fd.append('router_id', id)
        let res = await axios({
            url: process.env.REACT_APP_ENDPOINT_ROUTER_DISABLE,
            method: 'post',
            timeout: 30000,
            // headers: {
            //     'Content-Type': 'application/json',
            // },
            data: fd
        }) 
        return res.data
    }
    catch (err) {
        console.error('error on disable  router  ' + err);
        return null
    }
}

export async function update_router(id, name, email, firstname, lastname, zipcode, civility, birthday){
    try {
        var fd = new FormData() 
        fd.append('user_id', base64_decode(localStorage.getItem(base64_encode('user_id'))))
        fd.append('apikey', base64_decode(localStorage.getItem(base64_encode('apikey'))))
        fd.append('router_id', id)
        fd.append('router_name',name)
        fd.append('router_email',email)
        fd.append('router_firstname',firstname)
        fd.append('router_lastname',lastname)
        fd.append('router_zipcode',zipcode)
        fd.append('router_civility',civility)
        fd.append('router_birthday',birthday)
        let res = await axios({
            url: process.env.REACT_APP_ENDPOINT_ROUTER_UPDATE,
            method: 'post',
            timeout: 30000,
            // headers: {
            //     'Content-Type': 'application/json',
            // },
            data: fd
        }) 
        return res.data
    }
    catch (err) {
        console.error('error getting liste router  ' + err);
        return null
    }
}