import axios from 'axios'
import { base64_decode, base64_encode } from './Utilities';
// var user_id = base64_decode(localStorage.getItem(base64_encode('user_id')))
// var apikey = base64_decode(localStorage.getItem(base64_encode('apikey')))
export async function liste_crea(user_id, apikey,nb,onlyme) {
    try {
        var fd = new FormData() 
        fd.append('user_id', user_id)
        fd.append('apikey', apikey)
        fd.append('number', nb)
        fd.append('onlyme', onlyme)

        let res = await axios({
            url: process.env.REACT_APP_ENDPOINT_CREA_LISTE,
            method: 'post',
            timeout: 30000,
            // headers: {
            //     'Content-Type': 'application/json',
            // },
            data: fd
        }) 
        return res.data
    }
    catch (err) {
        console.error('error authentification ' + err);
        return null
    }
}

export async function detail_crea(user_id, apikey,creaid) {
    try {
        var fd = new FormData() 
        fd.append('user_id', user_id)
        fd.append('apikey', apikey)
        fd.append('creaid', creaid)
        let res = await axios({
            url: process.env.REACT_APP_ENDPOINT_CREA_DETAILS,
            method: 'post',
            timeout: 30000,
            // headers: {
            //     'Content-Type': 'application/json',
            // },
            data: fd
        }) 
        return res.data
    }
    catch (err) {
        console.error('error authentification ' + err);
        return null
    }
}

export async function update_crea(user_id, apikey,baseid,creaid, code, code_type, draft_type, user_id_creativity, draft_id) {
    try {
        var fd = new FormData() 
        fd.append('user_id', user_id)
        fd.append('apikey', apikey)
        fd.append('creaid', creaid)
        fd.append('baseid', baseid)
        fd.append('code', code)
        fd.append('code_type', code_type)
        fd.append('draft_type', draft_type)
        fd.append('user_id_creativity', user_id_creativity)
        fd.append('draft_id', draft_id)
        let res = await axios({
            url: process.env.REACT_APP_ENDPOINT_CREA_UPDATE,
            method: 'post',
            timeout: 30000,
            // headers: {
            //     'Content-Type': 'application/json',
            // },
            data: fd
        }) 
        return res.data
    }
    catch (err) {
        console.error('error authentification ' + err);
        return null
    }
}

export async function filterCrea(user_id, apikey,filterby, limit) {
    try {
        var fd = new FormData() 
        fd.append('user_id', user_id)
        fd.append('apikey', apikey)
        fd.append('filterby', filterby)
        fd.append('limit', limit)
        let res = await axios({
            url: process.env.REACT_APP_ENDPOINT_CREA_FILTER,
            method: 'post',
            timeout: 30000,
            // headers: {
            //     'Content-Type': 'application/json',
            // },
            data: fd
        }) 
        return res.data
    }
    catch (err) {
        console.error('error authentification ' + err);
        return null
    }
}

export async function reupload_crea(user,apikey,tagsname, pixels, img, link,jumping, comment,  compress, crea_id, crea_base_id,user_id, username, trigger=false) {
    try {
        var fd = new FormData()
        fd.append('user_id', user)
        fd.append('apikey', apikey)
        fd.append('creapixeloption', pixels)
        fd.append('creaimgoption', img)
        fd.append('crealinkoption',link)
        fd.append('creajumpingoption',jumping)
        fd.append('creacommentoption',comment)
        fd.append('creacompressoption',compress)
        fd.append('creatriggeroption',trigger)
        fd.append('crea_id', crea_id)
        fd.append('creabaseid', crea_base_id)
        fd.append('user_id_creativity', user_id)
        fd.append('user_id_creativity_name', username)
        fd.append('tagsname', tagsname)
        let res = await axios({
            url: process.env.REACT_APP_ENDPOINT_CREA_REOPTIMIZE,
            method: 'post',
            timeout: 30000,
            // headers: {
            //     'Content-Type': 'application/json',
            // },
            data: fd
        }) 
        return res.data
    }
    catch (err) {
        console.error('error reupload crea => ' + err);
        return null
    }
}

export async function upload_multiple(user_id, apikey,creaid, data, clientid, clientoptin) {
    try {
        var fd = new FormData() 
        fd.append('user_id', user_id)
        fd.append('apikey', apikey)
        fd.append('stats_apikey', base64_decode(localStorage.getItem(base64_encode('stats_apikey'))))
        fd.append('crea_id', creaid)
        fd.append('data', JSON.stringify(data))
        fd.append('client_id', clientid)
        if(clientoptin !== null){
            fd.append('process', 1)
        }else{
            fd.append('process', 0)
        }
        let res = await axios({
            url: process.env.REACT_APP_ENDPOINT_KONTICREA_BAT_OPTIMIZE_MULTIPLE,
            method: 'post',
            timeout: 30000,
            // headers: {
            //     'Content-Type': 'application/json',
            // },
            data: fd
        }) 
        return res.data
    }
    catch (err) {
        console.error('error authentification ' + err);
        return null
    }
}

export async function reuse(user_id, apikey,creaid, baseid, segments) {
    try {
        var fd = new FormData() 
        fd.append('user_id', user_id)
        fd.append('apikey', apikey)
        fd.append('stats_apikey', base64_decode(localStorage.getItem(base64_encode('stats_apikey'))))
        fd.append('crea_id', creaid)
        fd.append('segment', JSON.stringify(segments))
        fd.append('baseid', baseid)

        let res = await axios({
            url: process.env.REACT_APP_ENDPOINT_KONTICREA_BAT_REUSE,
            method: 'post',
            timeout: 30000,
            // headers: {
            //     'Content-Type': 'application/json',
            // },
            data: fd
        }) 
        return res.data
    }
    catch (err) {
        console.error('error authentification ' + err);
        return null
    }
}


