import React, { Component } from 'react'
import back from '../../images/back.png'
import { Redirect } from 'react-router'
import axios from 'axios';
import TextArea from 'antd/lib/input/TextArea';
import { base64_encode, base64_decode, generateKey } from '../Utilities/Utilities';
import { getMainData } from '../Utilities/GetMainData'
import { SyncOutlined, MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
// import AceEditor from 'react-ace';
// import "ace-builds/src-noconflict/mode-html";
// import "ace-builds/src-noconflict/theme-eclipse";
// import "ace-builds/webpack-resolver";
import Editor from '@monaco-editor/react';
import timezoneJson from '../../timezone.json'
import Modal from 'react-modal'
import { DatePicker, Tooltip, Row, Col, Select, Switch, Input, Checkbox, Button, Radio, AutoComplete, message, Tag, Collapse, Dropdown, Menu, Table } from 'antd';
import ReactSrcDocIframe from 'react-srcdoc-iframe';
import loading from '../../images/loading.gif'
import loadingIcon from '../../images/loading3.gif'

import moment from 'moment';
import { Picker } from 'emoji-mart'
import emoji_icon from '../../images/emoji.png'
import 'emoji-mart/css/emoji-mart.css'
import '../../css/Dashboard.css'
import info from '../../images/info.jpg'
import { ColumnsType, } from 'antd/es/table';
import { list_top_object } from '../Utilities/ExpertSender';
import { base_user } from '../Utilities/User';
import { all_base, footer_konticrea, header_konticrea } from '../Utilities/Base';
import { list_client, list_tags } from '../Utilities/Stats';

const { Option } = Select;
const { Panel } = Collapse;

interface DataType {
    key: React.Key;
    object: string;
    tog: float;
    cr: float;
}

const columns: ColumnsType<DataType> = [
    {
        title: "Date",
        dataIndex: "started",
        width: '9%'
    },
    {
        title: "Campagn",
        dataIndex: "advertiser",
        width: '12%'
    },
    {
        title: "Object",
        dataIndex: "subject",
        width: '30%',
        ellipsis: true
    },
    {
        title: "Aboutis",
        dataIndex: "number_aboutis",
        width: '8%',
        sorter: (a, b) => a.number_aboutis - b.number_aboutis,

    },
    {
        title: "Sent",
        dataIndex: "sent_count",
        width: '7%',
        sorter: (a, b) => a.sent_count - b.sent_count,

    },
    {
        title: "Bounce",
        dataIndex: "bounces_percent",
        width: '7%',
        sorter: (a, b) => a.bounces_percent - b.bounces_percent,

    },
    {
        title: "TO",
        dataIndex: "opens_percent",
        width: '7%',
        // defaultSortOrder : 'descend',
        sorter: (a, b) => a.opens_percent - b.opens_percent,
    },
    {
        title: 'CR',
        dataIndex: 'clicks_unique_percent',
        width: '7%',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.clicks_unique_percent - b.clicks_unique_percent,
    },
    {
        title: "Complaints",
        dataIndex: "complaints_percent",
        width: '9%'
    },
    {
        title: "Desabo",
        dataIndex: "unsubscriptions_percent",
        width: '7%',
        sorter: (a, b) => a.unsubscriptions_percent - b.unsubscriptions_percent,
    },
    {
        title: 'Click/Desabo',
        dataIndex: 'rapport_clikers_unsub',
        width: '10%',
        sorter: (a, b) => a.rapport_clikers_unsub - b.rapport_clikers_unsub,
    }
]
const dateFormat = 'YYYY-MM-DD HH:mm:ss';
const defaultTime = '00:00:00';
class NewCreativities extends Component {
    constructor(props) {
        super(props)

        this.state = {
            // liste 
            liste_tags: [],
            liste_image_without_alt: [],
            liste_keywords: [],
            liste_base: [],
            liste_base_all: [],
            liste_base_display: [],
            liste_country: [],
            liste_advertisers: [],
            liste_clients: [],
            liste_affiliate: [],
            template_header: [],
            template_footer: [],
            liste_model: [],
            liste_draft: [],
            liste_links: [],
            liste_segment: [],
            liste_suppression: [],
            top_object: [],
            //
            user_id: base64_decode(localStorage.getItem(base64_encode('user_id'))),
            username: base64_decode(localStorage.getItem(base64_encode('username'))),
            apikey: base64_decode(localStorage.getItem(base64_encode('apikey'))),
            stats_apikey: base64_decode(localStorage.getItem(base64_encode('stats_apikey'))),
            endpoint: base64_decode(localStorage.getItem(base64_encode('endpoint'))),
            isBack: false,
            title: 'NEW CREATIVITY',
            //modal html step
            show_code: false,
            step1: 'block',
            step2: 'none',
            submitDisplay: 'block',
            previousDisplay: 'none',
            finishDisplay: 'none',
            show_emoji: false,
            show_emoji_preheader: false,
            // creativity
            crea_code: null,
            crea_tags_id: null,
            crea_tags_name: null,
            crea_base_id: null,
            crea_advertiser_name: null,
            crea_advertiser_id: null,
            crea_clients_name: null,
            crea_clients_id: null,
            crea_affiliate_name: null,
            crea_affiliate_id: null,
            crea_creativities: null,
            crea_brand: null,
            //default value for database
            crea_base_country_id: null,
            crea_base_country_name: null,
            crea_base_currency_id: null,
            crea_base_currency_name: null,
            crea_base_default_header_id: null,
            crea_base_default_footer_id: null,
            crea_base_default_header_konticrea_id: null,
            crea_base_default_footer_konticrea_id: null,
            crea_base_default_pixels: false,
            crea_base_default_img: false,
            crea_base_default_link: false,
            crea_base_default_cutting: false,
            crea_base_default_comment: false,
            crea_base_default_compress: true,
            crea_base_default_stats: false,
            crea_base_default_router: false,
            crea_base_default_trigger: false,
            crea_base_default_test: false,
            crea_base_default_jumping: true,
            //creativities stats:
            crea_stats_comment: null,
            crea_stats_segment: [],
            crea_stats_suppression: [],
            crea_stats_revenu: [{ "modelid": null, "modelname": "", "payout": "", "coment": "" }],
            crea_stats_date: moment().add(1, 'days'),
            crea_stats_status: '1',
            crea_stats_scheduled_type: '1',
            crea_stats_available_from: moment().add(1, 'days').hour(6).minute(0).second(0),
            crea_stats_available_until: moment().add(30, 'days').hour(23).minute(59).second(0),
            crea_timezone: 'W. Europe Standard Time',
            //creativities router:
            crea_deliveryMethod: 'SendTimeOptimization',
            display_sto_value: 'block',
            display_throttling_value: 'none',
            crea_sto_value: '24h',
            crea_throttling_value: 8,
            crea_router_sender: null,
            crea_router_object: null,
            crea_router_object_length: 0,
            crea_router_preheader: null,
            crea_router_preheader_length: 0,
            crea_router_type: "Newsletters",
            crea_router_message_id: null,
            router_notification: 'none',
            //creativities sharing:
            crea_sharing_status: true,
            crea_sharing_service: ["FACEBOOK", "TWITTER", "WHATSAPP"],
            crea_sharing_title: [],
            crea_sharing_url: null,
            crea_sharing_position: null,
            //creativities google annotations:
            crea_annotation_status: true,
            crea_annotation_subject: null,
            crea_annotation_offer: null,
            isCroping: true,
            // loading icon & image
            dislpay_loading_object: 'none',
            dislpay_data_object: 'none',
            displayLoadingIconDatabase: 'block',
            displayLoadingImageDatabase: 'none',
            displayLoadingIconClients: 'block',
            displayLoadingImageClients: 'none',
            displayLoadingIconTags: 'block',
            displayLoadingImageTags: 'none',
            displayLoadingIconTemplate: 'block',
            displayLoadingImageTemplate: 'none',
            loading_upload: false,
            // other
            show_database_all: false,
            continue_anyway: false,
            // segments request
            display_age_gender: 'block',
            age_gender_checked: false,
            dwh_age: [],
            dwh_sexe: [],
            dwh_domain_family: [],
            display_volume: 'block',
            dwh_csp: [],
            dwh_score_of_landlords: [],
            dwh_score_of_individual_houses: [],
            dwh_score_of_tax_households: [],
            dwh_poverty_score: [],
            dwh_score_median_income: [],
            dwh_zipcode: null,
            volume_checked: false,
            dwh_volume: null,
            dwh_optin_telemarketing: false,
            dwh_optin_sms: false,
            dwh_fed_now: false,
            defaultActivePanel: ["2", "3"],
            activeKeyPanel: ["2", "3"],

        }
    }


    componentDidMount() {
        this.getData()
        if (this.props.location.state !== undefined) {
            this.getCreativitiesDetails(this.props.location.state.crea_id)
        }
    }

    getData() {
        Promise.resolve(getMainData(this.state.user_id, this.state.apikey, this.state.stats_apikey, 0, true, true, true, false, true, true, true, true, false)).then((value) => {
            this.setState({
                liste_tags: value['all_tags'],
                liste_base: value['base_user'],
                liste_base_all: value['all_base'],
                liste_base_display: value['base_user'],
                liste_country: value['all_country'],
                liste_clients: value['all_clients'],
                liste_model: value['all_models'],
            })
        });
    }

    getListSegments(database_id) {
        var fd = new FormData()
        fd.append('user_id', this.state.user_id)
        fd.append('apikey', this.state.apikey)
        fd.append('stats_apikey', this.state.stats_apikey)
        fd.append('base_id', database_id)
        axios.post(process.env.REACT_APP_ENDPOINT_STATS_SEGMENT, fd)
            .then(res => {
                var dwh_request = {
                    "id": 0,
                    "name": "DWH Request"
                }
                res.data.unshift(dwh_request)
                this.setState({
                    liste_segment: res.data
                })
            })
    }

    getListSuppression(database_id) {
        var fd = new FormData()
        fd.append('user_id', this.state.user_id)
        fd.append('apikey', this.state.apikey)
        fd.append('stats_apikey', this.state.stats_apikey)
        fd.append('base_id', database_id)
        axios.post(process.env.REACT_APP_ENDPOINT_STATS_SUPPRESSION, fd)
            .then(res => {
                console.log(res.data)
                this.setState({
                    liste_suppression: res.data
                })
            })
    }

    getListHeaderKonticrea(value) {
        try {
            Promise.resolve(header_konticrea(this.state.user_id, this.state.apikey, value)).then(data => {
                this.setState({
                    template_header: data
                })
            })
        }
        catch (err) {
            console.error('error getting list header => ' + err);
            return null
        }
    }

    getListFooterKonticrea(value) {
        try {
            Promise.resolve(footer_konticrea(this.state.user_id, this.state.apikey, value)).then(data => {
                this.setState({
                    template_footer: data
                })
            })
        }
        catch (err) {
            console.error('error getting list footer => ' + err);
            return null
        }
    }

    getCreativitiesDetails(crea_id) {
        try {
            var fd = new FormData()
            fd.append('user_id', this.state.user_id)
            fd.append('apikey', this.state.apikey)
            fd.append('creaid', crea_id)
            axios.post(process.env.REACT_APP_ENDPOINT_CREA_DETAILS, fd)
                .then(res => {
                    this.onAdvertiserNewCreaChange(res.data[0]['advertiser'])
                    var sharing_service = res.data[0]['sharingservice'] === 'None' ? null : (base64_decode(res.data[0]['sharingservice'])).replaceAll("'", '"')
                    var promotion_offer = null
                    if (base64_decode(res.data[0]['object']) !== "null") {
                        var object = base64_decode(res.data[0]['object'])
                        var status = object.match(/\d+%/)
                        if (status !== null) {
                            promotion_offer = '-' + status[0]
                        }
                    }
                    this.setState({
                        //crea detail
                        crea_brand: res.data[0]['brand'],
                        crea_advertiser_id: res.data[0]['advid'],
                        crea_advertiser_name: res.data[0]['advertiser'],
                        // crea_name: ,
                        crea_creativities: res.data[0]['creativity'],
                        crea_clients_id: parseInt(res.data[0]['clientid']),
                        crea_affiliate_id: parseInt(res.data[0]['affid']),
                        crea_tags_id: parseInt(res.data[0]['tags_id']),
                        crea_stats_revenu: JSON.parse(res.data[0]['stats_model']),
                        crea_stats_comment: res.data[0]['stats_comment'] !== "null" ? res.data[0]['stats_comment'] : null,
                        // crea_stats_segment: res.data[0]['stats_segment'] !== "null" ? res.data[0]['stats_segment'] : null,
                        crea_base_country_id: parseInt(res.data[0]['stats_countryid']),
                        crea_base_currency_id: parseInt(res.data[0]['stats_currencyid']),
                        //crea_sending
                        crea_router_sender: base64_decode(res.data[0]['sender']) !== "null" ? base64_decode(res.data[0]['sender']) : null,
                        crea_router_object: base64_decode(res.data[0]['object']) !== "null" ? base64_decode(res.data[0]['object']) : null,
                        crea_router_object_length: base64_decode(res.data[0]['object']) !== "null" ? (base64_decode(res.data[0]['object'])).length : 0,
                        crea_router_preheader: base64_decode(res.data[0]['preheader']) !== "null" ? base64_decode(res.data[0]['preheader']) : null,
                        crea_router_preheader_length: base64_decode(res.data[0]['preheader']) !== "null" ? (base64_decode(res.data[0]['preheader'])).length : 0,
                        crea_router_type: res.data[0]['router_message'],
                        // crea_router_message_id: res.data[0]['router_id'] !== 0 ? res.data[0]['router_id'] : null,
                        //saring:
                        crea_sharing_status: true,
                        crea_sharing_service: res.data[0]['sharingservice'] === 'None' ? ["FACEBOOK", "TWITTER", "WHATSAPP"] : JSON.parse(sharing_service),
                        // crea_sharing_url: base64_decode(res.data[0]['sharingurl']) !== 'null' ? JSON.parse(base64_decode(res.data[0]['sharingurl'])) : null,
                        crea_sharing_position: res.data[0]['sharingposition'] === 'None' ? null : base64_decode(res.data[0]['sharingposition']),
                        crea_sharing_title: base64_decode(res.data[0]['object']) !== "null" ? base64_decode(res.data[0]['object']) : null,
                        //google annotation
                        // crea_annotation_status: res.data[0]['promotion_status'] === 1 ? true : false,
                        crea_annotation_status: true,
                        crea_annotation_subject: base64_decode(res.data[0]['object']) !== "null" ? base64_decode(res.data[0]['object']) : null,
                        crea_annotation_offer: promotion_offer
                    })
                    this.onGetListAffiliate(res.data[0]['clientid'])
                    this.getTopObject(res.data[0]['tags_id'])
                    //this.onClientsNewCreaChange(res.data[0]['clientid'])
                })
        } catch (error) {
            console.log(error)
        }



    }

    getListTags() {
        try {
            Promise.resolve(list_tags()).then(value => {
                this.setState({
                    liste_tags: value
                })
            })
        }
        catch (err) {
            console.error('error getting list tags ' + err);
            return null
        }
    }

    getListKeywords(value) {
        var tagsname = ""
        this.state.liste_tags.map(item => {
            if (parseInt(value) === parseInt(item['id'])) {
                tagsname = item['tag']
            }
            return null
        })
        var fd = new FormData()
        fd.append('user_id', this.state.user_id)
        fd.append('apikey', this.state.apikey)
        fd.append('tagsname', tagsname)
        axios.post(process.env.REACT_APP_ENDPOINT_KONTICREA_LIST_KEYWORD, fd)
            .then(res => {
                if (res.data['keywords'] !== null) {
                    this.setState({
                        liste_keywords: (res.data['keywords']).sort(),
                        crea_tags_name: tagsname
                    })
                }
            })
    }

    getListBaseUser() {
        try {
            Promise.resolve(base_user(this.state.user_id, this.state.apikey)).then(value => {
                this.setState({
                    liste_base: value,
                    liste_base_display: value,
                    show_database_all: false
                })
            })
        }
        catch (err) {
            console.error('eror getting user base ' + err);
        }
    }

    getListBaseAll() {
        try {
            Promise.resolve(all_base(this.state.user_id, this.state.apikey)).then(value => {
                this.setState({
                    liste_base_all: value
                })
            })
        }
        catch (err) {
            console.error('eror getting user base ' + err);
        }
    }

    getListAdvertisers(advername) {
        try {
            var fd = new FormData()
            fd.append('user_id', this.state.user_id)
            fd.append('apikey', this.state.apikey)
            fd.append('stats_apikey', base64_decode(localStorage.getItem(base64_encode('stats_apikey'))))
            fd.append('advername', advername)
            axios.post(process.env.REACT_APP_ENDPOINT_STATS_ADVERTISER, fd)
                .then(res => {
                    this.setState({ liste_advertisers: res.data })
                })
        }
        catch (err) {
            console.log('error getting liste advertiser from stats ' + err)
            return null
        }
    }

    getListClients() {
        try {
            Promise.resolve(list_client(this.state.user_id, this.state.apikey)).then(value => {
                this.setState({
                    liste_clients: value
                })
            })
        }
        catch (err) {
            console.error('error authentification ' + err);
            return null
        }
    }

    updateText = (value, index) => {
        var images = [...this.state.liste_image_without_alt]
        images[index]["alt"] = value
        this.setState({ images })
        return null
    }

    renderAltDescription() {
        return this.state.liste_image_without_alt.map((item, index) =>
            <tr key={index}>
                <td style={{ verticalAlign: 'top', height: 200, width: '40%', backgroundColor: 'currentcolor' }}>
                    <img alt="image_alt_description" src={item['image']} style={{ width: '80%', height: '80%', borderRadius: 5, display: 'block', margin: '0 auto' }} />
                </td>
                <td style={{ width: '30%', textAlign: 'center' }}>
                    <Input
                        size="medium"
                        style={{ width: '80%', margin: '0 auto', fontSize: 16 }}
                        key={index}
                        type="text"
                        value={item["alt"]}
                        onChange={(e) => this.updateText(e.target.value, index)}
                    // onChange={(e) => {console.log(e.target.value)}}
                    />
                </td>
                <td style={{ width: '30%' }}>
                    <div style={{ overflow: 'scroll', width: '100%', maxWidth: 300, height: 182, textAlign: 'center' }}>
                        {
                            this.state.liste_keywords.map(keyword => {
                                var key = generateKey()
                                return (
                                    <Tag
                                        key={key}
                                        closable="true"
                                        id="tag"
                                        color="blue"
                                        style={{ marginBottom: 6, textAlign: 'center', fontSize: 13, cursor: 'pointer' }}
                                        onClick={(e) => {
                                            const images = [...this.state.liste_image_without_alt];
                                            images[index]["alt"] = e.target.textContent
                                            this.setState({ images });
                                        }}
                                    >
                                        {keyword}
                                    </Tag>
                                )
                            })
                        }
                    </div>
                </td>
            </tr>

        )
    }

    onValidateHtmlCode() {
        var fd = new FormData()
        fd.append('creacode', base64_encode(this.state.crea_code))
        fd.append('user_id', this.state.user_id)
        fd.append('apikey', this.state.apikey)
        axios.post(process.env.REACT_APP_ENDPOINT_KONTICREA_VALIDATE_HTML, fd)
            .then(res => {
                var response = res.data['images']
                if (response.length === 0) {
                    this.onRequestListLinks()
                    this.setState({
                        show_code: false
                    })
                } else {
                    this.setState({
                        submitDisplay: 'none',
                        finishDisplay: 'block',
                        previousDisplay: 'block',
                        liste_image_without_alt: res.data['images'],
                        step1: 'none',
                        step2: 'block',
                        crea_sharing_position: 'footer'
                    })
                }

            })
    }

    onPreviousShowCode() {
        this.setState({
            submitDisplay: 'block',
            finishDisplay: 'none',
            previousDisplay: 'none',
            step1: 'block',
            step2: 'none',
        })
    }

    onFinishAltattribute() {
        var status = true
        this.state.liste_image_without_alt.map(item => {
            if (item['alt'] === "") {
                status = false;
            }
            return null

        })
        if (status === false) {
            message.warning('All images alt attributes need to be filled')
        } else {
            this.onRequestListLinks()
            this.setState({
                show_code: false, crea_sharing_position: 'footer'
            })
        }
    }

    onRequestListLinks() {
        var fd = new FormData()
        fd.append('user_id', this.state.user_id)
        fd.append('apikey', this.state.apikey)
        fd.append('user_id', this.state.user_id)
        fd.append('html', base64_encode(this.state.crea_code))
        fd.append('list_link', true)
        axios.post(process.env.REACT_APP_ENDPOINT_KONTICREA_LINKS_REQUEST, fd)
            .then(res => {
                var i = Math.floor(Math.random() * res.data['links'].length);
                this.setState({
                    liste_links: res.data['links'],
                    crea_sharing_url: res.data['links'][i]
                })
            })
    }

    onBaseChange(value) {
        this.setState({ crea_base_id: value })
        this.state.liste_base_display.map(item => {

            if (parseInt(item['id']) === parseInt(value)) {
                this.getListHeaderKonticrea(value)
                this.getListFooterKonticrea(value)
                console.log(item['router'])
                if (parseInt(item['router']) === 1) {
                    this.getListSegments(value)
                    this.getListSuppression(value)
                    this.getListDraft(value, this.state.crea_router_type)
                }
                this.setState({
                    crea_base_country_id: item['country'] !== null && item['country'] !== undefined ? parseInt(item['country']) : null,
                    crea_base_currency_id: item['currency'] !== null && item['currency'] !== undefined ? parseInt(item['currency']) : null,
                    crea_base_default_header_id: item['defaultHeaderId'] === undefined ? "null" : item['defaultHeaderId'],
                    crea_base_default_footer_id: item['defaultFooterId'] === undefined ? "null" : item['defaultFooterId'],
                    crea_router_sender: item['defaultSender'] !== 'null' ? item['defaultSender'] : null,
                    crea_base_default_header_konticrea_id: item['defaultHeaderKonticrea'] !== '0' ? item['defaultHeaderKonticrea'] : null,
                    crea_base_default_footer_konticrea_id: item['defaultFooterKonticrea'] !== '0' ? item['defaultFooterKonticrea'] : null,
                    crea_base_default_pixels: item['defaultpix'] === 1 || item['defaultpix'] === '1' ? true : false,
                    crea_base_default_img: item['defaultimg'] === 1 || item['defaultimg'] === '1' ? true : false,
                    crea_base_default_link: item['defaultlink'] === 1 || item['defaultlink'] === '1' ? true : false,
                    // crea_base_default_cutting: item['defaultcutting'] === 1 ? true : false,
                    crea_base_default_comment: item['defaultcomment'] === 1 ? true : false,
                    // crea_base_default_compress: item['defaultcompress'] === 1 ? true : false,
                    // crea_base_default_trigger: item['defaulttrigger'] === 0 ? false  : true,
                    crea_base_default_stats: item['stats_checked'] === 0 ? false : true,
                    crea_base_default_router: item['router_checked'] === 0 ? false : true,
                })
            }
            return null
        })
    }

    onAdvertiserNewCreaChange(value) {
        this.setState({ crea_advertiser_name: value })
        if (value.length < 3) {
            return null
        } else {
            this.getListAdvertisers(value)
        }
    }

    onGetListAffiliate(client_id) {
        this.state.liste_clients.map(item => {
            if (parseInt(item.id) === parseInt(client_id)) {
                this.setState({
                    liste_affiliate: item['affiliatemanagers']
                })
            }
            return null
        })
    }

    onClientsNewCreaChange(value) {
        this.setState({ liste_affiliate: [] })
        var crea = null
        var affiliate = []
        var client = null
        this.state.liste_clients.map(item => {
            if (parseInt(item.id) === parseInt(value)) {
                client = item['name']
                affiliate = item['affiliatemanagers']
                var today = new Date();
                var mm = String(today.getMonth() + 1).padStart(2, '0');
                var yyyy = today.getFullYear();
                today = mm + '-' + yyyy;
                if (this.props.location.state !== undefined) {
                    crea = (this.state.crea_advertiser_name + '-' + item['name'] + '-' + today).replace(' ', '-')
                } else {
                    crea = (this.state.crea_advertiser_name + '-' + item['name'] + '-' + today).replace(' ', '-')
                }
            }
            return null
        })
        this.setState({
            crea_clients_id: parseInt(value),
            liste_affiliate: affiliate,
            crea_client_name: client,
            crea_creativities: crea,
        })
    }

    onAffiliateNewCreaChange(value) {
        this.setState({ crea_affiliate_id: value })
        this.state.liste_affiliate.map(item => {
            if (parseInt(item.id) === parseInt(value)) {
                this.setState({
                    crea_affiliate_name: item['name']
                })
            }
            return null

        })
    }

    updateCreaName() {
        if (this.state.crea_advertiser_name !== null && this.state.crea_clients_name !== null) {
            var today = new Date();
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();
            today = mm + '-' + yyyy;
            var crea_name = this.state.crea_advertiser_name + '-' + this.state.crea_clients_name + '-' + today
            this.setState({
                crea_creativities: crea_name,
            })
        }
    }

    getListModel() {
        if (localStorage.getItem(base64_encode('stats_model')) === null) {
            var fd = new FormData()
            fd.append('user_id', this.state.user_id)
            fd.append('apikey', this.state.apikey)
            fd.append('stats_apikey', this.state.stats_apikey)
            axios.post(process.env.REACT_APP_ENDPOINT_STATS_MODELS, fd)
                .then(res => {
                    console.log(res.data)
                    localStorage.setItem(base64_encode('stats_model'), base64_encode(JSON.stringify(res.data)))
                    this.setState({
                        liste_model: res.data
                    })
                })
        } else {
            this.setState({
                liste_model: JSON.parse(base64_decode(localStorage.getItem(base64_encode('stats_model'))))
            })
        }
    }

    getListDraft(baseid, draft_type) {
        try {
            var fd = new FormData()
            fd.append('user_id', this.state.user_id)
            fd.append('apikey', this.state.apikey)
            fd.append('base_id', baseid)
            fd.append('draft_type', draft_type)
            axios.post(process.env.REACT_APP_ENDPOINT_ES_DRAFT_LIST, fd)
                .then(res => {
                    if (res.data['status'] !== undefined || res.data['message'] === "error getting list draft") {
                        this.setState({
                            liste_draft: []
                        })
                    } else {
                        this.setState({
                            liste_draft: res.data
                        })
                    }
                })
        }
        catch (err) {
            console.error('error getting list draft ' + err);
            return null
        }
    }

    onModelChange(modelid, modelname, i) {
        var old = this.state.crea_stats_revenu
        old[i]['modelid'] = modelid
        old[i]['modelname'] = modelname
        this.setState({
            crea_stats_revenu: old
        })
    }

    onPayoutChange(payout, i) {
        var old = this.state.crea_stats_revenu
        old[i]['payout'] = payout
        this.setState({
            crea_stats_revenu: old
        })
    }

    onCommentChange(comment, i) {
        var old = this.state.crea_stats_revenu
        old[i]['coment'] = comment
        this.setState({
            crea_stats_revenu: old
        })
    }

    onAddStatsRange() {
        var old = this.state.crea_stats_revenu
        old.push({ "modelid": null, "modelname": "", "payout": "" })
        this.setState({
            crea_stats_revenu: old
        })
    }

    onRemoveStatsRange(index) {
        var old = this.state.crea_stats_revenu
        old.splice(index, 1)
        this.setState({
            crea_stats_revenu: old
        })
    }

    createRevenuForm() {
        return this.state.crea_stats_revenu.map((el, i) =>
            <Row key={el}>
                <Col span={3} style={{ padding: 3, textAlign: 'center' }}>
                    <p>Model(<strong style={{ color: 'red' }}>*</strong>)</p>
                </Col>

                <Col span={8} style={{ padding: 3 }}>
                    <Select
                        size="medium"
                        showSearch
                        optionFilterProp="children"
                        style={{ width: '100%', color: '#192a56', textAlign: 'center' }}
                        value={el['modelid']}
                        onChange={
                            (value) => {
                                this.state.liste_model.map(model => {
                                    if (parseInt(model['id']) === parseInt(value)) {
                                        this.onModelChange(value, model['name'], i)
                                    }
                                    return null
                                })
                            }
                        }
                    >
                        {
                            this.state.liste_model.map(item => {
                                var key = generateKey()
                                return (
                                    <Option key={key} value={item['id']}>{item['name'].toUpperCase()}</Option>
                                )
                            })
                        }
                    </Select>
                </Col>

                <Col span={3} style={{ padding: 3, textAlign: 'center' }}>
                    <p>Payout</p>
                </Col>

                <Col span={8}>
                    <Row>
                        <Col span={6}>
                            <Input
                                size="medium"
                                value={el['payout']}
                                style={{ width: '100%', color: '#192a56', textAlign: 'center' }}
                                onChange={
                                    (e) => {
                                        this.onPayoutChange(e.target.value, i)
                                    }
                                }
                            />

                        </Col>
                        <Col span={1}></Col>
                        <Col span={17}>
                            <Input
                                size="medium"
                                value={el['coment']}
                                style={{ width: '98%', color: '#192a56', textAlign: 'center' }}
                                placeholder='comment here'
                                onChange={
                                    (e) => {
                                        this.onCommentChange(e.target.value, i)
                                    }
                                }
                            />
                        </Col>

                    </Row>
                </Col>

                {
                    i === 0 && <Col span={2} style={{ paddingLeft: 0, textAlign: 'center', paddingTop: 7 }}>
                        <Tooltip title="Add rows" placement="right">
                            <PlusCircleOutlined
                                style={{ fontSize: 20 }}
                                onClick={() => {
                                    this.onAddStatsRange()
                                }}
                            />
                        </Tooltip>
                    </Col>
                }

                {
                    i !== 0 && <Col span={2} style={{ paddingLeft: 0, textAlign: 'center', paddingTop: 7 }}>
                        <Tooltip title="Remove rows" placement="right">
                            <MinusCircleOutlined
                                style={{ fontSize: 20 }}
                                onClick={() => {
                                    this.onRemoveStatsRange(i)
                                }}
                            />
                        </Tooltip>
                    </Col>
                }

            </Row>
        )
    }

    addEmoji(e) {
        let emoji = e.native
        let old_object = ""
        if (this.state.crea_router_object === null) {
            old_object = ""
        } else {
            old_object = this.state.crea_router_object
        }
        let result = old_object + " " + emoji
        this.setState({ crea_router_object: result })
    }

    addEmojiPreheader(e) {
        let emoji = e.native
        let old_object = ""
        if (this.state.crea_router_preheader === null) {
            old_object = ""
        } else {
            old_object = this.state.crea_router_preheader
        }
        let result = old_object + " " + emoji
        this.setState({ crea_router_preheader: result })
    }

    onOptimizeCreativity() {
        this.setState({ loading_upload: true })
        var advertiser_id = 0
        this.state.liste_advertisers.map(item => {
            if (this.state.crea_advertiser_name === item['name']) {
                advertiser_id = parseInt(item['id'])
            }
            return null
        })


        if (this.state.crea_base_id === null || this.state.crea_base_country_id === null || this.state.crea_base_currency_id === null || this.state.crea_advertiser_name === null || this.state.crea_clients_id === null || this.state.crea_affiliate_id === null || this.state.crea_tags_id === null || this.state.crea_brand === null || this.state.crea_creativities === null || this.state.crea_code === null || this.state.crea_stats_available_from === null || advertiser_id === 0) {
            message.warning('All mandatory fields need to be filled', 2);
            this.setState({ loading_upload: false })
            if (this.state.crea_base_id === null) { message.info('DATABASE IS MANDATORY'); }
            if (this.state.crea_base_country_id === null) { message.info('COUNTRY IS MANDATORY'); }
            if (this.state.crea_base_currency_id === null) { message.info('CURRENCY IS MANDATORY'); }
            if (this.state.crea_advertiser_name === null) { message.info('ADVERTISER IS MANDATORY'); }
            if (advertiser_id === 0) { message.info('ADVERTISER MUST BE SELECTED FROM THE LIST'); }
            if (this.state.crea_clients_id === null) { message.info('CLIENT IS MANDATORY'); }
            if (this.state.crea_affiliate_id === null) { message.info('AFFILIATE IS MANDATORY'); }
            if (this.state.crea_tags_id === null) { message.info('TAGS IS MANDATORY'); }
            if (this.state.crea_brand === null) { message.info('BRAND IS MANDATORY'); }
            if (this.state.crea_creativities === null) { message.info('CREATIVITIES IS MANDATORY'); }
            if (this.state.crea_code === null) { message.info('HTML CODE IS EMPTY'); }
            if (this.state.crea_stats_available_from === null) { message.info('FIELD AVAILABLE FROM IS MANDATORY'); }
            return null
        }

        var check_models = true
        this.state.crea_stats_revenu.map(item => {
            if (item['modelid'] === null) {
                check_models = false
            }
            return null
        })

        if (check_models === false) {
            message.warning('All mandatory fields need to be filled');
            message.info('MODELS  IS MANDATORY');
            this.setState({
                loading_upload: false
            })
            return null
        }

        var tags_name = ""
        var dwh_tags = ""
        this.state.liste_tags.map(item => {
            if (parseInt(this.state.crea_tags_id) === parseInt(item['id'])) {
                tags_name = item['tag']
                dwh_tags = item['dwtag']
            }
            return null
        })

        var client_name = 0
        this.state.liste_clients.map(item => {
            if (this.state.crea_clients_id === item['id']) {
                client_name = item['name']
            }
            return null
        })
        var affiliate_name = 0
        this.state.liste_affiliate.map(item => {
            if (this.state.crea_affiliate_id === item['id']) {
                affiliate_name = item['name']
            }
            return null
        })
        var country_name = ""
        var country_dwh = ""
        var currency_name = ""
        var currency_dwh = ""

        this.state.liste_country.map(item => {
            if (this.state.crea_base_country_id === item['id']) {
                country_name = item['countrycode']
                country_dwh = item['dwname']
            }
            if (this.state.crea_base_currency_id === item['currencyid']) {
                if (item['currencyid'] === 1) {
                    currency_dwh = 'EURO'
                }
                if (item['currencyid'] === 3) {
                    currency_dwh = '’AUSTRALIAN_DOLLAR’'
                }
                if (item['currencyid'] === 4) {
                    currency_dwh = 'NEW_ZELEAND_DOLLAR'
                }
                if (item['currencyid'] === 5) {
                    currency_dwh = 'POUNDS'
                }
                if (item['currencyid'] === 6) {
                    currency_dwh = 'ZLOTY'
                }
                if (item['currencyid'] === 8) {
                    currency_dwh = 'NORWEGIAN_KRONER'
                }
                if (item['currencyid'] === 9) {
                    currency_dwh = 'NORWEGIAN_KRONER'
                }
                if (item['currencyid'] === 10) {
                    currency_dwh = 'PESO'
                }
                currency_name = item['currencyname']
            }
            return null
        })

        if (this.state.crea_base_default_router === true) {
            if (this.state.crea_router_object === null || this.state.crea_router_sender === null) {
                message.warning('ROUTER DATA FIELDS NEED TO BE FILLED WHEN SENDING TO THE ROUTER', 2);
                this.setState({
                    loading_upload: false
                })
                if (this.state.crea_router_object === null) { message.info('OBJECT IS MANDATORY'); }
                if (this.state.crea_router_sender === null) { message.info('SENDER IS MANDATORY'); }
                return null
            }
        }

        if (this.state.crea_sharing_status === true) {
            if (this.state.crea_sharing_url === null) {
                message.warning('URL NEED TO BE SET WHEN SHARING BUTTON IS ACTIVATE', 2);
                this.setState({
                    loading_upload: false
                })
                return null
            }
        }

        // if((this.state.crea_router_preheader === null || (this.state.crea_router_preheader).length === 0) && this.state.continue_anyway === false){
        //     console.log('test')
        //     this.setState({
        //         router_notification: 'block'
        //     })
        //     return null
        // }

        if (advertiser_id === 0) {
            message.warning('All mandatory fields need to be filled', 2);
            this.setState({
                loading_upload: false
            })
            message.info('ADVERTISER MUST BE SELECTED ON THE LIST');
            return null
        } else {
            console.log(advertiser_id, this.state.crea_advertiser_name)
            var fd = new FormData()
            fd.append('user_id', this.state.user_id)
            fd.append('apikey', this.state.apikey)
            fd.append('stats_apikey', this.state.stats_apikey)
            fd.append('creacode', base64_encode(this.state.crea_code))
            fd.append('creabaseid', this.state.crea_base_id)
            fd.append('creaname', this.state.crea_creativities)
            fd.append('brand', this.state.crea_brand)
            fd.append('creapixeloption', this.state.crea_base_default_pixels)
            fd.append('creaimgoption', this.state.crea_base_default_img)
            fd.append('creacutoption', this.state.crea_base_default_cutting)
            fd.append('crealinkoption', this.state.crea_base_default_link)
            fd.append('creacommentoption', this.state.crea_base_default_comment)
            fd.append('creacompressoption', this.state.crea_base_default_compress)
            fd.append('creatriggeroption', this.state.crea_base_default_trigger)
            fd.append('creajumpingoption', this.state.crea_base_default_jumping)
            fd.append('createst', this.state.crea_base_default_test)
            fd.append('creaadvertiserid', advertiser_id)
            fd.append('creaadvertisername', this.state.crea_advertiser_name)
            fd.append('creaclientid', this.state.crea_clients_id)
            fd.append('creaclientname', client_name)
            fd.append('creaaffiliateid', this.state.crea_affiliate_id)
            fd.append('creaaffiliateName', affiliate_name)
            fd.append('creatagsid', this.state.crea_tags_id)
            fd.append('creatagsname', tags_name)
            fd.append('header_id', this.state.crea_base_default_header_konticrea_id)
            fd.append('footer_id', this.state.crea_base_default_footer_konticrea_id)
            fd.append('liste_images_without_alt', JSON.stringify(this.state.liste_image_without_alt))
            fd.append('stats_status', this.state.crea_base_default_stats)
            fd.append('stats_revenu', JSON.stringify(this.state.crea_stats_revenu))
            fd.append('stats_countryid', this.state.crea_base_country_id)
            fd.append('stats_countryname', country_name)
            fd.append('stats_currencyid', this.state.crea_base_currency_id)
            fd.append('stats_currencyname', currency_name)
            fd.append('stats_segment', JSON.stringify(this.state.crea_stats_segment))
            fd.append('stats_suppression', JSON.stringify(this.state.crea_stats_suppression))
            fd.append('stats_comment', this.state.crea_stats_comment)
            fd.append('stats_date', moment(this.state.crea_stats_date).format(dateFormat).toString())
            fd.append('stats_campagn_status', this.state.crea_stats_status)
            fd.append('stats_campagn_type', this.state.crea_stats_scheduled_type)
            fd.append('stats_campagn_available_from', moment(this.state.crea_stats_available_from).format(dateFormat).toString())
            fd.append('stats_campagn_available_until', this.state.crea_stats_available_until !== null ? moment(this.state.crea_stats_available_until).format(dateFormat).toString() : null)
            fd.append('router_status', this.state.crea_base_default_router)
            fd.append('sender', this.state.crea_router_sender)
            fd.append('object', this.state.crea_router_object)
            fd.append('preheader', this.state.crea_router_preheader)
            fd.append('draftid', this.state.crea_router_message_id)
            fd.append('messageType', this.state.crea_router_type)
            fd.append('sharing', this.state.crea_sharing_status)
            fd.append('sharingservice', this.state.crea_sharing_service)
            fd.append('sharingurl', this.state.crea_sharing_url)
            fd.append('sharingtitle', this.state.crea_sharing_title)
            fd.append('sharingposition', this.state.crea_sharing_position)
            fd.append('promotion', this.state.crea_annotation_status)
            fd.append('promotion_subject', this.state.crea_annotation_subject)
            fd.append('promotion_discount_offer', this.state.crea_annotation_offer)
            //dwh_parameter
            // country_dwh
            fd.append('dwh_country', country_dwh)
            fd.append('dwh_currency', currency_dwh)
            fd.append('dwh_tags', dwh_tags)
            fd.append('dwh_max_volume', this.state.dwh_volume)
            fd.append('dwh_domain_family', JSON.stringify(this.state.dwh_domain_family))
            fd.append('dwh_gender', JSON.stringify(this.state.dwh_sexe))
            fd.append('dwh_age_range', JSON.stringify(this.state.dwh_age))
            fd.append('dwh_csp', JSON.stringify(this.state.dwh_csp))
            fd.append('dwh_landlords', JSON.stringify(this.state.dwh_score_of_landlords))
            fd.append('dwh_individual_house', JSON.stringify(this.state.dwh_score_of_individual_houses))
            fd.append('dwh_tax_house', JSON.stringify(this.state.dwh_score_of_tax_households))
            fd.append('dwh_poverty', JSON.stringify(this.state.dwh_poverty_score))
            fd.append('dwh_median', JSON.stringify(this.state.dwh_score_median_income))
            fd.append('dwh_zipcode', this.state.dwh_zipcode)
            fd.append('dwh_optin_telemarketing', this.state.dwh_optin_telemarketing === false ? 0 : 1)
            fd.append('dwh_optin_sms', this.state.dwh_optin_sms === false ? 0 : 1)
            fd.append('dwh_fed_now', this.state.dwh_fed_now === false ? 0 : 1)

            //delivery method
            fd.append('deliveryMethod', this.state.crea_deliveryMethod)
            fd.append('deliveryValue', this.state.crea_deliveryMethod === 'SendTimeOptimization' ? this.state.crea_sto_value : this.state.crea_throttling_value)
            
            axios.post(process.env.REACT_APP_ENDPOINT_KONTICREA_BAT_OPTIMIZE, fd)
                .then(res => {
                    setTimeout(() => {
                        this.setState({
                            isBack: true
                        })
                    }, 2000)
                })
        }
    }

    getTopObject(tags_id) {
        this.setState({
            dislpay_loading_object: 'block',
            dislpay_data_object: 'none'
        })
        var tagsname = null
        this.state.liste_tags.map(tag => {
            if (parseInt(tag['id']) === parseInt(tags_id)) {
                tagsname = tag['tag']
            }
            return null
        })
        Promise.resolve(list_top_object(this.state.user_id, this.state.apikey, tagsname)).then(value => {
            this.setState({
                top_object: value,
                dislpay_loading_object: 'none',
                dislpay_data_object: 'block'
            })
        })
    }

    onCountryChange(value) {
        this.state.liste_country.map(item => {
            if (parseInt(value) === item['id']) {
                this.setState({
                    crea_base_country_id: value,
                    crea_base_currency_id: item['currencyid']
                })
            }
            return null
        })
    }

    onDeliveryMethodChanged(value) {
        if (value === 'SendTimeOptimization') {
            this.setState({ crea_deliveryMethod: value, display_throttling_value: 'none', display_sto_value: 'block' })
        } else {
            this.setState({ crea_deliveryMethod: value, display_throttling_value: 'block', display_sto_value: 'none' })
        }
    }


    render() {
        

        if (this.state.isBack) {
            localStorage.setItem('defaulttabcrea', '3')
            return (<Redirect to={{
                pathname: '/app/creativities/task'
            }} />)
        }

        const bg = {
            overlay: {
                backgroundColor: "rgba(0, 0, 0, .5)",
                position: 'top'
            }
        }

        const data: DataType[] = this.state.top_object

        const menu = (
            <Menu style={{ textAlign: 'center', color: '#fff', width: 1250, height: '61vh', opacity: 1, marginLeft: '200px', padding: 0 }}>
                <div style={{ width: 1250, height: '100%', overflowX: 'hidden', overflowY: 'auto' }}>
                    <div style={{ display: `${this.state.dislpay_loading_object}` }}>
                        <img src={loadingIcon} style={{ width: 380, height: 350, marginTop: '8%' }} alt="loading ..." />
                    </div>
                    <div style={{ display: `${this.state.dislpay_data_object}` }}>
                        <Table
                            pagination={{ pageSize: 8 }}
                            columns={columns}
                            dataSource={data}
                            style={{ height: '60vh', overflowX: 'hidden', overflowY: 'scroll', width: '100%' }}
                            onRow={(record, i) => {
                                return {
                                    onClick: event => {
                                        var object = record['subject']
                                        var status = object.match(/\d+%/)
                                        var offer = null
                                        if (status !== null) {
                                            offer = '-' + status[0]
                                        }
                                        this.setState({
                                            crea_router_object: record['subject'],
                                            crea_annotation_subject: record['subject'],
                                            crea_annotation_offer: offer,
                                            crea_sharing_title: record['subject']
                                        })
                                    }

                                };
                            }}
                        />
                    </div>
                </div>

            </Menu>
        );

        return (
            <div style={{ height: '99vh', overflowX: 'hidden', overflowY: 'auto'}}>
                <img
                    alt="back"
                    src={back}
                    style={{ width: 40, height: 40, marginTop: '-2px', marginLeft: '-2px', position: 'relative', cursor: 'finger' }}
                    onClick={() => {
                        this.setState({
                            isBack: true
                        })
                    }}
                />
                <div style={{ fontSize: 13, padding: 0, margin: 0 }}>
                    <Row>
                        <Col span={12} style={{width: '48vh'}}>
                            <div style={{ margin: '0 auto', textAlign: 'center', width: '60%' }}>
                                <span style={{ textDecoration: 'underline' }}><strong>OPTIMIZATION PROCESS</strong></span>
                            </div>
                            <Row style={{ padding: 5 }}>
                                <Col span={3} style={{ padding: 3, textAlign: 'center' }}>
                                    <p>Base(<strong style={{ color: 'red' }}>*</strong>)</p>
                                </Col>
                                <Col span={8}>
                                    <Select
                                        size="medium"
                                        showSearch
                                        optionFilterProp="children"
                                        style={{ width: '100%', color: '#192a56', textAlign: 'center' }}
                                        defaultValue={this.state.crea_base_id}
                                        value={this.state.crea_base_id}
                                        onChange={
                                            (value) => {
                                                this.onBaseChange(value)
                                            }
                                        }
                                    >
                                        {
                                            this.state.liste_base_display !== null && this.state.liste_base_display.map((item) => {
                                                var key = generateKey()
                                                return (
                                                    <Option key={key} value={item.id}>{item.basename}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Col>
                                <Col span={2} style={{ textAlign: 'center', padding: 3 }}>
                                    <label style={{ padding: 3, fontWeight: 400 }}>MyDBs</label>
                                </Col>
                                <Col span={2} style={{ padding: 3 }}>
                                    <Switch
                                        defaultChecked={this.state.show_database_all}
                                        checked={this.state.show_database_all}
                                        onChange={(e) => {
                                            var data = this.state.liste_base_all
                                            if (e === true) {
                                                this.setState({
                                                    show_database_all: e,
                                                    liste_base_display: data
                                                })
                                            } else {
                                                this.setState({
                                                    show_database_all: e,
                                                    liste_base_display: data
                                                })
                                            }
                                        }}
                                    />
                                </Col>
                                <Col span={1} style={{ padding: 5 }}>
                                    <label style={{ fontWeight: 400 }}>All</label>
                                </Col>
                                <Col span={6} style={{ textAlign: 'center' }}>
                                    <button
                                        style={{ width: '80%', fontSize: 12 }}
                                        className="btn btn-outline-info"
                                        onClick={() => { this.setState({ show_code: true }) }}
                                    >
                                        HTML CODE
                                    </button>
                                </Col>
                                <Col span={2} style={{ textAlign: 'center' }}>
                                    <div style={{ display: `${this.state.displayLoadingIconDatabase}` }}>
                                        <SyncOutlined
                                            style={{ fontSize: '18px', color: '#23bcbe' }}
                                            onClick={() => {
                                                localStorage.removeItem(base64_encode('base_user'))
                                                localStorage.removeItem(base64_encode('all_base'))
                                                this.setState({
                                                    displayLoadingImageDatabase: 'block',
                                                    displayLoadingIconDatabase: 'none',
                                                    show_database_all: false
                                                })
                                                this.getListBaseUser()
                                                this.getListBaseAll()
                                                setTimeout(() => {
                                                    this.setState({
                                                        displayLoadingImageDatabase: 'none',
                                                        displayLoadingIconDatabase: 'block',
                                                    })
                                                    message.info('List database was refreshed', 2)
                                                }, 2000)

                                            }
                                            }
                                        />
                                    </div >
                                    <div style={{ display: `${this.state.displayLoadingImageDatabase}` }}>
                                        <img
                                            src={loading}
                                            style={{ width: 25, height: 25 }}
                                            alt="loading"
                                        />
                                    </div>
                                </Col>
                            </Row>
                            {/* <br /> */}
                            <Row style={{ padding: 5 }}>
                                <Col span={3} style={{ padding: 3, textAlign: 'center' }}>
                                    <p>Country(<strong style={{ color: 'red' }}>*</strong>)</p>
                                </Col>
                                <Col span={8}>
                                    <Select
                                        size="medium"
                                        showSearch
                                        optionFilterProp="children"
                                        style={{ width: '100%', color: '#192a56', textAlign: 'center' }}
                                        value={this.state.crea_base_country_id}
                                        defaultValue={this.state.crea_base_country_id}
                                        onChange={(value) => {
                                            this.onCountryChange(value)
                                        }}
                                    >
                                        {
                                            this.state.liste_country.map(item => {
                                                var key = generateKey()
                                                return (
                                                    <Option key={key} value={item.id}>{item.countrycode}</Option>
                                                )
                                            })
                                        }

                                    </Select>
                                </Col>
                                <Col span={3} style={{ padding: 3, textAlign: 'center' }}>
                                    <p>Currency(<strong style={{ color: 'red' }}>*</strong>)</p>
                                </Col>
                                <Col span={8}>
                                    <Select
                                        size="medium"
                                        showSearch
                                        optionFilterProp="children"
                                        style={{ textAlign: 'center', width: '100%', color: '#192a56', fontWeight: 700 }}
                                        value={this.state.crea_base_currency_id}
                                        defaultValue={this.state.crea_base_currency_id}
                                        onChange={(value) => {
                                            this.setState({
                                                crea_base_currency_id: value
                                            })
                                        }}
                                    >
                                        {
                                            this.state.liste_country.map(item => {
                                                var key = generateKey()
                                                return (
                                                    <Option key={key} value={item.currencyid}>{item.currencyname}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Col>
                            </Row>
                            {/* <br /> */}
                            <Row style={{ padding: 5 }}>
                                <Col span={3} style={{ padding: 3, textAlign: 'center' }}>
                                    <p>Advertiser(<strong style={{ color: 'red' }}>*</strong>)</p>
                                </Col>
                                <Col span={8} style={{ textAlign: 'center' }}>
                                    <AutoComplete
                                        style={{ textAlign: 'center', fontSize: 16, fontFamily: 'Lato', width: '100%', color: '#192a56', fontWeight: 800 }}
                                        value={this.state.crea_advertiser_name}
                                        defaultValue={this.state.crea_advertiser_name}
                                        onChange={this.onAdvertiserNewCreaChange.bind(this)}
                                    >
                                        {

                                            this.state.liste_advertisers !== null && this.state.liste_advertisers.map(item => {
                                                var key = generateKey()
                                                return (
                                                    <Option style={{ fontSize: 16, fontFamily: 'Lato' }} key={key} value={item.name}>{item.name}</Option>
                                                )
                                            })
                                        }
                                    </AutoComplete>
                                </Col>
                                <Col span={3} style={{ padding: 3, textAlign: 'center' }}>
                                    <p>Client(<strong style={{ color: 'red' }}>*</strong>)</p>
                                </Col>
                                <Col span={8}>
                                    <Select
                                        showSearch
                                        optionFilterProp="children"
                                        style={{ width: '100%', color: '#192a56', textAlign: 'center' }}
                                        value={this.state.crea_clients_id}
                                        defaultValue={this.state.crea_clients_id}
                                        onChange={this.onClientsNewCreaChange.bind(this)}
                                    >
                                        {
                                            this.state.liste_clients !== null && this.state.liste_clients.map(item => {
                                                if (item['affiliatemanagers'].length === 0) {
                                                    return null
                                                } else {
                                                    var key = generateKey()
                                                    return (
                                                        <Option style={{ fontSize: 14, fontFamily: 'Lato', color: '#192a56', fontWeight: 600 }} key={key} value={item.id}>{item.name}</Option>
                                                    )
                                                }
                                            })
                                        }

                                    </Select>
                                </Col>
                                <Col span={2} style={{ textAlign: 'center' }}>
                                    <div style={{ display: `${this.state.displayLoadingIconClients}` }}>
                                        <SyncOutlined
                                            style={{ fontSize: '18px', color: '#23bcbe' }}
                                            onClick={
                                                () => {
                                                    this.setState({
                                                        displayLoadingImageClients: 'block',
                                                        displayLoadingIconClients: 'none',
                                                    })
                                                    localStorage.removeItem(base64_encode('Stats_clients'))
                                                    setTimeout(() => {
                                                        this.getListClients()
                                                        this.setState({
                                                            displayLoadingImageClients: 'none',
                                                            displayLoadingIconClients: 'block',
                                                        })
                                                    }, 2000)
                                                }
                                            }
                                        />
                                    </div >
                                    <div style={{ display: `${this.state.displayLoadingImageClients}` }}>
                                        <img
                                            alt="loading 2"
                                            src={loading}
                                            style={{ width: 25, height: 25 }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            {/* <br /> */}
                            <Row style={{ padding: 5 }}>
                                <Col span={3} style={{ padding: 3, textAlign: 'center' }}>
                                    <p>Affiliate(<strong style={{ color: 'red' }}>*</strong>)</p>
                                </Col>
                                <Col span={8}>
                                    <Select
                                        size="medium"
                                        showSearch
                                        optionFilterProp="children"
                                        style={{ width: '100%', color: '#192a56', textAlign: 'center' }}
                                        value={this.state.crea_affiliate_id}
                                        defaultValue={this.state.crea_affiliate_id}
                                        onChange={this.onAffiliateNewCreaChange.bind(this)}
                                    >
                                        {

                                            this.state.liste_affiliate !== null && this.state.liste_affiliate.map(item => {
                                                var key = generateKey()
                                                return (
                                                    <Option style={{ fontSize: 16, fontFamily: 'Lato' }} key={key} value={item.id}>{item.name}</Option>
                                                )
                                            })
                                        }

                                    </Select>
                                </Col>
                                <Col span={3} style={{ padding: 3, textAlign: 'center' }}>
                                    <p>Tags(<strong style={{ color: 'red' }}>*</strong>)</p>
                                </Col>
                                <Col span={8}>
                                    <Select
                                        size="medium"
                                        showSearch
                                        optionFilterProp="children"
                                        style={{ width: '100%', color: '#192a56', textAlign: 'center' }}
                                        value={this.state.crea_tags_id}
                                        defaultValue={this.state.crea_tags_id}
                                        onChange={
                                            (value) => {
                                                this.getListKeywords(value)
                                                this.getTopObject(value)
                                                this.setState({
                                                    crea_tags_id: value
                                                })
                                            }
                                        }
                                    >
                                        {
                                            this.state.liste_tags !== null && this.state.liste_tags.map((item) => {
                                                var key = generateKey()
                                                return (
                                                    <Option key={key} value={item['id']}>{item.tag}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Col>
                                <Col span={2} style={{ textAlign: 'center' }}>
                                    <div style={{ marginLeft: 0, display: `${this.state.displayLoadingIconTags}` }}>
                                        <SyncOutlined
                                            style={{ fontSize: '18px', color: '#23bcbe' }}
                                            onClick={
                                                () => {
                                                    this.setState({
                                                        displayLoadingImageTags: 'block',
                                                        displayLoadingIconTags: 'none',
                                                    })
                                                    localStorage.removeItem(base64_encode('tags'))
                                                    this.getListTags()
                                                    setTimeout(() => {
                                                        this.setState({
                                                            displayLoadingImageTags: 'none',
                                                            displayLoadingIconTags: 'block',
                                                        })
                                                    }, 2000)
                                                }
                                            }
                                        />
                                    </div >
                                    <div style={{ marginLeft: 15, display: `${this.state.displayLoadingImageTags}` }}>
                                        <img
                                            alt="loading 3"
                                            src={loading}
                                            style={{ width: 25, height: 25 }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            {/* <br /> */}
                            <Row style={{ padding: 5 }}>
                                <Col span={3} style={{ padding: 3, textAlign: 'center' }}>
                                    <p>Brand(<strong style={{ color: 'red' }}>*</strong>)</p>
                                </Col>
                                <Col span={8}>
                                    <Input
                                        type="text"
                                        size="medium"
                                        id="field_style"
                                        value={this.state.crea_brand}
                                        defaultValue={this.state.crea_brand}
                                        onChange={
                                            (e) => {
                                                this.setState({
                                                    crea_brand: e.target.value
                                                })
                                            }
                                        }
                                        style={{ width: '100%', color: '#192a56', textAlign: 'center' }}
                                    />
                                </Col>
                                <Col span={3} style={{ padding: 3, textAlign: 'center' }}>
                                    <p>Creativities(<strong style={{ color: 'red' }}>*</strong>)</p>
                                </Col>
                                <Col span={8}>
                                    <Input
                                        type="text"
                                        size="medium"
                                        id="field_style"
                                        style={{ width: '100%', color: '#192a56', textAlign: 'center' }}
                                        value={this.state.crea_creativities}
                                        defaultValue={this.state.crea_creativities}
                                        onChange={
                                            (e) => {
                                                this.setState({
                                                    crea_creativities: e.target.value
                                                })
                                            }
                                        }
                                        onClick={this.updateCreaName.bind(this)}
                                    />
                                </Col>
                            </Row>
                            {/* <br /> */}
                            <Row style={{ padding: 5 }}>
                                <Col span={3} style={{ padding: 3, textAlign: 'center' }}>
                                    <p>Header</p>
                                </Col>
                                <Col span={8}>
                                    <Select
                                        size="medium"
                                        showSearch
                                        optionFilterProp="children"
                                        style={{ width: '100%', color: '#192a56', textAlign: 'center' }}
                                        value={this.state.crea_base_default_header_konticrea_id}
                                        onChange={
                                            (value) => {
                                                this.setState({
                                                    crea_base_default_header_konticrea_id: value
                                                })
                                            }
                                        }
                                    >
                                        <Option key="1" value={null}>do not add header</Option>
                                        {
                                            this.state.template_header && this.state.template_header.map(item => {
                                                var key = generateKey()
                                                return (
                                                    <Option key={key} value={item['headerid']}>{item['name']}</Option>
                                                )
                                            })
                                        }

                                    </Select>
                                </Col>
                                <Col span={3} style={{ padding: 3, textAlign: 'center' }}>
                                    <p>Footer</p>
                                </Col>
                                <Col span={8}>
                                    <Select
                                        size="medium"
                                        showSearch
                                        optionFilterProp="children"
                                        style={{ width: '100%', color: '#192a56', textAlign: 'center' }}
                                        value={this.state.crea_base_default_footer_konticrea_id}
                                        onChange={
                                            (value) => {
                                                this.setState({
                                                    crea_base_default_footer_konticrea_id: value
                                                })
                                            }
                                        }
                                    >
                                        <Option key="1" value={null}>do not add footer</Option>
                                        {
                                            this.state.template_footer && this.state.template_footer.map(item => {
                                                var key = generateKey()
                                                return (
                                                    <Option key={key} value={item['footerid']}>{item['name']}</Option>
                                                )
                                            })
                                        }

                                    </Select>
                                </Col>
                                <Col span={2} style={{ textAlign: 'center' }}>
                                    <div style={{ marginLeft: 0, display: `${this.state.displayLoadingIconTemplate}` }}>
                                        <SyncOutlined
                                            style={{ fontSize: '18px', color: '#23bcbe' }}
                                            onClick={
                                                () => {
                                                    this.setState({
                                                        displayLoadingImageTemplate: 'block',
                                                        displayLoadingIconTemplate: 'none',
                                                    })
                                                    localStorage.removeItem(base64_encode('header_konticrea_' + this.state.crea_base_id))
                                                    localStorage.removeItem(base64_encode('footer_konticrea_' + this.state.crea_base_id))
                                                    this.getListHeaderKonticrea(this.state.crea_base_id)
                                                    this.getListFooterKonticrea(this.state.crea_base_id)

                                                    setTimeout(() => {
                                                        this.setState({
                                                            displayLoadingImageTemplate: 'none',
                                                            displayLoadingIconTemplate: 'block',
                                                        })
                                                    }, 2000)
                                                }
                                            }
                                        />
                                    </div >

                                    <div style={{ marginLeft: 15, display: `${this.state.displayLoadingImageTemplate}` }}>
                                        <img
                                            alt="loading 4"
                                            src={loading}
                                            style={{ width: 25, height: 25 }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            {/* <br /> */}
                            <Row style={{ padding: 5 }}>
                                <Col span={3} style={{ padding: 3, textAlign: 'center' }}>
                                    <p>Segments</p>
                                </Col>
                                <Col span={8}>
                                    <Select
                                        mode="multiple"
                                        optionFilterProp="children"
                                        showSearch
                                        size="medium"
                                        style={{ width: '100%', margin: '0 auto', color: '#192a56', fontWeight: 700 }}
                                        value={this.state.crea_stats_segment}
                                        onChange={(value) => {
                                            this.setState({ crea_stats_segment: value })
                                        }}
                                    >
                                        <Option key="123156456" value="0">Base NOT FR</Option>
                                        {
                                            this.state.liste_segment && this.state.liste_segment.map(item => {
                                                var key = generateKey()
                                                return (
                                                    <Option key={key} value={parseInt(item.id)}>{item.id + ' - ' + item.name}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Col>
                                <Col span={3} style={{ padding: 3, textAlign: 'center' }}>
                                    <p>Suppression</p>
                                </Col>
                                <Col span={8}>
                                    <Select
                                        mode="multiple"
                                        optionFilterProp="children"
                                        showSearch
                                        size="medium"
                                        style={{ width: '100%', margin: '0 auto', color: '#192a56', fontWeight: 700 }}
                                        value={this.state.crea_stats_suppression}
                                        onChange={(value) => {
                                            this.setState({ crea_stats_suppression: value })
                                        }}
                                    >
                                        {
                                            this.state.liste_suppression && this.state.liste_suppression.map(item => {
                                                var key = generateKey()
                                                return (
                                                    <Option key={key} value={parseInt(item.Id)}>{item.Id + ' - ' + item.Name}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Col>
                                {/* <Col span={2} style={{ textAlign: 'center' }}>
                                    <div style={{ marginLeft: 0, display: `${this.state.displayLoadingIconTemplate}` }}>
                                        <SyncOutlined
                                            style={{ fontSize: '18px', color: '#23bcbe' }}
                                            onClick={
                                                () => {
                                                    this.setState({
                                                        displayLoadingImageTemplate: 'block',
                                                        displayLoadingIconTemplate: 'none',
                                                    })
                                                    localStorage.removeItem('header_konticrea_' + this.state.crea_base_id)
                                                    localStorage.removeItem('footer_konticrea_' + this.state.crea_base_id)
                                                    setTimeout(() => {
                                                        this.setState({
                                                            displayLoadingImageTemplate: 'none',
                                                            displayLoadingIconTemplate: 'block',
                                                        })
                                                    }, 2000)
                                                }
                                            }
                                        />
                                    </div >

                                    <div style={{ marginLeft: 15, display: `${this.state.displayLoadingImageTemplate}` }}>
                                        <img
                                            alt="loading 4"
                                            src={loading}
                                            style={{ width: 25, height: 25 }}
                                        />
                                    </div>
                                </Col> */}
                            </Row>
                            {/* <br /> */}
                            {this.createRevenuForm()}
                            {/* <br /> */}
                            <Row style={{ padding: 5 }}>
                                <Col span={3} style={{ padding: 3, textAlign: 'center' }}>
                                    <p>Comment</p>
                                </Col>
                                <Col span={8}>
                                    <Input
                                        value={this.state.crea_stats_comment}
                                        size="medium"
                                        id="field_style"
                                        style={{ width: '100%', color: '#192a56', textAlign: 'center' }}
                                        onChange={(e) => { this.setState({ crea_stats_comment: e.target.value }) }}
                                    />
                                </Col>
                                <Col span={3} style={{ padding: 3, textAlign: 'center' }}>
                                    <p>Status</p>
                                </Col>
                                <Col span={8}>
                                    <Select
                                        size="medium"
                                        showSearch
                                        optionFilterProp="children"
                                        style={{ width: '100%', color: '#192a56', textAlign: 'center' }}
                                        value={this.state.crea_stats_status}
                                        onChange={(value) => {
                                            this.setState({
                                                crea_stats_status: value
                                            })
                                        }}
                                    >
                                        <Option value="1">BAT not send</Option>
                                        <Option value="2">BAT sent, no approved</Option>
                                        <Option value="3">BAT approved</Option>
                                        <Option value="4">Campaign scheduled</Option>
                                        <Option value="5">Campaign sent</Option>
                                        <Option value="6">Campaign billed</Option>
                                        <Option value="7">Campaign Cancelled</Option>
                                    </Select>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={3} style={{ padding: 3, textAlign: 'center' }}>
                                    <p>Timezone</p>
                                </Col>
                                <Col span={19}>
                                    <Select
                                        optionFilterProp="children"
                                        showSearch
                                        size="medium"
                                        style={{ width: '99.3%', margin: '0 auto', color: '#192a56', fontWeight: 700, textAlign: 'center' }}
                                        value={this.state.crea_timezone}
                                        onChange={(value) => {
                                            this.setState({ crea_timezone: value })
                                        }}
                                    >
                                        {timezoneJson.map(item => (
                                            <Option value={item['Value']}>
                                                {item['Text']}
                                            </Option>
                                        ))}
                                    </Select>

                                </Col>
                            </Row>
                            {/* <br /> */}
                            <Row style={{ padding: 5 }}>
                                <Col span={3} style={{ padding: 5, textAlign: 'center' }}>
                                    <p>Date Scheduled</p>
                                </Col>
                                <Col span={8} style={{ textAlign: 'center' }}>
                                    <DatePicker
                                        // showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }} 
                                        showTime
                                        format={dateFormat}
                                        style={{ margin: '0 auto', width: '100%', textAlign: 'center' }}
                                        defaultValue={moment().add(1, 'days').hour(6).minute(0).second(0)}
                                        disabledDate={current => {
                                            return current && current <= moment().subtract(1, 'days');
                                        }}
                                        onChange={(a, b) => {
                                            this.setState({
                                                crea_stats_date: a,
                                                crea_stats_available_from: a,
                                                // crea_stats_available_until: a.add(30, 'days')
                                            })
                                        }}
                                    />
                                </Col>
                                <Col span={3} style={{ padding: 3, textAlign: 'center' }}>
                                    <p>Scheduled Type</p>
                                </Col>
                                <Col span={8}>
                                    <Select
                                        size="medium"
                                        showSearch
                                        optionFilterProp="children"
                                        style={{ width: '100%', color: '#192a56', textAlign: 'center' }}
                                        value={this.state.crea_stats_scheduled_type}
                                        onChange={(value) => {
                                            this.setState({
                                                crea_stats_scheduled_type: value
                                            })
                                        }}
                                    >
                                        <Option value="1">Normal</Option>
                                        <Option value="2">Split Test</Option>
                                        <Option value="3">ES - Split Test Single Message</Option>
                                        <Option value="4">Multiple Shoots</Option>
                                    </Select>
                                </Col>

                            </Row>

                            <Row style={{ padding: 5 }}>
                                <Col span={3} style={{ paddingTop: 9, textAlign: 'center' }}>
                                    <p>Delivery Method</p>
                                </Col>
                                <Col span={8}>
                                    <Select
                                        optionFilterProp="children"
                                        showSearch
                                        size="medium"
                                        style={{ width: '100%', color: '#192a56', textAlign: 'center' }}
                                        value={this.state.crea_deliveryMethod}
                                        onChange={
                                            (value) => {
                                                this.onDeliveryMethodChanged(value)
                                            }}
                                    >
                                        <Option key="1" value="Throttling">Throttling</Option>
                                        <Option key="2" value="SendTimeOptimization">Send Time Optimization</Option>
                                    </Select>
                                </Col>
                                <Col span={3} style={{ paddingTop: 9, textAlign: 'center' }}>
                                    <p>Value</p>
                                </Col>
                                <Col span={8} style={{ display: `${this.state.display_sto_value}` }}>
                                    <Select
                                        optionFilterProp="children"
                                        showSearch
                                        size="medium"
                                        style={{ width: '100%', color: '#192a56' }}
                                        value={this.state.crea_sto_value}
                                        onChange={(value) => {
                                            this.setState({
                                                crea_sto_value: value
                                            })
                                        }}
                                    >
                                        <Option key="24 hours" value="24h">24 hours</Option>
                                        <Option key="7 days" value="7d">7 days</Option>
                                    </Select>
                                </Col>

                                <Col span={8} style={{ display: `${this.state.display_throttling_value}` }}>
                                    <Input
                                        type="number"
                                        min="1"
                                        max="72"
                                        value={this.state.crea_throttling_value}
                                        onChange={(e) => { this.setState({ crea_throttling_value: e.target.value }) }}
                                        style={{ width: '100%', color: '#192a56', textAlign: 'center' }}
                                    />
                                </Col>

                            </Row>

                            {/* <br /> */}
                            <Row style={{ padding: 5 }}>
                                <Col span={3} style={{ padding: 3, textAlign: 'center' }}>
                                    <p>Available From</p>
                                </Col>
                                <Col span={8}>
                                    <DatePicker
                                        showTime
                                        format={dateFormat}
                                        style={{ width: '100%', textAlign: 'center' }}
                                        size="medium"
                                        value={this.state.crea_stats_available_from}
                                        defaultValue={moment().add(1, 'days').hour(6).minute(0).second(0)}
                                        disabledDate={current => {
                                            return current && current <= moment().subtract(1, 'days');
                                        }}
                                        onChange={(a, b) => {
                                            this.setState({
                                                crea_stats_available_from: a
                                            })
                                        }}
                                    />
                                </Col>
                                <Col span={3} style={{ padding: 3, textAlign: 'center' }}>
                                    <p>Available Until</p>
                                </Col>
                                <Col span={8}>
                                    <DatePicker
                                        defaultValue={moment().add(1, 'days').hour(6).minute(0).second(0)}
                                        showTime
                                        format={dateFormat}
                                        style={{ width: '100%', textAlign: 'center' }}
                                        value={this.state.crea_stats_available_until}
                                        size="medium"
                                        disabledDate={current => {
                                            return current && current <= moment().subtract(1, 'days');
                                        }}
                                        onChange={(a, b) => {
                                            console.log(typeof b)
                                            console.log(typeof a)

                                            this.setState({
                                                crea_stats_available_until: a
                                            })
                                        }}
                                    />
                                </Col>
                            </Row>
                            {/* <br /> */}

                            <br />
                            <Row style={{ fontWeight: '400px !important' }}>
                                <Col span={4} style={{ textAlign: 'center' }}>
                                    <Checkbox
                                        checked={this.state.crea_base_default_pixels}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                this.setState({ crea_base_default_pixels: e.target.checked })
                                            } else {
                                                this.setState({ crea_base_default_pixels: e.target.checked })
                                            }
                                        }}
                                    >
                                        <span style={{ fontSize: 14, fontWeight: 400 }}>Pixels</span>
                                    </Checkbox>
                                </Col>
                                <Col span={4} style={{ textAlign: 'center' }}>
                                    <Checkbox
                                        checked={this.state.crea_base_default_img}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                this.setState({ crea_base_default_img: e.target.checked })
                                            } else {
                                                this.setState({ crea_base_default_img: e.target.checked })
                                            }
                                        }}
                                    >
                                        <span style={{ fontSize: 14, fontWeight: 400 }}>Image</span>
                                    </Checkbox>
                                </Col>
                                <Col span={4} style={{ textAlign: 'center' }}>
                                    <Checkbox
                                        checked={this.state.crea_base_default_compress}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                this.setState({ crea_base_default_compress: e.target.checked })
                                            } else {
                                                this.setState({ crea_base_default_compress: e.target.checked })
                                            }
                                        }}
                                    >
                                        <span style={{ fontSize: 14, fontWeight: 400 }}>Compress</span>
                                    </Checkbox>
                                </Col>
                                <Col span={4} style={{ textAlign: 'center' }}>
                                    <Checkbox
                                        checked={this.state.crea_base_default_cutting}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                this.setState({ crea_base_default_cutting: e.target.checked })
                                            } else {
                                                this.setState({ crea_base_default_cutting: e.target.checked })
                                            }
                                        }}
                                    >
                                        <span style={{ fontSize: 14, fontWeight: 400 }}>Cutting</span>
                                    </Checkbox>
                                </Col>
                                <Col span={4} style={{ textAlign: 'center' }}>
                                    <Checkbox
                                        checked={this.state.crea_base_default_link}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                this.setState({ crea_base_default_link: e.target.checked })
                                            } else {
                                                this.setState({ crea_base_default_link: e.target.checked })
                                            }
                                        }}
                                    >
                                        <span style={{ fontSize: 14, fontWeight: 400 }}>Link</span>
                                    </Checkbox>
                                </Col>
                                <Col span={4} style={{ textAlign: 'center' }}>
                                    <Checkbox
                                        checked={this.state.crea_base_default_jumping}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                this.setState({ crea_base_default_jumping: e.target.checked })
                                            } else {
                                                this.setState({ crea_base_default_jumping: e.target.checked })
                                            }
                                        }}
                                    >
                                        <span style={{ fontSize: 14, fontWeight: 400 }}>Jumping</span>
                                    </Checkbox>
                                </Col>
                            </Row>
                            {/* <br /> */}
                            <Row>
                                <Col span={4} style={{ textAlign: 'center' }}>
                                    <Checkbox
                                        style={{ marginLeft: '28px' }}
                                        checked={this.state.crea_base_default_comment}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                this.setState({ crea_base_default_comment: e.target.checked })
                                            } else {
                                                this.setState({ crea_base_default_comment: e.target.checked })
                                            }
                                        }}
                                    >
                                        <span style={{ fontSize: 14, fontWeight: 400 }}>Comment</span>
                                    </Checkbox>
                                </Col>
                                <Col span={4} style={{ textAlign: 'center' }}>
                                    <Checkbox
                                        style={{ marginLeft: '-8px' }}
                                        checked={this.state.crea_base_default_stats}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                this.setState({
                                                    crea_base_default_stats: e.target.checked,
                                                })
                                            } else {
                                                this.setState({
                                                    crea_base_default_stats: e.target.checked,
                                                })
                                            }
                                        }}
                                    >
                                        <span style={{ fontSize: 14, fontWeight: 400 }}>Stats</span>
                                    </Checkbox>
                                </Col>
                                <Col span={4} style={{ textAlign: 'center' }}>
                                    <Checkbox
                                        style={{ marginLeft: '-20px' }}
                                        checked={this.state.crea_base_default_router}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                this.setState({
                                                    crea_base_default_router: e.target.checked,
                                                })
                                            } else {
                                                this.setState({
                                                    crea_base_default_router: e.target.checked,
                                                })
                                            }
                                        }}
                                    >
                                        <span style={{ fontSize: 14, fontWeight: 400 }}>Router</span>
                                    </Checkbox>
                                </Col>
                                <Col span={4} style={{ textAlign: 'center' }}>
                                    <Checkbox
                                        style={{ marginLeft: '-2px' }}
                                        checked={this.state.crea_base_default_trigger}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                this.setState({
                                                    crea_base_default_trigger: e.target.checked,
                                                })
                                            } else {
                                                this.setState({
                                                    crea_base_default_trigger: e.target.checked,
                                                })
                                            }
                                        }}
                                    >
                                        <span style={{ fontSize: 14, fontWeight: 400 }}>Trigger</span>
                                    </Checkbox>
                                </Col>
                                <Col span={4} style={{ textAlign: 'center' }}>
                                    <Checkbox
                                        style={{ marginLeft: '-0px' }}
                                        checked={this.state.crea_base_default_test}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                this.setState({
                                                    crea_base_default_test: e.target.checked,
                                                })
                                            } else {
                                                this.setState({
                                                    crea_base_default_test: e.target.checked,
                                                })
                                            }
                                        }}
                                    >
                                        <span style={{ fontSize: 14, fontWeight: 400 }}>Test</span>
                                    </Checkbox>
                                </Col>
                            </Row>
                            <br />
                            <div style={{ margin: '0 auto', textAlign: 'center', width: '60%' }}>
                                <span style={{ textDecoration: 'underline' }}><strong>SENDING  PROCESS</strong></span>
                            </div>
                            <br />
                            <Row>
                                <Col span={3} style={{ padding: 3, textAlign: 'center' }}>
                                    <p>Sender</p>
                                </Col>

                                <Col span={19}>
                                    <Input
                                        size="medium"
                                        type="text"
                                        style={{ width: '100%', color: '#192a56', fontWeight: 700 }}
                                        value={this.state.crea_router_sender}
                                        defaultValue={this.state.crea_router_sender}
                                        onChange={(e) => {
                                            this.setState({ crea_router_sender: e.target.value })
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col span={3} style={{ padding: 3, textAlign: 'center' }}>
                                    <p>Subject</p>
                                </Col>

                                <Col span={17}>
                                    <Dropdown overlay={menu} placement="top">
                                        <Input
                                            size="medium"
                                            type="text"
                                            style={{ width: '100%', color: '#192a56', fontWeight: 700 }}
                                            value={this.state.crea_router_object}
                                            defaultValue={this.state.crea_router_object}
                                            onChange={
                                                (e) => {
                                                    var object = e.target.value
                                                    var status = object.match(/\d+%/)
                                                    if (status !== null) {
                                                        this.setState({
                                                            crea_router_object: e.target.value,
                                                            crea_annotation_subject: e.target.value,
                                                            crea_sharing_title: e.target.value,
                                                            crea_router_object_length: e.target.value.length,
                                                            crea_annotation_offer: '-' + status[0],
                                                        })
                                                    } else {
                                                        this.setState({
                                                            crea_router_object: e.target.value,
                                                            crea_annotation_subject: e.target.value,
                                                            crea_sharing_title: e.target.value,
                                                            crea_router_object_length: e.target.value.length,
                                                            crea_annotation_offer: ''
                                                        })
                                                    }

                                                }
                                            }
                                        />
                                    </Dropdown>

                                </Col>
                                <Col span={1} style={{ textAlign: 'center', padding: 3 }}>
                                    <img
                                        alt="emoji"
                                        id="emoji-img"
                                        src={emoji_icon} style={{ width: 25, height: 25 }}
                                        onClick={() => {
                                            this.setState({ show_emoji: true })
                                        }}
                                    />
                                </Col>
                                <Col span={3} style={{ padding: 5, textAlign: 'left' }}>
                                    <p>({this.state.crea_router_object_length} characters)</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={3} style={{ padding: 13, textAlign: 'center' }}>
                                    <p>Preheader</p>
                                </Col>
                                <Col span={17}>
                                    <TextArea
                                        style={{ width: '100%', resize: 'none', color: '#192a56', fontWeight: 700 }}
                                        value={this.state.crea_router_preheader}
                                        size='small'
                                        defaultValue={this.state.crea_router_preheader}
                                        onChange={
                                            (e) => {
                                                this.setState({
                                                    crea_router_preheader: e.target.value,
                                                    crea_router_preheader_length: e.target.value.length
                                                })
                                            }
                                        }
                                    />
                                </Col>
                                <Col span={1} style={{ textAlign: 'center', padding: 3 }}>
                                    <img
                                        alt="emoji-img"
                                        id="emoji-img"
                                        src={emoji_icon} style={{ width: 25, height: 25 }}
                                        onClick={() => {
                                            this.setState({ show_emoji_preheader: true })
                                        }}
                                    />
                                </Col>
                                <Col span={3} style={{ padding: 5, textAlign: 'left' }}>
                                    <p>( {this.state.crea_router_preheader_length} characters)</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={3} style={{ padding: 13, textAlign: 'center' }}>
                                    <p>Type</p>
                                </Col>
                                <Col span={8}>
                                    <div style={{ textAlign: 'center' }}>
                                        <Radio.Group
                                            buttonStyle="solid"
                                            value={this.state.crea_router_type}
                                            defaultValue={this.state.crea_router_type}
                                            style={{ padding: 8 }}
                                            onChange={(e) => {
                                                this.getListDraft(this.state.crea_base_id, e.target.value)
                                                this.setState({
                                                    crea_router_type: e.target.value
                                                })

                                            }}
                                        >
                                            <Radio.Button style={{ textAlign: 'center', fontWeight: 400 }} value="Newsletters">Newsletter</Radio.Button>
                                            <Radio.Button style={{ textAlign: 'center', fontWeight: 400 }} value="Recurrings">Recurring</Radio.Button>
                                            <Radio.Button style={{ textAlign: 'center', fontWeight: 400 }} value="Triggers">Trigger</Radio.Button>
                                        </Radio.Group>
                                    </div>
                                </Col>
                                <Col span={3} style={{ padding: 13, textAlign: 'center' }}>
                                    <p>Draft Id:</p>
                                </Col>
                                <Col span={8} style={{ padding: 8, textAlign: 'center' }}>
                                    <div style={{ textAlign: 'center' }}>
                                        <Select
                                            size="medium"
                                            showSearch
                                            optionFilterProp="children"
                                            style={{ width: '100%', color: '#192a56', fontWeight: 700 }}
                                            value={this.state.crea_router_message_id}
                                            defaultValue={this.state.crea_router_message_id}
                                            onChange={
                                                (value) => {
                                                    this.setState({
                                                        crea_router_message_id: value
                                                    })
                                                }
                                            }
                                        >

                                            <Option key="0" value={null}>Create new draft</Option>
                                            {
                                                this.state.liste_draft && this.state.liste_draft.map(item => {
                                                    var key = generateKey()
                                                    return (
                                                        <Option key={key} value={item.Id}>{item.Id} - {item.Subject}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                            </Row>
                        </Col>

                        <Col span={12}>
                            <div style={{ margin: '0 auto', textAlign: 'center', width: '60%' }}>
                                <span style={{ textDecoration: 'underline' }}><strong>DELIVERY CHECK LIST</strong></span>
                            </div>
                            <div style={{ height: '80vh', overflow: 'scroll', overflowX: 'hidden' }}>
                                <Collapse
                                    defaultActiveKey={["3", "2"]}
                                >
                                    <Panel header={<strong>SHARING BUTTONS</strong>} key="4">
                                        <Row>
                                            <Col span={5} style={{ padding: 3, textAlign: 'center' }}>
                                                <p>Service status:</p>
                                            </Col>
                                            <Col span={19} style={{ textAlign: 'left' }}>
                                                <Checkbox
                                                    style={{ padding: 5 }}
                                                    checked={this.state.crea_sharing_status}
                                                    onChange={
                                                        (e) => {
                                                            if (e.target.checked === true) {
                                                                this.setState({
                                                                    crea_sharing_status: e.target.checked,

                                                                })
                                                            } else {
                                                                this.setState({
                                                                    crea_sharing_status: e.target.checked,
                                                                    crea_sharing_position: null
                                                                })
                                                            }

                                                        }
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={5} style={{ padding: 3, textAlign: 'center' }}>
                                                <p>Service selection:</p>
                                            </Col>
                                            <Col span={19} style={{ textAlign: 'left' }}>
                                                <Select
                                                    mode="multiple"
                                                    size="medium"
                                                    showSearch="true"
                                                    optionFilterProp="children"
                                                    style={{ width: '100%', color: '#192a56', textAlign: 'center' }}
                                                    value={this.state.crea_sharing_service}
                                                    onChange={(value) => {
                                                        this.setState({
                                                            crea_sharing_service: value
                                                        })
                                                    }}

                                                >
                                                    <Option value="FACEBOOK">Facebook</Option>
                                                    <Option value="MESSENGER">Messenger</Option>
                                                    <Option value="TWITTER">Twitter</Option>
                                                    <Option value="LINKEDIN">LinkedIn</Option>
                                                    <Option value="PINTEREST">Pinterest</Option>
                                                    <Option value="SKYPE">Skype</Option>
                                                    <Option value="WHATSAPP">WhatsApp</Option>
                                                </Select>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={5} style={{ padding: 3, textAlign: 'center' }}>
                                                <p>Url to Share(*)</p>
                                            </Col>
                                            <Col span={19}>
                                                <Select
                                                    size="medium"
                                                    showSearch
                                                    optionFilterProp="children"
                                                    style={{ width: '100%', color: '#192a56', fontWeight: 700 }}
                                                    value={this.state.crea_sharing_url}
                                                    defaultValue={this.state.crea_sharing_url}
                                                    onChange={
                                                        (value) => {
                                                            this.setState({
                                                                crea_sharing_url: value
                                                            })
                                                        }
                                                    }
                                                >

                                                    {
                                                        this.state.liste_links !== null && this.state.liste_links.map(item => {
                                                            var key = generateKey()
                                                            return (
                                                                <Option key={key} value={item}>{item}</Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={5} style={{ padding: 3, textAlign: 'center' }}>
                                                <p>Title</p>
                                            </Col>
                                            <Col span={19}>
                                                <Input
                                                    type="text"
                                                    size="medium"
                                                    style={{ width: '100%', color: '#192a56', fontWeight: 700 }}
                                                    value={this.state.crea_sharing_title}
                                                    onChange={
                                                        (e) => {
                                                            this.setState({
                                                                crea_sharing_title: e.target.value
                                                            })
                                                        }
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={5} style={{ padding: 3, textAlign: 'center' }}>
                                                <p>Position</p>
                                            </Col>

                                            <Col span={19} style={{ textAlign: 'center' }}>
                                                <Radio.Group
                                                    buttonStyle="solid"
                                                    value={this.state.crea_sharing_position}
                                                    style={{ padding: 0 }}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            crea_sharing_position: e.target.value
                                                        })
                                                    }}
                                                >
                                                    <Radio.Button style={{ width: 120, textAlign: 'center', fontWeight: 400 }} value="header">Header</Radio.Button>
                                                    <Radio.Button style={{ width: 120, textAlign: 'center', fontWeight: 400 }} value="footer">Footer</Radio.Button>
                                                    <Radio.Button style={{ width: 225, textAlign: 'center', fontWeight: 400 }} value="image">Image width detection</Radio.Button>
                                                </Radio.Group>
                                            </Col>
                                        </Row>
                                    </Panel>

                                    <Panel header={<strong>GOOGLE ANNOTATION</strong>} key="2">
                                        <Row>
                                            <Col span={5} style={{ padding: 3, textAlign: 'center' }}>
                                                <p>Service status:</p>
                                            </Col>

                                            <Col span={19} style={{ textAlign: 'left' }}>
                                                <Checkbox
                                                    style={{ padding: 5 }}
                                                    checked={this.state.crea_annotation_status}
                                                    onChange={
                                                        (e) => {
                                                            this.setState({
                                                                crea_annotation_status: e.target.checked
                                                            })
                                                        }
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={5} style={{ padding: 3, textAlign: 'center' }}>
                                                <p>Subject</p>
                                            </Col>
                                            <Col span={17}>
                                                <Input
                                                    size="medium"
                                                    type="text"
                                                    style={{ width: '100%', color: '#192a56', fontWeight: 700 }}
                                                    value={this.state.crea_annotation_subject}
                                                    defaultValue={this.state.crea_annotation_subject}
                                                    onChange={
                                                        (e) => {
                                                            this.setState({
                                                                crea_annotation_subject: e.target.value,
                                                            })
                                                        }
                                                    }
                                                />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col span={5} style={{ padding: 3, textAlign: 'center' }}>
                                                <p>Discount Offer</p>
                                            </Col>
                                            <Col span={17}>
                                                <Input
                                                    size="medium"
                                                    type="text"
                                                    style={{ width: '30%', color: '#192a56', fontWeight: 700 }}
                                                    value={this.state.crea_annotation_offer}
                                                    defaultValue={this.state.crea_annotation_offer}
                                                    onChange={
                                                        (e) => {
                                                            this.setState({
                                                                crea_annotation_offer: e.target.value,
                                                            })
                                                        }
                                                    }
                                                />
                                            </Col>
                                        </Row>

                                    </Panel>

                                    <Panel header={<strong>SEGMENTS REQUEST</strong>} key="3">
                                        <Row>
                                            <Col span={11}>
                                                <Input
                                                    placeholder='Maximum volume to request'
                                                    size="medium"
                                                    style={{ width: '100%', margin: '0 auto' }}
                                                    type="text"
                                                    value={this.state.dwh_volume}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            dwh_volume: e.target.value
                                                        })
                                                    }}
                                                />
                                            </Col>
                                            <Col span={2}></Col>
                                            <Col span={11}>
                                                <Select
                                                    placeholder="Domain family"
                                                    mode="multiple"
                                                    showSearch
                                                    value={this.state.dwh_domain_family}
                                                    optionFilterProp="children"
                                                    style={{ width: '100%', color: '#192a56' }}
                                                    onChange={(value) => {
                                                        var hasVal = Object.values(value).includes("all");
                                                        if (hasVal) {
                                                            console.log('hasVal is true')
                                                            var all = ["apple", "free", "gmail", "laposte", "sfr", "orange", "outlook", "yahoo"]
                                                            this.setState({
                                                                dwh_domain_family: all
                                                            })
                                                            return null
                                                        } else {
                                                            this.setState({
                                                                dwh_domain_family: value
                                                            })
                                                        }
                                                    }}
                                                >
                                                    <Option value="all">------all------</Option>
                                                    <Option value="apple">Apple</Option>
                                                    <Option value="free">Free</Option>
                                                    <Option value="gmail">Gmail</Option>
                                                    <Option value="laposte">Laposte</Option>
                                                    <Option value="orange">Orange</Option>
                                                    <Option value="outlook">Outlook</Option>
                                                    <Option value="sfr">Sfr</Option>
                                                    <Option value="yahoo">Yahoo</Option>
                                                    <Option value="bouygues">Bouygues</Option>
                                                    <Option value="others">Others</Option>

                                                </Select>
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col span={11}>
                                                <Select
                                                    placeholder="Gender"
                                                    mode="multiple"
                                                    showSearch
                                                    optionFilterProp="children"
                                                    style={{ width: '100%', color: '#192a56' }}
                                                    value={this.state.dwh_sexe}
                                                    onChange={(value) => {
                                                        this.setState({
                                                            dwh_sexe: value
                                                        })
                                                    }}
                                                >
                                                    <Option value="M">Homme</Option>
                                                    <Option value="F">Femme</Option>
                                                    <Option value="O">Unknown</Option>
                                                </Select>
                                            </Col>
                                            <Col span={2}></Col>
                                            <Col span={11}>
                                                <Select
                                                    placeholder="Age range"
                                                    mode="multiple"
                                                    showSearch
                                                    optionFilterProp="children"
                                                    style={{ width: '100%', color: '#192a56' }}
                                                    onChange={(value) => {
                                                        this.setState({
                                                            dwh_age: value
                                                        })
                                                    }}
                                                >
                                                    <Option value="18">-18</Option>
                                                    <Option value="18-25">18-25</Option>
                                                    <Option value="26-35">26-35</Option>
                                                    <Option value="36-45">36-45</Option>
                                                    <Option value="46-55">46-55</Option>
                                                    <Option value="56-65">56-65</Option>
                                                    <Option value="65">65+</Option>
                                                </Select>
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col span={11}>
                                                <Select
                                                    placeholder="CSP score"
                                                    mode="multiple"
                                                    showSearch
                                                    optionFilterProp="children"
                                                    style={{ width: '100%', color: '#192a56' }}
                                                    onChange={(value) => {
                                                        this.setState({
                                                            dwh_csp: value
                                                        })
                                                    }}
                                                >
                                                    <Option value="0">0</Option>
                                                    <Option value="1">1</Option>
                                                    <Option value="2">2</Option>
                                                    <Option value="3">3</Option>
                                                    <Option value="4">4</Option>
                                                    <Option value="5">5</Option>

                                                </Select>
                                            </Col>
                                            <Col span={2}></Col>
                                            <Col span={11}>
                                                <Select
                                                    placeholder="Landlords score"
                                                    mode="multiple"
                                                    showSearch
                                                    optionFilterProp="children"
                                                    style={{ width: '100%', color: '#192a56' }}
                                                    onChange={(value) => {
                                                        this.setState({
                                                            dwh_score_of_landlords: value
                                                        })
                                                    }}
                                                >
                                                    <Option value="0">0</Option>
                                                    <Option value="1">1</Option>
                                                    <Option value="2">2</Option>
                                                    <Option value="3">3</Option>

                                                </Select>
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col span={11}>
                                                <Select
                                                    placeholder="Individual houses score"
                                                    mode="multiple"
                                                    showSearch
                                                    optionFilterProp="children"
                                                    style={{ width: '100%', color: '#192a56' }}
                                                    onChange={(value) => {
                                                        this.setState({
                                                            dwh_score_of_individual_houses: value
                                                        })
                                                    }}
                                                >
                                                    <Option value="0">0</Option>
                                                    <Option value="1">1</Option>
                                                    <Option value="2">2</Option>
                                                    <Option value="3">3</Option>
                                                </Select>
                                            </Col>
                                            <Col span={2}></Col>
                                            <Col span={11}>
                                                <Select
                                                    placeholder="Tax households score"
                                                    mode="multiple"
                                                    showSearch
                                                    optionFilterProp="children"
                                                    style={{ width: '100%', color: '#192a56' }}
                                                    onChange={(value) => {
                                                        this.setState({
                                                            dwh_score_of_tax_households: value
                                                        })
                                                    }}
                                                >
                                                    <Option value="0">0</Option>
                                                    <Option value="1">1</Option>
                                                    <Option value="2">2</Option>
                                                    <Option value="3">3</Option>

                                                </Select>
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col span={11}>
                                                <Select
                                                    placeholder="Poverty score"
                                                    mode="multiple"
                                                    showSearch
                                                    optionFilterProp="children"
                                                    style={{ width: '100%', color: '#192a56' }}
                                                    onChange={(value) => {
                                                        this.setState({
                                                            dwh_poverty_score: value
                                                        })
                                                    }}
                                                >
                                                    <Option value="0">0</Option>
                                                    <Option value="1">1</Option>
                                                    <Option value="2">2</Option>
                                                    <Option value="3">3</Option>
                                                </Select>
                                            </Col>
                                            <Col span={2}></Col>
                                            <Col span={11}>
                                                <Select
                                                    placeholder="Median income score"
                                                    mode="multiple"
                                                    showSearch
                                                    optionFilterProp="children"
                                                    style={{ width: '100%', color: '#192a56' }}
                                                    onChange={(value) => {
                                                        this.setState({
                                                            dwh_score_median_income: value
                                                        })
                                                    }}
                                                >
                                                    <Option value="0">0</Option>
                                                    <Option value="1">1</Option>
                                                    <Option value="2">2</Option>
                                                    <Option value="3">3</Option>

                                                </Select>
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col span={11}>
                                                <TextArea
                                                    placeholder='zipcode liste, use comma (,) as separator'
                                                    style={{ width: '100%', resize: 'none', color: '#192a56' }}
                                                    value={this.state.dwh_zipcode}
                                                    defaultValue={this.state.dwh_zipcode}
                                                    onChange={
                                                        (e) => {
                                                            this.setState({
                                                                dwh_zipcode: e.target.value
                                                            })
                                                        }
                                                    }
                                                />
                                            </Col>
                                            <Col span={2}></Col>
                                            <Col span={11}>
                                                <Row>
                                                    <Col span={10}>
                                                        <span>Optin Telemarketing:</span>
                                                        <br />
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        <Switch
                                                            defaultChecked={this.state.dwh_optin_telemarketing}
                                                            checked={this.state.dwh_optin_telemarketing}
                                                            onChange={(e) => {
                                                                if (e === true) {
                                                                    this.setState({
                                                                        dwh_optin_telemarketing: e,
                                                                    })
                                                                } else {
                                                                    this.setState({
                                                                        dwh_optin_telemarketing: e,
                                                                    })
                                                                }
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col span={8}>
                                                        <span>Optin SMS:</span>
                                                        <br />
                                                        &nbsp;&nbsp;&nbsp;<Switch
                                                            defaultChecked={this.state.dwh_optin_sms}
                                                            checked={this.state.dwh_optin_sms}
                                                            onChange={(e) => {
                                                                if (e === true) {
                                                                    this.setState({
                                                                        dwh_optin_sms: e,
                                                                    })
                                                                } else {
                                                                    this.setState({
                                                                        dwh_optin_sms: e,
                                                                    })
                                                                }
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col span={6}>
                                                        <span>Fed now:</span>
                                                        <br />
                                                        &nbsp;&nbsp;&nbsp;<Switch
                                                            defaultChecked={this.state.dwh_fed_now}
                                                            checked={this.state.dwh_fed_now}
                                                            onChange={(e) => {
                                                                if (e === true) {
                                                                    this.setState({
                                                                        dwh_fed_now: e,
                                                                    })
                                                                } else {
                                                                    this.setState({
                                                                        dwh_fed_now: e,
                                                                    })
                                                                }
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>

                                            </Col>
                                        </Row>
                                    </Panel>
                                </Collapse>
                            </div>
                            <br />
                            <br />
                            <div>
                                <Row>
                                    <Col span={12} style={{ textAlign: 'center' }}>
                                        <Button
                                            className="btn btn-secondary"
                                            style={{ width: '75%', height: 50, fontSize: 16 }}
                                            onClick={() => {
                                                this.setState({
                                                    isBack: true
                                                })
                                            }}
                                        >
                                            CANCEL
                                        </Button>
                                    </Col>
                                    <Col span={12} style={{ textAlign: 'center' }}>
                                        <Button
                                            loading={this.state.loading_upload}
                                            className="btn btn-success"
                                            style={{ width: '75%', height: 50, fontSize: 16 }}
                                            onClick={this.onOptimizeCreativity.bind(this)}
                                        >
                                            SUBMIT
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>

                <Modal
                    id="code"
                    isOpen={this.state.show_code}
                    onRequestClose={() => { this.setState({ show_code: false, crea_sharing_position: 'footer' }) }}
                    shouldCloseOnOverlayClick={true}
                    style={bg}
                    ariaHideApp={false}
                >
                    <div style={{ background: '#001529', marginTop: '0px', textAlign: 'center', width: '100%', marginLeft: '0px' }}                        >
                        <h3 style={{ color: "#fff", padding: 5 }}>HTML CODE</h3>
                    </div>
                    <span style={{ color: "#C23616", marginTop: '-46px', fontSize: 35, float: 'right', cursor: 'pointer' }} onClick={() => { this.setState({ show_code: false, crea_sharing_position: 'footer' }) }}>X</span>
                    <div>
                        <Row>
                            <Col span={12}>
                                <div style={{ display: `${this.state.step1}` }}>
                                    {/* <AceEditor
                                        mode="html"
                                        theme="eclipse"
                                        placeholder="Paste code here"
                                        style={{ width: '100%', height: '62vh', border: '1px solid gray', marginTop: '0px' }}
                                        value={this.state.crea_code}
                                        onChange={(value) => {
                                            this.setState({ crea_code: value })
                                        }}
                                        setOptions={{
                                            displayIndentGuides: false,
                                            enableBasicAutocompletion: true,
                                            enableLiveAutocompletion: true,
                                            enableSnippets: true,
                                            wrap: true,
                                            fontSize: 13
                                        }}
                                    /> */}
                                    <Editor
                                        height="76vh"
                                        defaultLanguage="html"
                                        theme="vs-dark"
                                        defaultValue={this.state.crea_code}
                                        style={{ width: '100%', border: '1px solid gray', marginTop: '0px' }}
                                        onChange={(value) => {
                                            this.setState({ crea_code: value })
                                        }}
                                    />
                                </div>

                                <div style={{ display: `${this.state.step2}`, height: '71vh', overflow: 'scroll', overflowX: 'hidden' }}>
                                    <Row>
                                        <Col span={3} style={{ padding: 3, textAlign: 'center' }}>
                                            <p style={{ fontSize: 18, fontFamily: 'Lato' }}>Tags(<strong style={{ color: 'red' }}>*</strong>)</p>
                                        </Col>
                                        <Col span={8}>
                                            <Select
                                                size="medium"
                                                showSearch
                                                optionFilterProp="children"
                                                style={{ width: '100%', color: '#192a56', textAlign: 'center' }}
                                                value={this.state.crea_tags_id}
                                                defaultValue={this.state.crea_tags_id}
                                                onChange={
                                                    (value) => {
                                                        this.getListKeywords(value)
                                                        this.setState({
                                                            crea_tags_id: value
                                                        })
                                                    }
                                                }
                                            >
                                                {
                                                    this.state.liste_tags !== null && this.state.liste_tags.map((item) => {
                                                        var key = generateKey()
                                                        return (
                                                            <Option key={key} value={item['id']}>{item.tag}</Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Col>
                                    </Row>
                                    <br />
                                    <table border="1" className="table" style={{ marginTop: '-12px', marginLeft: '-5px', fontSize: 12 }}>
                                        <thead className="bg-dark" style={{ color: '#fff' }}>
                                            <tr>
                                                <th style={{ textAlign: 'center' }}>Images</th>
                                                <th style={{ textAlign: 'center' }}>Description</th>
                                                <th style={{ textAlign: 'center' }}>Keywords</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.renderAltDescription()
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </Col>
                            {/* <Col span={1}></Col> */}
                            <Col span={12}>
                                <ReactSrcDocIframe
                                    className="embed-responsive-item" srcDoc={this.state.crea_code}
                                    style={{ width: '100%', height: '62vh', marginTop: '-1px', marginLeft: '0px', overflow: 'scroll', border: 'none' }}
                                />
                                <br />
                                <Row>
                                    <Col span={12} style={{ textAlign: 'center' }}>
                                        <Button
                                            style={{ width: '90%', height: 50, fontSize: 16 }}
                                            className="btn btn-secondary"
                                            onClick={() => {
                                                this.setState({ show_code: false, crea_sharing_position: 'footer' })
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </Col>

                                    <Col span={12} style={{ display: `${this.state.submitDisplay}` }}>
                                        <Button
                                            style={{ width: '90%', height: 50, display: `${this.state.submitDisplay}`, fontSize: 16 }}
                                            className="btn btn-success"
                                            onClick={this.onValidateHtmlCode.bind(this)}
                                        >
                                            Submit
                                        </Button>
                                    </Col>

                                    <Col span={6} style={{ display: `${this.state.previousDisplay}` }}>
                                        <Button
                                            style={{ width: '90%', height: 50, display: `${this.state.previousDisplay}`, fontSize: 16 }}
                                            className="btn btn-warning"
                                            onClick={this.onPreviousShowCode.bind(this)}
                                        >
                                            Previous
                                        </Button>
                                    </Col>

                                    <Col span={6} style={{ display: `${this.state.finishDisplay}` }}>
                                        <Button
                                            style={{ width: '90%', height: 50, fontSize: 16 }}
                                            className="btn btn-success"
                                            onClick={this.onFinishAltattribute.bind(this)}
                                        >
                                            Finished
                                        </Button>
                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Modal>

                <Modal
                    id="emoji"
                    isOpen={this.state.show_emoji}
                    onRequestClose={() => { this.setState({ show_emoji: false }) }}
                    shouldCloseOnOverlayClick={true}
                    style={bg}
                >
                    <div style={{ marginTop: '-20px', marginLeft: '-20px', width: '108.4%', textAlign: 'center' }}>
                        <Picker
                            onSelect={this.addEmoji.bind(this)}
                            style={{ position: 'relative', width: '100%', overflow: 'scroll', fontSize: 10 }}
                        />
                    </div>
                </Modal>

                <Modal
                    id="emoji"
                    isOpen={this.state.show_emoji_preheader}
                    onRequestClose={() => { this.setState({ show_emoji_preheader: false }) }}
                    shouldCloseOnOverlayClick={true}
                    style={bg}
                >
                    <div style={{ marginTop: '-20px', marginLeft: '-20px', width: '108.4%', textAlign: 'center' }}>
                        <Picker
                            onSelect={this.addEmojiPreheader.bind(this)}
                            style={{ position: 'relative', width: '100%', overflow: 'scroll', fontSize: 10 }}
                        />
                    </div>
                </Modal>
            </div>
        )
    }
}

export default NewCreativities