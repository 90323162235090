import { Col, Row, Tag, Tabs, Select, Checkbox, Button, Collapse, message, Input, AutoComplete, DatePicker, Tooltip, Dropdown, Menu } from 'antd'
import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import back from '../../images/back.png'
import { WarningOutlined, MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { base64_decode, base64_encode } from '../Utilities/Utilities'
import ReactSrcDocIframe from 'react-srcdoc-iframe';
import { detail_crea, reupload_crea, update_crea } from '../Utilities/Crea';
import Modal from 'react-modal';
import { getMainData } from '../Utilities/GetMainData';
// import AceEditor from 'react-ace';
// import "ace-builds/src-noconflict/mode-html";
// import "ace-builds/src-noconflict/theme-eclipse";
// import "ace-builds/webpack-resolver";
import Editor from '@monaco-editor/react';
import emoji from '../../images/emoji.png'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Picker } from 'emoji-mart'
import 'emoji-mart/css/emoji-mart.css'
import { generateKey } from '../Utilities/Utilities';
import { list_advertiser, list_footer, list_header, list_seedlist, list_segment, list_subscriber, list_suppression } from '../Utilities/Stats';
import { list_draft, save_draft, scheduled } from '../Utilities/ExpertSender';
import moment from 'moment';
import axios from 'axios'
const { Panel } = Collapse;
const { TabPane } = Tabs;
const { Option } = Select
const { TextArea } = Input
const dateFormat = 'YYYY-MM-DD';
class DetailCreativities extends Component {
	constructor(props) {
		super(props)
		this.state = {
			user_id: base64_decode(localStorage.getItem(base64_encode('user_id'))),
			username: base64_decode(localStorage.getItem(base64_encode('username'))),
			apikey: base64_decode(localStorage.getItem(base64_encode('apikey'))),
			stats_apikey: base64_decode(localStorage.getItem(base64_encode('stats_apikey'))),
			//liste
			liste_user: [],
			liste_base_user: [],
			liste_tags: [],
			liste_clients: [],
			liste_advertiser: [],
			liste_country: [],
			liste_models: [],
			liste_footer: [],
			liste_header: [],
			liste_seedlist: [],
			liste_draft: [],
			liste_affiliate: [],
			liste_segments: [],
			liste_subscriber: [],
			liste_suppression: [],
			liste_hour: [
				{ "hour": "12 am", "value": "24" },
				{ "hour": "1 am", "value": "1" },
				{ "hour": "2 am", "value": "2" },
				{ "hour": "3 am", "value": "3" },
				{ "hour": "4 am", "value": "4" },
				{ "hour": "5 am", "value": "5" },
				{ "hour": "6 am", "value": "6" },
				{ "hour": "7 am", "value": "7" },
				{ "hour": "8 am", "value": "8" },
				{ "hour": "9 am", "value": "9" },
				{ "hour": "10 am", "value": "10" },
				{ "hour": "11 am", "value": "11" },

				{ "hour": "12 pm", "value": "24" },
				{ "hour": "1 pm", "value": "13" },
				{ "hour": "2 pm", "value": "14" },
				{ "hour": "3 pm", "value": "15" },
				{ "hour": "4 pm", "value": "16" },
				{ "hour": "5 pm", "value": "17" },
				{ "hour": "6 pm", "value": "18" },
				{ "hour": "7 pm", "value": "19" },
				{ "hour": "8 pm", "value": "20" },
				{ "hour": "9 pm", "value": "21" },
				{ "hour": "10 pm", "value": "22" },
				{ "hour": "11 pm", "value": "23" }
			],
			liste_days: [],
			liste_month: [],
			//loadingButton
			loadingReoptimize: false,
			loadingUpdateOptimized: false,
			loadingUpdateOriginal: false,
			loadingSaveStats: false,
			loadingUpdateStats: false,
			loadingSave: false,
			loadingSend: false,
			loadingScheduled: false,
			goBack: false,
			crea_links_count: 0,
			crea_images_count: 0,
			crea_images_size: 0,
			crea_html_size: 0,
			crea_total_size: 0,
			//crea_details:
			crea_id: this.props.match.params.crea_id,
			crea_user_id: null,
			crea_username: null,
			crea_tags_id: null,
			crea_base_id: null,
			crea_html_optimized: null,
			crea_html_original: null,
			crea_html_broken: null,
			crea_domain_list: null,
			crea_reoptimize_pixels: false,
			crea_reoptimize_img: false,
			crea_reoptimize_link: false,
			crea_reoptimize_jumping: true,
			crea_reoptimize_comment: false,
			crea_reoptimize_compress: false,
			//router details
			crea_router_sender: null,
			crea_router_fromemail: null,
			crea_router_replyname: null,
			crea_router_replyemail: null,
			crea_router_object: null,
			crea_router_preheader: null,
			crea_router_message_type: null,
			crea_router_header_id: null,
			crea_router_footer_id: null,
			crea_router_seedlist_id: [],
			crea_router_message_id: null,
			//stats details
			crea_stats_adv_id: null,
			crea_stats_adv_name: null,
			crea_stats_client_id: null,
			crea_stats_client_name: null,
			crea_stats_affiliate_id: null,
			crea_stats_affiliate_name: null,
			crea_stats_country_id: null,
			crea_stats_currency_id: null,
			crea_stats_comment: null,
			crea_stats_segment: [],
			crea_stats_campagn_name: null,
			crea_stats_brand: null,
			crea_stats_status: null,
			crea_stats_type: null,
			crea_stats_date: null,
			crea_stats_id: null,
			crea_focus_id: null,
			crea_stats_available_from: null,
			crea_stats_available_until: null,
			crea_stats_sendout_id: null,
			crea_stats_revenu: [{ "modelid": null, "modelname": "", "payout": "", "coment": "" }],

			crea_end_date: null,
			crea_recurring_hour: [],
			crea_recurring_minutes: 1,
			crea_recurring_days: [],
			//previeuw type
			show_reoptimize: false,
			showDesktop: 'block',
			showOriginal: 'none',
			showImageDisable: 'none',
			showMobile: 'none',
			showGa: 'none',
			crea_preview_type: 'desktop',
			//router scheduled
			displayRouterData: 'block',
			displayRouterScheduled: 'none',
			segmentValue: [],
			suppressionValue: [],
			seedlistValue: [],
			timezone: 'W. Europe Standard Time',
			list_timezone: [
				{ "Text": "(UTC-10:00) Hawaii", "Value": "Hawaiian Standard Time" },
				{ "Text": "(UTC-09:00) Alaska", "Value": "Alaskan Standard Time" },
				{ "Text": "(UTC-08:00) Pacific Time (US & Canada)", "Value": "Pacific Standard Time" },
				{ "Text": "(UTC-07:00) Mountain Time (US & Canada)", "Value": "Mountain Standard Time" },
				{ "Text": "(UTC-06:00) Central America", "Value": "Central America Standard Time" },
				{ "Text": "(UTC-06:00) Central Time (US & Canada)", "Value": "Central Standard Time" },
				{ "Text": "(UTC-05:00) Eastern Time (US & Canada)", "Value": "Eastern Standard Time" },
				{ "Text": "(UTC-04:00) Atlantic Time (Canada)", "Value": "Atlantic Standard Time" },
				{ "Text": "(UTC-04:00) Cuiaba", "Value": "Central Brazilian Standard Time" },
				{ "Text": "(UTC-03:00) Brasilia", "Value": "E. South America Standard Time" },
				{ "Text": "(UTC-03:00) Cayenne, Fortaleza", "Value": "SA Eastern Standard Time" },
				{ "Text": "(UTC-03:00) Buenos Aires", "Value": "Argentina Standard Time" },
				{ "Text": "(UTC-03:00) Salvador", "Value": "Bahia Standard Time" },
				{ "Text": "(UTC-02:00) Coordinated Universal Time - 02", "Value": "UTC-02" },
				{ "Text": "(UTC) Coordinated Universal Time", "Value": "UTC" },
				{ "Text": "(UTC) Dublin, Edinburgh, Lisbon, London", "Value": "GMT Standard Time" },
				{ "Text": "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna", "Value": "W. Europe Standard Time" },
				{ "Text": "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague", "Value": "Central Europe Standard Time" },
				{ "Text": "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris", "Value": "Romance Standard Time" },
				{ "Text": "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb", "Value": "Central European Standard Time" },
				{ "Text": "(UTC+02:00) Athens, Bucharest", "Value": "GTB Standard Time" },
				{ "Text": "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius", "Value": "FLE Standard Time" },
				{ "Text": "(UTC+02:00) Jerusalem", "Value": "Israel Standard Time" },
				{ "Text": "(UTC+02:00) Kaliningrad (RTZ 1)", "Value": "Kaliningrad Standard Time" },
				{ "Text": "(UTC+03:00) Moscow, St. Petersburg, Volgograd (RTZ 2)", "Value": "Russian Standard Time" },
				{ "Text": "(UTC+04:00) Abu Dhabi, Muscat", "Value": "Arabian Standard Time" },
				{ "Text": "(UTC+05:00) Ekaterinburg (RTZ 4)", "Value": "Ekaterinburg Standard Time" },
				{ "Text": "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi", "Value": "India Standard Time" },
				{ "Text": "(UTC+06:00) Astana", "Value": "Central Asia Standard Time" },
				{ "Text": "(UTC+07:00) Bangkok, Hanoi, Jakarta", "Value": "SE Asia Standard Time" },
				{ "Text": "(UTC+07:00) Krasnoyarsk (RTZ 6)", "Value": "North Asia Standard Time" },
				{ "Text": "(UTC+06:00) Novosibirsk (RTZ 5)", "Value": "N. Central Asia Standard Time" },
				{ "Text": "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi", "Value": "China Standard Time" },
				{ "Text": "(UTC+08:00) Irkutsk (RTZ 7)", "Value": "North Asia East Standard Time" },
				{ "Text": "(UTC+08:00) Kuala Lumpur, Singapore", "Value": "Singapore Standard Time" },
				{ "Text": "(UTC+09:00) Osaka, Sapporo, Tokyo", "Value": "Tokyo Standard Time" },
				{ "Text": "(UTC+09:00) Yakutsk (RTZ 8)", "Value": "Yakutsk Standard Time" },
				{ "Text": "(UTC+10:00) Canberra, Melbourne, Sydney", "Value": "AUS Eastern Standard Time" },
				{ "Text": "(UTC+10:00) Vladivostok, Magadan (RTZ 9)", "Value": "Vladivostok Standard Time" },
				{ "Text": "(UTC+12:00) Magadan", "Value": "Magadan Standard Time" },
				{ "Text": "(UTC+12:00) Auckland, Wellington", "Value": "New Zealand Standard Time" }
			],
			deliveryMethod: 'SendTimeOptimization',
			sto_value: '24 hours',
			throttling_type: "Manual",
			throttling_delay: 1,
			displayThrottlingOption: 'none',
			displayThrottlingDelay: 'none',
			displaySTODelay: 'block',
			stoDelay: '24 hours',
			displayTimeTravelInfo: 'none',
			displayScheduleBtn: 'none',
			scheduleSegment: [],
			scheduleSubscriber: [],
			scheduleSuppression: [],
			scheduleDate: null,
			loadingStartScheduled: false,
			blacklist_check: null,
			// stats action
			display_update_stats: 'none',
			display_send_stats: 'none',
			// template display
			display_template_routeur: 'none',
			//scheduled validation
			isScheduled: false
		}
	}

	componentDidMount() {
		this.getData()
	}

	getData() {
		Promise.resolve(getMainData(this.state.user_id, this.state.apikey, this.state.stats_apikey, 0, true, false, true, false, true, true, true, true, false)).then((value) => {
			this.setState({
				liste_user: value['all_user'],
				liste_tags: value['all_tags'],
				liste_clients: value['all_clients'],
				liste_advertiser: value[''],
				liste_country: value['all_country'],
				liste_models: value['all_models'],
				liste_base_user: value['base_user']
			})
			this.onGetCreaDetail()
		});
	}

	onGetCreaDetail() {
		Promise.resolve(detail_crea(this.state.user_id, this.state.apikey, this.state.crea_id)).then((value) => {
			console.log(value)

			const imgRex = /<img.*?src="(.*?)"[^>]+>/g;
			const images = [];
			let img;
			var optimized = base64_decode(value[0]['optimized'])
			while ((img = imgRex.exec(optimized))) {
				images.push(img[1]);
			}
			images.map(item => {
				optimized = optimized.replace(item, '#')
				return null
			})
			var username = null
			this.state.liste_user.map(item => {
				if (parseInt(item['id']) === parseInt(this.state.crea_user_id)) {
					username = item['username']
				}
				return null
			})
			var blacklist_check = value[0]['domain_list'] !== 'null' || value[0]['domain_list'] !== null ? base64_decode(value[0]['domain_list']) : null
			var database_default_header_id = null
			var database_default_footer_id = null
			var database_default_seedlist = null

			var default_from_email = null
			var default_reply_email = null
			var default_sender = null
			var default_reply_name = null
			//getting default value for template
			this.state.liste_base_user.map((item) => {
				if (parseInt(item['id']) === parseInt(value[0]['base_id'])) {
					database_default_header_id = (item['defaultHeaderId'] !== null && item['defaultHeaderId'] !== 'null' && item['defaultHeaderId'] !== '') ? parseInt(item['defaultHeaderId']) : null
					database_default_footer_id = (item['defaultFooterId'] !== null && item['defaultFooterId'] !== 'null' && item['defaultFooterId'] !== '') ? parseInt(item['defaultFooterId']) : null
					database_default_seedlist = (item['defaultSeedId'] !== null && item['defaultSeedId'] !== 'null' && item['defaultSeedId'] !== '') ? (item['defaultSeedId']).split(',') : []
					default_from_email = (item['defaultFromEmail'] !== null && item['defaultFromEmail'] !== 'null' && item['defaultFromEmail'] !== '') ? item['defaultFromEmail'] : null
					default_reply_email = (item['defaultReplyEmail'] !== null && item['defaultReplyEmail'] !== 'null' && item['defaultReplyEmail'] !== '') ? item['defaultReplyEmail'] : null
					default_sender = (item['defaultSender'] !== null && item['defaultSender'] !== 'null' && item['defaultSender'] !== '') ? item['defaultSender'] : null
					default_reply_name = (item['defaultReplyName'] !== null && item['defaultReplyName'] !== 'null' && item['defaultReplyName'] !== '') ? item['defaultReplyName'] : null
				}
				return null
			})

			//getting liste template
			if (parseInt(value[0]['router']) === 1) {
				Promise.resolve(list_header(this.state.user_id, this.state.apikey, this.state.stats_apikey, value[0]['base_id'], null)).then((value) => {
					this.setState({ liste_header: value })
				});
				Promise.resolve(list_footer(this.state.user_id, this.state.apikey, this.state.stats_apikey, value[0]['base_id'], null)).then((value) => {
					this.setState({ liste_footer: value })
				});


				// Promise.resolve(list_seedlist(this.state.user_id, this.state.apikey, this.state.stats_apikey, value[0]['base_id'], null)).then((value) => {
				// 	this.setState({ liste_seedlist: value })
				// });

				Promise.resolve(list_draft(this.state.user_id, this.state.apikey, value[0]['base_id'], value[0]['router_message'])).then((value) => {
					if (('message') in value) {
						this.setState({ liste_draft: [] })
					} else {
						this.setState({ liste_draft: value })
					}
				});
				this.getListSegments(value[0]['base_id'])
				this.getListSubscriber(value[0]['base_id'])
				this.getListSuppression(value[0]['base_id'])
				this.getListSeedlist(value[0]['base_id'])

			}
			//getting stats info
			
			//getting segments liste
			this.onClientsChange(value[0]['clientid'])
			var segmentArray = []
			var suppressionArray = []
			var subscriberArray = []
			var seedlistArray = []
			

			if (value[0]['stats_segment'] === '[0]' || value[0]['stats_segment'] === null || value[0]['stats_segment'] === 'null' || value[0]['stats_segment'] === '[]' || (value[0]['stats_segment']).includes('[') === false) {
				segmentArray = []
			} else {
				// var myliste_seg = ((value[0]['stats_segment']).trim()).split(',')
				segmentArray = JSON.parse(value[0]['stats_segment'])
			}

			if (value[0]['stats_suppression'] === null || value[0]['stats_suppression'] === 'null' || value[0]['stats_suppression'] === '[]' || (value[0]['stats_suppression']).includes('[') === false) {
				suppressionArray = []
			} else {
				suppressionArray = JSON.parse(value[0]['stats_suppression'])
			}

			if(value[0]['dwh_status'] === 'VALIDATE' && value[0]['dwh_id'] !== null){
				var dwh_id = value[0]['dwh_id']
				var dwh_subscriber = []
				Promise.resolve(list_subscriber(this.state.user_id, this.state.apikey, this.state.stats_apikey, value[0]['base_id'])).then((value) => {
					var mydata = value.sort((a, b) => b.id - a.id);
					console.log(mydata)
					mydata.map(item => {
						if(item['Name'].includes(dwh_id)){
							dwh_subscriber.push(parseInt(item['id']))
						}
						this.setState({scheduleSubscriber: dwh_subscriber})
					})
				});
			}else{
				if (value[0]['stats_subscriber'] === null  ||  value[0]['stats_subscriber'] === 'null' || value[0]['stats_subscriber'] === '[]' || (value[0]['stats_subscriber']).includes('[') === false) {
					subscriberArray = []
				} else {
					subscriberArray = JSON.parse(value[0]['stats_subscriber'])
				}
				this.setState({scheduleSubscriber: subscriberArray})
			}

			

			this.setState({
				crea_links_count: value[0]['total_href'],
				crea_images_count: value[0]['total_images'],
				crea_images_size: parseInt(value[0]['images_size'] / Math.pow(1024, 1)),
				crea_html_size: parseInt(value[0]['html_size'] / Math.pow(1024, 1)),
				crea_total_size: parseInt(value[0]['images_size'] / Math.pow(1024, 1)) + parseInt(value[0]['html_size'] / Math.pow(1024, 1)),
				crea_html_optimized: base64_decode(value[0]['optimized']),
				crea_html_original: base64_decode(value[0]['original']),
				crea_html_broken: optimized,
				crea_domain_list: (value[0]['domain_list'] !== null && value[0]['domain_list'] !== 'null') ? base64_decode(value[0]['domain_list']) : null,
				crea_user_id: value[0]['user_id'],
				crea_username: username,
				crea_tags_id: parseInt(value[0]['tags_id']),
				crea_base_id: value[0]['base_id'],
				crea_reoptimize_pixels: parseInt(value[0]['pixel']) === 1 ? true : false,
				crea_reoptimize_img: parseInt(value[0]['img']) === 1 ? true : false,
				crea_reoptimize_link: parseInt(value[0]['link']) === 1 ? true : false,
				crea_reoptimize_comment: parseInt(value[0]['comment']) === 1 ? true : false,
				crea_reoptimize_compress: true,
				crea_router_sender: (value[0]['sender'] !== null && value[0]['sender'] !== "") ? base64_decode(value[0]['sender']) : default_sender,
				crea_router_fromemail: (value[0]['router_fromemail'] !== null && value[0]['router_fromemail'] !== "" && value[0]['router_fromemail'] !== "null" && default_from_email !== "null") ? value[0]['router_fromemail'] : default_from_email,
				crea_router_replyname: (value[0]['router_replyname'] !== null && value[0]['router_replyname'] !== "" && value[0]['router_replyname'] !== "null" && default_reply_name !== "null") ? value[0]['router_replyname'] : default_reply_name,
				crea_router_replyemail: (value[0]['router_replyemail'] !== null && value[0]['router_replyemail'] !== "" && value[0]['router_replyemail'] !== "null" && default_reply_email !== "null") ? value[0]['router_replyemail'] : default_reply_email,
				crea_router_object: (value[0]['object'] !== null && value[0]['object'] !== "" && value[0]['object'] !== "null" && value[0]['object'] !== "bnVsbA==") ? base64_decode(value[0]['object']) : '',
				crea_router_preheader: (value[0]['preheader'] !== "null" && value[0]['preheader'] !== null && value[0]['preheader'] !== "bnVsbA==") ? base64_decode(value[0]['preheader']) : '',
				crea_router_message_type: (value[0]['router_message'] !== null && value[0]['router_message'] !== "") ? value[0]['router_message'] : '',
				crea_router_header_id: (value[0]['header_id'] !== null && value[0]['header_id'] !== "0") ? parseInt(value[0]['header_id']) : database_default_header_id,
				crea_router_footer_id: (value[0]['footer_id'] !== null && value[0]['footer_id'] !== "0") ? parseInt(value[0]['footer_id']) : database_default_footer_id,
				crea_router_seedlist_id: database_default_seedlist,
				crea_router_message_id: (value[0]['router_id'] !== null && value[0]['router_id'] !== "") ? parseInt(value[0]['router_id']) : 0,

				crea_focus_id: value[0]['focus_id'],
				crea_stats_id: value[0]['stats_id'],
				crea_stats_revenu: JSON.parse(value[0]['stats_model']),
				crea_stats_adv_id: value[0]['advid'],
				crea_stats_adv_name: value[0]['advertiser'],
				crea_stats_client_id: parseInt(value[0]['clientid']),
				crea_stats_affiliate_id: parseInt(value[0]['affid']),
				crea_stats_country_id: parseInt(value[0]['stats_countryid']),
				crea_stats_currency_id: parseInt(value[0]['stats_currencyid']),
				crea_stats_segment: (value[0]['stats_segment']).includes('[') === true || value[0]['stats_segment'] === null || value[0]['stats_segment'] === 'null' || value[0]['stats_segment'] === '[]' ? [] : ((value[0]['stats_segment']).trim()).split(','),
				// scheduleSegment: (value[0]['stats_segment']).includes('[') === true || value[0]['stats_segment'] === null || value[0]['stats_segment'] === 'null' || value[0]['stats_segment'] === '[]' ? [] : ((value[0]['stats_segment']).trim()).split(','),
				scheduleSegment: segmentArray,
				// scheduleSuppression: value[0]['stats_suppression'] === null || value[0]['stats_suppression'] === '[]' ? [] : JSON.parse(value[0]['stats_suppression']),
				scheduleSuppression: suppressionArray,
				//scheduleSubscriber: subscriberArray,

				crea_stats_brand: value[0]['brand'],
				crea_stats_campagn_name: value[0]['creativity'],
				crea_stats_status: value[0]['stats_campagn_status'],
				crea_stats_type: value[0]['stats_schedule_type'],
				crea_stats_date: moment(value[0]['stats_date']),
				crea_stats_available_from: value[0]['stats_available_from'] !== 'null' ? moment(value[0]['stats_available_from']) : null,
				crea_stats_available_until: value[0]['stats_available_until'] !== null ? moment(value[0]['stats_available_until']) : null,
				crea_stats_sendout_id: value[0]['stats_id'] === null || value[0]['stats_id'] === 'null' ? null : value[0]['router_id'],
				displayScheduleBtn: (value[0]['router_id'] !== null && value[0]['router_id'] !== "" && value[0]['router_id'] !== "0") ? 'block' : 'none',
				blacklist_check: blacklist_check,

				display_send_stats: value[0]['stats_id'] === "0" ? "block" : "none",
				display_update_stats: value[0]['stats_id'] !== "0" ? "block" : "none",
				display_template_routeur: value[0]['header_id'] === "0" ? "block" : "none",

				deliveryMethod: value[0]['delivery_method'],
				displayThrottlingOption: value[0]['delivery_method'] === 'SendTimeOptimization' ? 'none' : 'block',
				throttling_delay: value[0]['delivery_method'] === 'Throttling' ? parseInt(value[0]['delivery_value']) : 4,
				displaySTODelay: value[0]['delivery_method'] === 'SendTimeOptimization' ? 'block' : 'none',
				stoDelay: value[0]['delivery_method'] === 'SendTimeOptimization' ? value[0]['delivery_value'] : '24 hours',
			})
		});
	}

	renderHtmlSizeWithoutImages() {
		if (this.state.crea_html_size >= 102) {
			return (
				<Col span={5}>
					<Row>
						<Col span={4} style={{ backgroundColor: 'red' }}>
							<WarningOutlined style={{ padding: 5, fontSize: 35, color: 'yellow' }} />
						</Col>
						<Col span={20} style={{ textAlign: 'center' }}>
							<div style={{ width: '100%', height: 'auto', margin: '0 auto' }}>
								<Tag color="#f50" style={{ height: 52, padding: 5, width: '100%' }}>
									<span style={{ textDecoration: 'underline', fontSize: 16 }}> Html Size</span>: &nbsp;&nbsp;<span><strong style={{ fontSize: '24px' }}>{this.state.crea_html_size}Ko</strong></span><br />
									<span style={{ fontSize: '20px !important' }}><strong>NEED TO BE COMPRESSED</strong></span>
								</Tag>
								<br />

							</div>
						</Col>
					</Row>
				</Col>
			)
		} else {
			return (
				<Col span={5}>
					<Row>
						<Col span={24} style={{ textAlign: 'center' }}>
							<div style={{ width: '100%', height: 'auto', margin: '0 auto' }}>
								<Tag color="#F0B311" style={{ height: 30, padding: 5, width: '100%' }}>
									<span style={{ textDecoration: 'underline', fontSize: 16 }}> Html Size</span>: &nbsp;&nbsp;<span><strong style={{ fontSize: '24px' }}>{this.state.crea_html_size}Ko</strong></span><br />
								</Tag>
								<br />
							</div>
						</Col>
					</Row>
				</Col>
			)
		}
	}

	renderHtmlSizeWithImages() {
		if (this.state.crea_total_size >= 1500) {
			return (
				<Col span={5}>
					<Row>
						<Col span={4} style={{ backgroundColor: 'red' }}>
							<WarningOutlined style={{ padding: 5, fontSize: 35, color: 'yellow' }} />
						</Col>
						<Col span={20} style={{ textAlign: 'center' }}>
							<div style={{ width: '100%', height: 'auto', margin: '0 auto' }}>
								<Tag color="#f50" style={{ height: 52, padding: 5, width: '100%' }}>
									<span style={{ textDecoration: 'underline', fontSize: 16 }}> Total Size</span>: &nbsp;&nbsp;<span><strong style={{ fontSize: '24px' }}>{this.state.crea_total_size}Ko</strong></span><br />
									<span style={{ fontSize: '20px !important' }}><strong>NEED TO BE COMPRESSED</strong></span>
								</Tag>
								<br />

							</div>
						</Col>
					</Row>
				</Col>
			)
		} else {
			return (
				<Col span={5}>
					<Row>
						<Col span={24} style={{ textAlign: 'center' }}>
							<div style={{ width: '80%', height: 'auto', margin: '0 auto' }}>
								<Tag color="#004A0E" style={{ height: 30, padding: 5, width: '100%' }}>
									<span style={{ textDecoration: 'underline', fontSize: 16 }}> Total Size</span>: &nbsp;&nbsp;<span><strong style={{ fontSize: '24px' }}>{this.state.crea_total_size}Ko</strong></span><br />
								</Tag>
								<br />
							</div>
						</Col>
					</Row>
				</Col>
			)
		}
	}

	onPreviewChange(value) {
		switch (value) {
			case 'desktop':
				this.setState({
					crea_preview_type: value,
					showDesktop: 'block',
					showOriginal: 'none',
					showImageDisable: 'none',
					showMobile: 'none',
					showGa: 'none',
				})
				break;
			case 'mobile':
				this.setState({
					crea_preview_type: value,
					showDesktop: 'none',
					showOriginal: 'none',
					showImageDisable: 'none',
					showMobile: 'block',
					showGa: 'none',
				})
				break;
			case 'ga':
				this.getPromotionPreview()
				this.setState({
					crea_preview_type: value,
					showDesktop: 'none',
					showOriginal: 'none',
					showImageDisable: 'none',
					showMobile: 'none',
					showGa: 'block',
				})
				break;
			case 'imagedisable':
				this.setState({
					crea_preview_type: value,
					showDesktop: 'none',
					showOriginal: 'none',
					showImageDisable: 'block',
					showMobile: 'none',
					showGa: 'none',
				})
				break;
			case 'original':
				this.setState({
					crea_preview_type: value,
					showDesktop: 'none',
					showOriginal: 'block',
					showImageDisable: 'none',
					showMobile: 'none',
					showGa: 'none',
				})
				break;
			default:
				this.setState({
					crea_preview_type: value,
					showDesktop: 'block',
					showOriginal: 'none',
					showImageDisable: 'none',
					showMobile: 'none',
					showGa: 'none',
				})
				break;
		}
	}

	onReoptimizingRequests() {
		this.setState({ loadingReoptimize: true })
		var tagsname = null
		this.state.liste_tags.map(item => {
			if (parseInt(this.state.crea_tags) === parseInt(item['id'])) {
				tagsname = item['tag']
			}
			return null
		})
		Promise.resolve(reupload_crea(this.state.user_id, this.state.apikey, tagsname, this.state.crea_reoptimize_pixels, this.state.crea_reoptimize_img, this.state.crea_reoptimize_link, this.state.crea_reoptimize_jumping, this.state.crea_reoptimize_comment, this.state.crea_reoptimize_compress, this.state.crea_id, this.state.crea_base_id, this.state.crea_user_id, this.state.crea_username, false)).then((value) => {
			this.setState({
				goBack: true
			})
		});
	}

	UpdateCode(status) {
		try {
			var html_code = null
			switch (status) {
				case 'optimized':
					html_code = base64_encode(this.state.crea_html_optimized)
					this.setState({ loadingUpdateOptimized: true })
					break;
				case 'original':
					html_code = base64_encode(this.state.crea_html_original)
					this.setState({ loadingUpdateOriginal: true })
					break;
				default:
					html_code = base64_encode(this.state.crea_html_optimized)
					break;
			}
			Promise.resolve(update_crea(this.state.user_id, this.state.apikey, this.state.crea_base_id, this.state.crea_id, html_code, status, this.state.crea_router_message_type, this.state.crea_user_id, this.state.crea_router_message_id)).then((value) => {
				setTimeout(() => {
					message.success('Code successfully update')
					this.setState({
						loadingUpdateOptimized: false,
						loadingUpdateOriginal: false
					})
				}, 2000)
			});
		}
		catch (err) {
			console.error('error authentification ' + err);
			return null
		}
	}

	onSaveAsDraft(status) {
		switch (status) {
			case 'save':
				this.setState({ loadingSave: true })
				break;
			case 'send':
				this.setState({ loadingSend: true })
				break;
			default:
				break;
		}
		var tagname = null
		this.state.liste_tags.map(item => {
			if (parseInt(this.state.crea_tags_id) === parseInt(item['id'])) {
				tagname = item['tag']
			}
			return null
		})
		var seed = null
		if (status === 'send') {
			seed = this.state.crea_router_seedlist_id
		}
		Promise.resolve(save_draft(this.state.user_id, this.state.apikey, this.state.crea_base_id, this.state.crea_id, this.state.crea_router_message_type, tagname, this.state.crea_router_object, this.state.crea_router_sender, this.state.crea_router_preheader, this.state.crea_router_fromemail, this.state.crea_router_replyname, this.state.crea_router_replyemail, this.state.crea_router_header_id, this.state.crea_router_footer_id, this.state.crea_router_message_id, this.state.crea_html_optimized, status, seed)).then((value) => {
			setTimeout(() => {
				message.success('Request successfully sent to the router')
				this.setState({
					loadingSave: false,
					loadingSend: false
				})
			}, 2000)

		});

	}

	onAdvertiserChange(value) {
		try {
			this.setState({ crea_stats_adv_name: value })
			if (value.length < 3) {
				return null
			} else {
				Promise.resolve(list_advertiser(this.state.user_id, this.state.apikey, this.state.stats_apikey, value)).then((value) => {
					this.setState({ liste_advertiser: value })
				});
			}
		} catch (error) {
			console.log('errror on advertiser change ' + error)
			return
		}
	}

	onModelChange(modelid, modelname, i) {
		var old = this.state.crea_stats_revenu
		old[i]['modelid'] = modelid
		old[i]['modelname'] = modelname
		this.setState({
			crea_stats_revenu: old
		})
	}

	onPayoutChange(payout, i) {
		var old = this.state.crea_stats_revenu
		old[i]['payout'] = payout
		this.setState({
			crea_stats_revenu: old
		})
	}

	onAddStatsRange() {
		var old = this.state.crea_stats_revenu
		old.push({ "modelid": null, "modelname": "", "payout": "" })
		this.setState({
			crea_stats_revenu: old
		})
	}

	onRemoveStatsRange(index) {
		var old = this.state.crea_stats_revenu
		old.splice(index, 1)
		console.log(old)
		this.setState({
			crea_stats_revenu: old
		})
	}


	renderStatsPayout() {
		// console.log(this.state.crea_stats_revenu)
		// if (this.state.crea_stats_revenu !== null) {
		// 	var key = generateKey()
		return this.state.crea_stats_revenu && this.state.crea_stats_revenu.map((el, i) =>
			<Row key={el}>
				<Col span={3} style={{ padding: 3, textAlign: 'center' }}>
					<p>Model(*)</p>
				</Col>
				<Col span={10}>
					<Select
						size="medium"
						showSearch
						optionFilterProp="children"
						style={{ width: '100%', color: '#192a56', fontWeight: 700, textAlign: 'center' }}
						value={el['modelid']}
						onChange={
							(value) => {
								this.state.liste_models.map(model => {
									if (parseInt(model['id']) === parseInt(value)) {
										this.onModelChange(value, model['name'], i)
									}
									return null
								})
							}
						}
					>
						{
							this.state.liste_models && this.state.liste_models.map(item => {
								var key = generateKey()
								return (
									<Option key={key} value={item['id']}>{(item['name']).toUpperCase()}</Option>
								)
							})
						}
					</Select>
				</Col>
				<Col span={3} style={{ padding: 3, textAlign: 'center' }}>
					<p>Payout(*)</p>
				</Col>
				<Col span={6}>
					<Input
						size="medium"
						value={el['payout']}
						style={{ width: '100%', color: '#192a56', fontWeight: 700, textAlign: 'center' }}
						onChange={(e) => {
							this.onPayoutChange(e.target.value, i)
						}}
					/>
				</Col>
				{
					i === 0 && <Col span={2} style={{ paddingLeft: 13 }}>
						<Tooltip title="Add rows" placement="bottom">
							<PlusCircleOutlined
								style={{ fontSize: 20 }}
								onClick={() => {
									this.onAddStatsRange()
								}}
							/>
						</Tooltip>
					</Col>
				}
				{
					i !== 0 && <Col span={2} style={{ paddingLeft: 13 }}>
						<Tooltip title="Remove rows" placement="bottom">
							<MinusCircleOutlined
								style={{ fontSize: 20 }}
								onClick={() => {
									this.onRemoveStatsRange(i)
								}}
							/>
						</Tooltip>
					</Col>
				}
			</Row>

		)
	}
	// }

	onClientsChange(value) {
		try {
			var liste_aff = []
			this.state.liste_clients.map(item => {
				if (parseInt(value) === parseInt(item['id'])) {
					liste_aff = item['affiliatemanagers']
				}
				return null
			})
			this.setState({
				liste_affiliate: liste_aff,
				crea_stats_client_id: value,
			})
		} catch (error) {
			console.log('error on client change ' + error)
		}
	}

	onSaveToStats(action) {
		this.setState({ loadingSaveStats: true })
		var country_name = null
		var currency_name = null
		var advid = null
		var client_name = null
		var affiliate_name = null
		var tagsname = null
		this.state.liste_advertiser.map(item => {
			if (item['name'] === this.state.crea_stats_adv_name) {
				advid = item['id']
			}
			return null
		})

		this.state.liste_clients.map(item => {
			if (parseInt(this.state.crea_stats_client_id) === parseInt(item['id'])) {
				client_name = item['name']
			}
		})

		this.state.liste_affiliate.map(item => {
			if (parseInt(this.state.crea_stats_affiliate_id) === parseInt(item['id'])) {
				affiliate_name = item['name']
			}
		})

		this.state.liste_country.map(item => {
			if (parseInt(this.state.crea_stats_country_id) === parseInt(item['id'])) {
				country_name = item['countrycode']
			}
			if (parseInt(this.state.crea_stats_currency_id) === parseInt(item['currencyid'])) {
				currency_name = item['currencyname']
			}
			return null
		})

		this.state.liste_tags.map(item => {
			if (parseInt(this.state.crea_tags_id) === item['id']) {
				tagsname = item['tag']
			}
		})

		if (advid === null || this.state.crea_stats_sendout_id === null || this.state.crea_stats_sendout_id === "0") {
			message.warning('Mandatory field empty !!!')
			this.setState({ loadingSaveStats: false })
			return
		} else {
			var fd = new FormData()
			fd.append('advname', this.state.crea_stats_adv_name)
			fd.append('clientname', client_name)
			fd.append('affname', affiliate_name)
			fd.append('tagsname', tagsname)
			fd.append('tagid', this.state.crea_tags_id)

			fd.append('user_id', this.state.crea_user_id)
			fd.append('apikey', base64_decode(localStorage.getItem(base64_encode('apikey'))))
			fd.append('stats_apikey', base64_decode(localStorage.getItem(base64_encode('stats_apikey'))))
			fd.append('baseid', this.state.crea_base_id)
			fd.append('creaid', this.state.crea_id)
			fd.append('stats_date', moment(this.state.crea_stats_date).format('YYYY-MM-DD').toString())
			fd.append('stats_comment', this.state.crea_stats_comment)
			fd.append('stats_segment', JSON.stringify(this.state.crea_stats_segment))
			fd.append('advid', advid)
			fd.append('clientid', this.state.crea_stats_client_id)
			fd.append('affid', this.state.crea_stats_affiliate_id)
			fd.append('countryid', this.state.crea_stats_country_id)
			fd.append('currencyid', this.state.crea_stats_currency_id)
			fd.append('countryname', country_name)
			fd.append('currencyname', currency_name)
			fd.append('stats_revenu', JSON.stringify(this.state.crea_stats_revenu))
			fd.append('stats_campagn_status', this.state.crea_stats_status)
			fd.append('stats_campagn_type', this.state.crea_stats_type)
			fd.append('campagn_name', this.state.crea_stats_campagn_name)
			fd.append('campagn_from', this.state.crea_stats_available_from !== null ? moment(this.state.crea_stats_available_from).format('YYYY-MM-DD').toString() : null)
			fd.append('campagn_until', this.state.crea_stats_available_until !== null ? moment(this.state.crea_stats_available_until).format('YYYY-MM-DD').toString() : null)
			fd.append('sendoutid', this.state.crea_stats_sendout_id)
			fd.append('brand', this.state.crea_stats_brand)
			fd.append('action', action)
			axios.post(process.env.REACT_APP_ENDPOINT_STATS_SCHEDULE_EMAILING, fd)
				.then(res => {
					message.info('Campaign created successfully at Stats and updated at the system', 3)
					this.setState({ loadingSaveStats: false, goBack: true })
				})
		}
	}

	onUpdateToStats() {
		if (this.state.crea_stats_id === null || this.state.crea_stats_id === "0") {
			message.warning('Campaign didn\'t present at stats', 2)
		} else {
			// this.setState({ loadingUpdateStats: true })
			var country_name = null
			var currency_name = null
			var advid = null

			this.state.liste_advertiser.map(item => {
				if (item['name'] === this.state.crea_stats_adv_name) {
					advid = item['id']
				}
				return null
			})
			this.state.liste_country.map(item => {
				if (parseInt(this.state.crea_stats_country_id) === parseInt(item['id'])) {
					country_name = item['countrycode']
				}
				if (parseInt(this.state.crea_stats_currency_id) === parseInt(item['currencyid'])) {
					currency_name = item['currencyname']
				}
				return null
			})
			if (advid === null || this.state.crea_stats_sendout_id === null || this.state.crea_stats_sendout_id === "0") {
				message.warning('Mandatory field empty !!!')
				return
			} else {
				var fd = new FormData()
				fd.append('user_id', this.state.crea_user_id)
				fd.append('apikey', base64_decode(localStorage.getItem(base64_encode('apikey'))))
				fd.append('stats_id', this.state.crea_stats_id)
				fd.append('focusid', this.state.crea_focus_id)
				fd.append('stats_apikey', base64_decode(localStorage.getItem(base64_encode('stats_apikey'))))
				fd.append('baseid', this.state.crea_base_id)
				fd.append('creaid', this.state.crea_id)
				fd.append('stats_date', moment(this.state.crea_stats_date).format('YYYY-MM-DD').toString())
				fd.append('stats_comment', this.state.crea_stats_comment)
				fd.append('stats_segment', this.state.crea_stats_segment)
				fd.append('advid', advid)
				fd.append('advername', this.state.crea_stats_adv_name)
				fd.append('clientid', this.state.crea_stats_client_id)
				fd.append('affid', this.state.crea_stats_affiliate_id)
				fd.append('countryid', this.state.crea_stats_country_id)
				fd.append('currencyid', this.state.crea_stats_currency_id)
				fd.append('countryname', country_name)
				fd.append('currencyname', currency_name)
				fd.append('stats_revenu', JSON.stringify(this.state.crea_stats_revenu))
				fd.append('stats_campagn_status', this.state.crea_stats_status)
				fd.append('stats_campagn_type', this.state.crea_stats_type)
				fd.append('campagn_name', this.state.crea_stats_campagn_name)
				fd.append('campagn_from', this.state.crea_stats_available_from !== null ? moment(this.state.crea_stats_available_from).format('YYYY-MM-DD').toString() : null)
				fd.append('campagn_until', this.state.crea_stats_available_until !== null ? moment(this.state.crea_stats_available_until).format('YYYY-MM-DD').toString() : null)
				fd.append('sendoutid', this.state.crea_stats_sendout_id)
				axios.post(process.env.REACT_APP_ENDPOINT_STATS_UPDATE_EMAILING, fd)
					.then(res => {
						// message.info('Campaign updated successfully at Stats and updated at the system', 3)
						// this.setState({ loadingUpdateStats: false, goBack: true })
					})
			}
		}

	}

	getListSegments(base_id) {
		Promise.resolve(list_segment(this.state.user_id, this.state.apikey, this.state.stats_apikey, base_id)).then((value) => {
			var mydata = value.sort((a, b) => b.id - a.id);
			this.setState({
				liste_segments: mydata
			})
		});
	}

	getListSubscriber(base_id) {
		Promise.resolve(list_subscriber(this.state.user_id, this.state.apikey, this.state.stats_apikey, base_id)).then((value) => {
			var mydata = value.sort((a, b) => b.id - a.id);
			this.setState({
				liste_subscriber: mydata
			})
		});
	}

	getListSuppression(base_id) {
		Promise.resolve(list_suppression(this.state.user_id, this.state.apikey, this.state.stats_apikey, base_id)).then((value) => {
			var mydata = value.sort((a, b) => b.Id - a.Id);
			this.setState({
				liste_suppression: mydata
			})
		});
	}

	getListSeedlist(base_id) {
		Promise.resolve(list_seedlist(this.state.user_id, this.state.apikey, this.state.stats_apikey, base_id,)).then((value) => {
			var mydata = value.sort((a, b) => b.id - a.id);
			this.setState({
				liste_seedlist: mydata
			})
		});
	}

	onDeliveryMethodChanged(value) {
		this.setState({ deliveryMethod: value })
		switch (value) {
			case 'Throttling':
				this.setState({ displaySTODelay: 'none', displayThrottlingOption: 'block', displayTimeTravelInfo: 'none' })
				break;

			case 'SendTimeOptimization':
				this.setState({ displaySTODelay: 'block', displayThrottlingOption: 'none', displayTimeTravelInfo: 'none', displayThrottlingDelay: 'none' })
				break;

			case 'None':
				this.setState({ displaySTODelay: 'none', displayThrottlingOption: 'none', displayTimeTravelInfo: 'block', displayThrottlingDelay: 'none' })
				break;
			default:
				break;
		}
	}

	onThrottlingTypeChange(value) {
		this.setState({ throttling_type: value })
		switch (value) {
			case "Manual":
				this.setState({ displaySTODelay: 'none', displayThrottlingDelay: 'block' })
				break;
			default:
				this.setState({ displayThrottlingDelay: 'none' })
				break;
		}
	}

	onScheduled() {
		this.setState({ loadingScheduled: true })
		var recurring_enddate = moment(this.state.crea_end_date).format('MM/DD/YYYY')
		var recurring_days = JSON.stringify(this.state.crea_recurring_days)
		var recurring_hour = this.state.crea_recurring_hour
		var recurring_months = JSON.stringify(this.state.liste_month)
		var recurring_minutes = this.state.crea_recurring_minutes
		var tagname = null
		var useTemplateRouter = this.state.display_template_routeur === 'block' ? 1 : 0
		this.state.liste_tags.map(item => {
			if (parseInt(this.state.crea_tags_id) === parseInt(item['id'])) {
				tagname = item['tag']
			}
			return null
		})

		var datetime = (moment(this.state.crea_stats_date).format('YYYY-MM-DD HH:mm:ss').toString()).split(' ')
		Promise.resolve(scheduled(
			this.state.user_id, this.state.apikey, this.state.crea_base_id,
			this.state.crea_router_sender, this.state.crea_router_fromemail,
			this.state.crea_router_replyname, this.state.crea_router_replyemail,
			this.state.crea_router_object, this.state.crea_router_preheader,
			this.state.crea_router_header_id, this.state.crea_router_footer_id,
			this.state.crea_router_message_id, this.state.crea_router_message_type,
			this.state.crea_html_optimized, null, this.state.scheduleSegment,
			this.state.scheduleSuppression, this.state.crea_router_seedlist_id,
			datetime[0], datetime[1], this.state.timezone, tagname, this.state.crea_tags_id,
			this.state.deliveryMethod, this.state.throttling_delay,
			this.state.throttling_type, this.state.stoDelay,
			recurring_enddate, recurring_days,
			recurring_hour, recurring_months,
			recurring_minutes, this.state.scheduleSubscriber, this.state.crea_id, useTemplateRouter)).then((value) => {
				message.success('Message successfully scheduled a the router', 2)
				setTimeout(() => {
					this.setState({
						loadingSave: false,
						loadingSend: false,
						isScheduled: true
					})
				}, 1500)
			});
	}

	getDaysInMonth(month, year) {
		let date = new Date(`${year}-${parseInt(month) + 1}-01`);
		let days = [];
		let months = []
		while (date.getMonth() === parseInt(month)) {
			days.push(date.getDate());
			months.push(date.getMonth())
			date.setDate(date.getDate() + 1);
		}
		this.setState({ liste_days: days })
	}

	removeDuplicates(arr) {
		return arr.filter((item,
			index) => arr.indexOf(item) === index);
	}

	getDaysBetweenDates(endDate) {
		const days = [];
		let months = []
		let currentDate = new Date(moment(this.state.crea_stats_date));
		while (currentDate <= endDate) {
			days.push((new Date(currentDate)).getDate());
			months.push((new Date(currentDate)).getMonth() + 1);
			currentDate.setDate(currentDate.getDate() + 1);
		}
		this.setState({ liste_days: days, liste_month: this.removeDuplicates(months) })
	}

	onClickScheduled() {
		var default_time = "00:00:00"
		var delivery_method = 'SendTimeOptimization'
		var throttling_type = 'Auto'
		var throttling_delay = '8'
		var displayThrottlingOption = 'none'
		var displayThrottlingDelay = 'none'
		var displaySTODelay = 'none'

		this.state.liste_segments && this.state.liste_segments.map(item => {
			this.state.crea_stats_segment && this.state.crea_stats_segment.map(elem => {
				if (parseInt(item['id']) === parseInt(elem)) {
					if (((item['name']).toLowerCase()).includes('impact_1')) {
						default_time = "06:00:00"
						delivery_method = 'Throttling'
						throttling_type = 'Manual'
						throttling_delay = '8'
						//display
						displayThrottlingOption = 'block'
						displayThrottlingDelay = 'block'
						displaySTODelay = 'none'
						return null
					} else {
						if (((item['name']).toLowerCase()).includes('impact_2')) {
							default_time = "12:00:00"
							delivery_method = 'Throttling'
							throttling_type = 'Manual'
							throttling_delay = '8'
							displayThrottlingOption = 'block'
							displayThrottlingDelay = 'block'
							displaySTODelay = 'none'
							return null
						} else {
							default_time = "00:00:00"
							delivery_method = 'SendTimeOptimization'
							throttling_type = 'Auto'
							throttling_delay = '1'
							displayThrottlingOption = 'none'
							displayThrottlingDelay = 'none'
							displaySTODelay = 'block'
							return null
						}
					}
				}
				return null
			})
			return null
		})
		var mydate = new Date(moment(this.state.crea_stats_date).format('YYYY-MM-DD'))
		var correctDateTime = moment(this.state.crea_stats_date).format('YYYY-MM-DD') + ' ' + default_time
		this.setState({
			displayRouterData: 'none',
			displayRouterScheduled: 'block',
			//value
			deliveryMethod: delivery_method,
			throttling_type: throttling_type,
			throttling_delay: throttling_delay,
			//display
			displayThrottlingDelay: displayThrottlingDelay,
			displayThrottlingOption: displayThrottlingOption,
			displaySTODelay: displaySTODelay,
			crea_stats_date: moment(correctDateTime),
			loadingStartScheduled: false,
			// scheduleSubscriber: 
		})

	}

	renderDomainBlacklistCheck() {
		if (this.state.displayScheduleBtn && this.state.blacklist_check !== null) {
			return (
				<div
					style={{ margin: '0 auto', width: '60%', fontSize: 14, padding: 15 }}
					dangerouslySetInnerHTML={{ __html: this.state.blacklist_check }}
				/>

			)
		}
	}

	renderDateScheduled() {
		if (this.state.crea_router_message_type === "Newsletters") {
			return (
				<div style={{ width: '100%' }}>
					<Row>
						<Col span={6} style={{ textAlign: 'center', padding: 3, fontSize: 18 }}>
							<span>Scheduled date</span>
						</Col>
						<Col span={18}>
							<DatePicker
								style={{ width: '100%', textAlign: 'center' }}
								showTime
								value={this.state.crea_stats_date}
								onChange={(a, b) => {
									this.setState({
										crea_stats_date: a,
										crea_end_date: null,
										liste_month: [],
									})
								}}
								disabledDate={current => {
									return current && current <= moment().subtract(1, 'days');
								}}
							/>
						</Col>
						{/* <Col span={3} style={{ textAlign: 'center', padding: 3, fontSize: 18 }}>
							<span>Delivery method</span>
						</Col>
						<Col span={9}>
							<Select
								optionFilterProp="children"
								showSearch
								size="medium"
								style={{ width: '100%', color: '#192a56', textAlign: 'center' }}
								value={this.state.deliveryMethod}
								onChange={
									(value) => {
										this.onDeliveryMethodChanged(value)
									}}
							>
								<Option key="1" value="Throttling">Throttling</Option>
								<Option key="2" value="SendTimeOptimization">Send Time Optimization</Option>
							</Select>
						</Col> */}
					</Row>
				</div>
			)
		} else {
			if (this.state.crea_router_message_type === "Recurrings") {
				return (
					<div>
						<Row>
							<Col span={4} style={{ textAlign: 'center', padding: 3, fontSize: 18 }}>
								<span>Start date</span>
							</Col>
							<Col span={8}>
								<DatePicker
									style={{ width: '100%', textAlign: 'center' }}
									// showTime
									value={this.state.crea_stats_date}
									onChange={(a, b) => {
										this.setState({
											crea_stats_date: a
										})
									}}
									disabledDate={current => {
										return current && current <= moment().subtract(1, 'days');
									}}
								/>
							</Col>
							<Col span={4} style={{ textAlign: 'center', padding: 3, fontSize: 18 }}>
								<span>End date</span>
							</Col>
							<Col span={6}>
								<DatePicker
									style={{ width: '100%', textAlign: 'center' }}
									// showTime
									disabledDate={current => {
										return current && current <= moment(this.state.crea_stats_date);
									}}
									value={this.state.crea_end_date}
									onChange={(a, b) => {
										this.getDaysBetweenDates(a)
										this.setState({ crea_end_date: a })
									}}

								/>
							</Col>
						</Row>
						<br />
						<Row>
							<Col span={4} style={{ textAlign: 'center', padding: 3, fontSize: 18 }}>
								<span>Days</span>
							</Col>
							<Col span={8}>
								<Select
									mode='multiple'
									optionFilterProp="children"
									showSearch
									size="medium"
									style={{ width: '100%', color: '#192a56', textAlign: 'center' }}
									value={this.state.crea_recurring_days}
									onChange={(value) => { this.setState({ crea_recurring_days: value }) }}
								>
									<Option key="-1" value={null}>Every day</Option>
									{
										this.state.liste_days && this.state.liste_days.map(item => {
											var key = generateKey()
											return (
												<Option key={key} value={parseInt(item)}>{item}</Option>
											)
										})
									}
								</Select>
							</Col>
							<Col span={4} style={{ textAlign: 'center', padding: 3, fontSize: 18 }}>
								<span>Hour</span>
							</Col>
							<Col span={6}>
								<Select
									mode='multiple'
									optionFilterProp="children"
									showSearch
									size="medium"
									style={{ width: '100%', color: '#192a56', textAlign: 'center' }}
									value={this.state.crea_recurring_hour}
									onChange={(value) => { this.setState({ crea_recurring_hour: value }) }}
								>
									<Option key="-1" value={null}>Every hour</Option>
									{
										this.state.liste_hour.map(item => {
											var key = generateKey()
											return (
												<Option key={key} value={parseInt(item.value)}>{item.hour}</Option>
											)
										})
									}
								</Select>
							</Col>
						</Row>
						<br />
						<Row>
							<Col span={4} style={{ textAlign: 'center', padding: 3, fontSize: 18 }}>
								<span>Month</span>
							</Col>
							<Col span={8}>
								<Select
									mode='multiple'
									optionFilterProp="children"
									showSearch
									size="medium"
									style={{ width: '100%', color: '#192a56', textAlign: 'center' }}
									value={this.state.liste_month}
									onChange={(value) => {
										this.setState({
											liste_month: value
										})
									}}
								>
									<Option key="-1" value={null}>Every month</Option>
									<Option key="-2" value={1}>January</Option>
									<Option key="-3" value={2}>February</Option>
									<Option key="-4" value={3}>March</Option>
									<Option key="-5" value={4}>April</Option>
									<Option key="-6" value={5}>May</Option>
									<Option key="-7" value={6}>June</Option>
									<Option key="-8" value={7}>July</Option>
									<Option key="-9" value={8}>August</Option>
									<Option key="-10" value={9}>September</Option>
									<Option key="-11" value={10}>October</Option>
									<Option key="-12" value={11}>November</Option>
									<Option key="-13" value={12}>December</Option>

								</Select>
							</Col>
							<Col span={4} style={{ textAlign: 'center', padding: 3, fontSize: 18 }}>
								<span>Minutes</span>
							</Col>
							<Col span={6}>
								<Input
									type="number"
									min="1"
									max="59"
									value={this.state.crea_recurring_minutes}
									onChange={(e) => { this.setState({ crea_recurring_minutes: e.target.value }) }}
									style={{ textAlign: 'center' }}
								/>
							</Col>
						</Row>
					</div>
				)
			}
		}
	}

	render() {
		if (this.state.goBack === true) {
			return (<Redirect to={{
				pathname: '/app/creativities/task'
			}} />)
		}

		if (this.state.isScheduled === true) {
			localStorage.setItem('defaulttabcrea', '2')
			var base_id = this.state.crea_base_id
			return (<Redirect to={{
				pathname: '/app/creativities/task',
				state: { base_id }
			}} />)
		}

		const bg = {
			overlay: {
				backgroundColor: "rgba(0, 0, 0, .5)",
			}
		}

		const menu = (
			// <Menu style={{ textAlign: 'center', color: '#fff', width: '20%', height: '30vh', opacity: 1,  padding: 0 }}>
			<div style={{ width: 800, height: '100%', overflowX: 'hidden', overflowY: 'auto' }}>
				<Picker
					emojisToShowFilter={false}

					showSearch={false}
					navPosition="bottom"
					search="none"
					style={{ width: '100%', overflow: 'scroll', fontSize: 12, float: 'right' }}
					onSelect={
						(emoji) => {
							var data = this.state.crea_router_object + emoji.native
							this.setState({ crea_router_object: data })
						}
					}
				/>
			</div>

			// </Menu>
		);

		return (
			<div style={{ width: '100%', height: '99.6vh', overflow: 'hidden', fontFamily: 'Gotham', fontSize: 18 }}>
				{/* <div>
					<img
						alt="back"
						src={back}
						style={{ width: 30, height: 30, marginTop: '0px', marginLeft: '0px', position: 'absolute' }}
						onClick={() => {
							console.log('test')
							this.setState({ goBack: true })
						}}
					/>
				</div> */}

				<div style={{ height: '100%' }}>
					<div>
						<Row>
							<Col span={1}>
								<img
									alt="back"
									src={back}
									style={{ width: 30, height: 30, marginTop: '0px', marginLeft: '0px', position: 'absolute' }}
									onClick={() => {
										console.log('test')
										this.setState({ goBack: true })
									}}
								/>
							</Col>
							<Col span={3}>
								<Row>
									<Col span={24} style={{ textAlign: 'center' }}>
										<div style={{ width: '80%', height: 'auto', margin: '0 auto' }}>
											<Tag color="#2db7f5" style={{ height: 30, padding: 5, width: '100%' }}>
												<span style={{ textDecoration: 'underline', fontSize: 16 }}> Hyperlinks </span>: &nbsp;&nbsp;<span><strong style={{ fontSize: '16px' }}>{this.state.crea_links_count}</strong></span>
											</Tag>
										</div>
									</Col>
								</Row>
							</Col>
							<Col span={3}>
								<Row>
									<Col span={24} style={{ textAlign: 'center' }}>
										<div style={{ width: '80%', height: 'auto', margin: '0 auto' }}>
											<Tag color="#87d068" style={{ height: 30, padding: 5, width: '100%' }}>
												<span style={{ textDecoration: 'underline', fontSize: 16 }}> Images </span>: &nbsp;&nbsp;<span><strong style={{ fontSize: '16px' }}>{this.state.crea_images_count}</strong></span>
											</Tag>
										</div>
									</Col>
								</Row>
							</Col>
							<Col span={3}>
								<Row>
									<Col span={24} style={{ textAlign: 'left' }}>
										<div style={{ width: '80%', height: 'auto', margin: '0 auto' }}>
											<Tag color="#87d068" style={{ height: 30, padding: 5, width: '100%' }}>
												<span style={{ textDecoration: 'underline', fontSize: 16 }}> Images Size</span>: &nbsp;&nbsp;<span><strong style={{ fontSize: '16px' }}>{this.state.crea_images_size}Ko</strong></span>
											</Tag>
										</div>
									</Col>
								</Row>
							</Col>
							{this.renderHtmlSizeWithoutImages()}
							<Col span={1}></Col>
							{this.renderHtmlSizeWithImages()}
						</Row>
					</div>

					<div style={{ marginTop: 8, width: '100%', height: '95vh' }}>
						<Row>
							<Col span={10}>
								<Tabs type="card" style={{ height: '100%', width: '100%', padding: 0 }} defaultActiveKey={"1"}>
									<TabPane tab={<div style={{ textAlign: 'center' }}><span style={{ fontSize: 18, fontWeight: 600 }}>PREVIEW</span></div>} key="1" style={{ height: '88.5vh' }}>
										<div style={{ width: '100%', margin: '0 auto', padding: 5, marginTop: '0px', marginBottom: 0 }}>
											<Row>
												<Col span={6} style={{ textAlign: 'center', padding: 5, fontSize: 18, fontFamily: 'Lato !important' }}>
													<span>Preview type</span>
												</Col>
												<Col span={7}>
													<Select
														size="large"
														style={{ width: '90%', textAlign: 'center', fontWeight: 600, fontSize: 16 }}
														value={this.state.crea_preview_type}
														onChange={(value) => {
															this.onPreviewChange(value)
														}}
													>
														<Option key="11" value="desktop">Desktop</Option>
														<Option key="51" value="original">Original</Option>
														<Option key="21" value="mobile">Mobile</Option>
														<Option key="41" value="imagedisable">Images disable</Option>
														{/* <Option key="31" value="ga">Google Annotation</Option> */}
													</Select>
												</Col>
												<Col span={6}>
													<button
														className="btn btn-info"
														style={{ borderRadius: 10, fontSize: 15, color: '#fff', height: 40, width: '98%', margin: '0 auto', fontWeight: 800 }}
														onClick={() => {
															this.setState({
																show_reoptimize: true
															})

														}}
													>
														REOPTIMIZE
													</button>
												</Col>
												{/* <Col span={1}></Col> */}
												<Col span={5}>
													<button
														className="btn btn-success"
														style={{ borderRadius: 10, fontSize: 15, height: 40, width: '98%', margin: '0 auto' }}
														onClick={() => {
															var new_link = process.env.REACT_APP_ENDPOINT_CREA_PREVIEW + '=' + base64_encode(this.state.crea_id)
															window.open(new_link, '_blank')

														}}
													>
														VIEW TAB
													</button>
												</Col>
											</Row>
										</div>
										<div style={{ height: '80vh', width: '100%', display: `${this.state.showDesktop}` }}>
											<ReactSrcDocIframe
												title="preview"
												className="embed-responsive-item" srcDoc={this.state.crea_html_optimized}
												style={{ width: '100%', height: '100%', marginLeft: '0px', overflow: 'scroll', border: 'none' }}
											/>
										</div>
										<div style={{ height: '80vh', width: '100%', display: `${this.state.showOriginal}` }}>
											<ReactSrcDocIframe
												title="preview"
												className="embed-responsive-item" srcDoc={this.state.crea_html_original}
												style={{ width: '100%', height: '60vh', marginLeft: '0px', overflow: 'scroll', border: 'none' }}
											/>
										</div>
										<div style={{ height: '80vh', width: '100%', display: `${this.state.showImageDisable}` }}>
											<ReactSrcDocIframe
												title="preview"
												className="embed-responsive-item" srcDoc={this.state.crea_html_broken}
												style={{ width: '100%', height: '60vh', marginLeft: '0px', overflow: 'scroll', border: 'none' }}
											/>
										</div>
										<div style={{ height: '80vh', width: '100%', display: `${this.state.showMobile}` }}>
											<div className="smartphone">
												<div className="content">
													<iframe
														title="preview"
														srcDoc={this.state.crea_html_optimized}
														style={{ width: 360, height: 480, marginTop: 157, marginLeft: '0px', overflow: 'scroll' }}
													/>
												</div>
											</div>
										</div>
									</TabPane>

									<TabPane tab={<div style={{ textAlign: 'center' }}><span style={{ fontSize: 18, fontWeight: 600 }}>CODE</span></div>} key="2">
										<Collapse defaultActiveKey={['1', '2']}>
											<Panel header={<span style={{ fontWeight: 700 }}>HTML CODE OPTIMIZED</span>} key="1" style={{ height: '43vh' }}>
												<div style={{ height: '30vh', marginTop: 5 }}>
													<Editor
														height="28vh"
														defaultLanguage="html"
														theme="vs-dark"
														defaultValue={this.state.crea_html_optimized}
														style={{ width: '100%', border: '1px solid gray', marginTop: '0px' }}
														onChange={(value) => {
															this.setState({ crea_html_optimized: value })
														}}
													/>
													<div style={{ padding: 15 }}>
														<Row>
															<Col span={11}>
																<Button
																	loading={this.state.loadingUpdateOptimized}
																	className="btn btn-success"
																	style={{ width: "100%", margin: '0 auto', fontSize: 15 }}
																	onClick={() => {
																		this.UpdateCode('optimized')
																	}}
																>
																	UPDATE
																</Button>
															</Col>
															<Col span={2}></Col>
															<Col span={11}>
																<CopyToClipboard text={this.state.crea_html_optimized}>
																	<Button
																		className="btn btn-primary"
																		style={{ width: "100%", margin: '0 auto', fontSize: 15 }}
																		onClick={() => {
																			message.info('Copied', 1)
																		}}
																	>
																		COPY
																	</Button>
																</CopyToClipboard>
															</Col>
														</Row>
													</div>
												</div>
											</Panel>
											<Panel header={<span style={{ fontWeight: 700 }}>HTML CODE ORIGINAL</span>} key="2" style={{ height: '43vh' }}>
												<div style={{ height: '65vh' }}>
													{/* <AceEditor
														mode="html"
														theme="eclipse"
														placeholder="Paste code here"
														style={{ marginTop: '-5px', width: '100%', height: '28vh', border: '1px solid gray', fontSize: 6 }}
														value={this.state.crea_html_original}
														onChange={(value) => {
															this.setState({
																crea_html_original: value
															})
														}}
														setOptions={{
															displayIndentGuides: false,
															enableBasicAutocompletion: true,
															enableLiveAutocompletion: true,
															enableSnippets: true,
															wrap: true,
															fontSize: 11
														}}
													/> */}
													<Editor
														height="28vh"
														defaultLanguage="html"
														theme="vs-dark"
														defaultValue={this.state.crea_html_original}
														style={{ width: '100%', border: '1px solid gray', marginTop: '0px' }}
														onChange={(value) => {
															this.setState({ crea_html_original: value })
														}}
													/>
													<div style={{ padding: 15 }}>
														<Row>
															<Col span={11}>
																<Button
																	loading={this.state.loadingUpdateOriginal}
																	className="btn btn-success"
																	style={{ width: "100%", margin: '0 auto', fontSize: 15 }}
																	onClick={() => {
																		this.UpdateCode('original')
																	}}
																>
																	UPDATE
																</Button>
															</Col>
															<Col span={2}></Col>
															<Col span={11}>
																<CopyToClipboard text={this.state.crea_html_original}>
																	<Button
																		className="btn btn-primary"
																		style={{ width: "100%", margin: '0 auto', fontSize: 15 }}
																		onClick={() => {
																			message.info('Copied', 1)
																		}}
																	>
																		COPY
																	</Button>
																</CopyToClipboard>
															</Col>
														</Row>
													</div>
												</div>
											</Panel>
										</Collapse>
									</TabPane>

									<TabPane tab={<div style={{ textAlign: 'center' }}><span style={{ fontSize: 18, fontWeight: 600 }}>BLACKLIST CHECK</span></div>} key="3">
										{this.renderDomainBlacklistCheck()}
									</TabPane>
								</Tabs>
							</Col>

							<Col span={14}>
								<Collapse accordion defaultActiveKey={['3']}>
									<Panel header={<span style={{ fontWeight: 700 }}>ROUTER</span>} key="3">
										<div style={{ height: '82vh', overflow: 'scroll', overflowX: 'hidden', display: `${this.state.displayRouterData}` }}>
											<Row>
												<Col span={24}>
													<Row>
														<Col span={3} style={{ textAlign: 'center', padding: 3 }}>
															<span>Sender</span>
														</Col>
														<Col span={10}>
															<Input
																type="text"
																value={this.state.crea_router_sender}
																style={{ width: '100%', color: '#192a56' }}
																onChange={(e) => { this.setState({ crea_router_sender: e.target.value }) }}
															/>
														</Col>
														<Col span={3} style={{ textAlign: 'center', padding: 3 }}>
															<span>FromEmail</span>
														</Col>
														<Col span={8}>
															<Input
																type="text"
																value={this.state.crea_router_fromemail}
																onChange={(e) => { this.setState({ crea_router_fromemail: e.target.value }) }}
																style={{ width: '100%', color: '#192a56' }}
															/>
														</Col>
													</Row>
													<br />
													<Row>
														<Col span={3} style={{ textAlign: 'center', padding: 3 }}>
															<span>ReplyToName</span>
														</Col>
														<Col span={10}>
															<Input
																type="text"
																value={this.state.crea_router_replyname}
																onChange={(e) => { this.setState({ crea_router_replyname: e.target.value }) }}
																style={{ width: '100%', color: '#192a56' }}
															/>
														</Col>
														<Col span={3} style={{ textAlign: 'center', padding: 3 }}>
															<span>ReplyEmail</span>
														</Col>
														<Col span={8}>
															<Input
																type="text"
																value={this.state.crea_router_replyemail}
																onChange={(e) => { this.setState({ crea_router_replyemail: e.target.value }) }}
																style={{ width: '100%', color: '#192a56' }}
															/>
														</Col>
													</Row>
													<br />
													<Row>
														<Col span={3} style={{ textAlign: 'center', padding: 3 }}>
															<span>Object</span>
														</Col>
														<Col span={19}>
															<Input
																type="text"
																value={this.state.crea_router_object}
																onChange={(e) => { this.setState({ crea_router_object: e.target.value }) }}
																style={{ width: '100%', color: '#192a56' }}
															/>
														</Col>
														<Col span={2}>
															<Dropdown overlay={menu} placement="bottom">
																<div>
																	<img src={emoji} alt="emoji" style={{ width: 28, height: 28, display: 'block', margin: '0 auto' }} />
																</div>
															</Dropdown>
														</Col>

													</Row>
													<br />
													<Row>
														<Col span={3} style={{ textAlign: 'center', padding: 15 }}>
															<span>Preheader</span>
														</Col>
														<Col span={21}>
															<TextArea
																type="text"
																value={this.state.crea_router_preheader}
																onChange={(e) => { this.setState({ crea_router_preheader: e.target.value }) }}
																style={{ width: '100%', color: '#192a56' }}
															/>
														</Col>
													</Row>
													<br />
													<Row>
														<Col span={3} style={{ textAlign: 'center', padding: 3 }}>
															<span>Tag</span>
														</Col>
														<Col span={9}>
															<Select
																optionFilterProp="children"
																showSearch
																size="medium"
																style={{ width: '100%', color: '#192a56', textAlign: 'center' }}
																value={this.state.crea_tags_id}
																onChange={(value) => { this.setState({ crea_tags_id: value }) }}
															>
																{
																	this.state.liste_tags && this.state.liste_tags.map(item => {
																		var key = generateKey()
																		return (
																			<Option key={key} value={parseInt(item.id)}>{item.tag}</Option>
																		)
																	})
																}
															</Select>
														</Col>
														<Col span={3} style={{ textAlign: 'center', padding: 3 }}>
															<span>Type</span>
														</Col>
														<Col span={9}>
															<Select
																optionFilterProp="children"
																showSearch
																size="medium"
																style={{ width: '100%', color: '#192a56', textAlign: 'center' }}
																value={this.state.crea_router_message_type}
																onChange={(value) => {
																	this.onGetDraft(this.state.crea_base_id, value)
																	this.setState({ crea_router_message_type: value })
																}}
															>
																<Option value="Newsletters">Newsletters</Option>
																<Option value="Recurrings">Recurrings</Option>
																<Option value="Triggers">Triggers</Option>
															</Select>
														</Col>
													</Row>
													<br />
													{
														this.state.display_template_routeur === 'block' &&
														<Row>
															<Col span={3} style={{ textAlign: 'center', padding: 3 }}>
																<span>Header</span>
															</Col>
															<Col span={9}>
																<Select
																	optionFilterProp="children"
																	showSearch
																	size="medium"
																	style={{ width: '100%', color: '#192a56', textAlign: 'center' }}
																	value={this.state.crea_router_header_id}
																	onChange={(value) => { this.setState({ crea_router_header_id: value }) }}
																>
																	{
																		this.state.liste_header && this.state.liste_header.map(item => {
																			var key = generateKey()
																			return (
																				<Option key={key} value={parseInt(item.id)} style={{ color: '#192a56' }}>{item.id}-{item.Name}</Option>
																			)
																		})
																	}

																</Select>

															</Col>
															<Col span={3} style={{ textAlign: 'center', padding: 3 }}>
																<span>Footer</span>
															</Col>
															<Col span={9}>
																<Select
																	optionFilterProp="children"
																	showSearch
																	size="medium"
																	style={{ width: '100%', color: '#192a56', textAlign: 'center' }}
																	value={this.state.crea_router_footer_id}
																	onChange={(value) => { this.setState({ crea_router_footer_id: value }) }}
																>
																	{
																		this.state.liste_footer && this.state.liste_footer.map(item => {
																			var key = generateKey()
																			return (
																				<Option key={key} value={parseInt(item.id)}>{item.id}-{item.Name}</Option>
																			)
																		})
																	}

																</Select>
															</Col>
														</Row>
													}
													<br />
													<Row>
														<Col span={3} style={{ textAlign: 'center', padding: 3 }}>
															<span>Seedlist</span>
														</Col>
														<Col span={9}>
															<Select
																optionFilterProp="children"
																showSearch
																mode='multiple'
																size="medium"
																style={{ width: '100%', color: '#192a56' }}
																value={this.state.crea_router_seedlist_id}
																onChange={(value) => { this.setState({ crea_router_seedlist_id: value }) }}
															>
																{
																	this.state.liste_seedlist && this.state.liste_seedlist.map(item => {
																		var key = generateKey()
																		return (
																			<Option key={key} value={item.id}>{item.id}-{item.Name}</Option>
																		)
																	})
																}

															</Select>
														</Col>
														<Col span={3} style={{ textAlign: 'center', padding: 3 }}>
															<span>Draft ID</span>
														</Col>
														<Col span={9}>
															<Select
																optionFilterProp="children"
																showSearch
																size="medium"
																style={{ width: '100%', color: '#192a56' }}
																value={this.state.crea_router_message_id}
																onChange={(value) => {
																	if (value !== null)
																		this.setState({ crea_router_message_id: value })
																}}
															>
																<Option key="464666" value="New">New draft</Option>
																{
																	this.state.liste_draft && this.state.liste_draft.map(item => {
																		var key = generateKey()
																		return (
																			<Option key={key} value={item.Id}>{item.Id} - {item.Subject}</Option>
																		)
																	})
																}

															</Select>
														</Col>
													</Row>
													<br />
													<Row>
														<Col span={3} style={{ textAlign: 'center', padding: 3 }}>
															<span>Subscriber Lists</span>
														</Col>
														<Col span={9}>
															<Select
																mode='multiple'
																optionFilterProp="children"
																showSearch
																size="medium"
																style={{ width: '100%', color: '#192a56', textAlign: 'center' }}
																value={this.state.scheduleSubscriber}
																onChange={(value) => { this.setState({ scheduleSubscriber: value }) }}
															>
																{
																	this.state.liste_subscriber && this.state.liste_subscriber.map(item => {
																		var key = generateKey()
																		return (
																			<Option key={key} value={parseInt(item.id)}>{item.id + ' - ' + item.Name}</Option>
																		)
																	})
																}
															</Select>
														</Col>
														<Col span={12}>
															{this.renderDateScheduled()}
														</Col>
													</Row>
													<br />
													<Row>
														<Col span={3} style={{ textAlign: 'center', padding: 3 }}>
															<span>Segments</span>
														</Col>
														<Col span={9}>
															<Select
																mode='multiple'
																optionFilterProp="children"
																showSearch
																size="medium"
																style={{ width: '100%', color: '#192a56', textAlign: 'center' }}
																value={this.state.scheduleSegment}
																onChange={(value) => { this.setState({ scheduleSegment: value }) }}
															>
																{
																	this.state.liste_segments && this.state.liste_segments.map(item => {
																		var key = generateKey()
																		return (
																			<Option key={key} value={parseInt(item.id)}>{item.id + ' - ' + item.name}</Option>
																		)
																	})
																}
															</Select>
														</Col>
														<Col span={3} style={{ textAlign: 'center', padding: 3, fontSize: 18 }}>
															<span>Delivery method</span>
														</Col>
														<Col span={9}>
															<Select
																optionFilterProp="children"
																showSearch
																size="medium"
																style={{ width: '100%', color: '#192a56', textAlign: 'center' }}
																value={this.state.deliveryMethod}
																onChange={
																	(value) => {
																		this.onDeliveryMethodChanged(value)
																	}}
															>
																<Option key="1" value="Throttling">Throttling</Option>
																<Option key="2" value="SendTimeOptimization">Send Time Optimization</Option>
															</Select>
														</Col>
													</Row>
													<br />
													<Row>
														<Col span={3} style={{ textAlign: 'center', padding: 3 }}>
															<span>Suppression Lists</span>
														</Col>
														<Col span={9}>
															<Select
																mode='multiple'
																optionFilterProp="children"
																showSearch
																size="medium"
																style={{ width: '100%', color: '#192a56', textAlign: 'center' }}
																value={this.state.scheduleSuppression}
																onChange={(value) => { this.setState({ scheduleSuppression: value }) }}
															>
																{
																	this.state.liste_suppression && this.state.liste_suppression.map(item => {
																		var key = generateKey()
																		return (
																			<Option key={key} value={parseInt(item.Id)}>{item.Id + ' - ' + item.Name}</Option>
																		)
																	})
																}
															</Select>
														</Col>
														<Col span={3} style={{ textAlign: 'center', padding: 3 }}>
															<span>Delay Time</span>
														</Col>
														{/* throttling options */}
														<Col span={6} style={{ display: `${this.state.displayThrottlingOption}` }}>
															<Select
																optionFilterProp="children"
																showSearch
																size="medium"
																style={{ width: '100%', color: '#192a56' }}
																value={this.state.throttling_type}
																onChange={(value) => {
																	this.onThrottlingTypeChange(value)
																}}
															>
																<Option key="31" value="Manual">Specify throttming time manually</Option>
															</Select>
														</Col>
														<Col span={2} style={{ textAlign: 'center', fontSize: 18, display: `${this.state.displayThrottlingOption}` }}>
															<Input
																type="number"
																min="1"
																max="72"
																value={this.state.throttling_delay}
																onChange={(e) => { this.setState({ throttling_delay: e.target.value }) }}
																style={{ width: '80%', color: '#192a56', textAlign: 'center' }}
															/>
														</Col>
														<Col span={1} style={{ textAlign: 'left', padding: 3, fontSize: 18, display: `${this.state.displayThrottlingOption}` }}>
															<span>hours</span>
														</Col>
														{/* sto options */}
														<Col span={9} style={{ textAlign: 'center', fontSize: 18, display: `${this.state.displaySTODelay}` }}>
															<Select
																optionFilterProp="children"
																showSearch
																size="medium"
																style={{ width: '100%', color: '#192a56' }}
																value={this.state.stoDelay}
																onChange={(value) => {
																	this.setState({
																		stoDelay: value
																	})
																}}
															>
																<Option key="24 hours" value="24 hours">24 hours</Option>
																<Option key="7 days" value="7 days">7 days</Option>
															</Select>
														</Col>
													</Row>
													<br />
													<Row>
														<Col span={3} style={{ textAlign: 'center', padding: 3, fontSize: 18 }}>
															<span>Timezone</span>
														</Col>
														<Col span={21}>
															<Select
																optionFilterProp="children"
																showSearch
																size="medium"
																style={{ width: '100%', color: '#192a56', textAlign: 'center' }}
																value={this.state.timezone}
																onChange={(value) => { this.setState({ timezone: value }) }}
															>
																{
																	this.state.list_timezone && this.state.list_timezone.map(item => {
																		var key = generateKey()
																		return (
																			<Option key={key} value={item['Value']}>{item['Text']}</Option>
																		)
																	})
																}
															</Select>
														</Col>
													</Row>
													<br />
													<Row style={{ marginTop: 10 }}>
														<Col span={8} style={{ textAlign: 'right' }}>
															<Button
																loading={this.state.loadingSave}
																className="btn btn-secondary"
																style={{ width: '64%', height: 50, fontSize: 18 }}
																onClick={() => { this.onSaveAsDraft('save') }}
															>
																Save as draft
															</Button>
														</Col>
														<Col span={8} style={{ textAlign: 'center' }}>
															<Button
																className="btn btn-success"
																style={{ width: '64%', height: 50, fontSize: 18 }}
																onClick={() => { this.onSaveAsDraft('send') }}

															>
																Save and Send
															</Button>
														</Col>
														<Col span={8} style={{ textAlign: 'center', display: `${this.state.displayScheduleBtn}` }}>
															<Button
																loading={this.state.loadingScheduled}
																className="btn btn-info"
																style={{ width: '64%', height: 50, fontSize: 18, color: '#fff' }}
																onClick={() => { this.onScheduled() }}

															>
																Schedule
															</Button>

														</Col>
													</Row>
												</Col>
											</Row>
										</div>
										<div style={{ height: '80vh', overflow: 'scroll', overflowX: 'hidden', overflowY: 'auto', display: `${this.state.displayRouterScheduled}`, padding: 0, border: '1px solid #001529' }}>
											<div className='step1' style={{ width: '100%', height: '100%' }}>
												<div style={{ textAlign: 'center', width: '100%', backgroundColor: '#001529', height: 50 }}>
													<h2 style={{ color: '#fff', padding: 10 }}><strong>RECIPIENTS</strong></h2>
												</div>
												<br />
												<Row>
													<Col span={4} style={{ textAlign: 'center', padding: 3, fontSize: 18 }}>
														<span>Subscriber Lists</span>
													</Col>
													<Col span={20}>
														<Select
															mode='multiple'
															optionFilterProp="children"
															showSearch
															size="medium"
															style={{ width: '92%', color: '#192a56', textAlign: 'center' }}
															value={this.state.scheduleSubscriber}
															onChange={(value) => { this.setState({ scheduleSubscriber: value }) }}
														>
															{
																this.state.liste_subscriber && this.state.liste_subscriber.map(item => {
																	var key = generateKey()
																	return (
																		<Option key={key} value={parseInt(item.id)}>{item.id + ' - ' + item.Name}</Option>
																	)
																})
															}
														</Select>
													</Col>
												</Row>
												<br />
												<Row>
													<Col span={4} style={{ textAlign: 'center', padding: 3, fontSize: 18 }}>
														<span>Segments</span>
													</Col>
													<Col span={20}>
														<Select
															mode='multiple'
															optionFilterProp="children"
															showSearch
															size="medium"
															style={{ width: '92%', color: '#192a56', textAlign: 'center' }}
															value={this.state.scheduleSegment}
															onChange={(value) => { this.setState({ scheduleSegment: value }) }}
														>
															{
																this.state.liste_segments && this.state.liste_segments.map(item => {
																	var key = generateKey()
																	return (
																		<Option key={key} value={parseInt(item.id)}>{item.id + ' - ' + item.name}</Option>
																	)
																})
															}
														</Select>
													</Col>
												</Row>
												<br />
												<Row>
													<Col span={4} style={{ textAlign: 'center', padding: 3, fontSize: 18 }}>
														<span>Seed Lists</span>
													</Col>
													<Col span={20}>
														<Select
															optionFilterProp="children"
															showSearch
															mode='multiple'
															size="medium"
															style={{ width: '92%', color: '#192a56' }}
															value={this.state.crea_router_seedlist_id}
															onChange={(value) => { this.setState({ crea_router_seedlist_id: value }) }}
														>
															{
																this.state.liste_seedlist && this.state.liste_seedlist.map(item => {
																	var key = generateKey()
																	return (
																		<Option key={key} value={item.id}>{item.id}-{item.Name}</Option>
																	)
																})
															}

														</Select>
													</Col>
												</Row>
												<br />
												<Row>
													<Col span={4} style={{ textAlign: 'center', padding: 3, fontSize: 18 }}>
														<span>Suppression Lists</span>
													</Col>
													<Col span={20}>
														<Select
															mode='multiple'
															optionFilterProp="children"
															showSearch
															size="medium"
															style={{ width: '92%', color: '#192a56', textAlign: 'center' }}
															value={this.state.scheduleSuppression}
															onChange={(value) => { this.setState({ scheduleSuppression: value }) }}
														>
															{
																this.state.liste_suppression && this.state.liste_suppression.map(item => {
																	var key = generateKey()
																	return (
																		<Option key={key} value={parseInt(item.Id)}>{item.Id + ' - ' + item.Name}</Option>
																	)
																})
															}
														</Select>
													</Col>
												</Row>
												<br />
												<Row>
													<Col span={4} style={{ textAlign: 'center', padding: 3, fontSize: 18 }}>
														<span>Timezone</span>
													</Col>
													<Col span={20}>
														<Select
															optionFilterProp="children"
															showSearch
															size="medium"
															style={{ width: '92%', color: '#192a56', textAlign: 'center' }}
															value={this.state.timezone}
															onChange={(value) => { this.setState({ timezone: value }) }}
														>
															{
																this.state.list_timezone && this.state.list_timezone.map(item => {
																	var key = generateKey()
																	return (
																		<Option key={key} value={item['Value']}>{item['Text']}</Option>
																	)
																})
															}
														</Select>
													</Col>
												</Row>
												<br />
												{this.renderDateScheduled()}
												<br />
												<Row>
													<Col span={4} style={{ textAlign: 'center', padding: 3, fontSize: 18 }}>
														<span>Delivery method</span>
													</Col>
													<Col span={6}>
														<Select
															optionFilterProp="children"
															showSearch
															size="medium"
															style={{ width: '92%', color: '#192a56', textAlign: 'center' }}
															value={this.state.deliveryMethod}
															onChange={
																(value) => {
																	this.onDeliveryMethodChanged(value)
																}}
														>
															<Option key="1" value="Throttling">Throttling</Option>
															<Option key="2" value="SendTimeOptimization">Send Time Optimization</Option>
														</Select>
													</Col>
													<Col span={6} style={{ display: `${this.state.displayThrottlingOption}` }}>
														<Select
															optionFilterProp="children"
															showSearch
															size="medium"
															style={{ width: '100%', color: '#192a56' }}
															value={this.state.throttling_type}
															onChange={(value) => {
																this.onThrottlingTypeChange(value)
															}}
														>
															<Option key="31" value="Manual">Specify throttming time manually</Option>
														</Select>
													</Col>
													<Col span={4} style={{ textAlign: 'center', fontSize: 18, display: `${this.state.displayThrottlingDelay}` }}>
														<Input
															type="number"
															min="1"
															max="72"
															value={this.state.throttling_delay}
															onChange={(e) => { this.setState({ throttling_delay: e.target.value }) }}
															style={{ width: '80%', color: '#192a56', textAlign: 'center' }}
														/>
													</Col>
													<Col span={3} style={{ textAlign: 'left', padding: 3, fontSize: 18, display: `${this.state.displayThrottlingDelay}` }}>
														<span>hours</span>
													</Col>
													<Col span={8} style={{ display: `${this.state.displaySTODelay}` }}>
														<span>Message will be sent at the automatically calculated optimal time for each recipient over a period of</span>
													</Col>
													<Col span={3} style={{ textAlign: 'center', fontSize: 18, display: `${this.state.displaySTODelay}` }}>
														<Select
															optionFilterProp="children"
															showSearch
															size="medium"
															style={{ width: '100%', color: '#192a56' }}
															value={this.state.stoDelay}
															onChange={(value) => {
																this.setState({
																	stoDelay: value
																})
															}}
														>
															<Option key="24 hours" value="24 hours">24 hours</Option>
															<Option key="7 days" value="7 days">7 days</Option>
														</Select>
													</Col>
													<Col span={3} style={{ textAlign: 'center', padding: 3, display: `${this.state.displaySTODelay}` }}>
														<span>from start date/time</span>
													</Col>
													<Col span={14} style={{ display: `${this.state.displayTimeTravelInfo}`, padding: 3 }}>
														<span>Message will be sent at each recipient’s local time according to their timezone</span>
													</Col>
												</Row>
												<br />
												<Row>
													<Col span={8}></Col>
													<Col span={4}>
														<Button
															className="btn btn-secondary"
															style={{ width: '90%', height: 40, fontSize: 15, textAlign: 'center' }}
															onClick={() => {
																this.setState({
																	displayRouterData: 'block',
																	displayRouterScheduled: 'none'
																})
															}}
														>
															CANCEL
														</Button>
													</Col>
													<Col span={4}>
														<Button
															loading={this.state.loadingScheduled}
															className="btn btn-success"
															style={{ width: '90%', height: 40, fontSize: 15, textAlign: 'center' }}
															onClick={() => { this.onScheduled() }}
														>
															SCHEDULED
														</Button>
													</Col>
													<Col span={8}></Col>
												</Row>
											</div>
										</div>
									</Panel>
									<Panel header={<span style={{ fontWeight: 700 }}>STATS</span>} key="4">
										<div style={{ height: '68vh', overflow: 'scroll', overflowX: 'hidden', overflowY: 'auto' }}>
											<Row>
												<Col span={24}>
													<Row>
														<Col span={3} style={{ textAlign: 'center', padding: 3 }}>
															<span>Advertiser</span>
														</Col>
														<Col span={10}>
															<AutoComplete
																size="medium"
																style={{ textAlign: 'center', fontSize: 16, fontFamily: 'Lato', width: '100%', color: '#192a56', fontWeight: 800 }}
																value={this.state.crea_stats_adv_name}
																defaultValue={this.state.crea_stats_adv_name}
																onChange={
																	(value) => {
																		this.onAdvertiserChange(value)
																	}
																}
															>
																{
																	this.state.liste_advertiser && this.state.liste_advertiser.map(item => {
																		var key = generateKey()
																		return (
																			<Option key={key} value={item.name}>{item.name}</Option>
																		)
																	})
																}
															</AutoComplete>
														</Col>
														<Col span={3} style={{ textAlign: 'center', padding: 3 }}>
															<span>Client</span>
														</Col>
														<Col span={8}>
															<Select
																size="medium"
																showSearch
																optionFilterProp="children"
																style={{ textAlign: 'center', width: '100%', color: '#192a56', fontWeight: 700 }}
																value={this.state.crea_stats_client_id}
																onChange={(value) => {
																	this.onClientsChange(value)
																}}
															>
																{
																	this.state.liste_clients && this.state.liste_clients.map(item => {
																		var key = generateKey()
																		return (
																			<Option key={key} value={item.id}>{item.name}</Option>
																		)
																	})
																}
															</Select>
														</Col>
													</Row>
													<br />
													<Row>
														<Col span={3} style={{ textAlign: 'center', padding: 3 }}>
															<span>Affiliate</span>
														</Col>
														<Col span={10}>
															<Select
																size="medium"
																showSearch
																optionFilterProp="children"
																style={{ textAlign: 'center', width: '100%', color: '#192a56', fontWeight: 700 }}
																value={this.state.crea_stats_affiliate_id}
																onChange={(value) => {
																	this.setState({ crea_stats_affiliate_id: value })
																}}
															>
																{
																	this.state.liste_affiliate && this.state.liste_affiliate.map(item => {
																		var key = generateKey()
																		return (
																			<Option key={key} value={parseInt(item.id)}>{item.name}</Option>
																		)
																	})
																}
															</Select>
														</Col>
														<Col span={3} style={{ textAlign: 'center', padding: 3 }}>
															<span>Tags</span>
														</Col>
														<Col span={8}>
															<Select
																size="medium"
																showSearch
																optionFilterProp="children"
																style={{ textAlign: 'center', width: '100%', color: '#192a56', fontWeight: 700 }}
																value={this.state.crea_tags_id}
																onChange={(value) => {
																	this.setState({ crea_tags_id: value })
																}}
															>
																{
																	this.state.liste_tags && this.state.liste_tags.map(item => {
																		var key = generateKey()
																		return (
																			<Option key={key} value={item.id}>{item.tag}</Option>
																		)
																	})
																}
															</Select>
														</Col>
													</Row>
													<br />
													<Row>
														<Col span={3} style={{ textAlign: 'center', padding: 3 }}>
															<span>Country</span>
														</Col>
														<Col span={10}>
															<Select
																size="medium"
																showSearch
																optionFilterProp="children"
																style={{ textAlign: 'center', width: '100%', color: '#192a56', fontWeight: 700 }}
																value={this.state.crea_stats_country_id}
																onChange={(value) => {
																	this.setState({ crea_stats_country_id: value })
																}}
															>
																{
																	this.state.liste_country && this.state.liste_country.map(item => {
																		var key = generateKey()
																		return (
																			<Option key={key} value={item.id}>{item.countrycode}</Option>
																		)
																	})
																}
															</Select>
														</Col>
														<Col span={3} style={{ textAlign: 'center', padding: 3 }}>
															<span>Currency</span>
														</Col>
														<Col span={8}>
															<Select
																size="medium"
																showSearch
																optionFilterProp="children"
																style={{ textAlign: 'center', width: '100%', color: '#192a56', fontWeight: 700 }}
																value={this.state.crea_stats_currency_id}
																onChange={(value) => {
																	this.setState({ crea_stats_currency_id: value })
																}}
															>
																{
																	this.state.liste_country && this.state.liste_country.map(item => {
																		var key = generateKey()
																		return (
																			<Option key={key} value={item.currencyid}>{item.currencyname}</Option>
																		)
																	})
																}
															</Select>
														</Col>
													</Row>
													<br />
													{this.renderStatsPayout()}
													<br />
													<Row>
														<Col span={3} style={{ padding: 3, textAlign: 'center' }}>
															<p>Comment</p>
														</Col>
														<Col span={10}>
															<Input
																value={this.state.crea_stats_comment}
																size="medium"
																style={{ width: '100%', color: '#192a56', fontWeight: 700, textAlign: 'center' }}
																onChange={(e) => { this.setState({ crea_stats_comment: e.target.value }) }}
															/>
														</Col>
														<Col span={3} style={{ padding: 3, textAlign: 'center' }}>
															<p>Segment</p>
														</Col>
														<Col span={8}>
															<Select
																mode="multiple"
																optionFilterProp="children"
																showSearch
																size="medium"
																style={{ width: '100%', margin: '0 auto', color: '#192a56', fontWeight: 700 }}
																value={this.state.crea_stats_segment}
																onChange={(value) => {
																	this.setState({ crea_stats_segment: value })
																}}
															>
																{
																	this.state.liste_segments && this.state.liste_segments.map(item => {
																		var key = generateKey()
																		return (
																			<Option key={key} value={parseInt(item.id)}>{item.id + ' - ' + item.name}</Option>
																		)
																	})
																}
															</Select>
															{/* <Input
																value={this.state.crea_stats_segment}
																size="medium"
																style={{ width: '100%', color: '#192a56', fontWeight: 700, textAlign: 'center' }}
																onChange={(e) => { this.setState({ crea_stats_segment: e.target.value }) }}
															/> */}
														</Col>
													</Row>
													<br />
													<Row>
														<Col span={3} style={{ padding: 3, textAlign: 'center' }}>
															<p>Campaign Name</p>
														</Col>
														<Col span={10}>
															<Input
																value={this.state.crea_stats_campagn_name}
																size="medium"
																style={{ width: '100%', color: '#192a56', fontWeight: 700, textAlign: 'center' }}
																onChange={(e) => { this.setState({ crea_stats_campagn_name: e.target.value }) }}
															/>
														</Col>
														<Col span={3} style={{ padding: 3, textAlign: 'center' }}>
															<p>Status</p>
														</Col>
														<Col span={8}>
															<Select
																size="medium"
																showSearch
																optionFilterProp="children"
																style={{ width: '100%', color: '#192a56', fontWeight: 700, textAlign: 'center' }}
																value={this.state.crea_stats_status}
																onChange={(value) => {
																	this.setState({
																		crea_stats_status: value
																	})
																}}
															>
																<Option key="1" value="1">BAT not send</Option>
																<Option key="2" value="2">BAT sent, no approved</Option>
																<Option key="3" value="3">BAT approved</Option>
																<Option key="4" value="4">Campaign scheduled</Option>
																<Option key="5" value="5">Campaign sent</Option>
																<Option key="6" value="6">Campaign billed</Option>
																<Option key="7" value="7">Campaign Cancelled</Option>
															</Select>
														</Col>
													</Row>
													<br />
													<Row>
														<Col span={3} style={{ padding: 3, textAlign: 'center' }}>
															<p>Brand</p>
														</Col>
														<Col span={10}>
															<Input
																value={this.state.crea_stats_brand}
																size="medium"
																style={{ width: '100%', color: '#192a56', fontWeight: 700, textAlign: 'center' }}
																onChange={(e) => { this.setState({ crea_stats_brand: e.target.value }) }}
															/>
														</Col>

														<Col span={3} style={{ padding: 3, textAlign: 'center' }}>
															<p>Scheduled type</p>
														</Col>
														<Col span={8}>
															<Select
																size="medium"
																showSearch
																optionFilterProp="children"
																style={{ width: '100%', color: '#192a56', fontWeight: 700, textAlign: 'center' }}
																value={this.state.crea_stats_type}
																onChange={(value) => {
																	this.setState({
																		crea_stats_type: value
																	})
																}}
															>
																<Option value="1">Normal</Option>
																<Option value="2">Split Test</Option>
																<Option value="3">ES - Split Test Single Message</Option>
																<Option value="4">Multiple Shoots</Option>
															</Select>
														</Col>

													</Row>
													<br />
													<Row>

														<Col span={3} style={{ padding: 3, textAlign: 'center' }}>
															<p>Date Scheduled</p>
														</Col>
														<Col span={10}>
															<DatePicker
																format={dateFormat}
																style={{ width: '100%', textAlign: 'center' }}
																size="medium"
																value={this.state.crea_stats_date}
																onChange={(a, b) => {
																	this.setState({
																		crea_stats_date: a,
																		crea_stats_available_from: a
																	})
																}}
																disabledDate={current => {
																	return current && current <= moment().subtract(1, 'days');
																}}
															/>
														</Col>
														<Col span={3} style={{ padding: 3, textAlign: 'center' }}>
															<p>Sendout ID</p>
														</Col>
														<Col span={8}>
															<Input
																value={this.state.crea_stats_sendout_id}
																size="medium"
																style={{ width: '100%', color: '#192a56', fontWeight: 700, textAlign: 'center' }}
																onChange={(e) => { this.setState({ crea_stats_sendout_id: e.target.value }) }}
															/>
														</Col>
													</Row>
													<br />
													<Row>
														<Col span={3} style={{ padding: 3, textAlign: 'center' }}>
															<p>Available from</p>
														</Col>
														<Col span={10}>
															<DatePicker
																format={dateFormat}
																style={{ width: '100%', textAlign: 'center' }}
																size="medium"
																value={this.state.crea_stats_available_from}
																onChange={(a, b) => {
																	this.setState({
																		crea_stats_available_from: a
																	})
																}}
															/>
														</Col>
														<Col span={3} style={{ padding: 3, textAlign: 'center' }}>
															<p>Available until</p>
														</Col>
														<Col span={8}>
															<DatePicker
																format={dateFormat}
																style={{ width: '100%', textAlign: 'center' }}
																size="medium"
																value={this.state.crea_stats_available_until}
																onChange={(a, b) => {
																	this.setState({
																		crea_stats_available_until: a
																	})
																}}
																disabledDate={current => {
																	return current && current <= moment().subtract(1, 'days');
																}}
															/>
														</Col>
													</Row>
													<br />
													<Row>
														<Col span={24} style={{ textAlign: 'center', display: `${this.state.display_update_stats}` }}>
															<Button
																loading={this.state.loadingUpdateStats}
																className="btn btn-primary"
																style={{ width: '64%', height: 50, fontSize: 15 }}
																onClick={() => { this.onSaveToStats("update") }}
															>
																UPDATE STATS DATA
															</Button>
														</Col>
														<Col span={24} style={{ textAlign: 'center', display: `${this.state.display_send_stats}` }}>
															<Button
																loading={this.state.loadingSaveStats}
																className="btn btn-success"
																style={{ width: '64%', height: 50, fontSize: 15 }}
																onClick={() => { this.onSaveToStats("insert") }}
															>
																SEND TO STATS
															</Button>
														</Col>
														<Col span={5}></Col>
													</Row>
												</Col>
											</Row>
										</div>
									</Panel>
								</Collapse>
							</Col>
						</Row>
					</div>
				</div>

				<Modal
					id="newreo"
					isOpen={this.state.show_reoptimize}
					onRequestClose={() => { this.setState({ show_reoptimize: false }) }}
					shouldCloseOnOverlayClick={true}
					ariaHideApp={false}
					style={bg}
				>
					<div style={{ fontSize: '5px !important', marginTop: '0px', marginLeft: '0px', width: '100%' }}>
						<div
							style={{ background: '#001529', marginTop: '0px', textAlign: 'center', width: '100%', marginLeft: '0px', height: '100%' }}
						>
							<h3 style={{ color: "#fff", padding: 5 }}>REOPTIMIZE CREATIVITY</h3>
						</div>
						<span
							style={{ color: "#C23616", marginTop: '-42px', fontSize: 29, float: 'right', cursor: 'pointer' }} onClick={() => { this.setState({ show_reoptimize: false }) }}
						>
							X
						</span>
					</div>
					<div style={{ width: '95%', padding: 0 }}>
						<Row>
							<Col span={6} style={{ textAlign: 'center' }}>
								<Checkbox
									checked={this.state.crea_reoptimize_pixels}
									onChange={(e) => {
										if (e.target.checked) {
											this.setState({ crea_reoptimize_pixels: e.target.checked })
										} else {
											this.setState({ crea_reoptimize_pixels: e.target.checked })
										}
									}}
								>
									<span style={{ fontSize: 14 }}>Pixels</span>
								</Checkbox>
							</Col>
							<Col span={6} style={{ textAlign: 'center' }}>
								<Checkbox
									checked={this.state.crea_reoptimize_img}
									onChange={(e) => {
										if (e.target.checked) {
											this.setState({ crea_reoptimize_img: e.target.checked })
										} else {
											this.setState({ crea_reoptimize_img: e.target.checked })
										}
									}}
								>
									<span style={{ fontSize: 14 }}>Image</span>
								</Checkbox>
							</Col>
							<Col span={6} style={{ textAlign: 'center' }}>
								<Checkbox
									checked={this.state.crea_reoptimize_compress}
									onChange={(e) => {
										if (e.target.checked) {
											this.setState({ crea_reoptimize_compress: e.target.checked })
										} else {
											this.setState({ crea_reoptimize_compress: e.target.checked })
										}
									}}
								>
									<span style={{ fontSize: 14 }}>Compress</span>
								</Checkbox>
							</Col>
							<Col span={6} style={{ textAlign: 'center' }}>
								<Checkbox
									checked={this.state.crea_reoptimize_link}
									onChange={(e) => {
										if (e.target.checked) {
											this.setState({ crea_reoptimize_link: e.target.checked })
										} else {
											this.setState({ crea_reoptimize_link: e.target.checked })
										}
									}}
								>
									<span style={{ fontSize: 14 }}>Link</span>
								</Checkbox>
							</Col>
						</Row>
						<br />
						<Row>
							<Col span={6} style={{ textAlign: 'center' }}>
								<Checkbox
									checked={this.state.crea_reoptimize_jumping}
									onChange={(e) => {
										if (e.target.checked) {
											this.setState({ crea_reoptimize_jumping: e.target.checked })
										} else {
											this.setState({ crea_reoptimize_jumping: e.target.checked })
										}
									}}
								>
									<span style={{ fontSize: 14 }}>Jumping</span>
								</Checkbox>
							</Col>
							<Col span={6} style={{ textAlign: 'center' }}>
								<Checkbox
									checked={this.state.crea_reoptimize_comment}
									onChange={(e) => {
										if (e.target.checked) {
											this.setState({ crea_reoptimize_comment: e.target.checked })
										} else {
											this.setState({ crea_reoptimize_comment: e.target.checked })
										}
									}}
									style={{ marginLeft: 27 }}
								>
									<span style={{ fontSize: 14 }}>Comment</span>
								</Checkbox>
							</Col>
						</Row>
						<br />
						<div
							style={{ textAlign: 'center', width: '50%', margin: '0 auto' }}
						>
							<Button
								loading={this.state.loadingReoptimize}
								className="btn btn-success"
								style={{ width: '50%', height: 40, fontFamily: 'Lato', fontWeight: 600, fontSize: 16 }}
								onClick={this.onReoptimizingRequests.bind(this)}
							>
								REOPTIMIZE
							</Button>
						</div>
					</div>
				</Modal>

			</div>
		)
	}
}
export default DetailCreativities