import React, { Component } from 'react';
import { Button, Col, Row, Select,  Tag,  Progress, Input } from 'antd'
import loading from '../../images/loading3.gif'
import { base64_encode, base64_decode, generateKey } from '../Utilities/Utilities'
import axios from 'axios';
import { getMainData } from '../Utilities/GetMainData';

const { Option } = Select

// interface DataType {
//     id: string;
//     // key: React.Key;
//     // object: string;
//     // unique_opens: float;
//     // unique_opens_percent: float;
//     // clickers: float;
//     // clickers_percent: float;
//     // sent: float;
//     // bounce: float;
//     // bounce_percent: float;
//     // subject: float;
//     type: string;
// }

// const columns: ColumnsType<DataType> = [
//     {
//         title: "Started",
//         dataIndex: "date",
//         width: '5%'
//     },
//     {
//         title: "Database",
//         dataIndex: "basename",
//         width: '5%',
//         textAlign: 'center',
//         ellipsis: true
//         // render: (i, record) => {
//         //     return (
//         //         <div style={{ textAlign: 'center' }}>
//         //             <Tag color={record['color']}>{record['basename']}</Tag>
//         //         </div>
//         //     )
//         // }

//     },
//     {
//         title: "Type",
//         dataIndex: "type",
//         width: '5%',
//         render: (id, data) => {
//             var color = null
//             if (data['type'] === 'Newsletters') {
//                 color = tags_nl
//             }
//             if (data['type'] === 'Recurrings') {
//                 color = tags_rc
//             }
//             if (data['type'] === 'Triggers') {
//                 color = tags_tr
//             }
//             return (
//                 <Tag color={color}>{id}</Tag>
//             )
//         }
//     },
//     {
//         title: "Id",
//         dataIndex: "id",
//         width: '3%'

//     },
//     {
//         title: "Subject",
//         dataIndex: "subject",
//         width: '15%',
//         ellipsis: true,
//     },
//     {
//         title: 'Progress',
//         dataIndex: 'unique_open_percent',
//         width: '5%',
//         render: (id, data) => {
//             var percent_done = parseFloat((100 * data['sent']) / data['recipients']).toFixed(2)
//             return (
//                 <div style={{ textAlign: 'center' }}>
//                     <p>{data['sent'] + "/" + data['recipients']}</p>
//                     <Progress showInfo={false} status="active" percent={percent_done} />
//                 </div>
//             )
//         }
//     },
//     {
//         title: 'Bounce',
//         dataIndex: 'bounce',
//         width: '5%',
//         render: (i, data) => {
//             return (
//                 <div style={{ textAlign: 'center' }}>
//                     <span>{data['bounce'] + "(" + data['bounce_percent'] + "%)"}</span>
//                 </div>
//             )
//         }
//     },
//     {
//         title: 'Opens',
//         dataIndex: 'unique_opens',
//         width: '5%',
//         render: (i, data) => {
//             return (
//                 <div style={{ textAlign: 'center' }}>
//                     <span>{data['unique_opens'] + "(" + data['unique_opens_percent'] + "%)"}</span>
//                 </div>
//             )
//         }
//     },
//     {
//         title: 'Clicks',
//         dataIndex: 'clickers',
//         width: '5%',
//         render: (i, data) => {
//             return (
//                 <div style={{ textAlign: 'center' }}>
//                     <span>{data['clickers'] + "(" + data['clickers_percent'] + "%)"}</span>
//                 </div>
//             )
//         }
//     }
// ]
class InprogressOverview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            liste_base: [],
            user_id: base64_decode(localStorage.getItem(base64_encode('user_id'))),
            username: base64_decode(localStorage.getItem(base64_encode('username'))),
            apikey: base64_decode(localStorage.getItem(base64_encode('apikey'))),
            stats_apikey: base64_decode(localStorage.getItem(base64_encode('stats_apikey'))),
            base_display: [],
            in_progress_display: [],
            in_progress_campagn: [],
            service_filter: "3",
            loading_submit: false,
            display_request: 'none',
            display_request_result: 'none',
            color_pack: {},
            keywords: null,
            default_database: []
        }

    }

    componentDidMount() {
        localStorage.setItem('defaulttabcrea', '1')
        this.getData()
    }

    getData() {
        Promise.resolve(getMainData(this.state.user_id, this.state.apikey, this.state.stats_apikey, 0, false, false, true, false, false, false, false, false, false, false)).then((value) => {
            var default_view = []
            var base_to_display = []
            value['base_user'].map(item => {
                if (item['login'] !== null && item['mdp'] !== null && item['service'] !== null && parseInt(item['router']) === 1) {
                    base_to_display.push(item)
                    default_view.push(item['id'])
                }
                return null
            })
            this.setState({
                liste_base: base_to_display,
                // base_display: default_view
            })
        });

    }

    getAllCampagnedInProgress(base_id) {
        this.setState({ loading_submit: true, display_request: 'block', display_request_result: 'none' })
        var fd = new FormData()
        fd.append('user_id', base64_decode(localStorage.getItem(base64_encode('user_id'))))
        fd.append('apikey', base64_decode(localStorage.getItem(base64_encode('apikey'))))
        // fd.append('service', this.state.service_filter)
        fd.append('base_id', JSON.stringify(base_id))
        axios.post(process.env.REACT_APP_ENDPOINT_IN_PROGRESS_OVERVIEW, fd)
            .then(res => {
                this.setState({
                    loading_submit: false,
                    in_progress_campagn: res.data,
                    display_request: 'none',
                    display_request_result: 'block',
                    in_progress_display: res.data
                })
            })
    }

    onFilterDomainList(e) {
        this.setState({ keywords: e.target.value })
        let result = []
        if (e.target.value !== '') {
            this.state.in_progress_campagn.map((base) => {
                if (base.basename.indexOf(e.target.value) > -1) {
                    result.push(base)
                    this.setState({ in_progress_campagn: result })
                }
                return null
            })

        } else {
            var data = this.state.in_progress_display
            this.setState({
                in_progress_campagn: data
            })
        }
    }


    render() {

        return (
            <div>
                <div style={{ width: '100%', fontSize: 16, height: '4vh', padding: 5 }}>
                    <Row>
                        <Col span={1} style={{ textAlign: 'center' }}>
                            <span style={{ fontWeight: 600 }}>Search: </span>
                        </Col>
                        <Col span={4}>
                            <Input
                                placeholder='Search'
                                showSearch
                                value={this.state.keywords}
                                size="medium"
                                style={{width: '92%', color: '#192a56',fontWeight: 700}}
                                onChange={this.onFilterDomainList.bind(this)}
                            />
                        </Col>
                        <Col span={1}>
                            <span style={{ fontWeight: 600 }}>Database: </span>
                        </Col>
                        <Col span={15}>
                            <Select
                                mode="multiple"
                                size="medium"
                                showSearch
                                optionFilterProp="children"
                                style={{ width: '98%', color: '#192a56', textAlign: 'center' }}
                                defaultValue={this.state.base_display}
                                value={this.state.base_display}
                                onChange={
                                    (value) => {
                                        this.setState({
                                            base_display: value
                                        })
                                    }
                                }
                            >
                                {
                                    this.state.liste_base !== null && this.state.liste_base.map((item) => {
                                        var key = generateKey()
                                        return (
                                            <Option key={key} value={item.id}>{item.basename}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </Col>
                        <Col span={2} style={{ textAlign: 'center' }}>
                            <Button
                                loading={this.state.loading_submit}
                                className='btn btn-success'
                                style={{ fontSize: 15, width: '100%' }}
                                onClick={() => {
                                    this.getAllCampagnedInProgress(this.state.base_display)
                                }}
                            >
                                Submit
                            </Button>
                        </Col>
                    </Row>
                </div>
                <div style={{ width: '100%', fontSize: 16, height: '88vh', marginTop: 5, textAlign: 'center', display: `${this.state.display_request}`}}>
                    <img src={loading} style={{ width: 380, height: 350, marginTop: '8%' }} alt="loading ..." />
                </div>
                <div style={{ width: '100%', fontSize: 16, height: '88vh', padding: 5, display: `${this.state.display_request_result}`, overflowY: 'scroll', overflowX: 'hidden'}}>
                    <table className="table table-striped" style={{ fontSize: 14, fontFamily: 'Arial'}}>
                        <thead className='dark' style={{backgroundColor: '#BDB76B' }}>
                            <tr>
                                <th style={{width: '5%', textAlign: 'center'}}>Id</th>
                                <th style={{width: '8%', textAlign: 'center'}}>Started</th>
                                <th style={{width: '5%', textAlign: 'center'}}>Unit</th>
                                <th style={{width: '5%', textAlign: 'center'}}>Type</th>
                                <th style={{width: '5%', textAlign: 'center'}}>Tags</th>
                                <th style={{width: '10%', textAlign: 'center'}}>From</th>
                                <th style={{width: '15%',textAlign: 'center'}}>Subject</th>
                                <th style={{textAlign: 'center'}}>Progress</th>
                                <th style={{textAlign: 'center'}}>Bounce</th>
                                <th style={{textAlign: 'center'}}>Openers</th>
                                <th style={{textAlign: 'center'}}>Clickers</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.in_progress_campagn && this.state.in_progress_campagn.map(campagn => {
                                    var key = generateKey()
                                    var percent_done = parseFloat((100 * campagn['Sent']) / campagn['Recipients']).toFixed(2)
                                    var bg_color = "#fff"
                                    var type_color = "#003c8f"
                                    if (campagn['Type'] === "Newsletters") { type_color = "processing" }
                                    if (campagn['Type'] === "Recurrings") { type_color = "success" }
                                    if (campagn['Type'] === "Triggers") { type_color = "warning" }
                                    
                                    if (parseFloat(campagn['BouncesPercent']) > 5) { bg_color = "#ffbcaf" }
                                    return (
                                        <tr key={key} style={{backgroundColor: `${bg_color}`}}>
                                            <td style={{textAlign: 'center'}}>{campagn['Id']}</td>
                                            <td style={{textAlign: 'center'}}>{campagn['Date']}</td>
                                            <td style={{textAlign: 'center'}}>
                                                <div style={{ textAlign: 'center' }}>
                                                    <Tag color={campagn['color']}>{campagn['basename']}</Tag>
                                                </div>
                                            </td>
                                            <td style={{textAlign: 'center'}}>
                                                <div style={{ textAlign: 'center' }}>
                                                    <Tag color={type_color}>{campagn['Type']}</Tag>
                                                </div>
                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                                {
                                                    campagn['Tags'].map(tags => {
                                                        var key2 = generateKey()
                                                        return (
                                                            <Tag key={key2} color="#142E7B">{tags}</Tag>
                                                        )
                                                    })
                                                }
                                            </td>
                                            <td style={{textAlign: 'center'}}>{campagn['sender']}</td>
                                            <td style={{textAlign: 'center'}}>{campagn['Subject']}</td>
                                            <td style={{textAlign: 'center'}}>
                                                <div style={{ textAlign: 'center' }}>
                                                    <p>{campagn['Sent'] + "/" + campagn['Recipients']}</p>
                                                    <Progress showInfo={false} status="active" percent={percent_done} />
                                                </div>
                                            </td>
                                            <td style={{textAlign: 'center'}}>
                                                {campagn['Bounces']}&nbsp;{"(" + campagn['BouncesPercent'] + "%)"}
                                            </td>
                                            <td style={{textAlign: 'center'}}>
                                                {campagn['Opens']}&nbsp;{"(" + campagn['OpensPercent'] + "%)"}
                                            </td>
                                            <td style={{textAlign: 'center'}}>
                                                {campagn['ClicksUnique']}&nbsp;{"(" + campagn['ClicksUniquePercent'] + "%)"}
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                {/* <div style={{ width: '100%', fontSize: 16, height: '4vh', padding: 5 }}>
                    <Row>
                        <Col span={6}>
                            <Input
                                placeholder='Search'
                                showSearch
                                value={this.state.keywords}
                                size="medium"
                                style={{border: '1px solid gray', color: '#192a56',fontWeight: 700}}
                                onChange={this.onFilterDomainList.bind(this)}
                            />
                        </Col>
                        <Col span={2} style={{ textAlign: 'center', padding: 5 }}>
                            <span style={{ fontWeight: 600 }}>Filter: </span>
                        </Col>
                        <Col span={4}>
                            <Select
                                style={{ width: '90%' }}
                                value={this.state.service_filter}
                                onChange={(value) => { this.setState({ service_filter: value }) }}
                            >
                                <Option value="2"> Service 2</Option>
                                <Option value="3"> Service 3</Option>
                                <Option value="4"> Service 4</Option>
                                <Option value="5"> Service 5</Option>
                                <Option value="6"> Service 6</Option>
                                <Option value="7"> Service 7</Option>
                            </Select>
                        </Col>
                        <Col span={2} style={{ textAlign: 'center' }}>
                            <Button
                                loading={this.state.loading_submit}
                                className='btn btn-success'
                                style={{ fontSize: 15, width: '100%' }}
                                onClick={this.getAllCampagnedInProgress.bind(this)}
                            >
                                Submit
                            </Button>
                        </Col>
                    </Row>
                </div>
                <div style={{ width: '100%', fontSize: 16, height: '65vh', marginTop: 5, textAlign: 'center', display: `${this.state.display_request}` }}>
                    <img src={loading} style={{ width: 380, height: 350, marginTop: '8%' }} alt="loading ..." />
                </div>
                <div style={{ width: '100%', fontSize: 16, height: '75vh', marginTop: 5, textAlign: 'center', display: `${this.state.display_request_result}`, overflowY: 'auto', overflowX: 'hidden'}}>
                    <Table
                        bordered
                        pagination={{ pageSize: 8 }}
                        columns={columns}
                        dataSource={data}
                        style={{ height: '100%', overflowX: 'hidden', overflowY: 'scroll', width: '100%' }}
                       
                    />
                </div> */}
            </div>
        );
    }
}

export default InprogressOverview;