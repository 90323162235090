import React, { Component } from 'react'
import { Card, Tag, Col, Row, Button, Input, Select, message, Checkbox, Tabs } from 'antd'
import TextArea from 'antd/lib/input/TextArea';
import { EditOutlined, SyncOutlined, CopyOutlined } from '@ant-design/icons';
import { liste_minisite, create_minisite, update_minisite, clone_minisite, check_bimi, updating_minisite_images, updating_minisite_rgpd } from '../Utilities/Minisite';
import '../../css/Minisite.css'
import { generateKey, base64_decode, base64_encode } from '../Utilities/Utilities';
import { Redirect } from 'react-router-dom';
import Modal from 'react-modal'
import axios from 'axios'
import loading from '../../images/loading3.gif'
import { all_base } from '../Utilities/Base';
import { list_country } from '../Utilities/Stats';
// import AceEditor from 'react-ace';
// import "ace-builds/src-noconflict/mode-html";
// import "ace-builds/src-noconflict/theme-eclipse";
// import "ace-builds/webpack-resolver";
import Editor from '@monaco-editor/react';
const { Meta } = Card;
const { Option } = Select
const { TabPane } = Tabs;

class Minisite extends Component {
	constructor(props) {
		super(props)

		this.state = {
			user_id: base64_decode(localStorage.getItem(base64_encode('user_id'))),
            apikey: base64_decode(localStorage.getItem(base64_encode('apikey'))),
            stats_apikey: base64_decode(localStorage.getItem(base64_encode('stats_apikey'))),
			isNew: false,
			isEdit: false,
			isUpdatingImages: false,
			isUpdatingRGPD: false,
			loadingSubmit: false,
			loadingSubmitImages: false,
			liste_minisite: [],
			liste_minisite_backs: [],
			liste_country: [],
			liste_base_all: [],
			display_loading: 'block',
			modal_title: "MINISITE CREATE",
			// filter minisite
			key_filter: null,
			// minisite information
			m_url: null,
			m_title: null,
			m_description: null,
			m_analytics: null,
			m_sub_email: "stephanie@kontikimedia.com",
			m_unsub_email: null,
			m_logo: null,
			m_banner: null,
			m_bimi: null,
			m_cookies: null,
			m_catch: null,
			m_country: "FR",
			m_minisite_id: null,
			m_base_id: null,
			m_clone_base_id: null,
			m_offer_catch: null,


			m_legal: null,
			m_cgu: null,
			m_privacy: null,
			m_cookies_policy: null,

			m_host: null,
			m_user: null,
			m_pass: null,

			displayImportLogo: 'none',
			displayImportBanner: 'none',
			displayKeepFile: 'none',
			displayImportlegaleNotice: 'none',
			displayImportCGU: 'none',
			displayImportPrivacy: 'none',
			displayImportCookies: 'none',
			addingTraffic: '1',
			m_status_adding_legal: 0,
			m_status_adding_cgu: 0,
			m_status_adding_privacy: 0,
			m_status_adding_cookies: 0,

			show_modal_bimi: false,
			bimi_check_result: [],
			display_loading_check: 'block',
			display_check_result: 'none',

		}
	}


	componentDidMount() {
		this.getListAllBase()
		this.onGetCountry()
		this.onGetListeMinisite()
	}

	onGetListeMinisite() {
		Promise.resolve(liste_minisite(this.state.user_id, this.state.apikey)).then((value) => {
			this.setState({
				liste_minisite: value,
				liste_minisite_backs: value,
				display_loading: 'none'
			})
		});
	}

	onFilterMinisite(e) {
		this.setState({ key_filter: e.target.value })
		let result = []
		if (e.target.value !== '') {
			this.state.liste_minisite.map((minisite) => {
				if (minisite.url.toLowerCase().indexOf(e.target.value) > -1) {
					result.push(minisite)
					this.setState({ liste_minisite: result })
				}
				return null
			})

		} else {
			var older_liste = this.state.liste_minisite_backs
			this.setState({
				liste_minisite: older_liste
			})
		}
	}

	handleFileInputLogo(e) {
		this.setState({
			m_logo: e.target.files[0]
		})
	}

	handleFileInputBanner(e) {
		this.setState({
			m_banner: e.target.files[0]
		})
	}

	handleFileInputBimi(e) {
		this.setState({
			m_bimi: e.target.files[0]
		})
	}

	onUpdatingImageModalClose() {
		this.setState({
			isUpdatingImages: false,
			m_bimi: null,
			m_logo: null,
			m_banner: null
		})
	}

	handleFileInputLegal(e) {
		this.setState({
			m_legal: e.target.files[0],
		})
	}

	handleFileInputCGU(e) {
		this.setState({
			m_cgu: e.target.files[0],
		})
	}

	handleFileInputPrivacy(e) {
		this.setState({
			m_privacy: e.target.files[0],
		})
	}

	handleFileInputCookies(e) {
		this.setState({
			m_cookies_policy: e.target.files[0],
		})
	}

	onGetCountry() {
		try {
			Promise.resolve(list_country(this.state.user_id, this.state.apikey, this.state.stats_apikey)).then(value => {
				this.setState({
					liste_country: value
				})
			})
		}
		catch (err) {
			console.error('error getting list country and currency ' + err);
			return null
		}
	}

	getListAllBase() {
		try {
			Promise.resolve(all_base(this.state.user_id, this.state.apikey)).then((value) => {
				let field = 'basename'
				var list_base_all = (value).sort((a, b) => (a[field] || "").toString().localeCompare((b[field] || "").toString()));
				this.setState({ liste_base_all: list_base_all })
			});
		}
		catch (err) {
			console.error('eror getting user base ' + err);
		}
	}

	onCreateMinisite() {
		this.setState({ loadingSubmit: true })
		if (this.state.m_title === null) {
			message.warn("Mandatory field need to be field", 2)
			this.setState({ loadingSubmit: false })
			return null
		}
		var data = {
			"m_url": this.state.m_url,
			"m_title": this.state.m_title,
			"m_description": this.state.m_description,
			"m_analytics": this.state.m_analytics,
			"m_sub_email": this.state.m_sub_email,
			"m_unsub_email": this.state.m_unsub_email,
			"m_cookies": this.state.m_cookies,
			"m_host": this.state.m_host,
			"m_user": this.state.m_user,
			"m_password": this.state.m_pass,
			"m_base_id": this.state.m_base_id,
			"m_country": this.state.m_country,
			"m_catch": this.state.m_catch,
			"m_offer_catch": this.state.m_offer_catch,
			"m_status_adding_traffic": parseInt(this.state.addingTraffic)
		}
		Promise.resolve(create_minisite(this.state.user_id, this.state.apikey,data)).then((value) => {
			setTimeout(() => {
				window.location.reload()
			}, 2000)

		});
	}

	onUpdateMinisite() {
		this.setState({ loadingSubmit: true })
		if (this.state.m_title === null) {
			message.warn("Mandatory field need to be field", 2)
			this.setState({ loadingSubmit: false })
			return null
		}
		var data = {
			"m_url": this.state.m_url,
			"m_title": this.state.m_title,
			"m_description": this.state.m_description,
			"m_analytics": this.state.m_analytics,
			"m_sub_email": this.state.m_sub_email,
			"m_unsub_email": this.state.m_unsub_email,
			"m_logo": this.state.m_logo,
			"m_banner": this.state.m_banner,
			"m_cookies": this.state.m_cookies,
			"m_host": this.state.m_host,
			"m_user": this.state.m_user,
			"m_password": this.state.m_pass,
			"m_base_id": this.state.m_base_id,
			"m_country": this.state.m_country,
			"m_catch": this.state.m_catch,
			"m_offer_catch": this.state.m_offer_catch,
			"m_status_adding_traffic": parseInt(this.state.addingTraffic)
		}
		Promise.resolve(update_minisite(this.state.user_id, this.state.apikey,data)).then((value) => {
			setTimeout(() => {
				window.location.reload()
			}, 2000)
		});
	}

	onCloneMinisite() {
		this.setState({ loadingSubmit: true })
		if (this.state.m_title === null) {
			message.warn("Mandatory field need to be field", 2)
			this.setState({ loadingSubmit: false })
			return null
		}
		var data = {
			"m_url": this.state.m_url,
			"m_title": this.state.m_title,
			"m_description": this.state.m_description,
			"m_analytics": this.state.m_analytics,
			"m_sub_email": this.state.m_sub_email,
			"m_unsub_email": this.state.m_unsub_email,
			"m_logo": this.state.m_logo,
			"m_banner": this.state.m_banner,
			"m_cookies": this.state.m_cookies,
			"m_legal": this.state.m_legal,
			"m_cgu": this.state.m_cgu,
			"m_privacy": this.state.m_privacy,
			"m_cookies_policy": this.state.m_cookies_policy,
			"m_host": this.state.m_host,
			"m_user": this.state.m_user,
			"m_password": this.state.m_pass,
			"m_base_id": this.state.m_base_id,
			"m_country": this.state.m_country,
			"m_catch": this.state.m_catch,
			"m_offer_catch": this.state.m_offer_catch,
			"m_clone_base_id": this.state.m_clone_base_id,
			"m_status_adding_traffic": 1 ? this.state.addingTraffic === true : 0,
			"m_status_adding_legal": this.state.m_status_adding_legal,
			"m_status_adding_cgu": this.state.m_status_adding_cgu,
			"m_status_adding_privacy": this.state.m_status_adding_privacy,
			"m_status_adding_cookies": this.state.m_status_adding_cookies
		}
		Promise.resolve(clone_minisite(this.state.user_id, this.state.apikey,data)).then((value) => {
			setTimeout(() => {
				window.location.reload()
			}, 2000)
		});
	}

	OncheckBimiIntegration(url) {
		this.setState({
			show_modal_bimi: true
		})
		Promise.resolve(check_bimi(this.state.user_id, this.state.apikey,url)).then((value) => {
			var data = []
			data.push(value)
			this.setState({
				bimi_check_result: data,
				display_loading_check: 'none',
				display_check_result: 'block',
			})
		});
	}

	onCloseModalNew() {
		this.setState({
			isNew: false,
			modal_title: '',
			displayKeepFile: 'none',
			m_url: null,
			m_title: null,
			m_description: null,
			m_analytics: null,
			m_sub_email: "stephanie@kontikimedia.com",
			m_unsub_email: null,
			m_logo: null,
			m_banner: null,
			m_cookies: null,
			m_catch: null,
			m_country: "FR",
			m_base_id: null,
			m_clone_base_id: null,
			m_offer_catch: null,
			m_legal: null,
			m_cgu: null,
			m_privacy: null,
			m_cookies_policy: null,
			m_host: null,
			m_user: null,
			m_pass: null,
		})
	}

	onUpdatingImages() {
		this.setState({ loadingSubmitImages: true })
		if (this.state.m_logo === null && this.state.m_banner === null && this.state.m_bimi === null) {
			message.warning('No images selected !!!')
			this.setState({ loadingSubmitImages: false })
			return null
		} else {
			var data = {
				"logo": this.state.m_logo,
				"banner": this.state.m_banner,
				"bimi": this.state.m_bimi,
				"base_id": this.state.m_minisite_id
			}
			Promise.resolve(updating_minisite_images(this.state.user_id, this.state.apikey,data)).then((value) => {
				setTimeout(() => {
					window.location.reload()
				}, 2000)
			});
		}
	}

	onUpdatingRgpdModalClose() {
		this.setState({
			isUpdatingRGPD: false
		})
	}

	onUpdateRgpdMinisite(){
		this.setState({ loadingSubmitImages: true })
		if (this.state.m_legal === null && this.state.m_cgu === null && this.state.m_cookies_policy === null && this.state.m_privacy === null) {
			message.warning('One RGPD file need to be filled !!!')
			this.setState({ loadingSubmitImages: false })
			return null
		} else {
			var data = {
				"legal": this.state.m_legal,
				"cookies_policy": this.state.m_cookies_policy,
				"cgu": this.state.m_cgu,
				"privacy": this.state.m_privacy,
				"base_id": this.state.m_minisite_id
			}
			Promise.resolve(updating_minisite_rgpd(this.state.user_id, this.state.apikey,data)).then((value) => {
				setTimeout(() => {
					window.location.reload()
				}, 2000)
			});
		}
	}


	render() {
		const bg = {
			overlay: {
				backgroundColor: "rgba(0, 0, 0, .5)",
			}
		}

		let button
		if (this.state.modal_title === "MINISITE CREATE") {
			button = <Button
				loading={this.state.loadingSubmit}
				className='btn btn-success'
				style={{ width: '40%', height: 55, fontSize: 25 }}
				onClick={this.onCreateMinisite.bind(this)}
			>
				SUBMIT
			</Button>
		} else {
			if (this.state.modal_title === "MINISITE UPDATE") {
				button = <Button
					loading={this.state.loadingSubmit}
					className='btn btn-success'
					style={{ width: '40%', height: 55, fontSize: 25 }}
					onClick={this.onUpdateMinisite.bind(this)}
				>
					UPDATE
				</Button>
			} else {
				if (this.state.modal_title === "MINISITE CLONED") {
					button = <Button
						loading={this.state.loadingSubmit}
						className='btn btn-success'
						style={{ width: '40%', height: 55, fontSize: 25 }}
						onClick={this.onCloneMinisite.bind(this)}
					>
						CLONE
					</Button>
				}

			}
		}

		return (
			<div style={{ padding: 10, height: '85vh', overflowY: 'auto', overflowX: 'hidden' }}>
				<Row>
					<Col span={2} style={{ paddingTop: 5, textAlign: 'center' }}>
						<span style={{ fontSize: 16 }}>Search: </span>
					</Col>
					<Col span={8}>
						<Input
							value={this.state.key_filter}
							size="medium"
							style={{ border: '1px solid gray', color: '#192a56', fontWeight: 700 }}
							onChange={this.onFilterMinisite.bind(this)}
						/>
					</Col>
					<Col span={1}></Col>
					<Col span={10} style={{ textAlign: 'left' }}>
						<Button
							style={{ width: '10%', fontSize: 16 }}
							className='btn btn-success'
							onClick={() => {
								this.setState({
									isNew: true,
									modal_title: "MINISITE CREATE"
								})
							}}
						>
							NEW +
						</Button>
					</Col>
				</Row>
				<br />
				<div style={{ width: '100%', fontSize: 16, height: '81vh', marginTop: 5, textAlign: 'center', display: `${this.state.display_loading}` }}>
					<img src={loading} style={{ width: 380, height: 350, marginTop: '8%' }} alt="loading ..." />
				</div>
				{
					this.state.liste_minisite && this.state.liste_minisite.map(item => {
						var key = generateKey()
						var url = "https://" + item['url']
						var analytics = null
						var url_image = url + '/assets/images/banner.jpg'
						switch (parseInt(item['analytics'])) {
							case 1:
								analytics = "Database controlled By DI"
								break;
							case 2:
								analytics = "External Database FR"
								break;
							case 3:
								analytics = "External Database NOT FR"
								break;
							case 4:
								analytics = "Internal Database"
								break;

							default:
								analytics = "Not definied"
								break;
						}
						return (
							<Row style={{ padding: 10, borderBottom: '1px dashed grey' }} key={key}>
								{/* <Col span={3}></Col> */}
								<Col span={10}>
									<div style={{ height: 250, width: '100%', overflow: 'hidden' }}>
										<a href={'https://' + item.url} target="_blank">
											<img src={url_image} style={{ width: '100%', height: '100%' }} />
										</a>
									</div>
								</Col>
								<Col span={1}></Col>
								<Col span={7}>
									<div>
										<h1 style={{ fontWeight: 700, textDecoration: 'underline' }}>{item['title']} <span style={{ fontSize: 24 }}>{'(' + item.abreviation + ')'}</span></h1>

									</div>
									<div style={{ fontSize: 14 }}>
										<Row>
											<Col span={6} style={{ textAlign: 'center' }}>
												<p>Description:</p>
											</Col>
											<Col span={14}>
												<p>{base64_decode(item['description'])}</p>
											</Col>
										</Row>
									</div>
									<div style={{ fontSize: 14 }}>
										<Row>
											<Col span={6} style={{ textAlign: 'center' }}>
												<p>Unsubscribe email:</p>
											</Col>
											<Col span={14}>
												<p>{item['unsub_email']}</p>
											</Col>
										</Row>
									</div>
									<div style={{ fontSize: 14 }}>
										<Row>
											<Col span={6} style={{ textAlign: 'center' }}>
												<p>Subscribe email:</p>
											</Col>
											<Col span={14}>
												<p>{item['sub_email']}</p>
											</Col>
										</Row>
									</div>
									<div style={{ fontSize: 14 }}>
										<Row>
											<Col span={6} style={{ textAlign: 'center' }}>
												<p>Analytics:</p>
											</Col>
											<Col span={14}>
												<p>{analytics}</p>
											</Col>
										</Row>
									</div>
									<div style={{ fontSize: 14 }}>
										<Row>
											<Col span={6} style={{ textAlign: 'center' }}>
												<p>Creation date:</p>
											</Col>
											<Col span={14}>
												<p>{item['create_at']}</p>
											</Col>
										</Row>
									</div>
								</Col>
								<Col span={4}>
									<Row>
										<Col span={24} style={{ textAlign: 'center' }}>
											<Button
												className='btn btn-warning'
												style={{ width: '65%', fontSize: 16, color: '#fff', height: 35, padding: 5 }}
												onClick={() => {
													this.setState({
														m_url: item['url'],
														m_title: item['title'],
														m_description: base64_decode(item['description']),
														m_analytics: item['analytics'],
														m_sub_email: item['sub_email'],
														m_unsub_email: item['unsub_email'],
														m_logo: null,
														m_banner: null,
														m_cookies: base64_decode(item['cookies']),
														m_catch: base64_decode(item['catch']),
														m_country: item['country'],
														m_base_id: item['id'],
														m_host: item['ftphost'],
														m_user: item['ftpuser'],
														m_pass: base64_decode(item['ftppass']),
														isNew: true,
														modal_title: "MINISITE UPDATE",
														m_offer_catch: base64_decode(item['offer_catch']),
														m_cgu: "0",
														m_legal: "0",
														m_privacy: "0",
														m_cookies_policy: "0",
														addingTraffic: item['traffic']
													})
												}}
											>
												EDIT
											</Button>
										</Col>
									</Row>
									{/* <br/>
									<Row>
										<Col span={24} style={{ textAlign: 'center' }}>
											<Button
												className='btn btn-secondary'
												style={{ width: '65%', fontSize: 16, color: '#fff', height: 35, padding: 5 }}
												onClick={() => {
													this.setState({
														m_url: item['url'],
														m_title: item['title'],
														m_description: base64_decode(item['description']),
														m_analytics: item['analytics'],
														m_sub_email: item['sub_email'],
														m_unsub_email: item['unsub_email'],
														m_logo: null,
														m_banner: null,
														m_cookies: base64_decode(item['cookies']),
														m_catch: base64_decode(item['catch']),
														m_country: item['country'],
														m_base_id: item['id'],
														m_clone_base_id: item['id'],
														m_host: item['ftphost'],
														m_user: item['ftpuser'],
														m_pass: base64_decode(item['ftppass']),
														isNew: true,
														modal_title: "MINISITE CLONED",
														m_offer_catch: base64_decode(item['offer_catch']),
														m_cgu: "0",
														m_legal: "0",
														m_privacy: "0",
														m_cookies_policy: "0"
													})
												}}
											>
												CLONE
											</Button>
										</Col>
									</Row> */}
									<br />
									<Row>
										<Col span={24} style={{ textAlign: 'center' }}>
											<Button
												className='btn btn-info'
												style={{ width: '65%', fontSize: 16, color: '#fff', height: 35, padding: 5 }}
												onClick={() => {
													this.setState({
														m_minisite_id: item['minisiteid'],
														isUpdatingImages: true
													})
												}}
											>
												CONFIGURES IMAGES
											</Button>
										</Col>
									</Row>
									<br />
									<Row>
										<Col span={24} style={{ textAlign: 'center' }}>
											<Button
												className='btn btn-success'
												style={{ width: '65%', fontSize: 16, color: '#fff', height: 35, padding: 5 }}
												onClick={() => {
													this.setState({
														isUpdatingRGPD: true,
														m_minisite_id: item['minisiteid']
													})
												}}
											>
												CONFIGURES RGPD FILES
											</Button>
										</Col>
									</Row>
									<br />
									<Row>
										<Col span={24} style={{ textAlign: 'center' }}>
											<Button
												className='btn btn-dark'
												style={{ width: '65%', fontSize: 16, color: '#fff', height: 35, padding: 5 }}
												onClick={() => {
													this.OncheckBimiIntegration(item.url)
												}}
											>
												CHECK BIMI
											</Button>
										</Col>
									</Row>
								</Col>
							</Row>
						)

					})
				}
				{/* </Row> */}

				<Modal
					id="new8"
					isOpen={this.state.isNew}
					onRequestClose={this.onCloseModalNew.bind(this)}
					shouldCloseOnOverlayClick={true}
					style={bg}
					ariaHideApp={false}
				>
					<div style={{ fontSize: '5px !important', marginTop: '0px', marginLeft: '0px', width: '100%' }}>
						<div
							style={{ background: '#001529', marginTop: '0px', textAlign: 'center', width: '100%' }}
						>
							<h3 style={{ color: "#fff", padding: 5 }}>{this.state.modal_title}</h3>
						</div>
						<span
							style={{ color: "#C23616", marginTop: '-45px', fontSize: 30, float: 'right', cursor: 'pointer' }}
							onClick={this.onCloseModalNew.bind(this)}
						>
							X
						</span>
					</div>
					<div>
						<Row>
							<Col span={24}>
								<Row style={{ paddingTop: 10 }}>
									<Col span={5} style={{ padding: 5, textAlign: 'center' }}>
										<span style={{ fontSize: 16 }}>Country</span>
									</Col>
									<Col span={16}>
										<Select
											size="medium"
											showSearch
											optionFilterProp="children"
											style={{ textAlign: 'center', width: '100%', color: '#192a56', fontWeight: 700 }}
											value={this.state.m_country}
											onChange={(value) => {
												this.setState({ m_country: value })
											}}
										>
											{
												this.state.liste_country && this.state.liste_country.map(item => {
													var key = generateKey()
													return (
														<Option key={key} value={item.countrycode}>{item.countrycode}</Option>
													)
												})
											}
										</Select>
									</Col>
								</Row>
								<Row style={{ paddingTop: 10 }}>
									<Col span={5} style={{ paddingTop: 10, textAlign: 'center' }}>
										<span style={{ fontSize: 16 }}>Database</span>
									</Col>
									<Col span={16}>
										<Select
											size="medium"
											showSearch
											optionFilterProp="children"
											style={{ width: '100%', color: '#192a56', textAlign: 'center' }}
											defaultValue={this.state.m_base_id}
											value={this.state.m_base_id}
											onChange={
												(value) => {
													this.setState({ m_base_id: value })
												}
											}
										>
											{
												this.state.liste_base_all !== null && this.state.liste_base_all.map((item) => {
													var key = generateKey()
													return (
														<Option key={key} value={item.id}>{item.basename}</Option>
													)
												})
											}
										</Select>
									</Col>

								</Row>
								<Row style={{ paddingTop: 10 }}>
									<Col span={5} style={{ padding: 5, textAlign: 'center' }}>
										<span style={{ fontSize: 16 }}>Title</span>
									</Col>
									<Col span={16}>
										<Input
											type="text"
											size="medium"
											id="field_style"
											value={this.state.m_title}
											defaultValue={this.state.m_title}
											onChange={
												(e) => {
													this.setState({
														m_title: e.target.value
													})
												}
											}
											style={{ width: '100%', color: '#192a56' }}
										/>
									</Col>
								</Row>
								<Row style={{ paddingTop: 10 }}>
									<Col span={5} style={{ padding: 5, textAlign: 'center' }}>
										<span style={{ fontSize: 16 }}>Description</span>
									</Col>
									<Col span={16}>
										<Input
											type="text"
											size="medium"
											id="field_style"
											value={this.state.m_description}
											defaultValue={this.state.m_description}
											onChange={
												(e) => {
													this.setState({
														m_description: e.target.value
													})
												}
											}
											style={{ width: '100%', color: '#192a56' }}
										/>
									</Col>
								</Row>
								<Row style={{ paddingTop: 10 }}>
									<Col span={5} style={{ padding: 5, textAlign: 'center' }}>
										<span style={{ fontSize: 16 }}>Analytics</span>
									</Col>
									<Col span={16}>
										<Select
											size="medium"
											showSearch
											optionFilterProp="children"
											style={{ width: '100%', color: '#192a56' }}
											value={this.state.m_analytics}
											onChange={
												(value) => {
													this.setState({
														m_analytics: value
													})
												}
											}
										>
											<Option value="1">Database controlled by DI</Option>
											<Option value="4">Internal database</Option>
											<Option value="2">External database (FR)</Option>
											<Option value="3">External database (NOT FR)</Option>

										</Select>
									</Col>
								</Row>
								<Row style={{ paddingTop: 10 }}>
									<Col span={5} style={{ padding: 5, textAlign: 'center' }}>
										<span style={{ fontSize: 16 }}>Subscription</span>
									</Col>
									<Col span={16}>
										<Input
											type="text"
											size="medium"
											id="field_style"
											value={this.state.m_sub_email}
											defaultValue={this.state.m_sub_email}
											onChange={
												(e) => {
													this.setState({
														m_sub_email: e.target.value
													})
												}
											}
											style={{ width: '100%', color: '#192a56' }}
										/>
									</Col>
								</Row>
								<Row style={{ paddingTop: 10 }}>
									<Col span={5} style={{ padding: 5, textAlign: 'center' }}>
										<span style={{ fontSize: 16 }}>Unsubscription</span>
									</Col>
									<Col span={16}>
										<Input
											type="text"
											size="medium"
											id="field_style"
											value={this.state.m_unsub_email}
											defaultValue={this.state.m_unsub_email}
											onChange={
												(e) => {
													this.setState({
														m_unsub_email: e.target.value
													})
												}
											}
											style={{ width: '100%', color: '#192a56' }}
										/>
									</Col>
								</Row>
								<Row style={{ paddingTop: 10 }}>
									<Col span={5} style={{ padding: 5, textAlign: 'center' }}>
										<span style={{ fontSize: 16 }}>Host</span>
									</Col>
									<Col span={16}>
										<Input
											type="text"
											size="medium"
											id="field_style"
											value={this.state.m_host}
											defaultValue={this.state.m_host}
											onChange={
												(e) => {
													this.setState({
														m_host: e.target.value
													})
												}
											}
											style={{ width: '100%', color: '#192a56' }}
										/>
									</Col>
								</Row>
								<Row style={{ paddingTop: 10 }}>
									<Col span={5} style={{ padding: 5, textAlign: 'center' }}>
										<span style={{ fontSize: 16 }}>User</span>
									</Col>
									<Col span={16}>
										<Input
											type="text"
											size="medium"
											id="field_style"
											value={this.state.m_user}
											defaultValue={this.state.m_user}
											onChange={
												(e) => {
													this.setState({
														m_user: e.target.value
													})
												}
											}
											style={{ width: '100%', color: '#192a56' }}
										/>
									</Col>
								</Row>
								<Row style={{ paddingTop: 10 }}>
									<Col span={5} style={{ padding: 5, textAlign: 'center' }}>
										<span style={{ fontSize: 16 }}>Password</span>
									</Col>
									<Col span={16}>
										<Input
											type="text"
											size="medium"
											id="field_style"
											value={this.state.m_pass}
											defaultValue={this.state.m_pass}
											onChange={
												(e) => {
													this.setState({
														m_pass: e.target.value
													})
												}
											}
											style={{ width: '100%', color: '#192a56' }}
										/>
									</Col>
								</Row>
								<Row style={{ paddingTop: 10 }}>
									<Col span={5} style={{ padding: 5, textAlign: 'center' }}>
										<span style={{ fontSize: 16 }}>Cookies</span>
									</Col>
									<Col span={16}>
										<TextArea
											type="text"
											value={this.state.m_cookies}
											onChange={(e) => { this.setState({ m_cookies: e.target.value }) }}
											style={{ width: '100%', color: '#192a56' }}
										/>
									</Col>
								</Row>
								<Row style={{ paddingTop: 10 }}>
									<Col span={5} style={{ padding: 5, textAlign: 'center' }}>
										<span style={{ fontSize: 16 }}>Catch</span>
									</Col>
									<Col span={16}>
										<TextArea
											type="text"
											value={this.state.m_catch}
											onChange={(e) => { this.setState({ m_catch: e.target.value }) }}
											style={{ width: '100%', color: '#192a56' }}
										/>
									</Col>
								</Row>
								<Row style={{ paddingTop: 10 }}>
									<Col span={5} style={{ padding: 5, textAlign: 'center' }}>
										<span style={{ fontSize: 16 }}>Offers text</span>
									</Col>
									<Col span={16}>
										<TextArea
											type="text"
											value={this.state.m_offer_catch}
											onChange={(e) => { this.setState({ m_offer_catch: e.target.value }) }}
											style={{ width: '100%', color: '#192a56' }}
										/>
									</Col>
								</Row>

								<Row style={{ paddingTop: 10 }}>
									<Col span={5} style={{ padding: 5, textAlign: 'center' }}>
										<span style={{ fontSize: 16 }}>Vignettes</span>
									</Col>
									<Col span={16}>
										<Select
											size='medium'
											style={{ width: '100%', textAlign: 'center' }}
											value={this.state.addingTraffic}
											onChange={(value) => { this.setState({ addingTraffic: value }) }}
										>
											<Option value="1">Activate</Option>
											<Option value="0">Deactivate</Option>
										</Select>
									</Col>
								</Row>
								<br />
								<div style={{ textAlign: 'center', width: '100%' }}>
									{button}
								</div>
							</Col>

							{/* <Col span={12}>
								<Row style={{ paddingTop: 10 }}>
									<Col span={5} style={{ padding: 5, textAlign: 'center' }}>
										<span style={{ fontSize: 16 }}>Logo</span>
									</Col>
									<Col span={6}>
										<Select
											size="medium"
											showSearch
											optionFilterProp="children"
											style={{ width: '100%', color: '#192a56' }}
											defaultValue="0"
											onChange={
												(value) => {
													console.log(typeof value)
													switch (value) {
														case "0":
															this.setState({ displayImportLogo: 'none' })
															break
														case "1":
															this.setState({ displayImportLogo: 'block' })
															break

													}
												}
											}
										>
											<Option value="0">Use default logo</Option>
											<Option value="1">Import images</Option>
										</Select>

									</Col>
									<Col span={1}></Col>
									<Col span={5} style={{ display: `${this.state.displayImportLogo}` }}>
										<input type="file" accept="image/png, image/gif, image/jpeg, image/jpg" onChange={(e) => {
											this.handleFileInputLogo(e)
										}} />
									</Col>
								</Row>
								<Row style={{ paddingTop: 10 }}>
									<Col span={5} style={{ padding: 5, textAlign: 'center' }}>
										<span style={{ fontSize: 16 }}>Banner</span>
									</Col>
									<Col span={6}>
										<Select
											size="medium"
											showSearch
											optionFilterProp="children"
											style={{ width: '100%', color: '#192a56' }}
											defaultValue="0"
											onChange={
												(value) => {
													console.log(typeof value)
													switch (value) {
														case "0":
															this.setState({ displayImportBanner: 'none' })
															break
														case "1":
															this.setState({ displayImportBanner: 'block' })
															break
													}
												}
											}
										>
											<Option value="0">Use default banner</Option>
											<Option value="1">Import images</Option>
										</Select>

									</Col>
									<Col span={1}></Col>
									<Col span={6} style={{ display: `${this.state.displayImportBanner}` }}>
										<input type="file" accept="image/png, image/gif, image/jpeg, image/jpg" onChange={(e) => {
											this.handleFileInputBanner(e)
										}} />
									</Col>
								</Row>

								<Row style={{ paddingTop: 10 }}>
									<Col span={5} style={{ padding: 5, textAlign: 'center' }}>
										<span style={{ fontSize: 16 }}>Legal notice</span>
									</Col>
									<Col span={6}>
										<Select
											size="medium"
											showSearch
											optionFilterProp="children"
											style={{ width: '100%', color: '#192a56' }}
											defaultValue="0"
											onChange={
												(value) => {
													console.log(typeof value)
													switch (value) {
														case "0":
															this.setState({ displayImportlegaleNotice: 'none', m_status_adding_legal: 0 })
															break
														case "1":
															this.setState({ displayImportlegaleNotice: 'block', m_status_adding_legal: 1 })
															break
														case "2":
															this.setState({ displayImportlegaleNotice: 'none', m_status_adding_legal: 2 })
															break
														case "3":
															this.setState({ displayImportlegaleNotice: 'none', m_status_adding_legal: 3 })
															break

													}
												}
											}
										>
											<Option value="0">Use default file</Option>
											<Option value="1">Import file</Option>
											<Option value="2">Don't add</Option>
											<Option calue="3" style={{ display: `${this.state.displayKeepFile}` }}>Keep the same file</Option>
										</Select>

									</Col>
									<Col span={1}></Col>
									<Col span={5} style={{ display: `${this.state.displayImportlegaleNotice}` }}>
										<input
											type="file"
											onChange={(e) => {
												this.handleFileInputLegal(e)
											}}
										/>
									</Col>
								</Row>
								<Row style={{ paddingTop: 10 }}>
									<Col span={5} style={{ padding: 5, textAlign: 'center' }}>
										<span style={{ fontSize: 16 }}>CGU</span>
									</Col>
									<Col span={6}>
										<Select
											size="medium"
											showSearch
											optionFilterProp="children"
											style={{ width: '100%', color: '#192a56' }}
											defaultValue="0"
											onChange={
												(value) => {
													console.log(typeof value)
													switch (value) {
														case "0":
															this.setState({ displayImportCGU: 'none', m_status_adding_cgu: 0 })
															break
														case "1":
															this.setState({ displayImportCGU: 'block', m_status_adding_cgu: 1 })
															break
														case "2":
															this.setState({ displayImportCGU: 'none', m_status_adding_cgu: 2 })
															break
														case "3":
															this.setState({ displayImportCGU: 'none', m_status_adding_cgu: 3 })
															break
													}
												}
											}
										>
											<Option value="0">Use default file</Option>
											<Option value="1">Import file</Option>
											<Option value="2">Don't add</Option>
											<Option calue="3" style={{ display: `${this.state.displayKeepFile}` }}>Keep the same file</Option>
										</Select>

									</Col>
									<Col span={1}></Col>
									<Col span={5} style={{ display: `${this.state.displayImportCGU}` }}>
										<input
											type="file"
											onChange={(e) => {
												this.handleFileInputCGU(e)
											}}
										/>
									</Col>
								</Row>
								<Row style={{ paddingTop: 10 }}>
									<Col span={5} style={{ padding: 5, textAlign: 'center' }}>
										<span style={{ fontSize: 16 }}>Privacy</span>
									</Col>
									<Col span={6}>
										<Select
											size="medium"
											showSearch
											optionFilterProp="children"
											style={{ width: '100%', color: '#192a56' }}
											defaultValue="0"
											onChange={
												(value) => {
													console.log(typeof value)
													switch (value) {
														case "0":
															this.setState({ displayImportPrivacy: 'none', m_status_adding_privacy: 0 })
															break
														case "1":
															this.setState({ displayImportPrivacy: 'block', m_status_adding_privacy: 1 })
															break
														case "2":
															this.setState({ displayImportPrivacy: 'none', m_status_adding_privacy: 2 })
															break
														case "3":
															this.setState({ displayImportPrivacy: 'none', m_status_adding_privacy: 3 })
															break
													}
												}
											}
										>
											<Option value="0">Use default file</Option>
											<Option value="1">Import file</Option>
											<Option value="2">Don't add</Option>
											<Option calue="3" style={{ display: `${this.state.displayKeepFile}` }}>Keep the same file</Option>
										</Select>

									</Col>
									<Col span={1}></Col>
									<Col span={5} style={{ display: `${this.state.displayImportPrivacy}` }}>
										<input
											type="file"
											onChange={(e) => {
												this.handleFileInputPrivacy(e)
											}}
										/>
									</Col>
								</Row>
								<Row style={{ paddingTop: 10 }}>
									<Col span={5} style={{ padding: 5, textAlign: 'center' }}>
										<span style={{ fontSize: 16 }}>Cookies Policy</span>
									</Col>
									<Col span={6}>
										<Select
											size="medium"
											showSearch
											optionFilterProp="children"
											style={{ width: '100%', color: '#192a56' }}
											defaultValue="0"
											onChange={
												(value) => {
													console.log(typeof value)
													switch (value) {
														case "0":
															this.setState({ displayImportCookies: 'none', m_status_adding_cookies: 0 })
															break
														case "1":
															this.setState({ displayImportCookies: 'block', m_status_adding_cookies: 1 })
															break
														case "2":
															this.setState({ displayImportCookies: 'none', m_status_adding_cookies: 2 })
															break
														case "3":
															this.setState({ displayImportCookies: 'none', m_status_adding_privacy: 3 })
															break
													}
												}
											}
										>
											<Option value="0">Use default file</Option>
											<Option value="1">Import file</Option>
											<Option value="2">Don't add</Option>
											<Option calue="3" style={{ display: `${this.state.displayKeepFile}` }}>Keep the same file</Option>
										</Select>

									</Col>
									<Col span={1}></Col>
									<Col span={5} style={{ display: `${this.state.displayImportCookies}` }}>
										<input
											type="file"
											onChange={(e) => {
												this.handleFileInputCookies(e)
											}} />
									</Col>
								</Row>
								<Row>
									<Col span={5} style={{ padding: 5, textAlign: 'center' }}>
										<span style={{ fontSize: 16 }}>Vignettes</span>
									</Col>
									<Col span={6} style={{ padding: 8, textAlign: 'center' }}>
										<Checkbox
											checked={this.state.addingTraffic}
											onChange={(e) => {
												if (e.target.checked) {
													this.setState({ addingTraffic: e.target.checked })
												} else {
													this.setState({ addingTraffic: e.target.checked })
												}
											}}
										>
										</Checkbox>
									</Col>
								</Row>
								<br /><br />
								<div style={{ textAlign: 'center', width: '100%' }}>
									{button}
								</div>
							</Col>  */}
						</Row>


					</div>

				</Modal>

				<Modal
					id="new9"
					isOpen={this.state.show_modal_bimi}
					onRequestClose={() => { this.setState({ show_modal_bimi: false, bimi_check_result: [], display_loading_check: 'block', display_check_result: 'none' }) }}
					shouldCloseOnOverlayClick={true}
					style={bg}
					ariaHideApp={false}
				>
					<div style={{ fontSize: '5px !important', marginTop: '0px', marginLeft: '0px', width: '100%' }}>
						<div
							style={{ background: '#001529', marginTop: '0px', textAlign: 'center', width: '100%' }}
						>
							<h3 style={{ color: "#fff", padding: 5 }}>BIMI INTEGRATION</h3>
						</div>
						<span
							style={{ color: "#C23616", marginTop: '-45px', fontSize: 30, float: 'right', cursor: 'pointer' }} onClick={() => { this.setState({ show_modal_bimi: false, bimi_check_result: [], display_loading_check: 'block', display_check_result: 'none' }) }}
						>
							X
						</span>
					</div>
					<div>
						<div style={{ width: '100%', display: `${this.state.display_loading_check}`, textAlign: 'center' }}>
							<img src={loading} alt="loading" style={{ padding: 15 }} />
						</div>
						<div style={{ width: '100%', display: `${this.state.display_check_result}` }}>
							{
								this.state.bimi_check_result && this.state.bimi_check_result.map(item => {
									console.log(item.bimi.status)
									var key = generateKey()
									return (
										<Row>
											<Col span={16} style={{ fontSize: 16 }}>
												<Row>
													<Col span={6} style={{ textDecoration: 'underline', textAlign: 'center' }}>Status</Col>
													<Col span={18}>
														<p>{item.bimi.status === true ? "True" : "False"}</p>
													</Col>
												</Row>
												<Row>
													<Col span={6} style={{ textDecoration: 'underline', textAlign: 'center' }}>Record</Col>
													<Col span={18}>
														<p>{item.bimi.record}</p>
													</Col>
												</Row>
												<Row>
													<Col span={6} style={{ textDecoration: 'underline', textAlign: 'center' }}>SVG Validation</Col>
													<Col span={18}>
														<p>{item.svg_validation.status === true ? "SVG validated" : "SVG NOT validated"}</p>
													</Col>
												</Row>
											</Col>
											<Col span={8}>
												<div style={{ width: 200, height: 'auto', textAlign: 'center' }}>
													<img src={item.bimi.svg} alt="svg" style={{ width: '100%', height: '100%' }} />
												</div>
											</Col>
										</Row>
									)
								})
							}

						</div>

					</div>

				</Modal>

				<Modal
					id="new10"
					isOpen={this.state.isUpdatingImages}
					onRequestClose={this.onUpdatingImageModalClose.bind(this)}
					shouldCloseOnOverlayClick={true}
					style={bg}
					ariaHideApp={false}
				>
					<div style={{ fontSize: '5px !important', marginTop: '0px', marginLeft: '0px', width: '100%' }}>
						<div
							style={{ background: '#001529', marginTop: '0px', textAlign: 'center', width: '100%' }}
						>
							<h3 style={{ color: "#fff", padding: 5 }}>UPDATING IMAGES</h3>
						</div>
						<span
							style={{ color: "#C23616", marginTop: '-45px', fontSize: 30, float: 'right', cursor: 'pointer' }}
							onClick={this.onUpdatingImageModalClose.bind(this)}
						>
							X
						</span>
					</div>
					<div style={{ width: '50%', margin: '0 auto' }}>
						<Row>
							<Col span={5} style={{ padding: 10, textAlign: 'center' }}>
								<span style={{ fontSize: 16 }}>Logo</span>
							</Col>
							<Col span={8} style={{ padding: 10 }}>
								<input
									type="file"
									accept="image/png, image/jpeg, image/jpg"
									onChange={(e) => {
										this.handleFileInputLogo(e)
									}}
								/>
							</Col>
						</Row>
						<Row>
							<Col span={5} style={{ padding: 10, textAlign: 'center' }}>
								<span style={{ fontSize: 16 }}>Banner</span>
							</Col>
							<Col span={8} style={{ padding: 10 }}>
								<input
									type="file"
									accept="image/png, image/jpeg, image/jpg"
									onChange={(e) => {
										this.handleFileInputBanner(e)
									}}
								/>
							</Col>
						</Row>
						<Row>
							<Col span={5} style={{ padding: 10, textAlign: 'center' }}>
								<span style={{ fontSize: 16 }}>Bimi</span>
							</Col>
							<Col span={8} style={{ padding: 10 }}>
								<input
									type="file"
									accept="image/svg"
									onChange={(e) => {
										this.handleFileInputBimi(e)
									}}
								/>
							</Col>
						</Row>
					</div>
					<br />
					<div style={{ width: '50%', margin: '0 auto', textAlign: 'center' }}>
						<Button
							loading={this.state.loadingSubmitImages}
							className='btn btn-success'
							style={{ width: '60%' }}
							onClick={this.onUpdatingImages.bind(this)}
						>
							SUBMIT
						</Button>
					</div>

				</Modal>

				<Modal
					id="new11"
					isOpen={this.state.isUpdatingRGPD}
					onRequestClose={this.onUpdatingRgpdModalClose.bind(this)}
					shouldCloseOnOverlayClick={true}
					style={bg}
					ariaHideApp={false}
				>
					<div style={{ fontSize: '5px !important', marginTop: '0px', marginLeft: '0px', width: '100%' }}>
						<div
							style={{ background: '#001529', marginTop: '0px', textAlign: 'center', width: '100%' }}
						>
							<h3 style={{ color: "#fff", padding: 5 }}>UPDATING RGPD FILES</h3>
						</div>
						<span
							style={{ color: "#C23616", marginTop: '-45px', fontSize: 30, float: 'right', cursor: 'pointer' }}
							onClick={this.onUpdatingRgpdModalClose.bind(this)}
						>
							X
						</span>
					</div>
					<div>
						<Row>
							<Col span={18}>
								<Tabs
									tabPosition="top"
									style={{ background: '#fff', height: '100%', paddingLeft: 20, width: '98%' }}
									defaultActiveKey="1"
								>
									<TabPane
										tab={<div style={{ width: 200, textAlign: 'center', fontWeight: 600, color: 'black' }}><span>LEGALE NOTICE</span></div>}
										key="1"
									>
										<Editor
									height="52vh"
									defaultLanguage="html"
									theme="vs-dark"
									defaultValue={this.m_legal.crea_code}
									style={{ width: '100%', border: '1px solid gray', marginTop: '0px' }}
									onChange={(value) => {
										this.setState({ m_legal: value })
									}}
								/>
										{/* <AceEditor
											mode="html"
											theme="eclipse"
											placeholder="Paste code here"
											style={{ width: '100%', height: '52vh', border: '1px solid gray', fontSize: 6, marginTop: 0 }}
											value={this.state.m_legal}
											onChange={(value) => {
												this.setState({
													m_legal: value
												})
											}}
											setOptions={{
												displayIndentGuides: false,
												enableBasicAutocompletion: true,
												enableLiveAutocompletion: true,
												enableSnippets: true,
												wrap: true,
												fontSize: 11
											}}
										/> */}

									</TabPane>
									<TabPane
										tab={<div style={{ width: 200, textAlign: 'center', fontWeight: 600, color: 'black' }}><span>CGU</span></div>}
										key="2"
									>
										{/* <AceEditor
											mode="html"
											theme="eclipse"
											placeholder="Paste code here"
											style={{ width: '100%', height: '52vh', border: '1px solid gray', fontSize: 6, marginTop: 0 }}
											value={this.state.m_cgu}
											onChange={(value) => {
												this.setState({
													m_cgu: value
												})
											}}
											setOptions={{
												displayIndentGuides: false,
												enableBasicAutocompletion: true,
												enableLiveAutocompletion: true,
												enableSnippets: true,
												wrap: true,
												fontSize: 11
											}}
										/> */}
										<Editor
									height="52vh"
									defaultLanguage="html"
									theme="vs-dark"
									defaultValue={this.m_cgu.crea_code}
									style={{ width: '100%', border: '1px solid gray', marginTop: '0px' }}
									onChange={(value) => {
										this.setState({ m_cgu: value })
									}}
								/>
									</TabPane>
									<TabPane
										tab={<div style={{ width: 200, textAlign: 'center', fontWeight: 600, color: 'black' }}><span>PRIVACY</span></div>}
										key="3"
									>
										{/* <AceEditor
											mode="html"
											theme="eclipse"
											placeholder="Paste code here"
											style={{ width: '100%', height: '52vh', border: '1px solid gray', fontSize: 6, marginTop: 0 }}
											value={this.state.m_privacy}
											onChange={(value) => {
												this.setState({
													m_privacy: value
												})
											}}
											setOptions={{
												displayIndentGuides: false,
												enableBasicAutocompletion: true,
												enableLiveAutocompletion: true,
												enableSnippets: true,
												wrap: true,
												fontSize: 11
											}}
										/> */}
										<Editor
									height="52vh"
									defaultLanguage="html"
									theme="vs-dark"
									defaultValue={this.m_cgu.m_privacy}
									style={{ width: '100%', border: '1px solid gray', marginTop: '0px' }}
									onChange={(value) => {
										this.setState({ m_privacy: value })
									}}
								/>
									</TabPane>
									<TabPane
										tab={<div style={{ width: 200, textAlign: 'center', fontWeight: 600, color: 'black' }}><span>COOKIE POLICY</span></div>}
										key="4"
									>
										{/* <AceEditor
											mode="html"
											theme="eclipse"
											placeholder="Paste code here"
											style={{ width: '100%', height: '52vh', border: '1px solid gray', fontSize: 6, marginTop: 0 }}
											value={this.state.m_privacy}
											onChange={(value) => {
												this.setState({
													m_cookies_policy: value
												})
											}}
											setOptions={{
												displayIndentGuides: false,
												enableBasicAutocompletion: true,
												enableLiveAutocompletion: true,
												enableSnippets: true,
												wrap: true,
												fontSize: 11
											}}
										/> */}
										<Editor
									height="52vh"
									defaultLanguage="html"
									theme="vs-dark"
									defaultValue={this.m_cgu.m_cookies_policy}
									style={{ width: '100%', border: '1px solid gray', marginTop: '0px' }}
									onChange={(value) => {
										this.setState({ m_cookies_policy: value })
									}}
								/>
									</TabPane>
								</Tabs>
							</Col>
							<Col span={6}>
								<Row>
									<Col span={24} style={{textAlign:  'center', padding: 10}}>
										<span><strong>TOOLS USED TO CONVERT WORD TO HTML</strong></span>
									</Col>
									<Col span={24}>
										<ul style={{fontSize: 16, marginLeft: 15}}>
											<li><a href="https://convertio.co/fr/doc-html/" target="_blank">Convertio</a></li>
											<li><a href="https://wordtohtml.net/" target="_blank">Word to HTML</a></li>
											<li><a href="https://www.zamzar.com/convert/doc-to-html/" target="_blank">ZAMZAR</a></li>
											<li><a href="https://wordhtml.com/" target="_blank">WORDHTML</a></li>
											<li><a href="https://word2cleanhtml.com/" target="_blank">WORD2CLEANHTML/</a></li>
											<li><a href="https://products.aspose.app/pdf/conversion/word-to-html" target="_blank">word-to-html</a></li>
											<li><a href="https://www.idrsolutions.com/online-word-to-html-converter" target="_blank">IDR SOLUTIONS</a></li>
										</ul>
									</Col>
									<Col span={24} style={{textAlign: 'center'}}>
										<Button
											loading={this.state.loadingSubmitImages}
											className='btn btn-success'
											style={{width: '85%', height: 50}}
											onClick={this.onUpdateRgpdMinisite.bind(this)}
										>
											UPDATE RGPD FILES
										</Button>
									</Col>
								</Row>
							</Col>
						</Row>

					</div>
				</Modal>
			</div>
		)
	}
}

export default Minisite