import axios from 'axios'
import { base64_decode, base64_encode } from './Utilities';


export async function all_base(user_id, apikey) {
    if(localStorage.getItem(base64_encode('all_base')) !== undefined && localStorage.getItem(base64_encode('all_base')) !== null){
        return JSON.parse(base64_decode(localStorage.getItem(base64_encode('all_base'))))
    }else{
        
        var fd = new FormData() 
        fd.append('user_id', user_id)
        fd.append('apikey', apikey)
        let res = await axios({
            url: process.env.REACT_APP_ENDPOINT_BASE_ALL,
            method: 'post',
            timeout: 20000,
            // headers: {
            //     'Content-Type': 'application/json',
            // },
            data: fd
        })
        localStorage.setItem(base64_encode('all_base'), base64_encode(JSON.stringify(res.data)))
        return res.data
    }
    
}

export async function header_konticrea(user_id, apikey,base_id) {
    var cache_name_header = "header_konticrea_"+base_id
    if(localStorage.getItem(base64_encode(cache_name_header)) !== undefined && localStorage.getItem(base64_encode(cache_name_header)) !== null){
        return JSON.parse(base64_decode(localStorage.getItem(base64_encode(cache_name_header))))
    }else{
        var fd = new FormData() 
        fd.append('user_id', user_id)
        fd.append('apikey', apikey)
        fd.append('baseid', base_id)

        let res = await axios({
            url: process.env.REACT_APP_ENDPOINT_KONTICREA_HEADER,
            method: 'post',
            timeout: 20000,
            // headers: {
            //     'Content-Type': 'application/json',
            // },
            data: fd
        })
        localStorage.setItem(base64_encode(cache_name_header), base64_encode(JSON.stringify(res.data['header'])))
        return res.data['header']
    }
    
}

export async function footer_konticrea(user_id, apikey,base_id) {
    var cache_name_footer = "footer_konticrea_"+base_id
    if(localStorage.getItem(base64_encode(cache_name_footer)) !== undefined && localStorage.getItem(base64_encode(cache_name_footer)) !== null){
        return JSON.parse(base64_decode(localStorage.getItem(base64_encode(cache_name_footer))))
    }else{
        
        var fd = new FormData() 
        fd.append('user_id', user_id)
        fd.append('apikey', apikey)
        fd.append('baseid', base_id)

        let res = await axios({
            url: process.env.REACT_APP_ENDPOINT_KONTICREA_FOOTER,
            method: 'post',
            timeout: 20000,
            // headers: {
            //     'Content-Type': 'application/json',
            // },
            data: fd
        })
        localStorage.setItem(base64_encode(cache_name_footer), base64_encode(JSON.stringify(res.data['footer'])))
        return res.data['footer']
    }
    
}

