import React, { Component } from 'react';
import { Row, Tabs } from 'antd';
import Preference from './Preference';
import User from './User';
import Domain from './Domain';
import Router from './Router';
import { base64_encode } from '../Utilities/Utilities';


const { TabPane } = Tabs;


class Dashboard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            defaultActiveKey : null
        }
    }

    componentWillMount(){
        if(localStorage.getItem(base64_encode('def_dash_pref')) !== null || localStorage.getItem(base64_encode('def_dash_pref')) !== undefined){
            this.setState({
                defaultActiveKey : localStorage.getItem(base64_encode('def_dash_pref'))
            })
        }else{
            localStorage.setItem(base64_encode('def_dash_pref'), '1')
            this.setState({
                defaultActiveKey : "1"
            })
        }
    }

    render() {
        return (
            <div style={{backgroundColor: '#ffffff !important'}}>
               <Row>
                   <div style={{borderRadius: '10px', height: 'auto', background: '#fff', width: '105%', margin: 0}}>
                        <Tabs 
                            tabPosition="top" 
                            style={{background: 'gray',height: '100%'}} 
                            defaultActiveKey={this.state.defaultActiveKey}
                        >
                            <TabPane 
                                tab={<div style={{width: 300,textAlign:'center',fontWeight: 600, color: '#fff'}}><span>Preference</span></div>}
                                key="1"
                            >
                                <Preference />
                            </TabPane>

                            <TabPane 
                                tab={<div style={{width: 300,textAlign:'center',fontWeight: 600, color: '#fff'}}><span>User</span></div>}
                                key="2"
                            >
                                <User/>
                            </TabPane>

                            <TabPane 
                                tab={<div style={{width: 300,textAlign:'center',fontWeight: 600, color: '#fff'}}><span>Domain</span></div>}
                                key="3"
                            >
                                <Domain/>
                            </TabPane>

                            <TabPane 
                                tab={<div style={{width: 300,textAlign:'center',fontWeight: 600, color: '#fff'}}><span>Router</span></div>}
                                key="4"
                            >
                                <Router/>
                            </TabPane>


                        </Tabs>



                   </div>
               </Row>
            </div>
        )
    }
}

export default Dashboard