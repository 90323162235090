import React, { Component } from 'react'
import { Tabs, Tag} from 'antd';
import {base64_encode, base64_decode} from '../Utilities/Utilities'
import Task from './Task';
import InprogressOverview from '../Overview/InprogressOverview';
import ScheduledOverview from '../Overview/ScheduledOverview';
import Bat from './Bat';


const { TabPane } = Tabs;
class Container extends Component {
    constructor(props) {
        super(props)

        this.state = {
            def_link_tab: localStorage.getItem('defaulttabcrea'),
			roles: base64_decode(localStorage.getItem(base64_encode('roles'))),
        }
    }

	componentDidMount(){


		// console.log(localStorage.getItem('defaulttabcrea'))
		// if(localStorage.getItem('defaulttabcrea') === "null" || localStorage.getItem('defaulttabcrea') === undefined){
		// 	localStorage.setItem('defaulttabcrea', '3')
		// 	this.setState({def_link_tab: '3'})
		// }
		
	}

	componentWillMount(){
		
	}

    render() {
        return (
			<Tabs defaultActiveKey={this.state.def_link_tab} style={{height: '99.8vh'}} style={{background: '#eee'}}>
				<TabPane 
					tab={<Tag color="cyan" style={{fontSize: 12}}>IN PROGRESS</Tag>}
					key="1"
				>
					<InprogressOverview/>
				</TabPane>
				{/* <TabPane 
					tab="SENT" key="2" >
				</TabPane> */}
				<TabPane 
					tab={<Tag color="lime" style={{fontSize: 12}}>SCHEDULED</Tag>}
					key="2" 
				>
					<ScheduledOverview base_id={this.props.location.state ? this.props.location.state.base_id : null}/>
				</TabPane>
				<TabPane 
					tab={<Tag color="geekblue" style={{fontSize: 12}}>DRAFT</Tag>}
					key="3" 
					style={{ height: '93vh'}}
				>
					<Task/>
				</TabPane>
				<TabPane 
					tab={<Tag color="purple" style={{fontSize: 12}}>BAT</Tag>}
					key="4" 
				>
					<Bat/>
				</TabPane>
			</Tabs>
        )
    }
}

export default Container