import React, { Component } from 'react'
import { base64_encode, base64_decode, generateKey } from '../Utilities/Utilities';
import {Redirect} from 'react-router-dom'
import {Row, Col, Avatar, Image, Input, Button, Select, Checkbox, message, Upload, Tooltip} from 'antd'
import axios from 'axios';
import { SyncOutlined } from '@ant-design/icons';
import loading from '../../images/loading.gif'
import '../../css/Dashboard.css'

const { Option }   = Select
class Preference extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            //liste
            liste_base: [],
            liste_header: [],
            liste_footer: [],
            liste_seedlist: [],
            template_footer: [],
            template_header: [],
            liste_country: [],
            user_id: base64_decode(localStorage.getItem(base64_encode('user_id'))),
            username: base64_decode(localStorage.getItem(base64_encode('username'))),
            apikey: base64_decode(localStorage.getItem(base64_encode('apikey'))),
            stats_apikey: base64_decode(localStorage.getItem(base64_encode('stats_apikey'))),
            endpoint: base64_decode(localStorage.getItem(base64_encode('endpoint'))),
            email: base64_decode(localStorage.getItem(base64_encode('email'))),
            roles: base64_decode(localStorage.getItem(base64_encode('roles'))),
            isLogin: true,
            //preference user 
            selected_image: null,
            //loading
            loadingUpdateUserInfo: false,
            displayLoadingIconDatabase: 'block',
            displayLoadingImageDatabase: 'none',
            loadingUpdateUserBase: false,
            //base 
            base_id: null,
            base_name: null,
            base_pix: null,
            base_link: null,
            base_img: null,
            base_cutting: null,
            base_compress: null,
            base_comment: null,
            base_stats: null,
            base_router: null,
            base_service: null,
            base_login: null,
            base_mdp: null,
            base_replyname: null,
            base_replyemail: null,
            base_sender: null,
            base_fromemail: null,
            base_header: null,
            base_footer: null,
            base_seedlist: null,
            base_country: null,
            base_currency: null,
            base_header_konticrea: 0,
            base_footer_konticrea: 0,


        }
    }

    componentDidMount(){
        localStorage.setItem(base64_encode('def_dash_pref'), "1")
        this.getListBaseUser()
        this.getListCountry()
    }

    getListBaseUser(){
		try {
			if(localStorage.getItem(base64_encode('base_user')) !== undefined && localStorage.getItem(base64_encode('base_user')) !== null){
                this.setState({
                    liste_base: JSON.parse(base64_decode(localStorage.getItem(base64_encode('base_user'))))
                })
			}else{
				var fd = new FormData() 
				fd.append('user_id', this.state.user_id)
				fd.append('apikey', this.state.apikey)
				axios.post(process.env.REACT_APP_ENDPOINT_USER_BASE, fd)
				.then(res => {
                    let field = 'basename'
                    var base_user = (res.data).sort((a, b) => (a[field] || "").toString().localeCompare((b[field] || "").toString()));
					localStorage.setItem(base64_encode('base_user'), base64_encode(JSON.stringify(res.data)))
					this.setState({liste_base: base_user})
				})
			}
		}
		catch (err) {
			console.error('eror getting user base ' + err);
		}
    }

    getListHeaderRouter(base_id=null, basename=null){
        if(basename === null || basename === undefined){
            basename = this.state.base_name
        }
        if(base_id === null || base_id === undefined){
            base_id = this.state.base_id
        }

        try {
            var name = 'Header_'+basename
            if(localStorage.getItem(base64_encode(name)) !== null){
                var localData = base64_decode(localStorage.getItem(base64_encode(name)))
                console.log(base64_decode(localStorage.getItem(base64_encode(name))))
                if(localData === '[{"Name":"no data","id":0}]'){
                    this.setState({
                        liste_header: JSON.parse(base64_decode(localStorage.getItem(base64_encode(name))))
                    })
                }else{
                    if(localData.includes("auth")){
                        console.log('two')
                        this.setState({
                            liste_header: JSON.parse('[{"Name":"no data","id":0}]')
                        })
                    }else{
                        if(localData === 'error decoding data'){
                        console.log('three')

                            this.setState({
                                liste_header: JSON.parse('[{"Name":"no data","id":0}]')
                            })
                        }else{
                        console.log('four')

                            this.setState({
                                liste_header:  JSON.parse(base64_decode(localStorage.getItem(base64_encode(name))))
                            })
                        }
                    }
                }
            }
            else{
                var fd = new FormData() 
                fd.append('user_id', this.state.user_id)
                fd.append('apikey', this.state.apikey)
                fd.append('stats_apikey', this.state.stats_apikey)
                fd.append('base_id', base_id)
                axios.post(process.env.REACT_APP_ENDPOINT_STATS_HEADER, fd)
                .then(res => {
                    localStorage.setItem(base64_encode(name), base64_encode(JSON.stringify(res.data)))
                    if('auth' in res.data){
                        this.setState({
                            liste_header: JSON.parse('[{"Name":"no data","id":0}]')
                        })
                    }else{
                        this.setState({
                            liste_header: res.data
                        })
                    }
                })
            }
        }
        catch (err) {
            alert('error geting list header => ' + err);
            return null
        }
    }

    getListCountry(){
        try {
            if(localStorage.getItem(base64_encode('Stats_country_and_currency')) !== undefined && localStorage.getItem(base64_encode('Stats_country_and_currency')) !== null){
                this.setState({
                    liste_country: JSON.parse(base64_decode(localStorage.getItem(base64_encode('Stats_country_and_currency'))))
                })
            }else{
                var fd = new FormData() 
                fd.append('user_id', this.state.user_id)
                fd.append('apikey', this.state.apikey)
                fd.append('stats_apikey', this.state.stats_apikey)
                axios.post(process.env.REACT_APP_ENDPOINT_STATS_COUNTRY_CURRENCY, fd)
                .then(res => {
                    localStorage.setItem(base64_encode('Stats_country_and_currency'), base64_encode(JSON.stringify(res.data)))
                    this.setState({
                        liste_country: res.data
                    })
                })
            }
        }
        catch (err) {
            console.error('error getting list country and currency ' + err);
            return null
        }
    }

    getListFooterRouter(base_id=null, basename=null){
        if(basename === null || basename === undefined){
            basename = this.state.base_name
        }
        if(base_id === null || base_id === undefined){
            base_id = this.state.base_id
        }
        try {
            var name = 'Footer_'+basename
            var localData = base64_decode(localStorage.getItem(base64_encode(name)))
            if(localStorage.getItem(base64_encode(name)) !== null ){
                if(localData === '[{"Name":"no data","id":0}]'){
                    this.setState({
                        liste_footer: JSON.parse(base64_decode(localStorage.getItem(base64_encode(name))))
                    })
                }else{
                    if(localData.includes("auth")){
                        this.setState({
                            liste_footer: JSON.parse('[{"Name":"no data","id":0}]')
                        })
                    }else{
                        if(localData === 'error decoding data'){
                            this.setState({
                                liste_footer: JSON.parse('[{"Name":"no data","id":0}]')
                            })
                        }else{
                            this.setState({
                                liste_footer:  JSON.parse(base64_decode(localStorage.getItem(base64_encode(name))))
                            })
                        }
                    }
                }
                
            }else{
                var fd = new FormData() 
                fd.append('user_id', this.state.user_id)
                fd.append('apikey', this.state.apikey)
                fd.append('stats_apikey', this.state.stats_apikey)
                fd.append('base_id', base_id)
                axios.post(process.env.REACT_APP_ENDPOINT_STATS_FOOTER, fd)
                .then(res => {
                   localStorage.setItem(base64_encode(name), base64_encode(JSON.stringify(res.data)))
                    if('auth' in res.data){
                        this.setState({
                            liste_footer: JSON.parse('[{"Name":"no data","id":0}]')
                        })
                    }else{
                        this.setState({
                            liste_footer: res.data
                        })
                    }
                })
            }
        }
        catch (err) {
            console.error('error geting list header => ' + err);
            return null
        }
    }

    getListSeedlist(base_id=null, basename=null){
        if(basename === null || basename === undefined){
            basename = this.state.base_name
        }
        if(base_id === null || base_id === undefined){
            base_id = this.state.base_id
        }

        try {
            var name = 'Seedlist_'+basename
            if(localStorage.getItem(base64_encode(name)) !== null ){
                var localData = base64_decode(localStorage.getItem(base64_encode(name)))
                if(localData === '[{"Name":"no data","id":0}]'){
                    this.setState({
                        liste_seedlist: JSON.parse(base64_decode(localStorage.getItem(base64_encode(name))))
                    })
                }else{
                    if(localData.includes("auth")){
                        this.setState({
                            liste_seedlist: JSON.parse('[{"Name":"no data","id":0}]')
                        })
                    }else{
                        if(localData === 'error decoding data'){
                            this.setState({
                                liste_seedlist: JSON.parse('[{"Name":"no data","id":0}]')
                            })
                        }else{
                            this.setState({
                                liste_seedlist:  JSON.parse(base64_decode(localStorage.getItem(base64_encode(name))))
                            })
                        }
                    }
                }
            }else{
                var fd = new FormData() 
                fd.append('user_id', this.state.user_id)
                fd.append('apikey', this.state.apikey)
                fd.append('stats_apikey', this.state.stats_apikey)
                fd.append('base_id', base_id)
                axios.post(process.env.REACT_APP_ENDPOINT_STATS_SEEDLIST, fd)
                .then(res => {
                    localStorage.setItem(base64_encode(name), base64_encode(JSON.stringify(res.data)))
                    this.setState({
                        liste_seedlist: res.data
                    })
                    // if('seedlists' in res.data){
                    //     // localStorage.setItem(base64_encode(name), base64_encode(JSON.stringify(res.data['seedlists'])))
                    //     this.setState({
                    //         liste_seedlist: res.data['seedlists']
                    //     })
                    // }else{
                    //     this.setState({
                    //         liste_seedlist: JSON.parse('[{"Name":"no data","id":0}]')
                    //     })
                    // }
                })
            }
        }
        catch (err) {
            console.error('error geting list header => ' + err);
            this.setState({
                liste_seedlist:[],
            })
            return null
        }
    }

    getListTemplateKonticrea(base_id){
        var url =  this.state.endpoint + 'api/creativities/template/list'
        var fd = new FormData()
        fd.append('user_id', this.state.user_id)
        fd.append('apikey', this.state.apikey)
        fd.append('base_id', base_id)

        axios.post(url, fd)
        .then(res => {
            this.setState({
                template_header: res.data['header'],
                template_footer: res.data['footer']
            })
        })
    }

    onFileChanged(e){
        if( e.target.files[0] === undefined){
            message.warning('error on uploading the images, please try again')
            return null
        }else{
            this.setState({selected_image: e.target.files[0]})
        }
    }

    onUpdateUserInfo(){
        this.setState({loadingUpdateUserInfo : true})
        if(this.state.username === '' || this.state.email === ''){
            message.error('all mandatory field is required', 3)
            return
        }else{
                var fd = new FormData()
                fd.append('user_id', this.state.user_id)
                fd.append('apikey' , this.state.apikey)
                fd.append('user_email', this.state.email)
                fd.append('username', this.state.username)
                if(this.state.selected_image !== null){
                    fd.append('image', this.state.selected_image)
                }
                axios.post(process.env.REACT_APP_ENDPOINT_USER_INFO_UPDATE, fd)
                .then(res => {
                    if(res.data['status'] === "ok"){
                        localStorage.clear()
                        setTimeout(() => {
                            this.setState({
                                isLogin: false,
                                loadingUpdateUserInfo : false
                            })
                        }, 1000)
                    }
                })
        }
    }

    onreloadListdatabase(){
        this.setState({
            displayLoadingIconDatabase: 'none',
            displayLoadingImageDatabase: 'block'
        })
        localStorage.removeItem(base64_encode('base_user'))
        this.getListBaseUser()
        setTimeout(() => {
            this.setState({
                displayLoadingIconDatabase: 'block',
                displayLoadingImageDatabase: 'none',
                base_id: null,
                base_name: null,
                base_pix: null,
                base_link: null,
                base_img: null,
                base_cutting: null,
                base_compress: null,
                base_comment: null,
                base_stats: null,
                base_router: null,
                base_service: null,
                base_login: null,
                base_mdp: null,
                base_replyname: null,
                base_replyemail: null,
                base_sender: null,
                base_fromemail: null,
                base_header: null,
                base_footer: null,
                base_seedlist: null,
                base_country: null,
                base_currency: null,
                base_header_konticrea: null,
                base_footer_konticrea: null,
            })
        }, 2000)
    }

    onBaseChange(value){
        this.state.liste_base.map(item => {
            if(parseInt(value) === parseInt(item['id'])){
                console.log(item)
                try {
                    this.getListHeaderRouter(value,item['basename'])
                } catch (error) {
                    console.log(error)
                }

                try {
                    this.getListFooterRouter(value,item['basename'])
                } catch (error) {
                    console.log(error)
                }

                try {
                    this.getListSeedlist(value,item['basename'])
                } catch (error) {
                    console.log(error)
                }
                try {
                    this.getListTemplateKonticrea(item['id'])
                } catch (error) {
                    console.log(error)
                }
                this.setState({
                    //base optimization
                    base_id: value,
                    base_name: item['basename'],
                    base_pix: item['defaultpix'] === null || item['defaultpix'] === 'null' || item['defaultpix'] === 0 || item['defaultpix'] === '0' ? false : true,
                    base_link: item['defaultlink'] === null || item['defaultlink'] === 'null' || item['defaultlink'] === 0 || item['defaultlink'] === '0' ? false : true,
                    base_img: item['defaultimg'] === null || item['defaultimg'] === 'null' || item['defaultimg'] === 0 || item['defaultimg'] === '0'  ? false : true,
                    base_cutting: item['defaultcutting'] === null || item['defaultcutting'] === 'null' || item['defaultcutting'] === 0 || item['defaultcutting'] === '0' ? false : true,
                    base_comment: item['defaultcomment'] === null || item['defaultcomment'] === 'null' || item['defaultcomment'] === 0  || item['defaultcomment'] === '0' ? false : true,
                    base_router: item['router_checked'] === '0' || item['router_checked'] === 0 || item['router_checked'] === null  ? false : true,
                    base_stats: item['stats_checked'] === '0' || item['stats_checked'] === 0 || item['stats_checked'] === null  ? false : true,
                    base_header_konticrea: item['defaultHeaderKonticrea'] === null || item['defaultHeaderKonticrea'] === 'null'  ? 0 : item['defaultHeaderKonticrea'],
                    base_footer_konticrea: item['defaultFooterKonticrea'] === null || item['defaultFooterKonticrea'] === 'null'  ? 0 : item['defaultFooterKonticrea'],
                    //base_router
                    base_service: item['router_url'] === null || item['router_url'] === 'null' ? null : item['router_url'],
                    base_login: item['login'] === null || item['login'] === 'null' ? null : item['login'],
                    base_mdp: item['mdp'] === null || item['mdp'] === base64_encode('null') ? null : base64_decode(item['mdp']),
                    base_login: item['login'] === null || item['login'] === 'null' ? null : item['login'],
                    base_sender: item['defaultSender'] === null || item['defaultSender'] === 'null' ? null : item['defaultSender'],
                    base_replyname: item['defaultReplyName'] === null || item['defaultReplyName'] === 'null' ? null : item['defaultReplyName'],
                    base_sender: item['defaultSender'] === null || item['defaultSender'] === 'null' ? null : item['defaultSender'],
                    base_fromemail: item['defaultFromEmail'] === null || item['defaultFromEmail'] === 'null' ? null : item['defaultFromEmail'],
                    base_replyemail: item['defaultReplyEmail'] === null || item['defaultReplyEmail'] === 'null' ? null : item['defaultReplyEmail'],
                    base_header: item['defaultHeaderId'] === null || item['defaultHeaderId'] === 'null' ? null : parseInt(item['defaultHeaderId']),
                    base_footer: item['defaultFooterId'] === null || item['defaultFooterId'] === 'null' ? null : parseInt(item['defaultFooterId']),
                    base_seedlist: item['defaultSeedId'] === null || item['defaultSeedId'] === 'null' ? [] : (item['defaultSeedId']).split(','),
                    base_country: item['country'] === null || item['country'] === 'null' || item['country'] === 0 || item['country'] === '0' ? null : parseInt(item['country']),
                    base_currency: item['currency'] === null || item['currency'] === 'null' || item['currency'] === '0' || item['currency'] === 0 ? null : parseInt(item['currency']),
                    
                })
            }
        })
      
    }

    onUpdateUserBase(){
        this.setState({
            loadingUpdateUserBase : true
        })
        var fd = new FormData()
        fd.append('user_id', this.state.user_id )
        fd.append('base_id', this.state.base_id)
        fd.append('apikey', this.state.apikey )
        fd.append('router_url' , this.state.base_service)
        fd.append('router_login' , this.state.base_login)
        fd.append('router_mdp' , this.state.base_mdp)
        fd.append('router_sender' , this.state.base_sender)
        fd.append('router_reply_name' , this.state.base_replyname)
        fd.append('router_from_email' , this.state.base_fromemail)
        fd.append('router_reply_email' , this.state.base_replyemail)
        fd.append('router_header' , this.state.base_header)
        fd.append('router_footer' , this.state.base_footer)
        fd.append('router_seedlist' , this.state.base_seedlist)
        fd.append('optimization_pix' , this.state.base_pix)
        fd.append('optimization_img' , this.state.base_img)
        fd.append('optimization_link' , this.state.base_link)
        fd.append('optimization_comment' , this.state.base_comment)
        fd.append('optimization_stats' , this.state.base_stats)
        fd.append('optimization_cutting' , this.state.base_cutting)
        fd.append('optimization_router' , this.state.base_router)
        fd.append('konticrea_header' , this.state.base_header_konticrea)
        fd.append('konticrea_footer' , this.state.base_footer_konticrea)
        fd.append('country' , this.state.base_country)
        fd.append('currency' , this.state.base_currency)
        axios.post(process.env.REACT_APP_ENDPOINT_USER_BASE_PREFERENCE, fd)
        .then(res => {
            localStorage.removeItem(base64_encode('base_user'))
            setTimeout( () => {
                this.setState({
                    loadingUpdateUserBase : false
                })
                window.location.reload()
            }, 2000)
        })
    }
    
    render() {
        if(this.state.isLogin === false){
            return (<Redirect to={{
                pathname: '/'
            }} />)
        }

        return (
            <div style={{backgroundColor: '#fff',  marginTop: '0px', height: '92.8vh', overflowY: 'scroll'}}>
                <Row>
                    <Col className="back" span={6} style={{height: '93vh'}}>
                        <Row>
                            <Col span={24}>
                                <div style={{textAlign: 'center'}}>
                                    <Avatar
                                        style={{width: 200, height: 220}}
                                        src={
                                            <Image  src={`data:image/jpeg;base64,${localStorage.getItem(base64_encode('avatar'))}`} 
                                            />
                                           
                                        }
                                    />
                                </div>
                            </Col>
                        </Row>
                        <br/>
                        <Row style={{textAlign: 'center', margin: '0 auto', width: '100%'}}>
                            <Col span={24}>
                                <Row>
                                    <Col span={4} style={{padding: 5, color: '#fff'}}>
                                        <span>Username</span>
                                    </Col>
                                    <Col span={18}>
                                        <Input 
                                            type="text"
                                            size="medium"
                                            style={{border: '1px solid gray', color: '#192a56',fontWeight: 700, textAlign: 'center'}}
                                            value={this.state.username}
                                            onChange={(e) => {
                                                this.setState({username: e.target.value})
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <br/>
                        <Row style={{textAlign: 'center', margin: '0 auto', width: '100%'}}>
                            <Col span={24}>
                                <Row>
                                    <Col span={4} style={{padding: 5, color: '#fff'}}>
                                        <span>Email</span>
                                    </Col>
                                    <Col span={18}>
                                        <Input 
                                            type="text"
                                            size="medium"
                                            style={{border: '1px solid gray', color: '#192a56',fontWeight: 700, textAlign: 'center'}}
                                            value={this.state.email}
                                            onChange={(e) => {
                                                this.setState({email: e.target.value})
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <br/>
                        <Row style={{textAlign: 'center', margin: '0 auto', width: '100%'}}>
                            <Col span={24}>
                                <Row>
                                    <Col span={4} style={{padding: 5, color: '#fff'}}>
                                        <span>Apikey</span>
                                    </Col>
                                    <Col span={18}>
                                        <Input
                                            disabled 
                                            type="text"
                                            size="medium"
                                            style={{border: '1px solid gray', color: '#192a56',fontWeight: 700, textAlign: 'center'}}
                                            value={this.state.apikey}
                                            onChange={(e) => {
                                                this.setState({apikey: e.target.value})
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <br/>
                        <Row style={{textAlign: 'center', margin: '0 auto', width: '100%'}}>
                            <Col span={24}>
                                <Row>
                                    <Col span={4} style={{padding: 5, color: '#fff'}}>
                                        <span>Role</span>
                                    </Col>
                                    <Col span={18}>
                                        <Input 
                                            disabled
                                            type="text"
                                            size="medium"
                                            style={{border: '1px solid gray', color: '#192a56',fontWeight: 700, textAlign: 'center'}}
                                            value={this.state.roles}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <br/>
                        <Row style={{textAlign: 'center', margin: '0 auto', width: '100%'}}>
                            <Col span={24}>
                                <Row>
                                    <Col span={4} style={{padding: 5, color: '#fff'}}>
                                        <span>Avatar</span>
                                    </Col>
                                    <Col span={14}>
                                        <input 
                                            type="file" 
                                            accept="*" 
                                            onChange={(e) => {
                                                this.onFileChanged(e)
                                            }} 
                                            style={{width: '100%'}}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row style={{textAlign: 'center', margin: '0 auto', width: '100%'}}>
                            <Col span={24}>
                                <div>
                                    <Button 
                                        loading={this.state.loadingUpdateUserInfo}
                                        className="btn btn-success"
                                        style={{marginTop: 38, width: '60%', fontSize: 16}}
                                        onClick={this.onUpdateUserInfo.bind(this)}
                                    >
                                        UPDATE
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Col>

                    <Col span={18}>
                        <Row>
                            <Col span={3} style={{textAlign: 'center', padding: 5}}>
                                Select database : 
                            </Col>
                            <Col span={7}>
                                <Select
                                    value={this.state.base_id}
                                    size="medium"
                                    showSearch
                                    optionFilterProp="children"
                                    style={{width: '100%', padding: 5, color: '#192a56',fontWeight: 700, textAlign: 'center' }}
                                    onChange={(value) => {
                                        this.onBaseChange(value)
                                    }}
                                >
                                    {
                                        this.state.liste_base && this.state.liste_base.map((item) => {
                                            var key = generateKey()
                                            return (
                                                <Option key={key} value={item.id}>{item.basename}</Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Col>
                            <Col span={1} style={{padding: 5}}>
                                <Tooltip title="Refresh" placement="bottom">
                                    <div style={{ display: `${this.state.displayLoadingIconDatabase}` }}>
                                        <SyncOutlined
                                            style={{ fontSize: '18px', color: '#23bcbe' }}
                                            onClick={
                                                this.onreloadListdatabase.bind(this)
                                            }
                                        />
                                    </div >

                                    <div style={{ display: `${this.state.displayLoadingImageDatabase}` }}>
                                        <img
                                            src={loading}
                                            style={{ width: 25, height: 25 }}
                                        />
                                    </div>
                                </Tooltip>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col span={12}>
                                <div style={{textAlign: 'center', width: '100%' ,backgroundColor: '#00769E'}}>
                                    <span style={{fontWeight: 700, textDecoration: 'underline', fontSize: 16, color: '#fff'}}>OPTIMIZATION PROCESS</span>
                                </div>
                                <br/>
                                <div style={{ width: '100%'}}>
                                    <table className="table table-bordered" style={{fontSize: 13}}>
                                        <thead>
                                            <tr>
                                                <th style={{width: 30}}>Object</th>
                                                <th>Checked</th>
                                                <th>Unchecked</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                                <tr>
                                                    <td>Pixels</td>
                                                    <td>Removing all pixels inside the html code</td>
                                                    <td>convert pixels image to dynamic image</td>
                                                    <td>
                                                        <Checkbox
                                                            checked={this.state.base_pix}
                                                            onChange={ (e) => {
                                                                if (e.target.checked) {
                                                                    this.setState({
                                                                        base_pix : true
                                                                    })
                                                                } else {
                                                                    this.setState({
                                                                        base_pix : false
                                                                    })
                                                                }
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Link</td>
                                                    <td>Tracking all hypertext link inside the HTML file</td>
                                                    <td>Leave all link untracked</td>
                                                    <td>
                                                        <Checkbox
                                                            checked={this.state.base_link}
                                                            onChange={ (e) => {
                                                                if (e.target.checked) {
                                                                    this.setState({
                                                                        base_link : true
                                                                    })
                                                                } else {
                                                                    this.setState({
                                                                        base_link : false
                                                                    })
                                                                }
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Image</td>
                                                    <td>Hosted all images on the server</td>
                                                    <td>Just making a redirection to the image url</td>
                                                    <td>
                                                        <Checkbox
                                                            checked={this.state.base_img}
                                                            onChange={ (e) => {
                                                                if (e.target.checked) {
                                                                    this.setState({
                                                                        base_img : true
                                                                    })
                                                                } else {
                                                                    this.setState({
                                                                        base_img : false
                                                                    })
                                                                }
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Cutting</td>
                                                    <td>Cut image inside the creativities</td>
                                                    <td>Just uploading all image inside creativities</td>
                                                    <td>
                                                        <Checkbox
                                                            checked={this.state.base_cutting}
                                                            onChange={ (e) => {
                                                                if (e.target.checked) {
                                                                    this.setState({
                                                                        base_cutting : true
                                                                    })
                                                                } else {
                                                                    this.setState({
                                                                        base_cutting : false
                                                                    })
                                                                }
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Comment</td>
                                                    <td>Removing all html and css comment inside the html</td>
                                                    <td>Don't remove anythings</td>
                                                    <td>
                                                        <Checkbox
                                                            checked={this.state.base_comment}
                                                            onChange={ (e) => {
                                                                if (e.target.checked) {
                                                                    this.setState({
                                                                        base_comment : true
                                                                    })
                                                                } else {
                                                                    this.setState({
                                                                        base_comment : false
                                                                    })
                                                                }
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Router</td>
                                                    <td>Creating draft directly at the router</td>
                                                    <td>Don't create  anythings on the router</td>
                                                    <td>
                                                        <Checkbox
                                                            checked={this.state.base_router}
                                                            onChange={ (e) => {
                                                                if (e.target.checked) {
                                                                    this.setState({
                                                                        base_router : true
                                                                    })
                                                                } else {
                                                                    this.setState({
                                                                        base_router : false
                                                                    })
                                                                }
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Stats</td>
                                                    <td>Sending directly campagn info to Stats (Router must be checked to be work)</td>
                                                    <td>Don't create  anythings on Stats</td>
                                                    <td>
                                                        <Checkbox
                                                            checked={this.state.base_stats}
                                                            onChange={ (e) => {
                                                                if (e.target.checked) {
                                                                    this.setState({
                                                                        base_stats : true
                                                                    })
                                                                } else {
                                                                    this.setState({
                                                                        base_stats : false
                                                                    })
                                                                }
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Header</td>
                                                    <td>
                                                        <Select
                                                            size="medium"
                                                            showSearch
                                                            optionFilterProp="children"
                                                            style={{ width: '100%', color: '#192a56', fontWeight: 700, textAlign: 'center' }}
                                                            value={this.state.base_header_konticrea}
                                                            onChange={
                                                                (value) => {
                                                                    this.setState({
                                                                        base_header_konticrea: value
                                                                    })
                                                                }
                                                            }
                                                        >
                                                            <Option key={"123"} value={"0"}>don't add header</Option>
                                                            {
                                                            this.state.template_header !== null && this.state.template_header.map(item =>{
                                                                console.log(item)
                                                                var key = generateKey()
                                                                return(
                                                                    <Option key={key} value={item['headerid']}>{item['name']}</Option>
                                                                )
                                                            }) 
                                                            }

                                                        </Select>
                                                    </td>
                                                    <td>Default Konticrea header for this database</td>
                                                </tr>
                                                <tr>
                                                    <td>Footer</td>
                                                    <td>
                                                        <Select
                                                            size="medium"
                                                            showSearch
                                                            optionFilterProp="children"
                                                            style={{ width: '100%', color: '#192a56', fontWeight: 700, textAlign: 'center' }}
                                                            value={this.state.base_footer_konticrea}
                                                            onChange={
                                                                (value) => {
                                                                    this.setState({
                                                                        base_footer_konticrea: value
                                                                    })
                                                                }
                                                            }
                                                        >
                                                            <Option key={"123"} value={"0"}>don't add footer</Option>
                                                            {
                                                            this.state.template_footer !== null && this.state.template_footer.map(item =>{
                                                                var key = generateKey()
                                                                return(
                                                                    <Option key={key} value={item['footerid']}>{item['name']}</Option>
                                                                )
                                                            }) 
                                                            }

                                                        </Select>
                                                    </td>
                                                    <td>Default Konticrea footer for this database</td>
                                                </tr>
                                        </tbody>
                                        </table>
                                    </div>
                            </Col>
                            <Col span={12}>
                                <div style={{textAlign: 'center', width: '100%' ,backgroundColor: '#00769E',}}>
                                    <span style={{fontWeight: 700, textDecoration: 'underline', fontSize: 16, fontFamily: 'Lato', color: '#fff'}}>ROUTER DATA</span>
                                </div>
                                <br/>
                                <Row>
                                    <Col span={6} style={{padding: 3, textAlign: 'center', fontSize: 14}}>
                                        <p>Service</p>
                                    </Col>

                                    <Col span={14}>
                                        <Select
                                            showSearch
                                            style={{color: '#192a56',fontWeight: 700, fontSize: 14, width: '100%'}}
                                            value={this.state.base_service}
                                            onChange={(value) => this.setState({base_service: value})}
                                        >
                                            <Option key="1" value="https://service2.esv2.com">Service 2</Option>
                                            <Option key="2" value="https://service3.esv2.com">Service 3</Option>
                                            <Option key="3" value="https://service4.esv2.com">Service 4</Option>
                                            <Option key="4" value="https://service5.esv2.com">Service 5</Option>
                                            <Option key="5" value="https://service6.esv2.com">Service 6</Option>
                                            <Option key="6" value="https://service7.esv2.com">Service 7</Option>
                                            <Option key="6" value="https://service8.esv2.com">Service 8</Option>
                                        </Select> 
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={6} style={{padding: 3, textAlign: 'center', fontSize: 14}}>
                                        <p>Login</p>
                                    </Col>

                                    <Col span={14}>
                                        <Input 
                                            type="text"
                                            size="medium"
                                            value={this.state.base_login}
                                            onChange={(e) => { this.setState({base_login : e.target.value})}}
                                            style={{color: '#192a56',fontWeight: 700, fontSize: 14}}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={6} style={{padding: 3, textAlign: 'center', fontSize: 14}}>
                                        <p>Password</p>
                                    </Col>

                                    <Col span={14}>
                                        <Input.Password 
                                            type="text"
                                            size="medium"
                                            value={this.state.base_mdp}
                                            onChange={(e) => { this.setState({base_mdp : e.target.value})}}
                                            style={{color: '#192a56',fontWeight: 700, fontSize: 14}}
                                        />
                                    </Col>
                                </Row>
                                
                                <Row>
                                    <Col span={6} style={{padding: 3, textAlign: 'center', fontSize: 14}}>
                                        <p>Sender</p>
                                    </Col>

                                    <Col span={14}>
                                        <Input 
                                            type="text"
                                            size="medium"
                                            value={this.state.base_sender}
                                            onChange={(e) => { this.setState({base_sender : e.target.value})}}
                                            style={{color: '#192a56',fontWeight: 700, fontSize: 14}}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={6} style={{padding: 3, textAlign: 'center', fontSize: 14}}>
                                        <p>ReplyTo</p>
                                    </Col>

                                    <Col span={14}>
                                        <Input 
                                            type="text"
                                            size="medium"
                                            value={this.state.base_replyname}
                                            onChange={(e) => { this.setState({base_replyname : e.target.value})}}
                                            style={{color: '#192a56',fontWeight: 700, fontSize: 14}}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={6} style={{padding: 3, textAlign: 'center', fontSize: 14}}>
                                        <p>FromEmail</p>
                                    </Col>

                                    <Col span={14}>
                                        <Input 
                                            type="text"
                                            size="medium"
                                            value={this.state.base_fromemail}
                                            onChange={(e) => { this.setState({base_fromemail : e.target.value})}}
                                            style={{color: '#192a56',fontWeight: 700, fontSize: 14}}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={6} style={{padding: 3, textAlign: 'center', fontSize: 14}}>
                                        <p>ReplyEmail</p>
                                    </Col>

                                    <Col span={14}>
                                        <Input 
                                            type="text"
                                            size="medium"
                                            value={this.state.base_replyemail}
                                            onChange={(e) => { this.setState({base_replyemail : e.target.value})}}
                                            style={{color: '#192a56',fontWeight: 700, fontSize: 14}}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={6} style={{padding: 3, textAlign: 'center', fontSize: 14}}>
                                        <p>DefaultHeader</p>
                                    </Col>

                                    <Col span={14}>
                                        <Select 
                                            showSearch={true}
                                            size="medium"
                                            value={this.state.base_header}
                                            onChange={(value) => {
                                                this.setState({base_header: value})
                                            }}
                                            style={{width: '100%', color: '#192a56',fontWeight: 700, fontSize: 14, textAlign:'center'}}
                                        >
                                            <Option key={"123"} value={0}>don't add default header</Option>
                                            {
                                                this.state.liste_header !== [] && this.state.liste_header.map(item => {
                                                    var key = generateKey()
                                                    return (
                                                        <Option key={key} value={parseInt(item['id'])}>{item['id']+'-'+item['Name']}</Option>
                                                    )
                                                })
                                            }                                   
                                        </Select>
                                    </Col>
                                    
                                </Row>
                                <Row>
                                    <Col span={6} style={{padding: 3, textAlign: 'center', fontSize: 14}}>
                                        <p>DefaultFooter</p>
                                    </Col>

                                    <Col span={14}>
                                        <Select 
                                            showSearch={true}
                                            size="medium"
                                            value={this.state.base_footer}
                                            style={{width: '100%', color: '#192a56',fontWeight: 700, fontSize: 14, textAlign: 'center'}}
                                            onChange={(value) => {
                                                this.setState({base_footer: value})
                                            }}
                                        >
                                             <Option key={"123"} value={0}>don't add default footer</Option>
                                            {
                                                this.state.liste_footer !== [] && this.state.liste_footer.map(item => {
                                                    var key = generateKey()
                                                    return (
                                                        <Option key={key} value={parseInt(item['id'])}>{item['id']+'-'+item['Name']}</Option>
                                                    )
                                                })
                                            } 
                                                
                                        </Select>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={6} style={{padding: 3, textAlign: 'center', fontSize: 14}}>
                                        <p>DefaultSeedList</p>
                                    </Col>

                                    <Col span={14}>
                                        <Select 
                                            optionFilterProp="children"
                                            mode="multiple"
                                            showSearch={true}
                                            size="medium"
                                            value={this.state.base_seedlist}
                                            defaultValue={this.state.base_seedlist}
                                            style={{width: '100%', color: '#192a56',fontWeight: 700, fontSize: 14}}
                                            onChange={(value) => {
                                                this.setState({base_seedlist: value})
                                            }}
                                        >
                                            {
                                                this.state.liste_seedlist  && this.state.liste_seedlist.map(item => {
                                                    var key = generateKey()
                                                    return(
                                                        <Option key={key} value={item.id}>{item.id+'-'+(item.Name).replace("]","")}</Option>
                                                    )
                                                })
                                            }

                                        </Select>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={6} style={{padding: 3, textAlign: 'center', fontSize: 14}}>
                                        <p>Country</p>
                                    </Col>

                                    <Col span={14}>
                                        <Select 
                                            optionFilterProp="children"
                                            showSearch={true}
                                            size="medium"
                                            value={this.state.base_country}
                                            defaultValue={this.state.base_country}
                                            style={{width: '100%', color: '#192a56',fontWeight: 700, fontSize: 14}}
                                            onChange={(value) => {
                                                this.setState({base_country: value})
                                            }}
                                        >
                                            {
                                                this.state.liste_country.map(item => {
                                                    var key = generateKey()
                                                    return (
                                                        <Option key={key} value={item.id}>{item.countrycode}</Option>
                                                    )
                                                })
                                            }

                                        </Select>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={6} style={{padding: 3, textAlign: 'center', fontSize: 14}}>
                                        <p>Currency</p>
                                    </Col>

                                    <Col span={14}>
                                        <Select 
                                            optionFilterProp="children"
                                            showSearch={true}
                                            size="medium"
                                            value={this.state.base_currency}
                                            defaultValue={this.state.base_currency}
                                            style={{width: '100%', color: '#192a56',fontWeight: 700, fontSize: 14}}
                                            onChange={(value) => {
                                                this.setState({base_currency: value})
                                            }}
                                        >
                                            {
                                                this.state.liste_country.map(item => {
                                                    var key = generateKey()
                                                    return (
                                                        <Option key={key} value={item.currencyid}>{item.currencyname}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Col>
                                </Row>
                                <Row>
                                    <div style={{width: '60%', margin: '0 auto'}}>
                                        <Button 
                                            loading={this.state.loadingUpdateUserBase}
                                            className="btn btn-success"
                                            style={{marginTop: 10, width: '60%', height: 50 ,marginLeft: 75, fontSize: 16}}
                                            onClick={this.onUpdateUserBase.bind(this)}
                                        >
                                            UPDATE
                                        </Button>
                                    </div>
                                    
                                </Row>
                            </Col> 
                        </Row>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default Preference